import { Backdrop, CircularProgress } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useEffect } from 'react';
import { getBody, lockscreen, unlockScreen } from '../../../../Utility/Loading';
import FpText from '../FpText/FpText';

type Props = {
  loading: boolean;
  variant: Variant;
  text?: string;
  insideComponent?: boolean;
};

const FpLoader = ({ insideComponent = false, loading, text, variant }: Props): JSX.Element => {
  const loadingText = !text ? 'Fetching data' : text;

  useEffect(() => {
    if (loading) {
      lockscreen(getBody());
    } else {
      unlockScreen(getBody());
    }
  }, [loading]);

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: insideComponent ? 'absolute' : 'fixed',
      }}
      open={loading}
    >
      <CircularProgress color="inherit" />
      <FpText variant={variant}>{loadingText}</FpText>
    </Backdrop>
  );
};

export default FpLoader;
