import { modalTitleAddEdit } from '@flowplan/flowplan-shared';
import { HealthState } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { useSnackbar, VariantType } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { ISensorInput } from '../../../../common/interfacesInput';
import AddItemModal from '../../../../Components/Modals/AddItemModal';
import { BeamHealthStatus, getBeamHealthStatus, NbConfig } from '../../../../hooks/BeamHealthStatus';
import { defaultHealthStatus } from '../../../../Models/graphSettings';
import { useStoreActions, useStoreState } from '../../../../Store';
import HealthWarning, { HealthWarningInfo } from '../../../inventory/components/HealthWarning/HealthWarning';
import { getUsageForEventsData } from '../../../stock-settings/types/stock-settings-utils';
import { validateInputDevice } from '../../services/newInstallation-validation';
import { IStockSettingSelection } from './StockSettings';
import NewInstallationUpdate from './Update';
import { Paths } from '../../../navigation/containers/Navigation/navigation-types';

type ShowFeedbackProps = {
  success: boolean;
  message: string;
};

const NewInstallationModal = (): JSX.Element => {
  const shouldRetrieveDetailsData = useStoreState((actions) => actions.newInstallationModel.shouldRetrieveDetailsData);
  const isUpdate = useStoreState((state) => state.newInstallationModel.isUpdate);
  const showSensorDetailsModal = useStoreState((state) => state.newInstallationModel.showModalNewSensor);
  const modalInteractionDisabled = useStoreState((state) => state.newInstallationModel.modalInteractionDisabled);
  const inventoryToUse = useStoreState((state) => state.inventoryModel.inventoryToUse);
  const firmwareReleases = useStoreState((state) => state.firmwareReleasesModel.firmwareReleases);

  const filterProducers = useStoreState((state) => state.staticDataModel.filterProducers);

  const getInstallationInput = useStoreActions((actions) => actions.newInstallationModel.getInstallationInput);
  const toggleModalInteractionDisabled = useStoreActions(
    (actions) => actions.newInstallationModel.toggleModalInteractionDisabled,
  );
  const addNewInstallation = useStoreActions((actions) => actions.newInstallationModel.addNewInstallation);
  // const addNewInstallationDraft = useStoreActions((actions) => actions.newInstallationModel.addNewInstallationDraft);
  const updateInstallation = useStoreActions((actions) => actions.newInstallationModel.updateInstallation);

  const toggleNewSensorModal = useStoreActions((actions) => actions.newInstallationModel.toggleNewSensorModal);
  const setIsMounted = useStoreActions((actions) => actions.newInstallationModel.setIsMounted);
  const getFilterDetailsData = useStoreActions((actions) => actions.deviceActionsModel.getFilterDetailsData);
  const resetInput = useStoreActions((actions) => actions.newInstallationModel.resetInput);

  const inputSerial = useStoreState((state) => state.newInstallationModel.inputSerial);
  const stock = useStoreState((state) => state.stockDailyDataModel.stock);
  const stockSettings = useStoreState((state) => state.stockDailyDataModel.stockSettings);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [selectedStockSettings, setSelectedStockSettings] = useState<IStockSettingSelection[]>([]);

  const [serialHealthState, setSerialHealthState] = useState<HealthState>(HealthState.Great);
  const [serialHealthStatus, setSerialHealthStatus] = useState<BeamHealthStatus>(defaultHealthStatus);
  const [showHealthWarning, setShowHealthWarning] = useState<HealthWarningInfo>({
    ignore: false,
    show: false,
  });

  const handleStockSettingSelections = (stockSettingItem: IStockSettingSelection) => {
    const itemInList = selectedStockSettings.find(
      (selectedStockType) => selectedStockType.stockType === stockSettingItem.stockType,
    );
    if (itemInList) {
      itemInList.usageForEvents = stockSettingItem.usageForEvents;
    } else {
      selectedStockSettings.push({
        stockId: stockSettingItem.stockId,
        stockType: stockSettingItem.stockType,
        usageForEvents: stockSettingItem.usageForEvents,
      });
    }
    setSelectedStockSettings([...selectedStockSettings]);
  };

  React.useEffect(() => {
    const stockSettingsOnInstallation: IStockSettingSelection[] = [];
    stock.forEach((stockItem) => {
      const stockSetting = stockSettings.find((stockSetting) => stockSetting.stock.stockType === stockItem.stockType);

      const usageForEvents = getUsageForEventsData({ stockType: stockItem.stockType, stockSetting, isUpdate });
      stockSettingsOnInstallation.push({
        id: stockSetting?.id,
        stockId: stockItem.id,
        stockType: stockItem.stockType,
        usageForEvents,
      });
    });
    setSelectedStockSettings(stockSettingsOnInstallation);
    return () => {
      setSelectedStockSettings([]);
    };
  }, [stock, stockSettings, isUpdate]);

  const choiceConfirmed = async () => {
    toggleModalInteractionDisabled(true);
    const input: ISensorInput = getInstallationInput();

    let feedback = 'Installation ' + (input.isUpdate ? 'updated' : 'added');
    const sensorInfo = validateInputDevice(input, filterProducers);
    if (!sensorInfo.success && sensorInfo.message !== undefined) {
      toggleModalInteractionDisabled(false);
      feedback = 'Error: ' + sensorInfo.message;
      showFeedback({ success: false, message: feedback });
      return;
    }

    if (input.isUpdate) {
      const requestResponse = await updateInstallation({
        requestPackage: sensorInfo,
        selectedStockSettings,
        stockSettings,
      });
      if (requestResponse.success) {
        setIsMounted(false);
        if (shouldRetrieveDetailsData) {
          const response = await getFilterDetailsData();
          if (!response) {
            navigate(Paths.installations);
          }
        }
        resetInput();
      } else {
        feedback = 'Error: ' + requestResponse.message;
      }
      showFeedback({ success: requestResponse.success, message: feedback });
      return;
    }

    delete sensorInfo.id;
    const requestResponse = await addNewInstallation({
      requestPackage: sensorInfo,
      selectedStockSettings,
      stockSettings,
    });
    if (requestResponse.success) {
      setIsMounted(false);
      if (shouldRetrieveDetailsData) {
        const response = await getFilterDetailsData();
        if (!response) {
          navigate(Paths.installations);
        }
      }
      resetInput();
    } else {
      feedback = 'Error: ' + requestResponse.message;
    }
    showFeedback({ success: requestResponse.success, message: feedback });
  };

  const showFeedback = ({ message, success }: ShowFeedbackProps) => {
    const variant: VariantType = success ? 'success' : 'error';
    enqueueSnackbar(message, { variant });
  };

  return (
    <>
      <AddItemModal
        isModalOpen={showSensorDetailsModal}
        confirmInteractionDisabled={false}
        interactionDisabled={modalInteractionDisabled}
        closeModal={() => {
          setIsMounted(false);
          resetInput();
          toggleNewSensorModal({ showModal: false, flowplanDeviceId: undefined, shouldRetrieveDetailsData: false });
        }}
        confirmAction={() => {
          const serialLookup = inventoryToUse.find((item) => item.flowplanDeviceId === inputSerial);
          if (!serialLookup) {
            enqueueSnackbar('Serial not found', { variant: 'error' });
            setSerialHealthState(HealthState.Great);
            return;
          }

          setSerialHealthState(serialLookup.healthState);
          const { dataConfiguration, batteryTypeId, firmware, hardware } = serialLookup;
          const nbConfigParsed = (dataConfiguration?.nbConfig as unknown as NbConfig) || null;
          const apn = nbConfigParsed?.gsmNbApn || 'None';
          const beamHealthStatus = getBeamHealthStatus({
            apn,
            batteryTypeId,
            firmware,
            firmwareReleases,
            hardware,
          });

          if (beamHealthStatus.shouldBeamBeReplaced) {
            setSerialHealthStatus(beamHealthStatus);
            setShowHealthWarning({
              ignore: false,
              show: true,
            });
            return;
          }
          choiceConfirmed();
        }}
        modalCancelLabel="Cancel"
        modalConfirmLabel="Confirm"
        modalTitle={modalTitleAddEdit(isUpdate, 'installation')}
      >
        <NewInstallationUpdate
          handleStockSettingSelections={handleStockSettingSelections}
          selectedStockSettings={selectedStockSettings}
        />
      </AddItemModal>
      <HealthWarning
        choiceSelection={(info) => {
          setShowHealthWarning(info);
          if (info.ignore) {
            choiceConfirmed();
          }
        }}
        goodActionLabel="Select another Beam"
        onlyShowOneButton={false}
        choiceSelectionInfo={showHealthWarning}
        healthStatus={serialHealthStatus}
        healthState={serialHealthState}
      />
    </>
  );
};

export default NewInstallationModal;
