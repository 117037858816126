import { daysRemainingStandardValue, DeviceStatus } from '@flowplan/flowplan-shared';
import { FirmwareRelease } from '@flowplan/flowplan-shared/lib/firmware.releases/firmware.releases';
import { ConsumptionState } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { IDeviceView, IInfoFilter, IInfoService } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { getBeamHealthStatus, healthBeamHasIssue, healthIsOnlyBatteryChange, healthIsOnlyOtaUpdateMissing } from '../../../../hooks/BeamHealthStatus';
import { isSignalPoor } from '../SignalStrength/signal-strength-parsing';
import { checkIfBatteryTaskShouldBeShown, taskInformationBattery } from './tasks-utility-battery';
import { taskInformationHardware } from './tasks-utility-hardware';
import { taskInformationHealth } from './tasks-utility-health';
import { taskInformationMaintenance } from './tasks-utility-maintenance';
import { taskInformationWaterFilter } from './tasks-utility-waterfilter';

export interface ITaskInformation {
  infoHeader: string;
  infoHighlight: string;
  infoList: string;
  infoTooltip: string;
  percentage?: number;
}

export interface IShowTaskIconsResult {
  showTaskWaterFilter: boolean;
  showTaskMaintenance: boolean;
  showTaskBattery: boolean;
  showTaskConnection: boolean;
  showTaskLowUsage: boolean;
  showTaskSignalStrength: boolean;
  showTaskHealth: boolean;
  taskHealthColor: 'green' | 'yellow';
}

export enum TaskType {
  HEAlTH,
  BATTERY,
  HARDWARE,
  MAINTANANCE,
  FILTER,
  CONNECTION,
  SIGNAL,
  USAGE,
  ALL,
}

type GetTasksToShow = {
  data: IDeviceView;
  firmwareReleases: FirmwareRelease[];
}

export const getTasksToShow = ({ data, firmwareReleases }: GetTasksToShow): IShowTaskIconsResult => {
  const {
    apn,
    batteryTypeId,
    batteryRemainingDays,
    batteryShowInformation,
    deviceStatus,
    firmware,
    hardware,
    signalStrengthAverage,
    signalStrengthLatest,
  } = data.infoDeviceMetrics;
  const { hasLowUsage } = data.infoUsage;

  const featureEnabledWaterFilter = data.infoFilter.featureEnabled;
  const featureEnabledMaintenance = data.infoService.featureEnabled;

  const daysLeftWaterFilter = featureEnabledWaterFilter ? data.infoFilter.daysLeft : daysRemainingStandardValue;
  const daysLeftMaintenance = featureEnabledMaintenance ? data.infoService.daysLeft : daysRemainingStandardValue;

  let differenceBetweenServiceAndFilterChange = daysLeftWaterFilter - daysLeftMaintenance;
  if (daysLeftWaterFilter === daysRemainingStandardValue && daysLeftMaintenance === daysRemainingStandardValue) {
    differenceBetweenServiceAndFilterChange = daysRemainingStandardValue;
  }

  if (differenceBetweenServiceAndFilterChange < 0) {
    differenceBetweenServiceAndFilterChange = differenceBetweenServiceAndFilterChange * -1;
  }

  const showTaskMaintenance =
    featureEnabledMaintenance &&
    daysLeftMaintenance < 30 &&
    (differenceBetweenServiceAndFilterChange < 30 || daysLeftMaintenance < 30);
  const showTaskWaterFilter =
    featureEnabledWaterFilter &&
    daysLeftWaterFilter < 30 &&
    (differenceBetweenServiceAndFilterChange < 30 || daysLeftWaterFilter < 30);

  const showTaskConnection = showConnectionTask(deviceStatus);
  const showTaskLowUsage = hasLowUsage;

  const signalStrength: number = signalStrengthAverage || signalStrengthLatest;

  const showTaskSignalStrength = isSignalPoor(signalStrength);

  const beamHealthStatus = getBeamHealthStatus({
    apn,
    batteryTypeId,
    firmware,
    firmwareReleases,
    hardware,
  });

  let showTaskBattery = checkIfBatteryTaskShouldBeShown({
    daysBetweenTasks: data.infoMeta.daysBetweenTask,
    daysLeftTask: data.infoMeta.daysLeft,
    batteryRemainingDays: batteryRemainingDays,
    batteryShowInformation: batteryShowInformation,
  });

  // Never recommend a battery change if the battery is not the right type
  if (!beamHealthStatus.batteryHealthy && showTaskBattery) {
    showTaskBattery = false;
  }

  const onlyBatteryChange = healthIsOnlyBatteryChange(beamHealthStatus);
  const onlyOtaUpdates = healthIsOnlyOtaUpdateMissing(beamHealthStatus);
  const somethingNotHealthy = healthBeamHasIssue(beamHealthStatus);

  const showTaskHealth = onlyBatteryChange && showTaskBattery ? false : somethingNotHealthy || onlyOtaUpdates;

  return {
    showTaskBattery,
    showTaskConnection,
    showTaskHealth,
    showTaskLowUsage,
    showTaskMaintenance,
    showTaskSignalStrength,
    showTaskWaterFilter,
    taskHealthColor: onlyOtaUpdates ? 'green' : 'yellow'
  };
};

export interface ITaskInformationToShowResult {
  taskInfoBattery: ITaskInformation;
  taskInfoHardware: ITaskInformation;
  taskInfoHealth: ITaskInformation;
  taskInfoMaintenance: ITaskInformation;
  taskInfoWaterFilter: ITaskInformation;
}

export type GetTaskInformation<T extends IDeviceView = IDeviceView> = {
  data: T;
  firmwareReleases: FirmwareRelease[];
};

export const getTaskInformation = ({ data, firmwareReleases }: GetTaskInformation): ITaskInformationToShowResult => {
  const { infoDeviceMetrics, infoFilter, infoService, infoUsage } = data;

  const { deviceStatus, firmware, batteryRemainingDays, batteryShowInformation } =
    infoDeviceMetrics;
  const { consumptionState } = infoUsage;

  const taskInfoBattery = taskInformationBattery({
    deviceStatus,
    firmware,
    batteryRemainingDays,
    batteryShowInformation
  });

  const showEstimate = showEstimatedUsage({
    consumptionState,
    infoFilter,
    infoService,
  });

  const taskInfoMaintenance = taskInformationMaintenance({
    infoService,
    showEstimate,
  });
  const taskInfoWaterFilter = taskInformationWaterFilter({
    infoFilter,
    showEstimate,
  });

  const taskInfoHardware = taskInformationHardware();

  const taskInfoHealth = taskInformationHealth({ data, firmwareReleases });

  return {
    taskInfoBattery,
    taskInfoHealth,
    taskInfoHardware,
    taskInfoMaintenance,
    taskInfoWaterFilter,
  };
};
const showConnectionTask = (deviceStatus: DeviceStatus) =>
  deviceStatus === DeviceStatus.Inactive || deviceStatus === DeviceStatus.NotSeen;

type ShowEstimateUsage = {
  consumptionState: ConsumptionState;
  infoFilter: IInfoFilter;
  infoService: IInfoService;
};

export const showEstimatedUsage = ({ consumptionState, infoFilter, infoService }: ShowEstimateUsage): boolean => {
  if (consumptionState === ConsumptionState.ESTIMATE) {
    return true;
  }

  const reqFeature = infoFilter.featureEnabled || infoService.featureEnabled;
  const reqUsage = infoFilter.estimatedUsedCapacity > 0 || infoService.estimatedMaintenanceUsage > 0;
  return reqUsage && reqFeature;
};
