import { IFilterProducer, minimumStringLength, serialLength } from '@flowplan/flowplan-shared';
import { IDeviceRequestPackage } from '@flowplan/flowplan-shared/lib/interfaces/installationView';
import { trimStringAndCheckLength } from '@hsjakobsen/utilities';
import { noSelectionMade } from '../../../Models/admin/admin.model';
import { DeviceTypes } from '../../../common/constsCategoryAndTypes';
import { ISensorInput } from '../../../common/interfacesInput';

export const validateInputDevice = (
  inputData: ISensorInput,
  filterProducers: IFilterProducer[],
): IDeviceRequestPackage => {
  const featureFilterEnabled = inputData.inputFilterOptions.inputFeatureFilter;
  const waterFilterFeatureEnabled = featureFilterEnabled ? 1 : 0;

  const featureServiceEnabled = inputData.inputService.inputFeatureService;
  const maintenanceFeatureEnabled = featureServiceEnabled ? 1 : 0;

  const deviceInfo: IDeviceRequestPackage = {
    success: false,
    flowmeterSerial: null, // TODO: This should be input via scans or manual adding
    waterFilterFeatureEnabled,
    maintenanceFeatureEnabled,
  };

  if (inputData.inputRequired.inputDeviceType === DeviceTypes.NoSelection) {
    deviceInfo.message = 'Machine type not selected';
    return deviceInfo;
  }

  const locationIdToUse = inputData.inputRequired.inputLocationId;
  if (locationIdToUse < 0) {
    deviceInfo.message = 'Location not selected';
    return deviceInfo;
  }

  const nameResponse = trimStringAndCheckLength(inputData.inputRequired.inputName, 'Name', minimumStringLength, false);
  if (!nameResponse.success) {
    deviceInfo.message = nameResponse.feedback;
    return deviceInfo;
  }

  const nameTooLong = nameResponse.result.length > 128;
  if (nameTooLong) {
    deviceInfo.message =
      'Name too long, max length is 128, current name is: ' + nameResponse.result.length + ' characters.';
    return deviceInfo;
  }

  let flowplanSerial: string = inputData.inputRequired.inputSerial.slice(6);
  if (!inputData.isUpdate) {
    const serialCheck = trimStringAndCheckLength(flowplanSerial, 'Device serial', serialLength, true);
    if (!serialCheck.success) {
      deviceInfo.message = 'Serial not selected';
      return deviceInfo;
    }
    flowplanSerial = serialCheck.result;
  }

  const flowmeterIdToUse = inputData.inputRequired.inputFlowmeterId;
  if (flowmeterIdToUse <= 0) {
    deviceInfo.message = 'Flowmeter not selected';
    return deviceInfo;
  }

  // External sensor
  const externalSensorIdToUse =
    inputData.inputExternalSensor.inputExternalSensorId !== -1
      ? inputData.inputExternalSensor.inputExternalSensorId
      : 0;

  let filterProducerIdToUse = null;
  let filterIdToUse = null;
  let capacityToUse = 0;
  if (featureFilterEnabled) {
    filterProducerIdToUse = inputData.inputFilterOptions.inputFilterProducerId;
    if (filterProducerIdToUse < 0) {
      deviceInfo.message = 'Filter producer not selected';
      return deviceInfo;
    }

    filterIdToUse = inputData.inputFilterOptions.inputFilterId;
    const filterProducerLookup = filterProducers.find(
      (x) => x.id === inputData.inputFilterOptions.inputFilterProducerId,
    );
    if (filterProducerLookup === undefined || (filterProducerLookup.data !== 0 && filterIdToUse < 1)) {
      deviceInfo.message = 'Filter not selected';
      return deviceInfo;
    }

    if (filterProducerLookup.data === 0) {
      filterIdToUse = null;
    }

    capacityToUse = Number(inputData.inputFilterOptions.inputCapacity);
    if (isNaN(capacityToUse) || capacityToUse <= 0) {
      deviceInfo.message = 'Total capacity must be above 0';
      return deviceInfo;
    }
  }

  let maintenanceAtLitersToUse = 0;
  let maintenanceChangeByLatestIdToUse = null;
  let maintenanceStartDate = null;
  let maintenanceCyclesToUse = null;

  if (featureServiceEnabled) {
    // if (isNaN(inputData.inputService.inputServiceAtLiters) || inputData.inputService.inputServiceAtLiters < 1) {
    //   deviceInfo.message = 'Maintenance liter input has to be more than 0';
    //   return deviceInfo;
    // }
    let maintenanceCyclesNotAllowed = false;
    if (
      inputData.inputRequired.inputDeviceType === DeviceTypes.SemiAutomatic ||
      inputData.inputRequired.inputDeviceType === DeviceTypes.FullyAutomatic ||
      inputData.inputRequired.inputDeviceType === DeviceTypes.FullyAutomaticFreshMilk
    ) {
      maintenanceCyclesNotAllowed = true;
    }

    maintenanceCyclesToUse = maintenanceCyclesNotAllowed ? inputData.inputService.inputMaintenanceAtCycles : null;
    maintenanceAtLitersToUse = inputData.inputService.inputServiceAtLiters;

    if (maintenanceCyclesToUse) {
      if (maintenanceCyclesToUse <= 0) {
        deviceInfo.message = 'Maintenance interval input has to be more than 0';
        return deviceInfo;
      }
    }

    if (!maintenanceCyclesToUse && maintenanceAtLitersToUse <= 0) {
      deviceInfo.message = 'Maintenance interval input has to be more than 0';
      return deviceInfo;
    }

    maintenanceChangeByLatestIdToUse =
      inputData.inputService.serviceChangeByLatestId !== noSelectionMade
        ? inputData.inputService.serviceChangeByLatestId
        : null;
    maintenanceStartDate =
      inputData.inputService.serviceStartDate !== undefined ? inputData.inputService.serviceStartDate : null;
  }

  const waterFilterhangeByLatestIdToUse =
    inputData.inputFilterOptions.inputChangeByLatestId === -1
      ? null
      : inputData.inputFilterOptions.inputChangeByLatestId;

  if (inputData.isUpdate) {
    deviceInfo.id = inputData.id;
  }
  deviceInfo.success = true;
  deviceInfo.name = nameResponse.result;
  deviceInfo.flowplanSerial = flowplanSerial;
  deviceInfo.deviceType = inputData.inputRequired.inputDeviceType;
  deviceInfo.shortDesc = inputData.inputRequired.inputShortDesc;
  deviceInfo.flowmeterId = flowmeterIdToUse;
  deviceInfo.locationId = locationIdToUse;
  deviceInfo.externalSensorId = externalSensorIdToUse;

  deviceInfo.waterFilterProducerId = filterProducerIdToUse;
  deviceInfo.waterFilterId = filterIdToUse;
  deviceInfo.waterFilterCapacity = capacityToUse;
  deviceInfo.waterFilterChangeByLatestId = waterFilterhangeByLatestIdToUse;
  deviceInfo.waterFilterMachineTypeId = inputData.inputFilterOptions.inputUsingMachineTypeId;

  deviceInfo.maintenanceAtLiters = maintenanceAtLitersToUse;
  deviceInfo.maintenanceChangeByLatestId = maintenanceChangeByLatestIdToUse;
  deviceInfo.maintenanceStartDate = maintenanceStartDate;
  deviceInfo.maintenanceCycles = maintenanceCyclesToUse;

  return deviceInfo;
};
