import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { SelectedOption } from '../common/constsFrontend';
import { getDateObject } from './time';

type IStartEndDate = {
  endDate: string;
  startDate: string;
}

export const getStartAndEndDateSelection = (selectedOption: SelectedOption): IStartEndDate => {
  const today = getDateObject(null);
  const endDate = today.format(dateFormatYMD);
  let startDate = '';
  switch (selectedOption) {
    case SelectedOption.Lifetime:
      startDate = today.subtract(6, 'months').format(dateFormatYMD);
      break;
    case SelectedOption.LastMonth:
      startDate = today.subtract(1, 'month').format(dateFormatYMD);
      break;
    case SelectedOption.SevenDays:
      startDate = today.subtract(1, 'week').format(dateFormatYMD);
      break;
  }

  return {
    endDate,
    startDate,
  };
};
