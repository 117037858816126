const { MODE, VITE_APP_DB_URL_LOCAL, VITE_APP_DB_URL_LIVE, VITE_APP_API_KEY_GOOGLE, VITE_APP_MUI_LICENSE, VITE_APP_CAPTCHA, VITE_APP_WEGLOT } = import.meta.env;


export const isDevelopment = MODE === 'development';
export const serverUrl = isDevelopment ? VITE_APP_DB_URL_LOCAL : VITE_APP_DB_URL_LIVE;
export const refreshInterval5Seconds = 15000;
export const refreshInterval10Seconds = 10000;
export const refreshInterval20Seconds = 20000;
export const refreshInterval30Seconds = 30000;
export const refreshIntervalOneMinute = 60000;
export const refreshInterval60Minutes = 3600000;
export const refreshTimerEveryLiter = 0.1;

// API Keys
export const googleAPIKey = VITE_APP_API_KEY_GOOGLE;

export const weglotAPIKey = VITE_APP_WEGLOT;
export const muiLicense = VITE_APP_MUI_LICENSE || '';
export const captchaKey = VITE_APP_CAPTCHA || '';
