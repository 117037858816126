import React, { Fragment } from 'react';

interface IInputComponentToggle {
  children: React.ReactNode;
  hideComponent: boolean;
}

const InputComponentToggle = ({ children, hideComponent }: IInputComponentToggle): JSX.Element => {
  if (hideComponent) {
    return <Fragment />;
  }

  return <Fragment>{children}</Fragment>;
};

export default InputComponentToggle;
