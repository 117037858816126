import { HardnessUnits } from "@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients";

export enum FilterSofteningType {
  CarbonateOnly = 0,
  TotalSoftening = 1,
  BothAndMicroSiemens = 2,
  MicroSiemens = 3,
}

type GetWaterHardnessInfo = {
  carbonateHardness: number;
  hardnessUnit: HardnessUnits;
  totalHardness: number;
}

export const getNotAvailableData = '-';

export const getWaterHardnessInfo = ({
  carbonateHardness,
  hardnessUnit,
  totalHardness
}: GetWaterHardnessInfo): string => {
  const carbonateHardnessInfo =
    carbonateHardness !== 0
      ? carbonateHardness.toString() + getWaterHardnessType({ hardnessUnit, includeLaymansTerm: false, spaceInFront: true, filterSofteningType: FilterSofteningType.CarbonateOnly })
      : getNotAvailableData;
  const totalHardnessInfo =
    totalHardness !== 0 ? totalHardness.toString() + getWaterHardnessType({ hardnessUnit, includeLaymansTerm: false, spaceInFront: true, filterSofteningType: FilterSofteningType.TotalSoftening }) : getNotAvailableData;
  const hardnessToShow = `${carbonateHardnessInfo} / ${totalHardnessInfo}`;
  return hardnessToShow;
};

// This function handles more hardness types dynamically.
type GetWaterHardnessType = {
  hardnessUnit: HardnessUnits;
  includeLaymansTerm: boolean,
  spaceInFront: boolean,
  filterSofteningType: FilterSofteningType;
}

export const getWaterHardnessType = ({ hardnessUnit, includeLaymansTerm, spaceInFront, filterSofteningType }: GetWaterHardnessType): string => {
  let hardnessScaleToReturn = spaceInFront ? ' ' : '';

  const usePPM = hardnessUnit === HardnessUnits.ppm;

  if (filterSofteningType === FilterSofteningType.TotalSoftening) {
    hardnessScaleToReturn += usePPM ? 'ppm' : '°dH';
    if (includeLaymansTerm) {
      hardnessScaleToReturn += ' - Total';
    }
  } else if (filterSofteningType === FilterSofteningType.CarbonateOnly) {
    hardnessScaleToReturn += usePPM ? 'ppm' : '°kH';
    if (includeLaymansTerm) {
      hardnessScaleToReturn += ' - Carbonate';
    }
  } else if (filterSofteningType === FilterSofteningType.BothAndMicroSiemens) {
    hardnessScaleToReturn += (usePPM ? 'ppm' : '°dH + °kH') + ' + μS/cm';

  } else if (filterSofteningType === FilterSofteningType.MicroSiemens) {
    hardnessScaleToReturn += 'μS/cm';
    if (includeLaymansTerm) {
      hardnessScaleToReturn = ' - MicroSiemens per cm';
    }
  }

  return hardnessScaleToReturn;
};

type GetWaterHardnessRange = {
  filterSofteningType: FilterSofteningType;
  hardnessUnit: HardnessUnits;
}

export const getWaterHardnessRange = ({ filterSofteningType, hardnessUnit }: GetWaterHardnessRange) => {
  let hardnessScaleToReturn = '';

  const usePPM = hardnessUnit === HardnessUnits.ppm;

  if (filterSofteningType === FilterSofteningType.CarbonateOnly) {
    hardnessScaleToReturn += usePPM ? '0-380' : '4-35';

  }

  if (filterSofteningType === FilterSofteningType.TotalSoftening) {
    hardnessScaleToReturn += usePPM ? '0-380' : '4-40';
  }

  if (filterSofteningType === FilterSofteningType.BothAndMicroSiemens) {
    hardnessScaleToReturn += '0-10000';
  }

  return hardnessScaleToReturn;
}