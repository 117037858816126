import { InstallationTag } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { Fragment, useState } from 'react';
import { useStoreState } from '../../../../Store';
import FpContainer from '../../../common/components/FpContainer/FpContainer';
import FpTag from '../../../common/components/FpTag/FpTag';
import { isUserGuest } from '../../../users/types/users-configs';
import InstallationDetailsItem from '../Common/InstallationDetailsItem';
import AddInstallationTagButton from './AddInstallationTags';
import InstallationTagOptions from './InstallationTagOptions';

type Props = {
  installationId: number;
  margins: boolean;
  icon?: React.ReactNode;
  showAddOption?: boolean;
  tags: InstallationTag[];
  selectTagFunction?(tag: InstallationTag): void;
};

export type AnchorOptions = {
  label: string;
  anchorEl: null | HTMLElement;
};

const InstallationTags = ({ icon, installationId, margins, showAddOption = true, tags }: Props): JSX.Element => {
  const currentUser = useStoreState((state) => state.authModel.currentUser);
  const guest = isUserGuest(currentUser.role);

  const [anchorEl, setAnchorEl] = useState<AnchorOptions>({
    anchorEl: null,
    label: '',
  });

  const closeTagMenu = () => {
    setAnchorEl({
      anchorEl: null,
      label: '',
    });
  };

  if (guest) {
    return <Fragment />;
  }

  return (
    <InstallationDetailsItem
      showBorder={false}
      boldTitle
      icon={icon}
      margins={margins}
      title={'Installation tags (' + tags.length + '):'}
    >
      <FpContainer basic>
        {tags.map((tag, index) => {
          return (
            <FpTag
              key={index}
              sx={{ fontWeight: 'bold', margin: '0.2rem' }}
              color={tag.tags.color}
              label={tag.tags.name}
              optionsFunction={setAnchorEl}
            >
              <InstallationTagOptions
                anchorOptions={anchorEl}
                closeMenu={closeTagMenu}
                installationId={installationId}
                tag={tag}
              />
            </FpTag>
          );
        })}
        {showAddOption ? (
          <AddInstallationTagButton installationId={installationId} userRole={currentUser.role} />
        ) : null}
      </FpContainer>
    </InstallationDetailsItem>
  );
};

export default InstallationTags;
