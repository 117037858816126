import signal0 from '../../../../assetstore/icons/signal/signal_0.png';
import signal1 from '../../../../assetstore/icons/signal/signal_1.png';
import signal2 from '../../../../assetstore/icons/signal/signal_2.png';
import signal3 from '../../../../assetstore/icons/signal/signal_3.png';
import signal4 from '../../../../assetstore/icons/signal/signal_4.png';
import signal5 from '../../../../assetstore/icons/signal/signal_5.png';
import { interpretSignalStrength, SignalColor } from './signal-strength-parsing';
import styles from './SignalStrengthComponent.module.scss';

interface ISignalStrengthComponent {
  network: number;
  signalStrength: number;
}

const SignalStrengthComponent = ({ network, signalStrength }: ISignalStrengthComponent): JSX.Element => {
  const signalInfo = interpretSignalStrength(network, signalStrength);
  const tooltip = 'Signal Strength: ' + signalInfo.signalText + ' ( RSSI: ' + signalStrength + ' )';

  let signalIconToShow;
  switch (signalInfo.signalColor) {
    case SignalColor.None:
      signalIconToShow = signal0;
      break;
    case SignalColor.Bad:
      signalIconToShow = signal1;
      break;
    case SignalColor.Poor:
      signalIconToShow = signal2;
      break;
    case SignalColor.Okay:
      signalIconToShow = signal3;
      break;
    case SignalColor.Good:
      signalIconToShow = signal4;
      break;
    case SignalColor.Excellent:
      signalIconToShow = signal5;
      break;
    default:
      signalIconToShow = signal0;
      break;
  }

  return <img className={styles.signalStrengthStatus} alt={tooltip} title={tooltip} src={signalIconToShow} />;
};

export default SignalStrengthComponent;
