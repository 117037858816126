import { Network, NetworkCode } from '@flowplan/flowplan-shared/lib/consts/constNetwork';
import { isNetworkGSMGT, isNetworkNarrowband } from '../../../../Utility/networkTypeCheck';

enum SignalBestAndWorstGSMT {
  Worst = -111,
  Best = -51,
}

enum SignalBestAndWorstLoRa {
  Worst = -130,
  Best = -40,
}

enum GSMGTSignalScale {
  Bad = -105,
  Poor = -95,
  Okay = -85,
  Good = -75,
}

enum NarrowbandSignalScale {
  Bad = -105, // according to Telia it can reach -120 before no signal
  Poor = -95,
  Okay = -85, // according to Telia it can reach -108 before it's not okay
  Good = -75, // according to Telia it can reach -90 before signal isn't great
}

enum LoRaSignalScale {
  Bad = -120,
  Poor = -110,
  Okay = -105,
  Good = -95,
}

export enum SignalText {
  Pending = 'Waiting for data to determine network',
  None = 'No signal data',
  Bad = 'Very poor signal',
  Poor = 'Poor signal',
  Okay = 'Okay signal',
  Good = 'Good signal',
  Excellent = 'Excellent signal',
}

export enum SignalColor {
  None = 'grey',
  Bad = 'red',
  Poor = 'orange',
  Okay = 'yellow',
  Good = 'green',
  Excellent = 'blue',
}

const redValue = '#ff695e';
const orangeValue = '#ff851b';
const yellowValue = '#ffe21f';
const greenValue = '#21ba45';

export const getNetworkType = (network: number): string => {
  let networkInfo = 'Network not detected yet';
  switch (network) {
    case NetworkCode.GSMGT:
      networkInfo = Network.GSMGT;
      break;
    case NetworkCode.Narrowband:
      networkInfo = Network.Narrowband;
      break;
  }
  return networkInfo.toUpperCase();
};

export interface ISignalStrengthResult {
  signalColor: string;
  signalText: string;
}

export const isSignalPoor = (signalStrength: number): boolean => {
  return signalStrength < -95;
};

export const interpretSignalStrength = (network: number, signalStrength: number): ISignalStrengthResult => {
  const result = {
    signalColor: SignalColor.None,
    signalText: SignalText.None,
  };

  if (signalStrength === 0) {
    return result;
  }

  if (network === NetworkCode.None) {
    result.signalText = SignalText.Pending;
    return result;
  }

  if (isNetworkGSMGT(network)) {
    if (signalStrength <= GSMGTSignalScale.Bad) {
      result.signalText = SignalText.Bad;
      result.signalColor = SignalColor.Bad;
    } else if (signalStrength > GSMGTSignalScale.Bad && signalStrength <= GSMGTSignalScale.Poor) {
      result.signalText = SignalText.Poor;
      result.signalColor = SignalColor.Poor;
    } else if (signalStrength > GSMGTSignalScale.Poor && signalStrength <= GSMGTSignalScale.Okay) {
      result.signalText = SignalText.Okay;
      result.signalColor = SignalColor.Okay;
    } else if (signalStrength > GSMGTSignalScale.Okay && signalStrength <= GSMGTSignalScale.Good) {
      result.signalText = SignalText.Good;
      result.signalColor = SignalColor.Good;
    } else if (signalStrength > GSMGTSignalScale.Good) {
      result.signalText = SignalText.Excellent;
      result.signalColor = SignalColor.Excellent;
    }
  } else if (isNetworkNarrowband(network)) {
    if (signalStrength <= NarrowbandSignalScale.Bad) {
      result.signalText = SignalText.Bad;
      result.signalColor = SignalColor.Bad;
    } else if (signalStrength > NarrowbandSignalScale.Bad && signalStrength <= NarrowbandSignalScale.Poor) {
      result.signalText = SignalText.Poor;
      result.signalColor = SignalColor.Poor;
    } else if (signalStrength > NarrowbandSignalScale.Poor && signalStrength <= NarrowbandSignalScale.Okay) {
      result.signalText = SignalText.Okay;
      result.signalColor = SignalColor.Okay;
    } else if (signalStrength > NarrowbandSignalScale.Okay && signalStrength <= NarrowbandSignalScale.Good) {
      result.signalText = SignalText.Good;
      result.signalColor = SignalColor.Good;
    } else if (signalStrength > NarrowbandSignalScale.Good) {
      result.signalText = SignalText.Excellent;
      result.signalColor = SignalColor.Excellent;
    }
  }

  return result;
};

export const getColorCodeForSignal = (network: number, signalStrength: number): string | undefined => {
  if (isNetworkGSMGT(network) || isNetworkNarrowband(network)) {
    if (signalStrength <= GSMGTSignalScale.Poor) {
      return redValue;
    } else if (signalStrength > GSMGTSignalScale.Poor && signalStrength <= GSMGTSignalScale.Okay) {
      return orangeValue;
    } else if (signalStrength > GSMGTSignalScale.Okay && signalStrength <= GSMGTSignalScale.Good) {
      return yellowValue;
    } else if (signalStrength > GSMGTSignalScale.Good) {
      return greenValue;
    }
  } else {
    if (signalStrength <= LoRaSignalScale.Poor) {
      return redValue;
    } else if (signalStrength > LoRaSignalScale.Poor && signalStrength <= LoRaSignalScale.Okay) {
      return orangeValue;
    } else if (signalStrength > LoRaSignalScale.Okay && signalStrength <= LoRaSignalScale.Good) {
      return yellowValue;
    } else if (signalStrength > LoRaSignalScale.Good) {
      return greenValue;
    }
  }

  return;
};

export const signalStrengthPercentage = (network: number, rssiValue: number): number => {
  if (isNetworkGSMGT(network) || isNetworkNarrowband(network)) {
    if (rssiValue < SignalBestAndWorstGSMT.Worst) {
      return 0;
    }

    if (rssiValue > SignalBestAndWorstGSMT.Best) {
      return 100;
    }

    const rssiIntervalGSM: number = SignalBestAndWorstGSMT.Best - SignalBestAndWorstGSMT.Worst;
    const rssiValueToUseGSM: number = rssiValue - SignalBestAndWorstGSMT.Worst;
    return Math.ceil((rssiValueToUseGSM / rssiIntervalGSM) * 100);
  } else {
    if (rssiValue < SignalBestAndWorstLoRa.Worst) {
      return 0;
    }

    if (rssiValue > SignalBestAndWorstLoRa.Best) {
      return 100;
    }

    const rssiIntervalLORA: number = SignalBestAndWorstLoRa.Best - SignalBestAndWorstLoRa.Worst;
    const rssiValueToUseLORA: number = rssiValue - SignalBestAndWorstLoRa.Worst;
    return Math.ceil((rssiValueToUseLORA / rssiIntervalLORA) * 100);
  }
};
