import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IDeviceConfiguration } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { IConnectionData, ISimpleLineData } from '@flowplan/flowplan-shared/lib/interfaces/installationView';
import { Action, Thunk, action, thunk } from 'easy-peasy';
import { IStoreModel } from '.';
import { SelectedOption } from '../common/constsFrontend';
import { getRouteForGraphData, parseDataForGraph } from './deviceData.utility';

export interface IAdminBaseRequest {
  deviceId: number;
  flowplanClientId: number;
}

export interface IDeviceConfigModel {
  batteryData: ISimpleLineData[];
  getBatteryData: Thunk<IDeviceConfigModel, SelectedOption, void, IStoreModel>;
  setBatteryData: Action<IDeviceConfigModel, ISimpleLineData[]>;

  connectionData: IConnectionData;
  retrieveConnectionData: Thunk<IDeviceConfigModel, IAdminBaseRequest, void, IStoreModel>;
  setConnectionData: Action<IDeviceConfigModel, IConnectionData>;

  uplinkCountData: ISimpleLineData[];
  retrieveUplinkCountData: Thunk<IDeviceConfigModel, SelectedOption, void, IStoreModel>;
  setUplinkCountData: Action<IDeviceConfigModel, ISimpleLineData[]>;

  deviceConfiguration: IDeviceConfiguration;
  retrieveDeviceConfiguration: Thunk<IDeviceConfigModel, number, void, IStoreModel>;
  setDeviceConfiguration: Action<IDeviceConfigModel, IDeviceConfiguration>;

  loadingBatteryData: boolean;
  setLoadingBatteryData: Action<IDeviceConfigModel, boolean>;
  loadingUplinkCountData: boolean;
  setLoadingUplinkCountData: Action<IDeviceConfigModel, boolean>;
}

const deviceConfigModel: IDeviceConfigModel = {
  batteryData: [],
  connectionData: {} as IConnectionData,
  deviceConfiguration: {} as IDeviceConfiguration,
  uplinkCountData: [],
  loadingBatteryData: false,
  loadingUplinkCountData: false,

  setLoadingBatteryData: action((state, payload) => {
    state.loadingBatteryData = payload;
  }),
  setLoadingUplinkCountData: action((state, payload) => {
    state.loadingUplinkCountData = payload;
  }),

  getBatteryData: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const selectedInstallation = getStoreState().graphSettingsModel.selectedInstallation;
    const requestRoute = getRouteForGraphData({ installationId: selectedInstallation.infoBasic.id, route: Routes.batteryData, selectedOption: payload });
    actions.setLoadingBatteryData(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: requestRoute });
    actions.setLoadingBatteryData(false);
    if (requestResponse.success) {
      const receivedData = requestResponse.data as ISimpleLineData[];
      actions.setBatteryData(receivedData);
    }
  }),
  retrieveConnectionData: thunk(async (actions, payload, { getStoreActions }) => {
    const route = Routes.connectionData + payload.deviceId + '/' + payload.flowplanClientId;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route });
    if (requestResponse.success) {
      actions.setConnectionData(requestResponse.data);
    }
  }),
  retrieveDeviceConfiguration: thunk(async (actions, payload, { getStoreActions }) => {
    const route = Routes.deviceConfiguration + payload;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route });
    if (requestResponse.success) {
      actions.setDeviceConfiguration(requestResponse.data);
    }
  }),
  retrieveUplinkCountData: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const selectedInstallation = getStoreState().graphSettingsModel.selectedInstallation;
    const requestRoute = getRouteForGraphData({ installationId: selectedInstallation.infoBasic.id, route: Routes.uplinkCountData, selectedOption: payload });

    actions.setLoadingUplinkCountData(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: requestRoute });
    actions.setLoadingUplinkCountData(false);
    if (requestResponse.success) {
      actions.setUplinkCountData(requestResponse.data);
    }
  }),
  setBatteryData: action((state, payload) => {
    state.batteryData = parseDataForGraph(payload);
  }),
  setConnectionData: action((state, payload) => {
    state.connectionData = payload;
  }),
  setDeviceConfiguration: action((state, payload) => {
    state.deviceConfiguration = payload;
  }),
  setUplinkCountData: action((state, payload) => {
    state.uplinkCountData = parseDataForGraph(payload);
  }),
};

export default deviceConfigModel;
