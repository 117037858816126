import { Grid } from '@mui/material';
import { useState } from 'react';
import { Input } from 'semantic-ui-react';
import { useStoreActions, useStoreState } from '../../Store';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../modules/common/components';
import FpButton from '../../modules/common/components/FpButton/FpButton';
import './ModalStyles.scss';
import FpContainer from '../../modules/common/components/FpContainer/FpContainer';

const ConfirmModalStrict = (): JSX.Element => {
  const confirmStrictCallback = useStoreState((state) => state.modalModel.confirmStrictCallback);
  const confirmStrictCancelLabel = useStoreState((state) => state.modalModel.confirmStrictCancelLabel);
  const confirmStrictConfirmLabel = useStoreState((state) => state.modalModel.confirmStrictConfirmLabel);
  const confirmStrictConfirmValue = useStoreState((state) => state.modalModel.confirmStrictConfirmValue);
  const confirmStrictShow = useStoreState((state) => state.modalModel.confirmStrictShow);
  const confirmStrictText = useStoreState((state) => state.modalModel.confirmStrictText);
  const confirmStrictTitle = useStoreState((state) => state.modalModel.confirmStrictTitle);
  const confirmStrictToggle = useStoreActions((actions) => actions.modalModel.confirmStrictToggle);

  const [confirmText, setConfirmText] = useState('');
  if (!confirmStrictShow && confirmText !== '') {
    setConfirmText('');
  }

  const isDisabled = confirmText !== confirmStrictConfirmValue;

  const closeModal = () => {
    confirmStrictToggle({
      title: '',
      showModal: false,
      confirmValue: '',
      text: '',
    });
  };

  const confirmAction = () => {
    closeModal();
    confirmStrictCallback();
  };

  return (
    <Dialog open={confirmStrictShow} onClose={closeModal}>
      <DialogTitle>{confirmStrictTitle}</DialogTitle>

      <DialogContent>
        <Grid container spacing={{ sm: 1 }} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FpContainer
              basic
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '1rem',
                marginBottom: '0.3rem'
              }}
            >
              {confirmStrictText}
            </FpContainer>
            <p className="modal-confirmation-text do-not-translate">
              <strong>{confirmStrictConfirmValue}</strong>
            </p>
            <Input
              placeholder={confirmText}
              size="large"
              className="center-text"
              onChange={(e, data) => {
                setConfirmText(data.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FpButton color="error" onClick={closeModal}>
          {confirmStrictCancelLabel}
        </FpButton>
        <FpButton color="secondary" onClick={confirmAction} disabled={isDisabled}>
          {confirmStrictConfirmLabel}
        </FpButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModalStrict;
