import { Color } from '@flowplan/flowplan-shared';
import { MapFilterOptions } from '@flowplan/flowplan-shared/lib/installations/dashboard';
import { IPieChartData } from '../../common/interfacesFrontend';

export interface ILocationsInfo {
  good: number;
  oneMonth: number;
  oneWeek: number;
  overdue: number;
}

export const defaultLocationsInfo: ILocationsInfo = {
  good: 0,
  oneMonth: 0,
  oneWeek: 0,
  overdue: 0,
};

export const defaultInstallationsGraphData: IPieChartData[] = [
  {
    name: 'Good',
    value: 0,
    label: 'Good',
    fill: Color.Good,
  },
  {
    name: '1 month',
    value: 0,
    label: 'One month',
    fill: Color.Month,
  },
  {
    name: '1 week',
    value: 0,
    label: 'One week',
    fill: Color.Week,
  },
  {
    name: 'Overdue',
    value: 0,
    label: 'Overdue',
    fill: Color.Overdue,
  },
];

export const defaultInstallationConnectionGraphData: IPieChartData[] = [
  {
    name: MapFilterOptions.Online,
    value: 0,
    label: MapFilterOptions.Online,
    fill: Color.Good,
  },
  {
    name: MapFilterOptions.NotSeen,
    value: 0,
    label: MapFilterOptions.NotSeen,
    fill: Color.Week,
  },
  {
    name: MapFilterOptions.Inactive,
    value: 0,
    label: MapFilterOptions.Inactive,
    fill: Color.Overdue,
  },
  {
    name: MapFilterOptions.Pending,
    value: 0,
    label: MapFilterOptions.Pending,
    fill: '#636363',
  },
];

export const MIN_SLIDER_VALUE = -14;
export const MAX_SLIDER_VALUE = 49;
export const SLIDER_STEP_SIZE = 1;

export const marksDaysFilterSelection = [
  {
    value: MIN_SLIDER_VALUE,
    label: 'Days',
  },
  {
    value: MAX_SLIDER_VALUE,
    label: 'Days',
  },
];

export type SelectionOption = 'Selection';

type getLimitFromOptionAndSelection = {
  option: MapFilterOptions | SelectionOption;
  limitSelection: number;
}

export const parseLabelForSlider = (value: number): string => {
  if (value === MIN_SLIDER_VALUE) {
    return 'All overdue';
  }
  if (value < 0) {
    return `${value} overdue`;
  }
  if (value === MAX_SLIDER_VALUE) {
    return 'Show all';
  }
  return `${value}`;
}

export const getLowerLimitFromOption = ({ limitSelection, option }: getLimitFromOptionAndSelection): number => {
  if (option === MapFilterOptions.Good) {
    return 31;
  } else if (option === MapFilterOptions.OneMonth) {
    return 0;
  } else if (option === MapFilterOptions.OneWeek) {
    return 0;
  } else if (option === MapFilterOptions.Overdue) {
    return MIN_SLIDER_VALUE;
  }

  return limitSelection;
};

export const getUpperLimitFromOption = ({ limitSelection, option }: getLimitFromOptionAndSelection): number => {
  if (option === MapFilterOptions.Good) {
    return MAX_SLIDER_VALUE;
  } else if (option === MapFilterOptions.OneMonth) {
    return 31;
  } else if (option === MapFilterOptions.OneWeek) {
    return 7;
  } else if (option === MapFilterOptions.Overdue) {
    return -1;
  }

  return limitSelection;
};