import { IClientRequestPackage, minimumStringLength } from '@flowplan/flowplan-shared';
import { trimStringAndCheckLength } from '@hsjakobsen/utilities';
import { IClientInput } from '../../../common/interfacesInput';

export const validateInputClient = (inputData: IClientInput): IClientRequestPackage => {
  const validationOutput: IClientRequestPackage = {
    success: false,
  };

  const response = trimStringAndCheckLength(inputData.inputName, 'Name', minimumStringLength, false);
  if (inputData.isUpdate) {
    validationOutput.id = inputData.id;
  }
  validationOutput.message = response.feedback;
  validationOutput.name = response.result;
  validationOutput.success = response.success;

  return validationOutput;
};
