import BuildIcon from '@mui/icons-material/Build';
import { Fragment } from 'react';
import FpText from '../../../../common/components/FpText/FpText';
import { Maintenance } from '../../../services/company-model-types';
import styles from '../Common/LowUsage.module.scss';
import LowUsageSpacer from '../Common/LowUsageSpacer';
import LowUsageWizardContent from '../Common/LowUsageWizardContent';
import { getLowUsageDays } from '../low-usage-utils';

type Props = {
  avgDailyConsumption: number;
  daysSinceLastFlowEvent: number;
  noConsumptionState: boolean;
  maintenance: Maintenance;
};

const LowUsageMaintenance = ({
  avgDailyConsumption,
  daysSinceLastFlowEvent,
  maintenance,
  noConsumptionState,
}: Props): JSX.Element => {
  const {
    cycleBasedMaintenance,
    cyclesAverageHistorically,
    cyclesCurrent,
    cyclesInterval,
    featureEnabled,
    maintenanceAtLiters,
    maintenanceDate,
    maintenanceDays,
    maintenanceUsed,
  } = maintenance;

  const averageToUse = cycleBasedMaintenance ? cyclesAverageHistorically : avgDailyConsumption;
  const maintenanceCapacity = cycleBasedMaintenance ? cyclesInterval : maintenanceAtLiters;
  const maintenanceUsage = cycleBasedMaintenance ? cyclesCurrent : maintenanceUsed;
  const maintenanceState = maintenanceCapacity - maintenanceUsage;

  const lowUsageDays = getLowUsageDays({
    daysSinceLastFlowEvent,
    actionDate: maintenanceDate,
    actionDays: maintenanceDays,
  });

  const estimatedMaintenanceUsage = averageToUse * lowUsageDays;

  const maintenanceUnit = cycleBasedMaintenance ? 'Cups' : 'L';
  const maintenanceInterval = ' / ' + maintenanceCapacity + maintenanceUnit;
  const maintenanceStatus = Math.round(maintenanceState) + maintenanceUnit + maintenanceInterval;
  const estimatedMaintenanceState = maintenanceState - estimatedMaintenanceUsage;
  const newEstimatedMaintenanceStatus = Math.round(estimatedMaintenanceState) + maintenanceUnit + maintenanceInterval;

  const maintenancePercentageRemaining = Math.round((maintenanceState / maintenanceCapacity) * 100);
  const estimatedMaintenancePercentageRemaining = Math.round((estimatedMaintenanceState / maintenanceCapacity) * 100);

  return (
    <LowUsageWizardContent title="Maintenance" icon={<BuildIcon sx={{ height: 60, width: 60, color: 'gray' }} />}>
      {featureEnabled && (
        <Fragment>
          <FpText variant="body1">Current:</FpText>
          <div className={maintenancePercentageRemaining < 0 ? styles.warning : styles.healthy}>
            <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
              {maintenanceStatus}
            </FpText>
          </div>
          <FpText variant="body1">{maintenancePercentageRemaining}% remaining</FpText>
          <LowUsageSpacer height={10} />
          <FpText variant="body1">Estimate:</FpText>
          {!noConsumptionState ? (
            <Fragment>
              <div className={estimatedMaintenancePercentageRemaining < 0 ? styles.warning : styles.healthy}>
                <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
                  {newEstimatedMaintenanceStatus}
                </FpText>
              </div>
              <FpText variant="body1">
                {estimatedMaintenancePercentageRemaining}%{' '}
                {estimatedMaintenancePercentageRemaining < 0 ? 'overdue' : 'remaining'}
              </FpText>
            </Fragment>
          ) : (
            <FpText variant="body1">No consumption data</FpText>
          )}
        </Fragment>
      )}
      {!featureEnabled && (
        <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
          Feature not enabled
        </FpText>
      )}
    </LowUsageWizardContent>
  );
};

export default LowUsageMaintenance;
