import { getSerialNumberToShow, notAvailable } from '@flowplan/flowplan-shared';
import { dateFormatYMD, timeFormatHMS } from '@hsjakobsen/utilities/lib/date';
import { Fragment, memo, useCallback, useEffect } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { getDateObject } from '../../../../Utility/time';
import DetailsItem from '../Common/DetailsItem';

const DeviceConfigurationComponent = memo((): JSX.Element => {
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const flowplanClientSelected = useStoreState((state) => state.adminDataModel.flowplanClientSelected);

  const connectionData = useStoreState((state) => state.deviceConfigModel.connectionData);
  const deviceConfiguration = useStoreState((state) => state.deviceConfigModel.deviceConfiguration);

  const retrieveConnectionData = useStoreActions((actions) => actions.deviceConfigModel.retrieveConnectionData);
  const retrieveDeviceConfiguration = useStoreActions(
    (actions) => actions.deviceConfigModel.retrieveDeviceConfiguration,
  );

  const retrieveData = useCallback(async () => {
    await retrieveDeviceConfiguration(baseFilter.infoBasic.id);

    await retrieveConnectionData({
      deviceId: baseFilter.infoBasic.id,
      flowplanClientId: flowplanClientSelected,
    });
  }, [baseFilter, flowplanClientSelected, retrieveConnectionData, retrieveDeviceConfiguration]);

  useEffect(() => {
    retrieveData();
  }, [retrieveData]);

  if (deviceConfiguration === undefined || deviceConfiguration.id === undefined) {
    return <p>No configuration information found</p>;
  }

  const infoForSimCard = deviceConfiguration.ccid === '-1' ? notAvailable : deviceConfiguration.ccid;
  return (
    <Fragment>
      <Header as="h3">Device information</Header>
      <Grid stackable columns={3} celled>
        <Grid.Column>
          <DetailsItem title="Device name:" value={baseFilter.infoBasic.name} translateValue={false} />
        </Grid.Column>

        <Grid.Column>
          <DetailsItem
            title="Flowplan device id:"
            value={getSerialNumberToShow(baseFilter.infoBasic.flowplanDeviceId)}
          />
        </Grid.Column>

        <Grid.Column>
          <DetailsItem
            title="Configuration received"
            value={'' + getDateObject(deviceConfiguration.receivedTime).format(dateFormatYMD + ' ' + timeFormatHMS)}
          />
        </Grid.Column>
      </Grid>

      <Header as="h3">Configuration and stats</Header>
      <Grid stackable columns={3} celled>
        <Grid.Column>
          <DetailsItem
            title="Heartbeat interval"
            value={'Once every ' + deviceConfiguration.heartbeatInterval / 60 + ' hours'}
          />
          <DetailsItem
            title="Payloads collected before sending"
            value={deviceConfiguration.flowEventsDepth + ' events'}
          />
          <DetailsItem title="Leak detection time" value={deviceConfiguration.flowEventAutoFlush + ' minutes'} />
          <DetailsItem
            title="Time after last FlowEvent till autosend"
            value={deviceConfiguration.flowEventsAggregrationTime + ' minutes'}
          />
          <DetailsItem
            title="Grouped Event threshold"
            value={deviceConfiguration.groupedFlowEventThreshold + ' pulses'}
          />
          <DetailsItem title="Small events limit" value={deviceConfiguration.smallFlowEventThreshold + ' pulses'} />
        </Grid.Column>
        <Grid.Column>
          <DetailsItem title="Max uplinks per hour" value={'' + deviceConfiguration.uplinksLimit} />
          <DetailsItem
            title="Feature set"
            value={deviceConfiguration.featureSet === 1 ? 'Continous mode' : 'Normal mode'}
          />
          <DetailsItem
            title="Pause between flowEvents"
            value={deviceConfiguration.flowEventDetectionTimeout + ' seconds'}
          />
          <DetailsItem title="Uplink max size" value={deviceConfiguration.uplinkMaxSize + ' bytes'} />
          <DetailsItem title="Sim card number" value={'' + infoForSimCard} />
          <DetailsItem
            title="Config NB"
            translateValue={false}
            value={JSON.stringify(deviceConfiguration.nbConfig)
              .replace('{', '')
              .replace('}', '')
              .replaceAll('"', '')
              .split(',')
              .join('\n')}
          />
        </Grid.Column>
        <Grid.Column>
          <DetailsItem title="Connections - total" value={'' + connectionData.connectionsTotal} />
          <DetailsItem title="Transmissions - total" value={'' + connectionData.transmissionsTotal} />
          <DetailsItem title="Errors - total" value={'' + connectionData.errorsTotal} />
          <DetailsItem title="Connections - since serial change" value={'' + connectionData.connectionsSerialChange} />
          <DetailsItem
            title="Transmissions - since serial change"
            value={'' + connectionData.transmissionsSerialChange}
          />
          <DetailsItem title="Errors - since serial change" value={'' + connectionData.errorsSerialChange} />
        </Grid.Column>
      </Grid>
    </Fragment>
  );
});

export default DeviceConfigurationComponent;
