import React, { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { IDropdownData } from '../../common/interfacesFrontend';
import './DropdownListMultipleSearch.scss';

interface IDropdownListMultipleSearch {
  items: IDropdownData[];
  placeholder: string;
  selectedValue: number[] | string[];
  onChangeFunction(e: SyntheticEvent, data: DropdownProps): void;
}

const DropdownListMultipleSearch = ({
  selectedValue,
  placeholder,
  items,
  onChangeFunction,
}: IDropdownListMultipleSearch): JSX.Element => {
  return (
    <Dropdown
      placeholder={placeholder}
      value={selectedValue}
      onChange={onChangeFunction}
      fluid
      multiple
      closeOnChange
      search
      selection
      options={items}
    />
  );
};

export default DropdownListMultipleSearch;
