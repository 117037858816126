import { Color, DeviceStatus, notAvailable } from '@flowplan/flowplan-shared';
import BatteryIcon from '../../assetstore/icons/power/BatteryIcon.png';
import BatteryIconUnknown from '../../assetstore/icons/power/BatteryIconUnknown.png';
import psuflowplan from '../../assetstore/icons/power/psuflowplan.png';
import { isNetworkGSMGT } from '../../Utility/networkTypeCheck';
import { BatteryStatusInformation, getPowerTooltip } from '../../Utility/Texts';
import './BatteryStatus.scss';

interface IBatteryStatus {
  deviceStatus: number;
  firmware: string;
  network: number;
  percentageStatus: number;
  showBatteryInformation: boolean;
}

interface IFillerStyle {
  height: string;
  background?: string;
}

const BatteryStatus = ({
  deviceStatus,
  firmware,
  network,
  percentageStatus,
  showBatteryInformation,
}: IBatteryStatus): JSX.Element => {
  let tooltip = getPowerTooltip({ network, percentageStatus });
  let batteryStatusToShow;

  if (deviceStatus === DeviceStatus.Pending) {
    tooltip = BatteryStatusInformation.Pending;
    batteryStatusToShow = BatteryIconUnknown;
    return <img className="battery-status" alt={tooltip} title={tooltip} src={batteryStatusToShow} />;
  }

  const isGSM = isNetworkGSMGT(network);
  if (!showBatteryInformation && !isGSM) {
    tooltip =
      firmware === undefined || firmware === notAvailable
        ? BatteryStatusInformation.FirmwareUnknown
        : BatteryStatusInformation.ActivityInfoNA;
    batteryStatusToShow = BatteryIconUnknown;
    return <img className="battery-status" alt={tooltip} title={tooltip} src={batteryStatusToShow} />;
  }

  if (isGSM) {
    batteryStatusToShow = psuflowplan;
    return <img className="battery-status" alt={tooltip} title={tooltip} src={batteryStatusToShow} />;
  }

  const fillerStyle: IFillerStyle = { height: `${percentageStatus}%` };
  if (percentageStatus > 20) {
    fillerStyle.background = Color.Month;
  } else if (percentageStatus >= 10) {
    fillerStyle.background = Color.Week;
  } else {
    fillerStyle.background = Color.Overdue;
  }

  return (
    <div className="battery-status-container">
      <div className="battery-status-progress-bar" aria-label={tooltip} title={tooltip}>
        <div className="battery-status-filler" style={fillerStyle} />
        <img className="battery-status" alt={tooltip} title={tooltip} src={BatteryIcon} />
      </div>
    </div>
  );
};

export default BatteryStatus;
