import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IVersionHistoryBackoffice } from '@flowplan/flowplan-shared/lib/versionHistory/versionHistory';
import { useEffect, useState } from 'react';
import { useStoreActions } from '../Store';
import { IResponse } from '../modules/server-requests/server-requests-model';

type LastSeenInfo = {
    showReleaseDialog: boolean;
    serverVersion: string;
    serverVersionDescription: string;
    showReload: boolean;
}

const defaultLastSeenInfo: LastSeenInfo = {
    showReleaseDialog: false,
    serverVersion: '',
    serverVersionDescription: '',
    showReload: false
}

export function useVersionCheck(navigationCount: number) {
    const [versionCheckInfo, setVersionCheckInfo] = useState<LastSeenInfo>(defaultLastSeenInfo);

    const get = useStoreActions((actions) => actions.serverRequestsModel.get);

    useEffect(() => {
        const checkVersion = navigationCount % 4 === 0;
        if (navigationCount > 0 && checkVersion) {
            const currentVersion = import.meta.env.VITE_APP_VERSION;
            (get({ route: Routes.versionHistoryBackoffice, withoutCredentials: true }) as Promise<IResponse>).then((response) => {
                const serverVersion: IVersionHistoryBackoffice = response.data as IVersionHistoryBackoffice;
                if (serverVersion && currentVersion) {
                    setVersionCheckInfo({
                        showReleaseDialog: serverVersion.name !== currentVersion,
                        serverVersion: serverVersion.name,
                        serverVersionDescription: serverVersion.description,
                        showReload: true
                    });

                } else {
                    setVersionCheckInfo({
                        serverVersion: 'Server unavailable',
                        serverVersionDescription: '',
                        showReleaseDialog: false,
                        showReload: false
                    });
                }
            });
        } else {
            setVersionCheckInfo(defaultLastSeenInfo);

        }
    }, [navigationCount, get]);
    return versionCheckInfo;
}