import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { InstallationCleaningHistory } from '@flowplan/flowplan-shared/lib/installation.cleaning.history/installation.cleaning.history';
import { sortByKey } from '@hsjakobsen/utilities';
import { Action, action, Thunk, thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { IStoreModel } from '../../Models';
import { IFilterNotification } from './installation-history-interfaces';

export interface InstallationDataModel {
  loadingNotifications: boolean;
  notifications: IFilterNotification[];
  notificationsFiltered: IFilterNotification[];
  setNotifications: Action<InstallationDataModel, IFilterNotification[]>;
  setNotificationsFiltered: Action<InstallationDataModel, IFilterNotification[]>;

  notificationFiltersSelection: number[];
  setNotificationFilterSelection: Action<InstallationDataModel, number[]>;

  setLoadingNotifications: Action<InstallationDataModel, boolean>;
  getInstallationHistory: Thunk<InstallationDataModel, void, void, IStoreModel>;

  // Cleaning history
  loadingCleaningHistory: boolean;
  cleaningHistory: InstallationCleaningHistory[];
  setCleaningHistory: Action<InstallationDataModel, InstallationCleaningHistory[]>;

  setLoadingCleaningHistory: Action<InstallationDataModel, boolean>;
  getInstallationCleaningHistory: Thunk<InstallationDataModel, void, void, IStoreModel>;


  onActionsThatFilterNotifications: ThunkOn<InstallationDataModel, void, IStoreModel>;
}

const installationDataModel: InstallationDataModel = {
  loadingNotifications: false,

  notifications: [],
  setNotifications: action((state, payload) => {
    state.notifications = payload;
  }),
  notificationsFiltered: [],
  setNotificationsFiltered: action((state, payload) => {
    state.notificationsFiltered = payload;
  }),

  notificationFiltersSelection: [],
  setNotificationFilterSelection: action((state, payload) => {
    sortByKey(payload, 'createdOn', false);
    state.notificationFiltersSelection = payload;
  }),

  getInstallationHistory: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingNotifications(true);
    const deviceId = getStoreState().graphSettingsModel.selectedInstallation.infoBasic.id;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.notifications + deviceId });
    if (requestResponse.success) {
      actions.setNotifications(requestResponse.data);
    }
    actions.setLoadingNotifications(false);
  }),

  setLoadingNotifications: action((state, payload) => {
    state.loadingNotifications = payload;
  }),

  getInstallationCleaningHistory: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingCleaningHistory(true);
    const deviceId = getStoreState().graphSettingsModel.selectedInstallation.infoBasic.id;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.MLInstallationCleaningHistory + deviceId });
    if (requestResponse.success) {
      actions.setCleaningHistory(requestResponse.data);
    }
    actions.setLoadingCleaningHistory(false);
  }),

  cleaningHistory: [],
  setCleaningHistory: action((state, payload) => {
    state.cleaningHistory = payload;
  }),

  loadingCleaningHistory: false,
  setLoadingCleaningHistory: action((state, payload) => {
    state.loadingCleaningHistory = payload;
  }),

  onActionsThatFilterNotifications: thunkOn(
    (actions) => [
      actions.setNotifications,
      actions.setNotificationFilterSelection,
    ],
    async (actions, target, { getState }) => {
      const state = getState();
      const notifications = state.notifications;
      const notificationFiltersSelected = state.notificationFiltersSelection;
      const notificationsFiltered = notifications.filter((item) => notificationFiltersSelected.length === 0 || notificationFiltersSelected.indexOf(item.notificationTypeId) > -1)
      actions.setNotificationsFiltered(notificationsFiltered.reverse());
    },
  ),
};

export default installationDataModel;