// @flow
/**
 * @file FpPagination.tsx
 * @description A reusable pagination component using Material-UI's Pagination and Stack components.
 * @author [Andreas E. Eriksen] <Andreas@Flowplan.dk>
 * @date 2024-08-01
 */

import { SxProps, Theme } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React from 'react';

/**
 * Props for the FpPagination component.
 * @typedef {Object} Props
 * @property {number} count - Total number of pages.
 * @property {number} page - Current active page.
 * @property {(event: React.ChangeEvent<unknown>, page: number) => void} onChange - Callback function that is called when the page is changed.
 * @property {'text' | 'outlined'} [variant='text'] - The variant to use for the pagination component.
 * @property {SxProps<Theme>} [sx] - The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {'primary' | 'secondary' | 'standard'} [color='standard'] - The color of the pagination component.
 * @property {boolean} [disabled=false] - If `true`, the pagination component is disabled.
 */
type Props = {
  count: number;
  page: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  variant?: 'text' | 'outlined';
  sx?: SxProps<Theme>;
  color?: 'primary' | 'secondary' | 'standard';
  disabled?: boolean;
};

/**
 * A reusable pagination component using Material-UI's Pagination and Stack components.
 * @param {Props} props - The properties for the FpPagination component.
 * @returns {JSX.Element} The rendered pagination component.
 */
const FpPagination = ({
  count,
  page,
  onChange,
  variant = 'text',
  sx,
  color = 'standard',
  disabled = false,
}: Props): JSX.Element => {
  return (
    <Stack spacing={2} sx={sx}>
      <Pagination count={count} page={page} onChange={onChange} variant={variant} color={color} disabled={disabled} />
    </Stack>
  );
};

FpPagination.displayName = 'FpPagination';

export default FpPagination;
