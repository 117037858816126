import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from 'react';
import { defaultModalConfirm } from '../../../../common/defaults';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import DeviceConfiguration from '../Configuration/DeviceConfiguration';

interface Props {
  alwaysLeftAlign?: boolean;
}
const InstallationConfiguration = (props: Props): JSX.Element => {
  const { alwaysLeftAlign } = props;
  const [showConfiguration, setShowConfiguration] = useState(false);

  const closeModal = () => {
    setShowConfiguration(false);
  };

  return (
    <>
      <FpButtonOutlinedAligned
        alwaysLeftAlign={alwaysLeftAlign}
        disabled={false}
        className={'greyButton'}
        icon={<SettingsIcon />}
        onClick={() => {
          setShowConfiguration(true);
        }}
        label="View Beam details"
      />

      <Dialog open={showConfiguration} onClose={closeModal} maxWidth={false} fullScreen>
        <DialogTitle>Device configuration</DialogTitle>

        <DialogContent>
          <DeviceConfiguration />
        </DialogContent>
        <DialogActions>
          <FpButton color="secondary" onClick={closeModal}>
            {defaultModalConfirm}
          </FpButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InstallationConfiguration;
