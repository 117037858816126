import { Button, Popup } from 'semantic-ui-react';
import { useStoreActions, useStoreState } from '../../../../Store';
import SignalStrengthHistoryComponent from './SignalStrengthHistoryComponent';

const SignalStrengthHistoryToggle = (): JSX.Element => {
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const confirmationToggle = useStoreActions((actions) => actions.modalModel.confirmationToggle);

  const title = 'Signal strength history for: ' + baseFilter.infoBasic.name;
  const showExternalSensorHistory = () => {
    const content = <SignalStrengthHistoryComponent />;
    confirmationToggle({
      content,
      showModal: true,
      title,
      confirmationSize: 'lg',
    });
  };

  return (
    <Popup
      content="View signal strength historically"
      trigger={<Button icon="line graph" basic onClick={() => showExternalSensorHistory()} />}
    />
  );
};

export default SignalStrengthHistoryToggle;
