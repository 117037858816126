import { useEffect } from 'react';
import { Dimmer, Loader, SemanticSIZES } from 'semantic-ui-react';
import { getBody, lockscreen, unlockScreen } from '../../Utility/Loading';

interface ILoaderComponent {
  loading: boolean;
  size: SemanticSIZES;
  text?: string;
}

const LoaderComponent = ({ loading, size, text }: ILoaderComponent): JSX.Element => {
  const loadingText = text === undefined ? 'Fetching data' : text;

  useEffect(() => {
    if (loading) {
      lockscreen(getBody());
    } else {
      unlockScreen(getBody());
    }
  }, [loading]);

  return (
    <Dimmer active={loading} inline="centered">
      <Loader size={size}>
        <h3>{loadingText}</h3>
      </Loader>
    </Dimmer>
  );
};

export default LoaderComponent;
