import { Color } from '@flowplan/flowplan-shared';
import { ISimpleLineData } from '@flowplan/flowplan-shared/lib/interfaces/installationView';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { getDateObject } from '../../../../Utility/time';
import CustomTooltip from '../Parts/CustomTooltip';

interface ISimpleLineChart {
  dataUnit: string;
  data: ISimpleLineData[];
  domain?: [string | number, string | number];
  yAxisTicks?: number[];
}

const DateLinechart = ({ data, dataUnit, domain, yAxisTicks }: ISimpleLineChart): JSX.Element => {
  return (
    <ResponsiveContainer width={'100%'} height={400}>
      <LineChart
        data={data}
        margin={{
          bottom: 5,
          left: 20,
          right: 30,
          top: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(tick) => {
            let formattedTick = tick;
            if (String(tick).search(':') > -1) {
              formattedTick = String(tick).substring(0, 10);
            } else {
              formattedTick = getDateObject(tick).format(dateFormatYMD);
            }

            return formattedTick;
          }}
        />
        <YAxis type="number" domain={domain} ticks={yAxisTicks} />
        <RechartsTooltip
          content={<CustomTooltip dataUnit={dataUnit} dataUnitTwo="" formatNumber={false} allowZeroValues={true} />}
          cursor={{
            fill: 'rgba(0,0,0, 0.4)',
            stroke: Color.FlowplanBlue,
            strokeWidth: 1,
          }}
        />
        {/* <Legend /> */}
        {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
        <Line type="monotone" dataKey="value" stroke="#82ca9d" />
        <Line type="monotone" dataKey="extraValue" stroke="#FFca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DateLinechart;
