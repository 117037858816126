import React from 'react';
import { IBaseInputProps } from '../../common/interfacesFrontend';
import './DisabledInput.scss';

const DisabledInput = ({ inputText, inputValue, isFirst, isFirstInRow }: IBaseInputProps): JSX.Element => {
  let inputStyle = 'input-disabled' + (isFirst !== undefined && isFirst ? '-first' : '');
  inputStyle += isFirstInRow !== undefined ? ' input-row' : '';

  return (
    <div className={inputStyle}>
      <div className="input-disabled-span">{inputValue}</div>
      <label className="input-disabled-label">{inputText}</label>
    </div>
  );
};

export default DisabledInput;
