import './logrocket-setup';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license-pro';
import { StoreProvider } from 'easy-peasy';
import { StrictMode, lazy } from 'react';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import store from './Store';
import { muiLicense } from './config';
import theme from './config/theme';
import BrowserSupport from './modules/common/components/BrowserSupport/BrowserSupport';
import CacheBuster from './modules/common/components/CacheBusting/CacheBuster';
import SnackbarProvider from './modules/common/components/SnackbarProvider';
import Page from './modules/navigation/components/Routes/Page';
import {
  FlowplanAdminUserRoute,
  ClientRoute,
  AdminUserRoute,
  PrivateRoute,
  TapsPrivateRoute,
  WaterFilterPrivateRoute,
  WaterFilterAdminUserRoute,
  DashboardPrivateRoute,
  TapsAdminUserRoute,
} from './modules/navigation/components/Routes/PageRoutes';
import './stylesheets/app.scss';
import './stylesheets/boxStyles.scss';
import './stylesheets/index.scss';
import './stylesheets/inputStyles.scss';
import { PageTitles, Paths } from './modules/navigation/containers/Navigation/navigation-types';

LicenseInfo.setLicenseKey(muiLicense);

const Login = lazy(() => import('./pages/Auth/Login'));

const TapKegInventory = lazy(() => import('./pages/Taps/TapKegStatus'));
const TapStock = lazy(() => import('./pages/Taps/TapStock'));
const Taps = lazy(() => import('./pages/Taps/Taps'));
const TapDashboard = lazy(() => import('./pages/Taps/Dashboard'));
const TapTechnician = lazy(() => import('./pages/Taps/TapTechnician'));

const AdminArea = lazy(() => import('./pages/AdminSection/AdminArea'));
const BulkInstallations = lazy(() => import('./pages/BulkInstallations/BulkInstallations'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const Support = lazy(() => import('./pages/Support/Support'));
const Installations = lazy(() => import('./pages/Installations/Installations'));
const WorkOrders = lazy(() => import('./pages/WorkOrders/WorkOrders'));
const Orders = lazy(() => import('./pages/Orders/Orders'));
const Locations = lazy(() => import('./pages/Locations/Locations'));
const ClientList = lazy(() => import('./pages/Clients/Clients'));
const Inventory = lazy(() => import('./pages/Inventory/Inventory'));
const PerformanceReports = lazy(() => import('./pages/PerformanceReports/PerformanceReports'));
const CompanySettings = lazy(() => import('./pages/CompanySettings/CompanySettings'));
const PasswordForgotten = lazy(() => import('./pages/Auth/PasswordForgotten'));
const PasswordResetPage = lazy(() => import('./pages/Auth/PasswordReset'));
const Users = lazy(() => import('./pages/Users/Users'));
const InventoryBottles = lazy(() => import('./modules/inventory-bottles/components/InventoryBottles'));
const InstallationDetailsSection = lazy(() => import('./pages/Installations/Installation.Details'));
const RegisterSample = lazy(() => import('./modules/inventory-bottles/components/RegisterSample'));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <BrowserRouter>
              <BrowserSupport>
                <CacheBuster>
                  <App>
                    <Routes>
                      <Route
                        path={Paths.login}
                        element={
                          <Page title={PageTitles.login}>
                            <Login />
                          </Page>
                        }
                      />
                      <Route
                        path={Paths.passwordReset + '/:username/:role/:passwordResetToken'}
                        element={
                          <Page title={PageTitles.passwordReset}>
                            <PasswordResetPage />
                          </Page>
                        }
                      />
                      <Route
                        path={Paths.passwordForgotten}
                        element={
                          <Page title={PageTitles.passwordForgotten}>
                            <PasswordForgotten />
                          </Page>
                        }
                      />
                      <Route
                        path={Paths.dashboard}
                        element={
                          <WaterFilterPrivateRoute title={PageTitles.dashboard}>
                            <Dashboard />
                          </WaterFilterPrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.clients}
                        element={
                          <ClientRoute title={PageTitles.clients}>
                            <ClientList />
                          </ClientRoute>
                        }
                      />
                      <Route
                        path={Paths.waterfilterLocations}
                        element={
                          <WaterFilterPrivateRoute title={PageTitles.waterfilterLocations}>
                            <Locations />
                          </WaterFilterPrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.installations}
                        element={
                          <WaterFilterPrivateRoute title={PageTitles.installations}>
                            <Installations />
                          </WaterFilterPrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.support}
                        element={
                          <PrivateRoute title={PageTitles.support}>
                            <Support />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.workOrders}
                        element={
                          <WaterFilterPrivateRoute title={PageTitles.workOrders}>
                            <WorkOrders />
                          </WaterFilterPrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.tapDashboard}
                        element={
                          <TapsPrivateRoute title={PageTitles.tapDashboard}>
                            <TapDashboard />
                          </TapsPrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.tapLocations}
                        element={
                          <TapsPrivateRoute title={PageTitles.tapLocations}>
                            <Taps />
                          </TapsPrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.tapStock + '/:locationId?'}
                        element={
                          <TapsPrivateRoute title={PageTitles.tapStock}>
                            <TapStock />
                          </TapsPrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.tapKeg + `/:locationId?/:sublocationId?`}
                        element={
                          <TapsPrivateRoute title={PageTitles.tapKeg}>
                            <TapKegInventory />
                          </TapsPrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.tapTechnician + '/:locationId?/:sublocationId?/:sensorId?'}
                        element={
                          <TapsPrivateRoute title={PageTitles.tapTechnician}>
                            <TapTechnician />
                          </TapsPrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.installationDetails}
                        element={
                          <WaterFilterPrivateRoute title={PageTitles.installationDetails}>
                            <InstallationDetailsSection />
                          </WaterFilterPrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.reports}
                        element={
                          <WaterFilterAdminUserRoute title={PageTitles.reports}>
                            <PerformanceReports />
                          </WaterFilterAdminUserRoute>
                        }
                      />
                      <Route
                        path={Paths.tapSettings}
                        element={
                          <TapsAdminUserRoute title={PageTitles.tapSettings}>
                            <CompanySettings />
                          </TapsAdminUserRoute>
                        }
                      />
                      <Route
                        path={Paths.users}
                        element={
                          <AdminUserRoute title={PageTitles.users}>
                            <Users />
                          </AdminUserRoute>
                        }
                      />
                      <Route
                        path={Paths.inventoryBeams}
                        element={
                          <PrivateRoute title={PageTitles.inventoryBeams}>
                            <Inventory />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.orders}
                        element={
                          <PrivateRoute title={PageTitles.orders}>
                            <Orders />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={Paths.admin + '/:section?/:flowplanClientId?'}
                        element={
                          <FlowplanAdminUserRoute title={PageTitles.admin}>
                            <AdminArea />
                          </FlowplanAdminUserRoute>
                        }
                      />
                      <Route
                        path={Paths.bulkInstallations}
                        element={
                          <FlowplanAdminUserRoute title={PageTitles.bulkInstallations}>
                            <BulkInstallations />
                          </FlowplanAdminUserRoute>
                        }
                      />
                      <Route
                        path={Paths.inventoryBottles}
                        element={
                          <FlowplanAdminUserRoute title={PageTitles.inventoryBottles}>
                            <InventoryBottles />
                          </FlowplanAdminUserRoute>
                        }
                      />
                      <Route
                        path={Paths.water}
                        element={
                          <FlowplanAdminUserRoute title={PageTitles.water}>
                            <RegisterSample />
                          </FlowplanAdminUserRoute>
                        }
                      />
                      <Route
                        path={Paths.root}
                        element={
                          <DashboardPrivateRoute title={PageTitles.dashboard}>
                            <Dashboard />
                          </DashboardPrivateRoute>
                        }
                      />
                    </Routes>
                  </App>
                </CacheBuster>
              </BrowserSupport>
            </BrowserRouter>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StoreProvider>
  </StrictMode>,
);
