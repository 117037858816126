import {
  minVersionRequiredChrome,
  minVersionRequiredFirefox,
  minVersionRequiredOpera,
  minVersionRequiredSafari,
} from '../common/constsFrontend';

// See the following link for info
// http://www.compulsivecoders.com/tech/javascript-es6-typeerror-object-entries-is-not-a-function/

// Edge is detected as chrome
// Internet explorer not supported

interface IBrowserInfo {
  name: string;
  version: string;
}

export const getBrowserInfo = (userAgent: string, appName: string, appVersion: string): IBrowserInfo => {
  let tem;
  let M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return { name: 'IE ', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = userAgent.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [appName, appVersion, '-?'];
  tem = userAgent.match(/version\/(\d+)/i);
  if (tem != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

export const checkBrowserCommand = (): boolean => {
  const browserInfo = getBrowserInfo(navigator.userAgent, navigator.appName, navigator.appVersion);
  const userVersion = parseFloat(browserInfo.version);

  if (browserInfo.name === 'IE') {
    return false;
  }

  if (browserInfo.name === 'Firefox') {
    return isVersionCompatible(minVersionRequiredFirefox, userVersion);
  }

  if (browserInfo.name === 'Chrome') {
    return isVersionCompatible(minVersionRequiredChrome, userVersion);
  }

  if (browserInfo.name === 'Safari') {
    return isVersionCompatible(minVersionRequiredSafari, userVersion);
  }

  if (browserInfo.name === 'Opera') {
    return isVersionCompatible(minVersionRequiredOpera, userVersion);
  }
  // Mobile
  // Needs to be added

  // if not found in the statements above, return false
  return false;
};

const isVersionCompatible = (reqVersion: number, userVersion: number) => userVersion >= reqVersion;
