import { notAvailable } from '@flowplan/flowplan-shared';
import { Dayjs } from 'dayjs';
import { diff, getDateObject } from '../../../Utility/time';
import { FaultyDeviceOverviewMarkerStatus } from '../assets/faulty-devices-icons';

interface IFaultyDeviceStatus {
  currentTime: Dayjs;
  lastSeen: string;
}

export const faultyDeviceStatus = ({ currentTime, lastSeen }: IFaultyDeviceStatus): number => {
  let markerStatusCode = FaultyDeviceOverviewMarkerStatus.short;

  if (lastSeen === notAvailable) {
    markerStatusCode = FaultyDeviceOverviewMarkerStatus.pending;
    return markerStatusCode;
  }

  const lastSeenDate = getDateObject(lastSeen);
  const timeSinceActive = diff(currentTime, lastSeenDate, 'days');

  if (timeSinceActive >= 0 && timeSinceActive < 2) {
    // 0 - 2 days
    markerStatusCode = FaultyDeviceOverviewMarkerStatus.online;
  } else if (timeSinceActive >= 2 && timeSinceActive <= 14) {
    // 2 - 14 days
    markerStatusCode = FaultyDeviceOverviewMarkerStatus.short;
  } else if (timeSinceActive > 14 && timeSinceActive <= 30) {
    // 15-30 days
    markerStatusCode = FaultyDeviceOverviewMarkerStatus.medium;
  } else if (timeSinceActive > 30 && timeSinceActive <= 60) {
    // 31-60 days
    markerStatusCode = FaultyDeviceOverviewMarkerStatus.long;
  } else {
    // gone for more than 60 days
    markerStatusCode = FaultyDeviceOverviewMarkerStatus.veryLong;
  }

  return markerStatusCode;
};
