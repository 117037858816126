import { forwardRef } from 'react';
import MuiDialogActions, { DialogActionsProps as MuiDialogActionsProps } from '@mui/material/DialogActions';

const DialogActions = forwardRef<HTMLDivElement, MuiDialogActionsProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <MuiDialogActions {...rest} ref={ref}>
      {children}
    </MuiDialogActions>
  );
});

DialogActions.displayName = 'DialogActions';

export default DialogActions;
