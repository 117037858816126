import { ChangeEvent } from 'react';
import './Checkbox.scss';

interface ICheckbox {
  inputKey: string;
  inputText: string;
  inputValue: boolean;
  wideFormat?: boolean;
  disabled?: boolean;
  handleInput(e: ChangeEvent, inputKey: string): void;
}

const Checkbox = ({ handleInput, inputKey, inputText, inputValue, wideFormat, disabled }: ICheckbox): JSX.Element => {
  const extraStyles = wideFormat === undefined ? 'checkbox-alignment-left' : 'checkbox-alignment-wide';

  return (
    <div className={'input-checkbox ' + extraStyles}>
      <label className="input-checkbox-label">
        <input
          type="checkbox"
          disabled={disabled}
          className="checkbox-input"
          name={inputText}
          checked={inputValue}
          onChange={(e) => handleInput(e, inputKey)}
        />
        {inputText}
      </label>
    </div>
  );
};

export default Checkbox;
