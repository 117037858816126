// NOTE: this map is bridging the gap between the value stored in our database,
// and the value used by weglot for changing language settings.
// When we extend our support for languages, this map should be extended as well,
// along with Languages enum in the shared package
export const languageToWeglotValueMap = new Map([
  ['English', 'en'],
  ['Dansk', 'da'],
  ['Italiano', 'it']
])

