import { formatError, formatResponse } from '@hsjakobsen/utilities';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Thunk, thunk } from 'easy-peasy';
import { IStoreModel } from '../../Models';
import { LocalStoreBasic, getBasicSetting } from '../../Utility/LocalStorageFunctions';
import { getDateObject } from '../../Utility/time';
import { getBaseRequestResponse } from '../../common/constsFrontend';
import { serverUrl } from '../../config';

const baseRequest = (route: string): string => {
  return serverUrl + route;
};

const getCredentials = (withCredentials: boolean, params?: unknown): AxiosRequestConfig | undefined => {
  const requestWithCredentials: AxiosRequestConfig = {
    params,
    withCredentials: true,
    headers: { Authorization: getBasicSetting(LocalStoreBasic.AccessToken) },
  };
  const credentials = withCredentials ? requestWithCredentials : undefined;
  return credentials;
};

export type IResponse = {
  message: string;
  success: boolean;
  data?: unknown;
  extraData?: unknown;
}

const parseServerErrorResponse = (error: AxiosError): IResponse => {
  const errorMessage: string = error.request.response;
  if (errorMessage === '') {
    return formatError('No response from server');
  }

  let response: IResponse;
  try {
    response = JSON.parse(errorMessage);
  } catch (e) {
    response = formatError('Error connecting to server');
  }

  return response;
};

type Get = {
  route: string,
  params?: unknown,
  noTokenCheck?: boolean;
  withoutCredentials?: boolean,
}

type Patch = {
  route: string,
  data?: unknown,
  noTokenCheck?: boolean;
  withoutCredentials?: boolean, // default is true
}

export type ServerRequestsModel = {
  evaluateToken: Thunk<ServerRequestsModel, void, void, IStoreModel>;

  get: Thunk<ServerRequestsModel, Get>;
  patch: Thunk<ServerRequestsModel, Patch>;
}

const serverRequestsModel: ServerRequestsModel = {
  get: thunk(async (actions, payload) => {
    const { route, withoutCredentials, params, noTokenCheck } = payload;
    if (!noTokenCheck) {
      await actions.evaluateToken();
    }

    let requestResponse: IResponse = getBaseRequestResponse();
    await axios
      .get(baseRequest(route), getCredentials(!withoutCredentials, params))
      .then((response) => {
        requestResponse = formatResponse(
          response.data.message,
          response.data.success,
          response.data.data,
          response.data.extraData,
        );
      })
      .catch((error: AxiosError) => {
        requestResponse = parseServerErrorResponse(error);
      });
    return requestResponse;
  }),

  patch: thunk(async (actions, payload) => {
    const { route, withoutCredentials, data, noTokenCheck } = payload;
    if (!noTokenCheck) {
      await actions.evaluateToken();
    }

    let requestResponse: IResponse = getBaseRequestResponse();
    await axios
      .patch(baseRequest(route), data, getCredentials(!withoutCredentials))
      .then((response) => {
        requestResponse = formatResponse(
          response.data.message,
          response.data.success,
          response.data.data,
          response.data.extraData,
        );
      })
      .catch((error: AxiosError) => {
        requestResponse = parseServerErrorResponse(error);
      });

    return requestResponse;
  }),

  evaluateToken: thunk(async (actions, payload, { getStoreActions }) => {
    const expiration = getBasicSetting(LocalStoreBasic.AccessTokenExpiring);
    const now = getDateObject(null);
    const tokenExpirationObj = getDateObject(expiration);
    const daysUntilExpiration = tokenExpirationObj.diff(now, 'days', true);
    if (expiration && daysUntilExpiration < 1.5) {
      await getStoreActions().authModel.useRefreshToken();
    }
  }),
};

export default serverRequestsModel;
