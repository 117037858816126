import {
  ILocationHistory,
  LocationEvents,
  SOURCE
} from '@flowplan/flowplan-shared/lib/location.history/location.history';
import { StockTypes } from '@flowplan/flowplan-shared/lib/stock/stock';
import { IUser } from '@flowplan/flowplan-shared/lib/users/users.database';
import { dateFormatDMY, timeFormatHM } from '@hsjakobsen/utilities/lib/date';
import _ from 'lodash';
import { getDateObject } from '../../Utility/time';
import { getStockMeasurementUnit } from '../stock/stock-utils';
import { IChanges, IChangesToInfo, IFormattedLocationHistory, IStockWarnings } from './location-history-interfaces';

export const formatLocationHistoryData = (
  dataSet: ILocationHistory,
  users: IUser[],
  forCSV = false,
): IFormattedLocationHistory => {
  const { changes, createdAt, event, source, userId } = dataSet;

  const timeStamp = getDateObject(createdAt).format(dateFormatDMY + ' ' + timeFormatHM);
  const title = translateLocationEvent(event);
  const username = getUserInfo({ forCSV, source, userId, users });
  const info = changesToInfo(event, changes as unknown);

  if (forCSV) {
    return {
      timeStamp,
      title,
      username,
      infoCSV: info.map((x) => `${x.changeName}: ${x.changeInfo}`),
    };
  }

  return {
    info,
    timeStamp,
    title,
    username,
  };
};

interface IGetUserInfo {
  source: SOURCE;
  userId: number;
  users: IUser[];
  forCSV: boolean;
}

const getUserInfo = ({ forCSV, source, userId, users }: IGetUserInfo) => {
  let userInfo = '';
  if (source === SOURCE.USER) {
    const userLookup = users.find((x) => x.id === userId);
    userInfo = 'User inactive';
    if (userLookup) {
      userInfo = userLookup.name;
      if (forCSV) {
        userInfo += ` (${userLookup.email})`;
      }
    } else if (!userLookup && userId < 3) {
      userInfo = 'Admin';
    }
  } else {
    userInfo = 'System';
  }
  return userInfo;
};

const translateLocationEvent = (event: LocationEvents) => {
  switch (event) {
    case LocationEvents.LOCATION_CREATED:
      return 'Location created';
    case LocationEvents.LOCATION_DELETED:
      return 'Location deleted';
    case LocationEvents.LOCATION_UPDATED:
      return 'Location updated';
    case LocationEvents.LOCATION_TAP_STOCK_CREATED:
      return 'Tap stock created';
    case LocationEvents.LOCATION_TAP_STOCK_DELETED:
      return 'Tap stock deleted';
    case LocationEvents.LOCATION_TAP_STOCK_RUNINGOUT:
      return 'Tap stock running out';
    case LocationEvents.LOCATION_TAP_STOCK_RUNOUT:
      return 'Tap stock depleted';
    case LocationEvents.LOCATION_TAP_STOCK_UPDATED:
      return 'Tap stock updated';
    case LocationEvents.LOCATION_TAP_STOCK_TOPUP:
      return 'Tap stock topped up';
    default:
      return 'Event not found';
  }
};


const changesToInfo = (event: LocationEvents, changes: unknown): IChangesToInfo[] => {
  const changesInfo: IChangesToInfo[] = [];

  if (event === LocationEvents.LOCATION_UPDATED || event === LocationEvents.LOCATION_STOCK_UPDATED) {
    const changesObj = changes as IChanges;
    const keys = Object.keys(changesObj);
    if (keys.length === 0) {
      return changesInfo;
    }
    keys.forEach((key: string) => {
      if (key === 'daysLeft') {
        return;
      }
      const change = changesObj[key];
      const keyName = key.replace(/([a-z])([A-Z])/g, '$1 $2');
      let changeName = _.capitalize(keyName);
      let changeInfo = `${change.old || 'N/A'} to ${change.new || 'N/A'}`;
      let extraChangeInfo = '';
      if (change.type) {
        const unit = getStockMeasurementUnit(change.type);
        const difference = Math.round(Number(change.new) - Number(change.old));
        changeName = _.capitalize(change.type);
        changeInfo = `Added ${difference} ${unit}.`;
        extraChangeInfo = `New total ${Math.round(Number(change.new))} ${unit}.`;
      } else if (key === 'clientId') {
        return;
      } else if (key === 'clientName') {
        changeName = 'Client changed';
        changeInfo = `${change.old} to ${change.new}`;
      }

      changesInfo.push({ changeInfo, changeName });
      if (extraChangeInfo !== '') {
        changesInfo.push({ changeName, changeInfo: extraChangeInfo });
      }
    });

    return changesInfo;
  }

  if (event === LocationEvents.LOCATION_STOCK_CREATED || event === LocationEvents.LOCATION_STOCK_DELETED) {
    const changesObj = changes as IChanges;
    const action = event === LocationEvents.LOCATION_STOCK_CREATED ? 'Added' : 'Removed';
    const type = String(changesObj['type']) as StockTypes;

    const changesInfo: IChangesToInfo[] = [{ changeName: action, changeInfo: _.capitalize(type) }];
    const unit = getStockMeasurementUnit(type);
    const extraChangeInfo = LocationEvents.LOCATION_STOCK_CREATED
      ? String(changesObj['totalAmount']) + unit
      : undefined;
    if (extraChangeInfo && event !== LocationEvents.LOCATION_STOCK_DELETED) {
      changesInfo.push({ changeName: 'Amount', changeInfo: extraChangeInfo });
    }
    return changesInfo;
  }
  if (event === LocationEvents.LOCATION_STOCK_RUNINGOUT || event === LocationEvents.LOCATION_STOCK_RUNOUT) {
    const changesCasted: IStockWarnings = changes as IStockWarnings;
    const { daysLeft, totalAmount, type } = changesCasted;
    const unit = getStockMeasurementUnit(type);
    const changeName = _.capitalize(type);
    const changeInfo = `Days left ${daysLeft.toFixed(0)}, remaining ${totalAmount.toFixed(1)} ${unit}.`;

    changesInfo.push({ changeInfo, changeName });

    return changesInfo;
  }

  switch (event) {
    case LocationEvents.LOCATION_CREATED:
    case LocationEvents.LOCATION_DELETED:
      return changesInfo;

    default:
      return [{ changeName: 'Event not found', changeInfo: '' }];
  }
};
