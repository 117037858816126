import { GridRowId } from '@mui/x-data-grid-premium';
import { action, Action, Thunk, thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { LatLngTuple } from 'leaflet';
import { mapCoordsDefault } from '../../../common/constsFrontend';
import { IPlacesResult } from '../../../Components/Peripherals/GoogleAPI/GooglePlacesSearchInterfaces';
import { IStoreModel } from '../../../Models';
import { handleDeleteRequest, handlePostRequest, handlePutRequest } from '../../server-requests/server-requests';
import { WorkOrderDirectionsItem, WorkOrderItemParsed, WorkOrderItemStatus, WorkOrderItemTaskTypes } from '../types/work-order-item-types';
import { WorkOrderInstallation, WorkOrderParsed } from '../types/work-order-types';
import { FlowplanProductTypes } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';

type FilterTasks = {
    daysLeft: number;
    distance: number;
}

type GetTasksForWorkOrder = {
    daysLeft: number;
    distance: number;
    installations: GridRowId[];
}

type CreateWorkOrderRequest = {
    daysLeft: number;
    installations: GridRowId[];
}

type UpdateWorkOrderItemTaskStatus = {
    workOrderItemId: number;
    task: WorkOrderItemTaskTypes;
    newStatus: WorkOrderItemStatus;
}

type getRouteRequest = {
    start: IPlacesResult;
    end: IPlacesResult;
    installations: WorkOrderDirectionsItem[];
}

export type WorkOrderModel = {
    getTasksForWorkOrder: Thunk<WorkOrderModel, GetTasksForWorkOrder, void, IStoreModel>;
    loadingTasksForWorkOrder: boolean;
    setLoadingTasksForWorkOrder: Action<WorkOrderModel, boolean>;

    filterTasks: Action<WorkOrderModel, FilterTasks>;
    setMapCenter: Action<WorkOrderModel, LatLngTuple>;
    mapCenter: LatLngTuple;

    workOrderTasks: WorkOrderInstallation[];
    setWorkOrderTasks: Action<WorkOrderModel, WorkOrderInstallation[]>

    workOrderTasksSelected: GridRowId[];
    setWorkOrderTasksSelected: Action<WorkOrderModel, GridRowId[]>

    workOrderTasksFiltered: WorkOrderInstallation[];
    setWorkOrderTasksFiltered: Action<WorkOrderModel, WorkOrderInstallation[]>

    createWorkOrder: Thunk<WorkOrderModel, CreateWorkOrderRequest, void, IStoreModel>;

    getWorkOrders: Thunk<WorkOrderModel, void, void, IStoreModel>;
    loadingWorkOrders: boolean;
    setLoadingWorkOrders: Action<WorkOrderModel, boolean>;

    workOrders: WorkOrderParsed[];
    setWorkOrders: Action<WorkOrderModel, WorkOrderParsed[]>

    workOrder: WorkOrderParsed | null;
    setWorkOrder: Action<WorkOrderModel, WorkOrderParsed | null>

    workOrderItem: WorkOrderItemParsed | null;
    setWorkOrderItem: Action<WorkOrderModel, WorkOrderItemParsed | null>

    getWorkOrderDetails: Thunk<WorkOrderModel, number, void, IStoreModel>;
    loadingWorkOrderDetails: boolean;
    setLoadingWorkOrderDetails: Action<WorkOrderModel, boolean>;

    updateWorkOrderItemTaskStatus: Thunk<WorkOrderModel, UpdateWorkOrderItemTaskStatus, void, IStoreModel>;

    viewingWorkOrders: boolean;
    setViewingWorkOrders: Action<WorkOrderModel, boolean>;

    viewingWorkOrderDetails: number | null;
    setViewingWorkOrderDetails: Action<WorkOrderModel, number | null>;

    getWorkOrderRoute: Thunk<WorkOrderModel, getRouteRequest, void, IStoreModel>;
    loadingWorkOrderRoute: boolean;
    setLoadingWorkOrderRoute: Action<WorkOrderModel, boolean>;

    removeWorkOrder: Thunk<WorkOrderModel, number, void, IStoreModel>; // workOrderId
    removeWorkOrderItem: Thunk<WorkOrderModel, number, void, IStoreModel>; // workOrderItemId

    onActionsThatUpdateWorkOrderDetails: ThunkOn<WorkOrderModel, void, IStoreModel>;
    onActionsThatUpdateWorkOrderList: ThunkOn<WorkOrderModel, void, IStoreModel>;
}

const workOrderModel: WorkOrderModel = {
    getTasksForWorkOrder: thunk(async (actions, payload, { getStoreActions }) => {
        actions.setWorkOrderTasks([]);
        actions.setLoadingTasksForWorkOrder(true);

        const { daysLeft, distance, installations } = payload;
        const params = { installations };

        const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/work-order/tasks/', params });
        actions.setLoadingTasksForWorkOrder(false);

        if (requestResponse.success) {
            const workOrderTasks = requestResponse.data as WorkOrderInstallation[];
            actions.setWorkOrderTasks(workOrderTasks);
            actions.filterTasks({ daysLeft, distance });
            const manualSelection = workOrderTasks.filter((item) => item.manualSelection);
            // TODO: Select all instead of just manual selection
            const manuallySelectedItems = manualSelection.map((item) => item.id)
            actions.setWorkOrderTasksSelected(manuallySelectedItems)

            if (manualSelection) {
                const { latitude, longitude } = manualSelection[0].installation.infoLocationAndClient;
                actions.setMapCenter([latitude, longitude]);
            }
        }
        return requestResponse.success;
    }),
    loadingTasksForWorkOrder: false,
    setLoadingTasksForWorkOrder: action((state, payload) => {
        state.loadingTasksForWorkOrder = payload;
    }),

    workOrderTasks: [],
    setWorkOrderTasks: action((state, payload) => {
        state.workOrderTasks = payload;
    }),

    mapCenter: mapCoordsDefault,
    setMapCenter: action((state, payload) => {
        state.mapCenter = payload;
    }),

    filterTasks: action((state, payload) => {
        const distanceToCheck = (payload.distance === 0 ? 0.1 : payload.distance) * 1000
        const filteredList = state.workOrderTasks.filter(
            (item) =>
                item.manualSelection || (item.daysLeft < payload.daysLeft && item.distances.find((item) => item.distance < distanceToCheck)),
        );
        state.workOrderTasksFiltered = filteredList;
    }),

    workOrderTasksSelected: [],
    setWorkOrderTasksSelected: action((state, payload) => {
        state.workOrderTasksSelected = payload;
    }),

    workOrderTasksFiltered: [],
    setWorkOrderTasksFiltered: action((state, payload) => {
        state.workOrderTasksFiltered = payload;
    }),

    createWorkOrder: thunk(async (actions, payload, { getState }) => {
        const workOrderTasks = getState().workOrderTasks;
        const installations: WorkOrderInstallation[] = [];
        payload.installations.forEach((installationId) => {
            const workOrderToSend = workOrderTasks.find((item) => item.id === installationId);
            if (workOrderToSend) {
                installations.push(workOrderToSend);
            }
        });
        const requestResponse = await handlePostRequest({ installations, daysLeft: payload.daysLeft }, '/work-order/', true);
        return requestResponse;
    }),
    getWorkOrders: thunk(async (actions, payload, { getStoreActions }) => {
        actions.setLoadingWorkOrders(true);
        const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/work-order/' });
        actions.setLoadingWorkOrders(false);
        if (requestResponse.success) {
            actions.setWorkOrders(requestResponse.data);
        }
        return requestResponse.success;
    }),
    loadingWorkOrders: false,
    setLoadingWorkOrders: action((state, payload) => {
        state.loadingWorkOrders = payload;
    }),

    workOrders: [],
    setWorkOrders: action((state, payload) => {
        state.workOrders = payload;
    }),

    workOrder: null,
    setWorkOrder: action((state, payload) => {
        state.workOrder = payload;
    }),

    workOrderItem: null,
    setWorkOrderItem: action((state, payload) => {
        state.workOrderItem = payload;
    }),

    getWorkOrderDetails: thunk(async (actions, payload, { getStoreActions }) => {
        actions.setLoadingWorkOrderDetails(true);
        const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/work-order/details/', params: { id: payload } });
        actions.setWorkOrder(requestResponse.success ? requestResponse.data : null);
        actions.setLoadingWorkOrderDetails(false);
        return requestResponse;
    }),
    loadingWorkOrderDetails: false,
    setLoadingWorkOrderDetails: action((state, payload) => {
        state.loadingWorkOrderDetails = payload;
    }),

    updateWorkOrderItemTaskStatus: thunk(async (actions, payload) => {
        const requestResponse = await handlePutRequest(payload, '/work-order-item/task-status/', true);
        return requestResponse.success;
    }),

    viewingWorkOrders: false,
    setViewingWorkOrders: action((state, payload) => {
        state.viewingWorkOrders = payload;
    }),

    viewingWorkOrderDetails: null,
    setViewingWorkOrderDetails: action((state, payload) => {
        state.viewingWorkOrderDetails = payload;
    }),

    getWorkOrderRoute: thunk(async (actions, payload) => {
        actions.setLoadingWorkOrders(true);
        const requestResponse = await handlePostRequest(payload, '/google/directions/', true);
        actions.setLoadingWorkOrders(false);
        return requestResponse;
    }),

    loadingWorkOrderRoute: false,
    setLoadingWorkOrderRoute: action((state, payload) => {
        state.loadingWorkOrderRoute = payload;
    }),

    removeWorkOrder: thunk(async (actions, payload) => {
        const route = '/work-order/' + payload;
        const requestResponse = await handleDeleteRequest({ id: payload }, route);
        return requestResponse;
    }),

    removeWorkOrderItem: thunk(async (actions, payload) => {
        const route = '/work-order-item/' + payload;
        const requestResponse = await handleDeleteRequest({ id: payload }, route);
        return requestResponse;
    }),

    onActionsThatUpdateWorkOrderDetails: thunkOn(
        (actions, storeActions) => [
            storeActions.deviceActionsModel.changeWaterFilter,
            storeActions.deviceActionsModel.changeBattery,
            storeActions.deviceActionsModel.changeSerial,
            storeActions.deviceActionsModel.performMaintenance,
            actions.updateWorkOrderItemTaskStatus,
            actions.removeWorkOrderItem,
        ],
        async (actions, target, { getState }) => {
            const modelState = getState();
            if (modelState.viewingWorkOrders) {
                await actions.getWorkOrders();
            }
            if (modelState.viewingWorkOrderDetails !== null) {
                const workOrder = await actions.getWorkOrderDetails(modelState.viewingWorkOrderDetails);

                if (modelState.workOrderItem !== null) {
                    const workOrderTyped = workOrder.data as WorkOrderParsed;
                    const workOrderItem = workOrderTyped.workOrderItems.find((item) => modelState.workOrderItem?.id === item.id);
                    if (workOrderItem) {
                        actions.setWorkOrderItem(workOrderItem);
                    }
                }
            }
        },
    ),
    onActionsThatUpdateWorkOrderList: thunkOn(
        (actions, storeActions) => [
            actions.createWorkOrder,
            actions.removeWorkOrder
        ],
        async (actions, target, { getState, getStoreActions }) => {
            const modelState = getState();
            if (modelState.viewingWorkOrders) {
                await actions.getWorkOrders();
                await getStoreActions().installationsModel.getInstallations({ flowplanProductType: FlowplanProductTypes.WaterFilter });
            }
        },
    ),
};

export default workOrderModel;
