import { HealthState } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import {
  Checkbox,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { FieldProps, getIn } from 'formik';
import { forwardRef } from 'react';

/**
 * This component is meant to generate a generic select dropdown.
 * You can use this component inside a Formik form.
 * This component only works inside <Field /> Component.
 * @param SelectProps it extends the usual MuiSelect props, Formik field props.
 */
export interface OptionProp {
  name: string;
  alternateName?: string;
  value: string | number;
  disabled?: boolean;
  extra?: HealthState;
}
export interface SelectProps extends FieldProps, Omit<MuiSelectProps, 'name' | 'value' | 'error'> {
  options: OptionProp[];
  MenuItemProps?: MenuItemProps;
  helperText?: string;
  FormControlProps: FormControlProps;
}

const Select = forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
  const {
    field: { onChange: fieldOnChange, ...otherFieldProps },
    form: { touched, errors, setFieldValue, setFieldTouched },
    label,
    options,
    MenuItemProps,
    FormControlProps,
    helperText,
    multiple,
    onChange,
    onBlur,
    children,
    ...otherProps
  } = props;

  // to get the field error we use getIn method
  const fieldError = getIn(errors, otherFieldProps.name);

  //  To know if the field has error or not
  const showError = getIn(touched, otherFieldProps.name) && !!fieldError;

  return (
    <FormControl error={showError} ref={ref} {...FormControlProps}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        error={showError}
        label={label}
        multiple={multiple}
        {...otherFieldProps}
        {...otherProps}
        onChange={
          onChange ??
          ((event) => {
            setFieldTouched(otherFieldProps.name, true, false);
            setFieldValue(otherFieldProps.name, event.target.value, true);
          })
        }
        onBlur={
          onBlur ??
          (() => {
            setFieldTouched(otherFieldProps.name, true, true);
          })
        }
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value} disabled={option.disabled} {...MenuItemProps}>
            {multiple && <Checkbox checked={otherFieldProps.value.indexOf(option.value) > -1} />}
            {option.name}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText>{showError ? fieldError : helperText}</FormHelperText>
      {children}
    </FormControl>
  );
});

Select.displayName = 'FormikSelect';

export default Select;
