import { forwardRef } from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

const AppBar = forwardRef<HTMLDivElement, MuiAppBarProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <MuiAppBar {...rest} ref={ref}>
      {children}
    </MuiAppBar>
  );
});

AppBar.displayName = 'AppBar';

export default AppBar;
