import { Color } from '@flowplan/flowplan-shared';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useSnackbar, VariantType } from 'notistack';
import { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { getDateObject } from '../../../../Utility/time';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import FpDatePicker from '../../../common/components/FpDatePicker';
import FpDialog from '../../../common/components/FpDialog/FpDialog';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpText from '../../../common/components/FpText/FpText';
import FpImageButton from '../FpImageButton/FpImageButton';
import { loadImage } from '../../../../Utility/dynamicImport';

interface Props {
  alwaysLeftAlign?: boolean;
}

const ChangeFlowmeter = (props: Props): JSX.Element => {
  const flowmeters = useStoreState((state) => state.staticDataModel.flowmeters);
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);

  const changeFlowmeter = useStoreActions((actions) => actions.deviceActionsModel.changeFlowmeter);

  const [showFlowmeterModal, setShowFlowmeterModal] = useState(false);
  const [selectedFlowmeterId, setSelectedFlowmeterId] = useState<number | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(getDateObject(null));
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
  const [flowmeterImageSrcs, setFlowmeterImageSrcs] = useState<string[]>([])

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const initialFlowmeter = baseFilter.infoFlowmeter.flowmeterName;
    const selectedFlowmeter = flowmeters.find((flowmeter) => flowmeter.id === selectedFlowmeterId);
    const sameAsInitial = selectedFlowmeter?.name === initialFlowmeter;
    setIsConfirmDisabled(sameAsInitial);
  }, [baseFilter, flowmeters, selectedFlowmeterId]);

  useEffect(() => {
    const async_effect = async () => {
      const dynamicImports = flowmeters.map(async (flowmeter) => {
        return await loadImage(flowmeter.id)
      })
      const srcArray = await Promise.all(dynamicImports)
      setFlowmeterImageSrcs(srcArray)
    }
    async_effect()
  }, [flowmeters, setFlowmeterImageSrcs])


  const openFlowmeterInfo = () => {
    const installedFlowmeter = flowmeters.find(
      (flowmeter) => flowmeter.name === baseFilter.infoFlowmeter.flowmeterName,
    );
    setSelectedFlowmeterId(installedFlowmeter?.id);

    setShowFlowmeterModal(true);
  };

  const closeFlowmeterModal = () => {
    setSelectedFlowmeterId(undefined);
    setSelectedDate(getDateObject(null));
    setShowFlowmeterModal(false);
  };

  const handleConfirm = async () => {
    setIsConfirmDisabled(true);

    if (!selectedFlowmeterId) {
      enqueueSnackbar('You need to select a flowmeter', { variant: 'error' });
      return;
    }

    const changeFlowmeterResponse = await changeFlowmeter({
      dateOfChange: selectedDate.format(dateFormatYMD),
      flowmeterId: selectedFlowmeterId,
      installationId: baseFilter.infoBasic.id,
    });

    setIsConfirmDisabled(false);

    const selectedFlowmeter = flowmeters.find((flowmeter) => flowmeter.id === selectedFlowmeterId);
    const feedback = changeFlowmeterResponse.success
      ? `Flowmeter changed to: ${selectedFlowmeter?.name}`
      : 'Error: ' + changeFlowmeterResponse.data;
    const variant: VariantType = changeFlowmeterResponse.success ? 'success' : 'error';
    enqueueSnackbar(feedback, { variant });

    if (changeFlowmeterResponse.success) {
      closeFlowmeterModal();
    }
  };

  return (
    <>
      <FpButtonOutlinedAligned
        className={'greyButton'}
        alwaysLeftAlign={props.alwaysLeftAlign}
        icon={<SettingsIcon />}
        onClick={openFlowmeterInfo}
        label="Change Flowmeter"
        disabled={false}
      />

      <FpDialog
        open={showFlowmeterModal}
        title="Flowmeter Details"
        subtitle="Select the flowmeter you want to use for this installation."
        onClose={closeFlowmeterModal}
        onConfirm={handleConfirm}
        isDisabled={isConfirmDisabled}
        confirmText="Confirm"
        cancelText="Cancel"
        maxWidth="sm"
      >
        <Box
          sx={{
            gap: '1rem',
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
          }}
        >
          {flowmeters.map((flowmeter) => {
            const isSelected = (selectedFlowmeterId && flowmeter.id === selectedFlowmeterId) || false;
            const index = flowmeters.findIndex((device) => flowmeter.id === device.id)
            return (
              <FpImageButton
                key={flowmeter.name}
                altText={flowmeter.name}
                imageSrc={flowmeterImageSrcs.at(index) ?? ''}
                isSelected={isSelected}
                onClick={() => setSelectedFlowmeterId(flowmeter.id)}
              >
                <FpText
                  variant={'body1'}
                  sx={{
                    color: isSelected ? Color.FlowplanBlue : '',
                    fontWeight: isSelected ? '600' : '',
                  }}
                >
                  {flowmeter.name}
                </FpText>
              </FpImageButton>
            );
          })}
        </Box>
        <FpDivider />
        <p className="center-text" style={{ marginTop: '1rem' }}>
          Please select the date, when you changed the flowmeter:
        </p>
        <div
          style={{
            maxWidth: '320px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '1.5rem auto 0',
          }}
        >
          <FpDatePicker
            value={selectedDate}
            label={'When was the flowmeter changed?'}
            size={'small'}
            minDate={getDateObject(baseFilter.infoBasic.initialInstallDate)}
            disableFuture={true}
            onChange={(date) => {
              const dateSelected = getDateObject(date);
              setSelectedDate(dateSelected);
            }}
          />
        </div>
      </FpDialog>
    </>
  );
};

export default ChangeFlowmeter;
