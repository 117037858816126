import { FlowplanProductTypes } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { sortByKey } from '@hsjakobsen/utilities';
import { action, Action, Thunk, thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { handleDeleteRequest, handlePostRequest, handlePutRequest } from '../../server-requests/server-requests';
import {
  ITapSensor,
  ITapSublocationCreate,
  ITapSublocationCreateWithSensors,
  ITapSublocationUpdate,
} from './tap.model';

// Define the base structure for sublocations
interface BaseSublocation {
  id: number;
  locationId: number;
  flowplanProductTypeId: FlowplanProductTypes;
  name: string;
  sensors?: ITapSensor[];
}

// Product-specific interfaces extending BaseSublocation
interface WaterfilterData extends BaseSublocation {
  carbonateHardness: number;
  totalHardness: number;
  microSiemensPerCm: number;
  ppm: number;
  address?: string;
  waterHardness?: string;
  waterFilter?: string;
  contactInformation?: string;
  dailyUsage?: string;
  locationStatus?: string;
  status?: string;
  details?: string[];
}

interface TapData extends BaseSublocation {
  address?: string;
  waterHardness?: string;
  waterFilter?: string;
  contactInformation?: string;
  dailyUsage?: string;
  locationStatus?: string;
  status?: string;
  details?: string[];
}

interface CoffeeData extends BaseSublocation {
  address?: string;
  waterHardness?: string;
  waterFilter?: string;
  contactInformation?: string;
  dailyUsage?: string;
  locationStatus?: string;
  status?: string;
  details?: string[];
}

// Unified Sublocation Data Type
export type SublocationData =
  | (BaseSublocation & { flowplanProductTypeId: FlowplanProductTypes.WaterFilter } & WaterfilterData)
  | (BaseSublocation & { flowplanProductTypeId: FlowplanProductTypes.Coffee } & CoffeeData)
  | (BaseSublocation & { flowplanProductTypeId: FlowplanProductTypes.Taps } & TapData);

// Historical sublocation changes interface
export interface ITapSublocationHistory {
  id: number;
  sublocationId: number;
  changeDate: Date;
  description: string;
}

// Default sublocation data
const defaultSublocation: SublocationData = {
  id: 0,
  locationId: 0,
  flowplanProductTypeId: FlowplanProductTypes.Taps,
  name: '',
};

// Model for managing sublocations
export interface ISublocationModel {
  sublocations: SublocationData[];
  sublocationHistory: ITapSublocationHistory[];
  sublocationData: SublocationData;
  loading: boolean;

  setSublocations: Action<ISublocationModel, SublocationData[]>;
  setSublocationHistory: Action<ISublocationModel, ITapSublocationHistory[]>;
  setSublocationData: Action<ISublocationModel, SublocationData>;
  setLoading: Action<ISublocationModel, boolean>;

  getSublocationsByLocationId: Thunk<ISublocationModel, number, void, IStoreModel>;
  getSublocationHistory: Thunk<ISublocationModel, number, void, IStoreModel>;
  getSublocationWithSensors: Thunk<ISublocationModel, number, void, IStoreModel>;
  createSublocation: Thunk<ISublocationModel, ITapSublocationCreate, void, IStoreModel>;
  updateSublocation: Thunk<ISublocationModel, ITapSublocationUpdate, void, IStoreModel>;
  batchCreateSublocationsWithSensors: Thunk<
    ISublocationModel,
    { locationId: number; sublocations: Array<ITapSublocationCreateWithSensors> },
    void,
    IStoreModel
  >;
  deleteSublocation: Thunk<ISublocationModel, { sublocationId: number, locationId: number }, void, IStoreModel>;

  batchCreateSublocations: Thunk<ISublocationModel, ITapSublocationCreate[]>;

  onActionsThatUpdateSublocation: ThunkOn<ISublocationModel, void, IStoreModel>;
}

// Implementation of the sublocation model
const sublocationModel: ISublocationModel = {
  sublocations: [],
  sublocationHistory: [],
  sublocationData: defaultSublocation,
  loading: false,

  setSublocations: action((state, sublocations) => {
    state.sublocations = sortByKey(sublocations, 'name', true);
  }),

  setSublocationHistory: action((state, history) => {
    state.sublocationHistory = history;
  }),

  setSublocationData: action((state, sublocation) => {
    state.sublocationData = sublocation;
  }),

  setLoading: action((state, loading) => {
    state.loading = loading;
  }),

  getSublocationsByLocationId: thunk(async (actions, locationId, { getStoreActions }) => {
    actions.setLoading(true);
    const { data, success } = await getStoreActions().serverRequestsModel.get({
      route: `/sublocations/${locationId}`,
    });
    if (success) actions.setSublocations(data);
    actions.setLoading(false);
  }),

  getSublocationWithSensors: thunk(async (actions, sublocationId, { getStoreActions }) => {
    actions.setLoading(true);
    const { data, success } = await getStoreActions().serverRequestsModel.get({
      route: `/sublocations/sensors/${sublocationId}`,
    });
    if (success) actions.setSublocationData(data);
    actions.setLoading(false);
  }),

  getSublocationHistory: thunk(async (actions, sublocationId, { getStoreActions }) => {
    actions.setLoading(true);
    const { data, success } = await getStoreActions().serverRequestsModel.get({
      route: `/sublocations/history?sublocationId=${sublocationId}`,
    });
    if (success) actions.setSublocationHistory(data);
    actions.setLoading(false);
  }),

  createSublocation: thunk(async (actions, sublocation) => {
    const { success } = await handlePostRequest(sublocation, '/sublocations/', true);
    if (success) actions.getSublocationsByLocationId(sublocation.locationId);
  }),
  updateSublocation: thunk(async (actions, payload) => {
    // NOTE: At the moment, we are only updating the sublocation name
    const response = await handlePutRequest({ name: payload.name }, `/sublocations/${payload.id}`, true);
    return response;
  }),

  deleteSublocation: thunk(async (actions, payload) => {
    const response = await handleDeleteRequest({ id: payload.sublocationId }, `/sublocations/${payload.sublocationId}`);
    return response;
  }),

  batchCreateSublocations: thunk(async (actions, playload) => {
    return await handlePostRequest(playload, `/sublocations/batch`, true);
  }),

  batchCreateSublocationsWithSensors: thunk(async (actions, payload) => {
    return await handlePostRequest(payload.sublocations, `/sublocations/sensors/${payload.locationId}`, true);
  }),

  onActionsThatUpdateSublocation: thunkOn(
    (actions) => [
      actions.createSublocation,
      actions.updateSublocation,
      actions.deleteSublocation,
      actions.batchCreateSublocationsWithSensors
    ],
    async (actions, payload, { getStoreActions }) => {
      const locationId = payload.payload.locationId
      const getSublocations = actions.getSublocationsByLocationId(locationId);
      const getLocations = getStoreActions().locationsModel.getLocations({ flowplanProductTypeId: FlowplanProductTypes.Taps })
      await Promise.all([getSublocations, getLocations])
    },
  ),
};

export default sublocationModel;
