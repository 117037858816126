import { getUserType, INotificationSettings } from '@flowplan/flowplan-shared';
import { LocationSearchSettings } from '@flowplan/flowplan-shared/lib/interfaces/notificationDb';
import { Languages, RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { modalTitleAddEdit } from '@flowplan/flowplan-shared/lib/utils/texts';
import { Divider } from '@mui/material';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { IDropdownData } from '../../../../common/interfacesFrontend';
import { IUserInput, IUserInputNotificationSettings } from '../../../../common/interfacesInput';
import Feedback from '../../../../Components/Feedback/Feedback';
import DropdownListSearchable from '../../../../Components/Input/DropdownListSearchable';
import InputComponentCanDisable from '../../../../Components/Input/InputComponentCanDisable';
import InputComponentToggle from '../../../../Components/Input/InputComponentToggle';
import TextInput from '../../../../Components/Input/TextInput';
import AddItemModal from '../../../../Components/Modals/AddItemModal';
import { usePasswordValidation } from '../../../../hooks/PasswordValidation';
import { useStoreActions, useStoreState } from '../../../../Store';
import { getPasswordText } from '../../../../Utility/Texts';
import PasswordChecks from '../../../authentication/components/PasswordChecks/PasswordChecks';
import PasswordGenerated from '../../../authentication/components/PasswordGenerated/PasswordGenerateds';
import UserNotificationSettings from '../../components/NotificationSettings/NotificationSettings';
import { isUserExternal, userTypeList } from '../../types/users-configs';
import LanguagePicker from '../../../weglot/LanguagePicker';

const inputKeyAccountName = 'accountName';
const inputKeyAccountUsername = 'accountUsername';
const inputKeyAccountEmail = 'accountEmail';
const inputKeyAccountCurrentPassword = 'accountCurrentPassword';
const inputKeyAccountPassword = 'accountNewPassword';
const inputKeyAccountPasswordRepeat = 'accountNewPasswordRepeat';
const inputKeyAccountUserType = 'accountUserType';
const inputKeyAccountAlertEmails = 'accountAlertEmails';
const inputKeyAccountAlertLeaks = 'accountAlertLeaks';
const inputKeyAccountAlertNotifications = 'accountAlertNotifications';
const inputKeyAccountConfirmationEmails = 'accountConfirmationEmails';
const inputKeyAccountConfirmationNotifications = 'accountConfirmationNotifications';

const AddNewUser = (): JSX.Element => {
  const modalUserData = useStoreState((state) => state.usersModel.modalUserData);
  const newUserErrorMessage = useStoreState((state) => state.usersModel.newUserErrorMessage);
  const newUserInteractionDisabled = useStoreState((state) => state.usersModel.newUserInteractionDisabled);
  const showNewUserModal = useStoreState((state) => state.usersModel.showNewUserModal);

  const setNewUserErrorMessage = useStoreActions((actions) => actions.usersModel.setNewUserErrorMessage);
  const toggleNewUserModal = useStoreActions((actions) => actions.usersModel.toggleNewUserModal);
  const handleUserData = useStoreActions((actions) => actions.usersModel.handleUserData);
  const getNotificationSettings = useStoreActions((actions) => actions.usersModel.getNotificationSettings);

  const access = useStoreState((state) => state.authModel.access);

  const [isUpdate, setIsUpdate] = useState(false);
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [inputName, setInputName] = useState('');
  const [inputUsername, setInputUserName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputClientId, setInputClientId] = useState<number | null>(null);
  const [inputUserType, setInputUserType] = useState(RoleType.ADMIN);
  const [inputCurrentPassword, setInputCurrentPassword] = useState('');
  const [inputNewPassword, setInputNewPassword] = useState('');
  const [inputNewPasswordRepeat, setInputNewPasswordRepeat] = useState('');
  const [inputAlertEmails, setInputAlertEmails] = useState(true);
  const [inputAlertLeaks, setInputAlertLeaks] = useState(true);
  const [inputConfirmationEmails, setInputConfirmationEmails] = useState(true);
  const [inputUserLanguage, setInputUserLanguage] = useState(Languages.ENGLISH);
  const [customerClients, setCustomerClients] = useState<IDropdownData[]>([]);
  const [showPasswordChecks, setShowPasswordChecks] = useState(false);

  const clientsDropdown = useStoreState((state) => state.clientsModel.clientsDropdown);

  useEffect(() => {
    setIsUpdate(modalUserData !== undefined);
    setShowPasswordChecks(modalUserData === undefined);

    if (modalUserData !== undefined) {
      setUserId(modalUserData.id);
      setInputName(modalUserData.name);
      setInputUserName(modalUserData.username);
      setInputEmail(modalUserData.email);
      setInputCurrentPassword('');
      setInputUserType(modalUserData.role);
    }
  }, [modalUserData]);

  useEffect(() => {
    async function retrieveNotificationSettings() {
      if (!userId) {
        return;
      }
      const notificationSettingResponse = await getNotificationSettings(userId);
      const notificationSettings: INotificationSettings = notificationSettingResponse.data;
      if (!notificationSettings) {
        return;
      }

      setInputAlertEmails(notificationSettings.alertEmails === 1);
      setInputConfirmationEmails(notificationSettings.confirmationEmails === 1);
      setInputAlertLeaks(notificationSettings.alertLeaks === 1);
    }

    retrieveNotificationSettings();
  }, [getNotificationSettings, userId]);

  useEffect(() => {
    let newClientId: number | null = inputClientId || null;
    if (isUserExternal(inputUserType)) {
      newClientId = inputClientId;
      setCustomerClients(clientsDropdown);
    }

    setInputClientId(newClientId);
  }, [inputUserType, inputClientId, clientsDropdown]);

  const handleInput = (ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
    setNewUserErrorMessage('');
    const value = ev.target.value;
    switch (inputKey) {
      case inputKeyAccountName:
        setInputName(value);
        break;
      case inputKeyAccountUsername:
        setInputUserName(value);
        break;
      case inputKeyAccountEmail:
        setInputEmail(value);
        setInputUserName(value);
        break;
      case inputKeyAccountCurrentPassword:
        setInputCurrentPassword(value);
        setShowPasswordChecks(value.length > 0);
        break;
      case inputKeyAccountPassword:
        setInputNewPassword(value);
        break;
      case inputKeyAccountPasswordRepeat:
        setInputNewPasswordRepeat(value);
        break;
      case inputKeyAccountUserType: {
        break;
      }
      case inputKeyAccountAlertEmails:
        setInputAlertEmails(ev.target.checked);
        break;
      case inputKeyAccountAlertLeaks:
        setInputAlertLeaks(ev.target.checked);
        break;
      case inputKeyAccountConfirmationEmails:
        setInputConfirmationEmails(ev.target.checked);
        break;
    }
  };

  const handleClicks = async () => {
    const idToUse: number = userId ? userId : -1;
    const userInput: IUserInput = {
      id: idToUse,
      inputClientId,
      inputCurrentPassword,
      inputEmail,
      inputName,
      inputNewPassword,
      inputNewPasswordRepeat,
      inputUsername,
      isUpdate,
      inputUserType,
      inputUserLanguage
    };
    const notificationInput: IUserInputNotificationSettings = {
      inputAlertEmails,
      inputConfirmationEmails,
      inputAlertLeaks,
      locationSearch: LocationSearchSettings.ClientName,
    };

    if (isUpdate || inputUserType === RoleType.ADMIN) delete userInput.inputClientId;

    const result = await handleUserData({ userInput, notificationInput });
    if (result) {
      clearState();
    }
  };

  const handleCloseModal = () => {
    clearState();
    toggleNewUserModal({ userData: undefined, showModal: false });
  };

  const clearState = () => {
    setUserId(undefined);
    setInputName('');
    setInputUserName('');
    setInputEmail('');
    setInputClientId(null);
    setInputUserType(RoleType.ADMIN);
    setInputCurrentPassword('');
    setInputNewPassword('');
    setInputNewPasswordRepeat('');
    setInputAlertEmails(true);
    setInputAlertLeaks(true);
    setInputConfirmationEmails(true);
    setShowPasswordChecks(true);
    setInputUserLanguage(Languages.ENGLISH)
  };
  const userTypeTextLookup =
    inputUserType === RoleType.EXTERNAL_TECHNICIAN ? 'System administrator' : getUserType(inputUserType);
  const modalTitle = modalTitleAddEdit(isUpdate, 'user');

  const canEditUser = access.users.manage;

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar, different] = usePasswordValidation({
    currentPassword: inputCurrentPassword,
    firstPassword: inputNewPassword,
    secondPassword: inputNewPasswordRepeat,
    role: inputUserType,
    lengthValidation: true,
    lowercaseValidation: true,
    specialcharacterValidation: inputUserType === RoleType.SUPER_ADMIN,
    uppercaseValidation: true,
    numberValidation: true,
  });

  const requirementsPassed = isUpdate
    ? !showPasswordChecks ||
    (validLength && hasNumber && upperCase && lowerCase && match && specialChar && (isUpdate ? different : true))
    : true;

  const showGuestCompanyOption = isUserExternal(inputUserType);

  return (
    <AddItemModal
      confirmInteractionDisabled={!requirementsPassed}
      isModalOpen={showNewUserModal}
      interactionDisabled={newUserInteractionDisabled}
      closeModal={handleCloseModal}
      confirmAction={handleClicks}
      modalCancelLabel="Cancel"
      modalConfirmLabel="Confirm"
      modalTitle={modalTitle}
    >
      <InputComponentCanDisable isDisabled={!canEditUser} inputValue={inputEmail} inputText="Email">
        <TextInput
          inputValue={inputEmail}
          inputKey={inputKeyAccountEmail}
          isFirst={true}
          inputText="Email*"
          handleInput={handleInput}
          isRequired={true}
        />
      </InputComponentCanDisable>

      <InputComponentCanDisable isDisabled={!canEditUser} inputValue={inputUsername} inputText="Username">
        <TextInput
          inputValue={inputUsername}
          inputKey={inputKeyAccountUsername}
          inputText={'Username* (' + inputUsername.length + '/50 characters)'}
          maxLength={50}
          handleInput={handleInput}
          isRequired={true}
        />
      </InputComponentCanDisable>

      <InputComponentCanDisable isDisabled={!canEditUser} inputValue={inputName} inputText="Name + surname">
        <TextInput
          inputValue={inputName}
          inputKey={inputKeyAccountName}
          inputText="Name + surname*"
          handleInput={handleInput}
          isRequired={true}
        />
      </InputComponentCanDisable>

      <InputComponentCanDisable isDisabled={isUpdate} inputValue={userTypeTextLookup} inputText="User type">
        <Divider sx={{ height: 10 }} />
        <DropdownListSearchable
          items={userTypeList}
          placeholder="Account type"
          selectedValue={inputUserType}
          onChangeFunction={(e: SyntheticEvent, { value }: DropdownProps) => {
            const userTypeToUse = String(value) as RoleType;
            setInputUserType(userTypeToUse);
          }}
        />
        <InputComponentToggle hideComponent={!showGuestCompanyOption}>
          <Divider sx={{ height: 5 }} />
          <DropdownListSearchable
            items={customerClients}
            placeholder="Company to restrict view to"
            selectedValue={inputClientId !== null ? inputClientId : 0}
            onChangeFunction={(e: SyntheticEvent, { value }: DropdownProps) => {
              const clientIdToUse = Number(value);
              setInputClientId(clientIdToUse);
            }}
          />
        </InputComponentToggle>
      </InputComponentCanDisable>

      <InputComponentToggle hideComponent={!isUpdate}>
        <TextInput
          disabled={!canEditUser}
          inputValue={inputCurrentPassword}
          inputKey={inputKeyAccountCurrentPassword}
          inputText="Current password*"
          type="password"
          handleInput={handleInput}
          isRequired={true}
        />
      </InputComponentToggle>

      {isUpdate ? (
        <>
          <TextInput
            disabled={!canEditUser}
            inputValue={inputNewPassword}
            inputKey={inputKeyAccountPassword}
            inputText={getPasswordText(isUpdate, false, true)}
            type="password"
            handleInput={handleInput}
            isRequired={true}
          />
          <TextInput
            disabled={!canEditUser}
            inputValue={inputNewPasswordRepeat}
            inputKey={inputKeyAccountPasswordRepeat}
            inputText={getPasswordText(isUpdate, true, true)}
            type="password"
            handleInput={handleInput}
            isRequired={true}
          />

          {showPasswordChecks ? (
            <PasswordChecks
              currentPasswordCheck={isUpdate}
              validLength={validLength}
              different={different}
              hasNumber={hasNumber}
              lowerCase={lowerCase}
              match={match}
              role={inputUserType}
              specialChar={specialChar}
              upperCase={upperCase}
            />
          ) : null}
        </>
      ) : (
        <PasswordGenerated />
      )}

      {canEditUser && (
        <>
          <UserNotificationSettings
            inputAlertEmails={inputAlertEmails}
            inputAlertLeaks={inputAlertLeaks}
            inputConfirmationEmails={inputConfirmationEmails}
            handleInput={handleInput}
            inputKeyAccountAlertEmails={inputKeyAccountAlertEmails}
            inputKeyLeakAlerts={inputKeyAccountAlertLeaks}
            inputKeyAccountAlertNotifications={inputKeyAccountAlertNotifications}
            inputKeyAccountConfirmationEmails={inputKeyAccountConfirmationEmails}
            inputKeyAccountConfirmationNotifications={inputKeyAccountConfirmationNotifications}
            userTypeValue={inputUserType}
          />
          <LanguagePicker sx={{ display: 'flex', alignItems: 'center', marginTop: '1.2rem', marginBottom: '1rem' }}
            language={inputUserLanguage}
            handleChange={(newValue) => {
              setInputUserLanguage(newValue)
            }}
          />


        </>
      )}

      <Feedback feedbackMessage={newUserErrorMessage} />
    </AddItemModal>
  );
};

export default AddNewUser;
