import {
  IAuthRequestFinishResetPassword,
  IAuthRequestInitiateResetPassword,
  IAuthRequestPasswordChange,
} from '@flowplan/flowplan-shared/lib/auth/auth.requests';
import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { IUserInformationBasic } from '@flowplan/flowplan-shared/lib/users/users.responses';
import { IResponse } from '@hsjakobsen/utilities';
import { Action, Thunk } from 'easy-peasy';
import { IStoreModel } from '../../../Models';

export const defaultUserValues: IUserInformationBasic = {
  role: RoleType.GUEST,
  name: '',
  storageKey: '',
  createdAt: ''
};

export const defaultAccess: IAccess = {
  clients: {
    manage: false,
    view: false,
  },
  flowplanAdmin: false,
  installations: {
    manage: false,
    manageExternalTasks: false,
    view: false,
  },
  inventory: {
    dataNotInSystem: false,
    lowUsageInstallations: false,
    manage: false,
    view: false,
  },
  locations: {
    manage: false,
    view: false,
  },
  orders: {
    manage: false,
    view: false,
  },
  support: {
    manage: false,
    view: false,
  },
  reports: {
    manage: false,
    view: false,
  },
  users: {
    manage: false,
    view: false,
  },
  workOrders: {
    manage: false,
    view: false,
  },
  companySettings: {
    manage: false,
    view: false
  },
  taps: {
    manage: false,
    view: false,
    edit: false
  },
  tapSettings: {
    manage: false,
    view: false,
    edit: false
  }

};

interface authenticatePayload {
  username: string;
  password: string;
}

interface IAccessSection {
  view: boolean;
  manage: boolean;
  // edit is a more strict rule: 
  // user can only update current records, no create nor delete privilege
  edit?: boolean;
}

interface SupportSection {
  view: boolean;
  manage: boolean;
}

interface IAccessSectionInventory extends IAccessSection {
  dataNotInSystem: boolean;
  lowUsageInstallations: boolean;
}

interface IAccessSectionInstallations extends IAccessSection {
  manageExternalTasks: boolean;
}

export interface IAccess {
  companySettings: IAccessSection;
  clients: IAccessSection;
  flowplanAdmin: boolean;
  installations: IAccessSectionInstallations;
  inventory: IAccessSectionInventory;
  locations: IAccessSection;
  orders: IAccessSection;
  reports: IAccessSection;
  support: SupportSection;
  users: IAccessSection;
  workOrders: IAccessSection;
  taps: IAccessSection;
  tapSettings: IAccessSection;
}

interface IPasswordChangeInfo {
  username: string;
  role: RoleType;
}

export interface IAuthModel {
  isAuthenticated: boolean;
  setIsAuthenticated: Action<IAuthModel, boolean>;

  showMenu: boolean;
  setShowMenu: Action<IAuthModel, boolean>;

  currentUser: IUserInformationBasic;

  access: IAccess;
  handleSuccess: Action<IAuthModel, IUserInformationBasic>;

  signout: Thunk<IAuthModel, void, void, IStoreModel>;
  resetAuthData: Action<IAuthModel, void>;

  isPasswordChangeRequired: boolean;
  setIsPasswordChangeRequired: Action<IAuthModel, IResponse>;

  useRefreshToken: Thunk<IAuthModel, void, void, IStoreModel>;
  verifySession: Thunk<IAuthModel, void, void, IStoreModel>;
  handleVerifySession: Thunk<IAuthModel, void, void, IStoreModel>;

  //changePasswordHandling

  formDisabled: boolean;
  setFormDisabled: Action<IAuthModel, boolean>;

  loading: boolean;
  setLoading: Action<IAuthModel, boolean>;

  loadingText: string;
  setLoadingText: Action<IAuthModel, string>;

  passwordChangeInfo: IPasswordChangeInfo;
  setPasswordChangeInfo: Action<IAuthModel, IPasswordChangeInfo>;

  showChangePasswordModal: boolean;
  setShowChangePasswordModal: Action<IAuthModel, boolean>;

  signInError: string;
  setSignInError: Action<IAuthModel, string>;

  retrieveData: Thunk<IAuthModel, boolean, void, IStoreModel>;

  failedLogins: number;
  setFailedLogins: Action<IAuthModel, number>;

  redirectToReferrer: boolean;
  setRedirectToReferrer: Action<IAuthModel, boolean>;

  savedLocation: string;
  setSavedLocation: Action<IAuthModel, string>;

  redirectToSavedLocation: boolean;
  setRedirectToSavedLocation: Action<IAuthModel, boolean>;

  authenticate: Thunk<IAuthModel, authenticatePayload, void, IStoreModel>;

  handleLoginResponse: Thunk<IAuthModel, IResponse, void, IStoreModel>;

  doLogin: Thunk<IAuthModel, authenticatePayload, void, IStoreModel>;

  //Forgot Password

  startForgotPassword: Thunk<IAuthModel, IAuthRequestInitiateResetPassword>;
  completeForgotPassword: Thunk<IAuthModel, IAuthRequestFinishResetPassword>;
  requiredPasswordChange: Thunk<IAuthModel, IAuthRequestPasswordChange>;

  loginChecks: Thunk<IAuthModel, void>;
}
