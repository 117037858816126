import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IStockSettingsView, IUsageForEvents } from '@flowplan/flowplan-shared/lib/stock.settings/stock.settings';
import { IResponse } from '@hsjakobsen/utilities';
import { Action, Thunk, action, thunk } from 'easy-peasy';
import { IStoreModel } from '../../Models';
import { IStockSettingSelection } from '../installations/components/NewInstallation/StockSettings';
import {
  handleDeleteRequest,
  handlePostRequest,
  handlePutRequest
} from '../server-requests/server-requests';
import {
  validateStockSettingDelete,
  validateStockSettingUpdate,
  validateStockSettings,
} from './types/stock-settings-utils';

interface ICreateStockSettings {
  stockId: number;
  sensorId: number;
  usageForEvents: IUsageForEvents;
}

interface IUpdateStockSetting {
  stockSettingId: number;
  usageForEvents: IUsageForEvents;
}

interface IGetStockParams {
  locationId?: number;
  sensorId?: number;
  flowplanClientId?: number;
}

interface IManageStockSettingUpdate {
  selectedStockSettings: IStockSettingSelection[];
  stockSettings: IStockSettingsView[];
  sensorId: number | null;
}

interface IManageStockSettingCreation {
  selectedStockSettings: IStockSettingSelection[];
  sensorId: number;
}

export interface IStockSettingsModel {
  loadingStockSettings: boolean;
  setLoadingStockSettings: Action<IStockSettingsModel, boolean>;

  manageStockSettingUpdates: Thunk<IStockSettingsModel, IManageStockSettingUpdate, void, IStoreModel>;
  manageStockSettingCreation: Thunk<IStockSettingsModel, IManageStockSettingCreation, void, IStoreModel>;

  getStockSettings: Thunk<IStockSettingsModel, IGetStockParams, void, IStoreModel>;
  createStockSettings: Thunk<IStockSettingsModel, ICreateStockSettings>;
  updateStockSettings: Thunk<IStockSettingsModel, IUpdateStockSetting>;
  removeStockSettings: Thunk<IStockSettingsModel, number>; // stockSettingId
}

const stockManagementModel: IStockSettingsModel = {
  loadingStockSettings: false,
  setLoadingStockSettings: action((state, payload) => {
    state.loadingStockSettings = payload;
  }),

  getStockSettings: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingStockSettings(true);

    const flowplanClientSelected = getStoreState().adminDataModel.flowplanClientSelected;
    payload.flowplanClientId = flowplanClientSelected;

    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.StockSettings, params: payload });
    let stockSettingsReceived: IStockSettingsView[] = [];
    if (requestResponse.success) {
      stockSettingsReceived = requestResponse.data as IStockSettingsView[];
    }
    actions.setLoadingStockSettings(false);

    return stockSettingsReceived;
  }),

  manageStockSettingCreation: thunk(async (actions, payload) => {
    const { selectedStockSettings, sensorId } = payload;
    let operationSuccess = true;
    let operationFeedback = '';

    for (const stockSetting of selectedStockSettings) {
      const { stockId, stockType, usageForEvents } = stockSetting;
      const isStockSettingValid = validateStockSettings({ stockType, usageForEvents });
      if (!isStockSettingValid) {
        continue;
      }
      const stockSettingResult: IResponse = await actions.createStockSettings({
        sensorId,
        stockId,
        usageForEvents,
      });
      operationSuccess = stockSettingResult.success;
      operationFeedback = stockSettingResult.message;
      if (!operationSuccess) {
        break;
      }
    }
    return {
      success: operationSuccess,
      message: operationFeedback,
    };
  }),
  manageStockSettingUpdates: thunk(async (actions, payload, { getStoreState }) => {
    const { selectedStockSettings, sensorId, stockSettings } = payload;
    if (!sensorId) {
      return {
        success: false,
        message: 'No installation selected',
      };
    }
    let operationFeedback = '';
    let operationSuccess = true;

    for (const stockItem of selectedStockSettings) {
      const { stockType, stockId, usageForEvents } = stockItem;
      const stockCheck = stockSettings.find((x) => x.stock.stockType === stockType);
      if (stockCheck) {
        continue;
      }

      const isNewStockSettingValid = validateStockSettings({ stockType, usageForEvents });
      if (!isNewStockSettingValid) {
        continue;
      }

      const stockSettingCreationResult: IResponse = await actions.createStockSettings({
        sensorId,
        stockId,
        usageForEvents,
      });

      operationSuccess = stockSettingCreationResult.success;
      operationFeedback = stockSettingCreationResult.message;
      if (!operationSuccess) {
        break;
      }
    }

    if (!operationSuccess) {
      return {
        success: false,
        message: operationFeedback,
      };
    }

    for (const selectedStockSetting of selectedStockSettings) {
      const existingStockSetting = stockSettings.find(
        (stockSetting) => stockSetting.stock.stockType === selectedStockSetting.stockType,
      );

      if (existingStockSetting) {
        const { usageForEvents } = existingStockSetting;
        const isStockSettingValid = validateStockSettingUpdate({
          existingUsageForEvents: usageForEvents,
          updatedUsageForEvents: selectedStockSetting.usageForEvents,
          stockType: selectedStockSetting.stockType,
        });
        if (!isStockSettingValid || !selectedStockSetting.id) {
          continue;
        }

        const stockSettingUpdateResult: IResponse = await actions.updateStockSettings({
          stockSettingId: selectedStockSetting.id,
          usageForEvents: selectedStockSetting.usageForEvents,
        });

        operationSuccess = stockSettingUpdateResult.success;
        operationFeedback = stockSettingUpdateResult.message;
        if (!operationSuccess) {
          break;
        }
      }
    }

    if (!operationSuccess) {
      return {
        success: false,
        message: operationFeedback,
      };
    }

    for (const selectedStockSetting of selectedStockSettings) {
      const existingStockSetting = stockSettings.find(
        (stockSetting) => stockSetting.stock.stockType === selectedStockSetting.stockType,
      );

      if (!existingStockSetting) {
        continue;
      }

      const isSettingDeleted = validateStockSettingDelete({
        existingUsageForEvents: existingStockSetting.usageForEvents,
        updatedUsageForEvents: selectedStockSetting.usageForEvents,
        stockType: selectedStockSetting.stockType,
      });

      if (!isSettingDeleted || !selectedStockSetting.id) {
        continue;
      }

      const stockSettingDeleteResult: IResponse = await actions.removeStockSettings(selectedStockSetting.id);
      operationSuccess = stockSettingDeleteResult.success;
      operationFeedback = stockSettingDeleteResult.message;
      if (!operationSuccess) {
        break;
      }
    }

    return {
      success: operationSuccess,
      message: operationFeedback,
    };
  }),

  createStockSettings: thunk(async (actions, payload) => {
    const requestResponse = await handlePostRequest(payload, Routes.StockSettings, true);
    return requestResponse;
  }),

  updateStockSettings: thunk(async (actions, payload) => {
    const route = Routes.StockSettings + payload.stockSettingId;
    const requestResponse = await handlePutRequest({ usageForEvents: payload.usageForEvents }, route, true);
    return requestResponse;
  }),

  removeStockSettings: thunk(async (actions, payload) => {
    const route = Routes.StockSettings + payload;
    const requestResponse = await handleDeleteRequest({ id: payload }, route);
    return requestResponse;
  }),
};

export default stockManagementModel;
