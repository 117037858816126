import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { Alert, Stack } from '@mui/material';
import { getPasswordLength } from '../../../../hooks/PasswordValidation';
import { isUserSuperAdmin } from '../../../users/types/users-configs';
import styles from './PasswordCheck.module.scss';

interface IPasswordChecks {
  validLength: boolean;
  hasNumber: boolean;
  upperCase: boolean;
  lowerCase: boolean;
  specialChar: boolean;
  match: boolean;
  different: boolean;
  role: RoleType | undefined;
  currentPasswordCheck: boolean;
}

const PasswordChecks = ({
  currentPasswordCheck,
  different,
  hasNumber,
  lowerCase,
  match,
  role,
  specialChar,
  upperCase,
  validLength,
}: IPasswordChecks): JSX.Element => {
  const requiredLength = getPasswordLength(role);
  const isSuperAdmin = isUserSuperAdmin(role || RoleType.ADMIN);

  return (
    <Stack sx={{ width: '100%', marginTop: 1 }} spacing={0.25}>
      <Alert className={styles.alertStyle} severity={validLength ? 'success' : 'error'}>
        Length of at least {requiredLength} characters
      </Alert>
      <Alert className={styles.alertStyle} severity={hasNumber ? 'success' : 'error'}>
        Includes a number
      </Alert>
      <Alert className={styles.alertStyle} severity={upperCase ? 'success' : 'error'}>
        Includes uppercase letters
      </Alert>
      <Alert className={styles.alertStyle} severity={lowerCase ? 'success' : 'error'}>
        Includes lowercase letters
      </Alert>
      {isSuperAdmin && (
        <Alert className={styles.alertStyle} severity={specialChar ? 'success' : 'error'}>
          Includes a special character, example ( !@#$%^& *_ )
        </Alert>
      )}
      <Alert className={styles.alertStyle} severity={match ? 'success' : 'error'}>
        Repeat new password
      </Alert>
      {currentPasswordCheck && (
        <Alert className={styles.alertStyle} severity={different ? 'success' : 'error'}>
          Password is different from old password
        </Alert>
      )}
    </Stack>
  );
};

export default PasswordChecks;
