import { DeviceStatus } from '@flowplan/flowplan-shared';
import { IDeviceStatusInfo } from '../../../common/interfacesFrontend';
import statusStyles from '../../../modules/common/components/ListStatus/ListStatus.module.scss';

const statusTextGood = 'All is great!';
const statusTextPending = 'Server has not received data from the installation yet';

const statusLabelGood = 'Online';
const statusLabelPending = 'Pending';
const statusLabelInactive = 'Inactive';

interface IGetStatusConnection {
  status: number;
  hoursNotSeen: number;
}

export const getStatusConnection = ({ status, hoursNotSeen }: IGetStatusConnection): IDeviceStatusInfo => {
  const statusInfo: IDeviceStatusInfo = {
    iconName: 'wifi',
    statusClassName: statusStyles.sensorLabel + ' ' + statusStyles.statusPending,
    statusLabel: statusLabelPending,
    statusTitle: statusTextPending,
  };

  if (status === DeviceStatus.Pending) {
    return statusInfo;
  }

  if (status === DeviceStatus.Online || hoursNotSeen < 48) {
    statusInfo.statusClassName = statusStyles.sensorLabel + ' ' + statusStyles.statusSuccess;
    statusInfo.statusLabel = statusLabelGood;
    statusInfo.statusTitle = statusTextGood;
  }

  if (status === DeviceStatus.NotSeen || (hoursNotSeen >= 48 && hoursNotSeen < 168)) {
    statusInfo.statusClassName = statusStyles.sensorLabel + ' ' + statusStyles.statusNotSeen;
    statusInfo.statusLabel = hoursNotSeen + ' hours';
    statusInfo.statusTitle = 'The server has not received data from the installation for: ' + hoursNotSeen + ' hours';
  }

  if (status === DeviceStatus.Inactive || hoursNotSeen >= 168) {
    statusInfo.statusClassName = statusStyles.sensorLabel + ' ' + statusStyles.statusInactive;
    statusInfo.statusLabel = statusLabelInactive;
    statusInfo.statusTitle =
      'The server has not received data from the installation for ' + (hoursNotSeen / 24).toFixed(0) + ' days';
  }

  return statusInfo;
};
