import { FirmwareRelease } from "@flowplan/flowplan-shared/lib/firmware.releases/firmware.releases";
import { IDeviceView } from "@flowplan/flowplan-shared/lib/interfaces/deviceViewObject";
import { getTaskInformation, getTasksToShow } from "../installations/components/Tasks/tasks-utility";
import { DashboardInstallationTableRow } from "./dashboard-model";

type ConvertIDeviceViewToDashboardInstallation = {
    installations: IDeviceView[];
    firmwareReleases: FirmwareRelease[];
}

export const convertIDeviceViewToDashboardInstallation = ({ firmwareReleases, installations }: ConvertIDeviceViewToDashboardInstallation): DashboardInstallationTableRow[] => {
    const extendedTableData = installations.map((row) => {
        const newRow = row as DashboardInstallationTableRow;
        newRow.taskDisplayOptions = getTasksToShow({ data: row, firmwareReleases });
        newRow.taskInfo = getTaskInformation({ data: row, firmwareReleases });
        return newRow;
    });
    return extendedTableData;
}