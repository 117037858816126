import React, { ChangeEvent, Fragment } from 'react';
import Feedback from '../../../../Components/Feedback/Feedback';
import TextInput from '../../../../Components/Input/TextInput';
import { useStoreActions, useStoreState } from '../../../../Store';

const inputKeyName = 'clientsGroupName';

interface INewClientsGroupInput {
  moveToNewInstallationStep?(): void;
}

const NewClientsGroupInput = ({ moveToNewInstallationStep }: INewClientsGroupInput): JSX.Element => {
  const newClientsGroupErrorMessage = useStoreState(
    (state) => state.flowplanClientGroupsModel.newClientsGroupErrorMessage,
  );
  const inputClientsGroupName = useStoreState((state) => state.flowplanClientGroupsModel.inputClientsGroupName);

  const setInputClientsGroupName = useStoreActions(
    (actions) => actions.flowplanClientGroupsModel.setInputClientsGroupName,
  );
  const handleClientsGroupData = useStoreActions((actions) => actions.flowplanClientGroupsModel.handleClientsGroupData);
  const setNewClientsGroupErrorMessage = useStoreActions(
    (actions) => actions.flowplanClientGroupsModel.setNewClientsGroupErrorMessage,
  );

  const handleKeyPress = async (e: React.KeyboardEvent<Element>): Promise<void> => {
    if (e.key === 'Enter') {
      if (moveToNewInstallationStep) {
        moveToNewInstallationStep();
        return;
      }
      await handleClientsGroupData();
    }
  };

  const handleInput = (ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
    setNewClientsGroupErrorMessage('');
    const value = ev.target.value;
    setInputClientsGroupName(value);
  };

  return (
    <Fragment>
      <TextInput
        inputValue={inputClientsGroupName}
        inputKey={inputKeyName}
        inputText="Customer group name*"
        handleInput={handleInput}
        handleKeyPress={handleKeyPress}
        isFirst={true}
        isRequired={true}
      >
        {/* <PopupWithIcon position="left center">
          <p>Client name is usually the customer you send your invoices to, e.g. "Google Inc"</p>
        </PopupWithIcon> */}
      </TextInput>

      <Feedback feedbackMessage={newClientsGroupErrorMessage} />
    </Fragment>
  );
};

export default NewClientsGroupInput;
