import {
  IWaterSampleRequestReceived,
  IWaterSampleRequestRegister,
  IWaterSampleRequestTested,
} from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { Action, Thunk, action, thunk } from 'easy-peasy';
import { IStoreModel } from '.';
import { handlePostRequest, handlePutRequest } from '../modules/server-requests/server-requests';

export interface ISampleCoordinates {
  latitude: number;
  longitude: number;
}

export const defaultSampleCoordinates: ISampleCoordinates = {
  latitude: 0,
  longitude: 0,
};

export interface IWaterSamplesModel {
  scannedQRCode: string;
  setScannedQRCode: Action<IWaterSamplesModel, string>;

  scannedLocation: ISampleCoordinates;
  setScannedLocation: Action<IWaterSamplesModel, ISampleCoordinates>;

  sampleLocationFound: boolean;
  setSampleLocationFound: Action<IWaterSamplesModel, boolean>;
  sampleBottleFound: boolean;
  setSampleBottleFound: Action<IWaterSamplesModel, boolean>;

  sampleRegister: Thunk<IWaterSamplesModel, IWaterSampleRequestRegister>;
  sampleReceived: Thunk<IWaterSamplesModel, IWaterSampleRequestReceived, void, IStoreModel>;
  sampleTested: Thunk<IWaterSamplesModel, IWaterSampleRequestTested>;
}

const waterSamplesModel: IWaterSamplesModel = {
  scannedLocation: defaultSampleCoordinates,
  setScannedLocation: action((state, payload) => {
    state.scannedLocation = payload;
  }),
  scannedQRCode: '',
  setScannedQRCode: action((state, payload) => {
    state.scannedQRCode = payload;
  }),

  sampleBottleFound: false,
  setSampleBottleFound: action((state, payload) => {
    state.sampleBottleFound = payload;
  }),

  sampleLocationFound: false,
  setSampleLocationFound: action((state, payload) => {
    state.sampleLocationFound = payload;
  }),

  sampleRegister: thunk(async (actions, payload) => {
    const route = Routes.waterSamples;
    const requestResponse = await handlePostRequest(payload, route, true);
    if (requestResponse.success) {
      // actions.setConnectionData(requestResponse.data);
    }
    return requestResponse;
  }),
  sampleReceived: thunk(async (actions, payload, { getStoreActions }) => {
    const route = Routes.waterSamples;
    const requestResponse = await getStoreActions().serverRequestsModel.patch({ data: payload, route });
    if (requestResponse.success) {
      // actions.setConnectionData(requestResponse.data);
    }
    return requestResponse;
  }),
  sampleTested: thunk(async (actions, payload) => {
    const route = Routes.waterSamples;
    const requestResponse = await handlePutRequest(payload, route, true);
    if (requestResponse.success) {
      // actions.setConnectionData(requestResponse.data);
    }
    return requestResponse;
  }),
};

export default waterSamplesModel;
