import { Fragment } from 'react';
import { getDateObject } from '../../../../../Utility/time';
import { useScreenSize } from '../../../../../hooks/ScreenSize';
import FpButtonOutlined from '../../../../common/components/FpButtonOutlined/OutlinedButton';
import FpDivider from '../../../../common/components/FpDivider/FpDivider';
import FpGrid from '../../../../common/components/FpGrid/FpGrid';
import FpGridItem from '../../../../common/components/FpGrid/FpGridItem';
import FpText from '../../../../common/components/FpText/FpText';
import { GooglePlacesInformation } from '../../../services/company-model';
import { LowUsageInstallationDetails } from '../../../services/company-model-types';
import LowUsageBeamInformation from '../BeamInformation/LowUsageBeamInformation';
import LowUsagePlacesInformation from '../GooglePlaces/LowUsagePlacesInfo';
import LowUsageMaintenance from '../Maintenance/LowUsageMaintenance';
import LowUsageConsumption from '../WaterConsumption/LowUsageWaterConsumption';
import LowUsageWaterFilter from '../WaterFilter/LowUsageWaterFilter';
import LowUsageSpacer from './LowUsageSpacer';
import styles from './LowUsageWizard.module.scss';
import { LowUsageAction } from '../low-usage-utils';

type Props = {
  installationDetails: LowUsageInstallationDetails;
  googlePlacesInformation: GooglePlacesInformation;
  loadingGooglePlacesInformation: boolean;
  performingLowUsageAction: boolean;
  installationAction(action: LowUsageAction, installationId: number): void;
};

const LowUsageWizard = ({
  googlePlacesInformation,
  installationAction,
  installationDetails,
  loadingGooglePlacesInformation,
  performingLowUsageAction,
}: Props): JSX.Element => {
  const isSmallScreen = useScreenSize('sm');

  const { beamInformation, usageInformation, installationDescription, installationName, id, maintenance, waterFilter } =
    installationDetails;

  const { averageDailyConsumptionHistorically, days, lastFlowEventDate } = usageInformation;
  const { filterChangeDays } = waterFilter;
  const noConsumptionState = Number(averageDailyConsumptionHistorically) === 0 || days === 0;

  const daysSinceLastFlowEvent = Math.round(getDateObject(null).diff(getDateObject(lastFlowEventDate), 'days', true));

  const daysToCalculateFilterUsageWith =
    filterChangeDays < daysSinceLastFlowEvent ? filterChangeDays : daysSinceLastFlowEvent;

  const estimatedUsage = Math.round(averageDailyConsumptionHistorically * daysToCalculateFilterUsageWith);

  const actionsGrid = { xs: 12, sm: 6, md: 3, lg: 3 };
  const layoutGridPrimaryRow = { xs: 12, sm: 8, md: 8, lg: 7 };
  const layoutGridSecondaryRow = { xs: 12, sm: 4, md: 4, lg: 5 };

  return (
    <>
      <FpText variant="h5" className='do-not-translate'>{installationName}</FpText>
      <FpText variant="h6" className='do-not-translate'>{installationDescription}</FpText>
      <FpDivider marginY="1rem" height="0rem" />
      <FpGrid
        container
        spacing={{ sm: 1 }}
        rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }}
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }}
      >
        <FpGridItem {...layoutGridPrimaryRow}>
          <LowUsageBeamInformation beamInformation={beamInformation} />
          <LowUsageSpacer />
          <LowUsageConsumption
            daysSinceLastFlowEvent={daysSinceLastFlowEvent}
            noConsumptionState={noConsumptionState}
            usageInformation={usageInformation}
          />
          <LowUsageSpacer />
          <LowUsageWaterFilter
            avgDailyConsumption={averageDailyConsumptionHistorically}
            daysSinceLastFlowEvent={daysSinceLastFlowEvent}
            noConsumptionState={noConsumptionState}
            waterFilter={waterFilter}
          />
        </FpGridItem>

        <FpGridItem {...layoutGridSecondaryRow}>
          {isSmallScreen && <LowUsageSpacer />}
          <LowUsageMaintenance
            avgDailyConsumption={averageDailyConsumptionHistorically}
            daysSinceLastFlowEvent={daysSinceLastFlowEvent}
            maintenance={maintenance}
            noConsumptionState={noConsumptionState}
          />
          <LowUsageSpacer />
          <LowUsagePlacesInformation
            backofficeLocationName={installationDetails.location.name}
            googlePlacesInformation={googlePlacesInformation}
            loadingGooglePlacesInformation={loadingGooglePlacesInformation}
          />
        </FpGridItem>
      </FpGrid>

      <FpDivider marginY="1rem" height="0rem" />
      <FpText variant="h5">Options</FpText>
      <FpGrid spacing={{ sm: 1 }} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
        <FpGridItem {...actionsGrid}>
          <div className={styles.buttonContainer}>
            <FpButtonOutlined
              className="greenButton"
              disabled={performingLowUsageAction}
              onClick={() => {
                installationAction(LowUsageAction.ViewInstallation, id);
              }}
            >
              View installation
            </FpButtonOutlined>
            <FpText variant="body1">View the installation details before making a decision.</FpText>
          </div>
        </FpGridItem>
        <FpGridItem {...actionsGrid}>
          <div className={styles.buttonContainer}>
            <FpButtonOutlined
              className="greyButton"
              disabled={performingLowUsageAction}
              onClick={() => {
                installationAction(LowUsageAction.DoNothing, id);
              }}
            >
              Skip for now
            </FpButtonOutlined>
            <FpText variant="body1">We will show this installation again next time you are here.</FpText>
          </div>
        </FpGridItem>
        <FpGridItem {...actionsGrid}>
          <div className={styles.buttonContainer}>
            <FpButtonOutlined
              className="orangeButton"
              disabled={performingLowUsageAction}
              onClick={() => {
                installationAction(LowUsageAction.Ignore, id);
              }}
            >
              Ignore
            </FpButtonOutlined>
            <FpText variant="body1">
              We won't do anything and <strong>wait for water usage to start again</strong> and you won't be notified
              again.
            </FpText>
          </div>
        </FpGridItem>
        <FpGridItem {...actionsGrid}>
          <div className={styles.buttonContainer}>
            <FpButtonOutlined
              className="tealButton"
              disabled={noConsumptionState || performingLowUsageAction}
              onClick={() => {
                installationAction(LowUsageAction.ActivateEstimate, id);
              }}
            >
              Activate estimated usage
            </FpButtonOutlined>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              {!noConsumptionState ? (
                <Fragment>
                  <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
                    {estimatedUsage}L
                  </FpText>
                  <FpText variant="body1"> usage will be added now.</FpText>
                </Fragment>
              ) : (
                <FpText variant="body1">No consumption found.</FpText>
              )}
            </div>

            <FpText variant="body1">
              Estimate usage (<strong>{Math.round(averageDailyConsumptionHistorically)}L daily</strong>) will activate.
            </FpText>
            <FpText variant="body1">It will continue until we see actual consumption again.</FpText>
          </div>
        </FpGridItem>
      </FpGrid>
    </>
  );
};

export default LowUsageWizard;
