import { Grid, useMediaQuery, useTheme } from '@mui/material';
import FpButtonOutlined from './OutlinedButton';

interface Props {
  alwaysLeftAlign?: boolean;
  className: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled: boolean;
  icon: React.ReactNode;
  label: string;
  onClick(): void;
}

const FpButtonOutlinedAligned = (props: Props): JSX.Element => {
  const { alwaysLeftAlign, disabled, className, color, label, onClick, icon } = props;
  const theme = useTheme();
  const mediaQueryIsSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = alwaysLeftAlign ? alwaysLeftAlign : mediaQueryIsSmallScreen;

  const startIcon = isSmallScreen ? undefined : icon;

  return (
    <FpButtonOutlined disabled={disabled} color={color} className={className} startIcon={startIcon} onClick={onClick}>
      {!isSmallScreen && <>{label}</>}

      {isSmallScreen && (
        <Grid container spacing={0} columnSpacing={0}>
          <Grid item xs={2} sm={2} md={2} lg={2} height={20}>
            {icon}
          </Grid>
          <Grid item xs={10} sm={10} md={10} lg={10} textAlign={'left'}>
            {label}
          </Grid>
        </Grid>
      )}
    </FpButtonOutlined>
  );
};

export default FpButtonOutlinedAligned;
