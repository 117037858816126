import { IBlacklistItem } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { Action, action, Thunk, thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { IStoreModel } from '.';
import { handleDeleteRequest, handlePostRequest } from '../modules/server-requests/server-requests';

export interface IBlackListItemView extends IBlacklistItem {
  flowplanDeviceId: string;
}

export interface IBlacklistDataModel {
  loadingBlacklistData: boolean;
  blacklist: IBlackListItemView[];

  getBlacklist: Thunk<IBlacklistDataModel, void, void, IStoreModel>;

  setBlacklist: Action<IBlacklistDataModel, IBlackListItemView[]>;
  setLoadingBlacklistData: Action<IBlacklistDataModel, boolean>;

  blacklistItem: Thunk<IBlacklistDataModel, string>;
  whitelistItem: Thunk<IBlacklistDataModel, string>;

  onActionsThatUpdateBlacklist: ThunkOn<IBlacklistDataModel, void, IStoreModel>;
}

const blacklistDataModel: IBlacklistDataModel = {
  blacklist: [],

  loadingBlacklistData: false,
  getBlacklist: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingBlacklistData(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.blacklist });
    if (requestResponse.success) {
      actions.setBlacklist(requestResponse.data);
    }
    actions.setLoadingBlacklistData(false);
  }),
  setBlacklist: action((state, payload) => {
    state.blacklist = payload;
  }),
  setLoadingBlacklistData: action((state, payload) => {
    state.loadingBlacklistData = payload;
  }),

  blacklistItem: thunk(async (actions, payload) => {
    const requestResponse = await handlePostRequest({ networkDeviceId: payload }, Routes.blacklist, true);
    return requestResponse;
  }),
  whitelistItem: thunk(async (actions, payload) => {
    const requestResponse = await handleDeleteRequest({ id: payload }, Routes.blacklist);
    return requestResponse;
  }),

  onActionsThatUpdateBlacklist: thunkOn(
    (actions) => [
      actions.whitelistItem,
      actions.blacklistItem,
    ],
    async (actions, target) => {
      await actions.getBlacklist();
    },
  ),
};

export default blacklistDataModel;
