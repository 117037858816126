import { IFlowplanClient } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { Action, Thunk, ThunkOn } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { IProduct } from '../../Products/types/products-types';
import { IProductDiscountBase } from '../../prodcut-discounts/types/product-discounts-types';

export enum OrderStatus {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export enum InvalidDeliveryType {
  NoSelection = 'INVALID',
}

export enum DeliveryType {
  Flowplan = 'FLOWPLAN',
  Shipment = 'SHIPMENT',
}

export type IAddress = {
  companyName: string;
  country: string;
  street: string;
  zipCode: string;
  city: string;
  email: string;
};

export type LegacyAddress = IAddress & {
  companyRegistrationNumber?: string;
};

export interface IProductForOrder extends Omit<IProduct, 'id'>, IProductDiscountBase {
  quantity: number;
}

export interface Order {
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  name: string;
  comment: string;
  deliveryAddress: IAddress;
  deliveryType: DeliveryType;
  deliveryFees: number;
  flowplanClientId: number;
  id: number;
  products: IProductForOrder[];
  status: OrderStatus;
  totalPrice: number;
  totalWeight: number;
  userId: number;
  flowplanClient?: IFlowplanClient;
}

export interface EditOrderForm {
  name: string;
  id?: number;
  status?: OrderStatus;
  createdAt?: string;
  updatedAt?: string;
  products: Array<OrderFormItem>;
  comment: string;
  deliveryType: DeliveryType | InvalidDeliveryType;
  deliveryFees: number;
  deliveryAddress: IAddress;
  flowplanClientId?: number;
  flowplanClient?: IFlowplanClient;
}

export interface OrderFormItem {
  id: number;
  quantity: number;
}

export enum OrderFormAddressType {
  DELIVERY = 'delivery',
}

export type DeliveryOption = {
  type: DeliveryType;
  label: string;
  infoDk?: string;
  infoInternational?: string;
};
export enum OrderOperationType {
  'CREATE' = 'create',
  'EDIT' = 'edit',
}

export const EMPTY_ADDRESS = {
  companyName: '',
  country: '',
  street: '',
  zipCode: '',
  city: '',
  email: '',
};

export const DELIVERYOPTIONS: Array<DeliveryOption> = [
  {
    type: DeliveryType.Flowplan,
    label: 'Pickup from Flowplan',
    infoDk: 'We will send you an email when your order is ready for pickup.',
    infoInternational: 'We will send you an email when your order is ready for pickup.',
  },
  {
    type: DeliveryType.Shipment,
    label: 'Postal delivery',
    infoDk:
      'Delivery is free for orders above 5,000 DKK/670 EUR. Otherwise, delivery fee will be added to the order once it has been processed.',
    infoInternational: 'Be sure to check your email about delivery updates.',
  },
];

export interface IOrdersModel {
  orders: Order[];
  getOrders: Thunk<IOrdersModel, number, void, IStoreModel>;
  setOrders: Action<IOrdersModel, Order[]>;
  loadingOrders: boolean;
  setLoadingOrder: Action<IOrdersModel, boolean>;
  deleteOrder: Thunk<IOrdersModel, number, void, IStoreModel>;
  updateOrder: Thunk<IOrdersModel, EditOrderForm, void, IStoreModel>;
  createOrder: Thunk<IOrdersModel, EditOrderForm, void, IStoreModel>;

  onActionsThatUpdateOrders: ThunkOn<IOrdersModel, void, IStoreModel>;

  draftOrderBuffer: { draftOrder: EditOrderForm; draftOrderItems: Array<IProductForOrder> } | null;
  setEditBuffer: Action<IOrdersModel, Order | string>;

  draftOrderItems: Array<IProductForOrder>;
  draftOrderForm: EditOrderForm;
  updateDraftOrder: Action<IOrdersModel, EditOrderForm>;
  addDraftOrderItem: Action<IOrdersModel, IProductForOrder>;
  updateDraftOrderItemQuantity: Action<IOrdersModel, IProductForOrder>;
  updateDraftOrderDeliveryAddress: Action<IOrdersModel, IAddress>;
  updateDraftOrderEmailForOrderUpdates: Action<IOrdersModel, string>;
  updateDraftOrderDeliveryType: Action<IOrdersModel, DeliveryType | InvalidDeliveryType>;
  updateDraftOrderDeliveryFees: Action<IOrdersModel, number>;
  updateDraftOrderStatus: Action<IOrdersModel, OrderStatus>;
  updateDraftOrderComment: Action<IOrdersModel, string>;
  deleteDraftOrderItem: Action<IOrdersModel, number>;
  resetDraftStates: Action<IOrdersModel>;
}
