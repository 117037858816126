import { sortByKey } from '@hsjakobsen/utilities';
import { action, Action, Thunk, thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { handleDeleteRequest, handlePostRequest, handlePutRequest } from '../../server-requests/server-requests';
import { IResponse } from '../../server-requests/server-requests-model';
import { FlowplanProductTypes } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';

export interface ITapStockBase {
  stockTypeId: number;
  name: string;
  capacity: number | null;
  currentInventory: number | null;
}

export interface TapStockCreate extends ITapStockBase {
  locationId: number;
}
export type ITapStockCreateWithoutLocationId = Omit<TapStockCreate, 'locationId'>;

export interface TapStockUpdate extends Partial<ITapStockBase> {
  id: number;
}
export interface ITapStockTopup {
  id: number;
  tapStockName: string;
  kegSize: number;
  topupAmount: number;
  capacity: number;
  currentInventory: number;
}
export type ITapStockTopupBatch = Array<ITapStockTopup>;
export type ITapStockBatchCreate = ITapStockCreateWithoutLocationId[];

export interface TapStock {
  id: number;
  locationId: number;
  name: string;
  currentInventory: number;
  capacity: number;
  stockTypeId: number;
  stockType: {
    name: string;
    multiplier: number
  }
}

export enum TapStockStatus {
  'EMPTY',
  'GOOD',
  'RUNNING_OUT'
}

const defaultTapStock: TapStock = {
  id: 0,
  locationId: 0,
  name: '',
  currentInventory: 0,
  capacity: 0,
  stockTypeId: 0,
  stockType: {
    name: '',
    multiplier: 0
  }
};

export type TapStockRefillData = { id: number; refillAmount: number };

type TapStockBulkRefillRequest = {
  refillData: TapStockRefillData[];
  locationId: number;
}

type BatchCreateTapStocks = {
  newStocks: ITapStockBase[];
  locationId: number;
}

type TapStockUpdateRequest = {
  data: TapStockUpdate;
  locationId: number;
}

type TapStockDeleteRequest = {
  stockId: number;
  locationId: number;
}

export interface ITapStockModel {
  tapStocks: TapStock[];
  tapStockData: TapStock;
  loading: boolean;
  setTapStocks: Action<ITapStockModel, TapStock[]>;
  setTapStockData: Action<ITapStockModel, TapStock>;
  setLoading: Action<ITapStockModel, boolean>;

  getTapStocksByLocationId: Thunk<ITapStockModel, number, void, IStoreModel>;

  topupTapStocksForm: ITapStockTopupBatch;
  addTopupTapStocksFormData: Action<ITapStockModel, ITapStockTopup>;
  updateTopupStocksFormItem: Action<ITapStockModel, ITapStockTopup>;
  resetTopupTapStockFormData: Action<ITapStockModel>;

  createTapStock: Thunk<ITapStockModel, TapStockCreate, void, IStoreModel>;
  updateTapStock: Thunk<ITapStockModel, TapStockUpdateRequest, void, IStoreModel>;
  deleteTapStock: Thunk<ITapStockModel, TapStockDeleteRequest, void, IStoreModel>;

  batchCreateTapStock: Thunk<ITapStockModel, BatchCreateTapStocks, void, IStoreModel>;
  bulkRefillTapStock: Thunk<ITapStockModel, TapStockBulkRefillRequest, void, IStoreModel>;
  refillTapStock: Thunk<ITapStockModel, TapStockUpdate[], void, IStoreModel>;

  onTapStockActions: ThunkOn<ITapStockModel, void, IStoreModel>;
}

const tapStockModel: ITapStockModel = {
  tapStocks: [],
  tapStockData: defaultTapStock, // Initialize with default tap stock
  loading: false,

  setTapStocks: action((state, tapStocks) => {
    state.tapStocks = sortByKey(tapStocks, 'name', true);
  }),

  setTapStockData: action((state, tapStock) => {
    state.tapStockData = tapStock;
  }),

  setLoading: action((state, loading) => {
    state.loading = loading;
  }),

  getTapStocksByLocationId: thunk(async (actions, locationId, { getStoreActions }) => {
    actions.setLoading(true);
    const { data, success }: IResponse = await getStoreActions().serverRequestsModel.get({
      route: `/taps/stocks/location/${locationId}`,
    });
    if (success) actions.setTapStocks(data as TapStock[]);
    actions.setLoading(false);
  }),

  createTapStock: thunk(async (actions, tapStock) => {
    tapStock.capacity = tapStock.currentInventory
    const result = await handlePostRequest(tapStock, '/taps/stocks', true);
    return result;
  }),

  updateTapStock: thunk(async (actions, updateRequest) => {
    const tapStock = updateRequest.data
    tapStock.capacity = tapStock.currentInventory
    const result = await handlePutRequest(tapStock, '/taps/stocks', true);
    return result;
  }),

  deleteTapStock: thunk(async (actions, { stockId }) => {
    const result = await handleDeleteRequest({ id: stockId }, `/taps/stocks/${stockId}`);
    return result;
  }),

  topupTapStocksForm: [],
  addTopupTapStocksFormData: action((state, payload) => {
    const newTapStockData = state.topupTapStocksForm.findIndex((tapStock) => tapStock.id === payload.id) === -1;
    if (newTapStockData) {
      state.topupTapStocksForm.push(payload);
    }
  }),
  updateTopupStocksFormItem: action((state, payload) => {
    const indexToUpdate = state.topupTapStocksForm.findIndex((tapStock) => tapStock.id === payload.id);
    if (indexToUpdate >= 0) {
      state.topupTapStocksForm[indexToUpdate].topupAmount = payload.topupAmount;
    }
  }),
  resetTopupTapStockFormData: action((state) => {
    state.topupTapStocksForm = [];
  }),

  refillTapStock: thunk(async (actions, tapStockTopup, { getStoreActions }) => {
    const processedTapStockTopup = tapStockTopup.map((tapStock) => {
      return {
        ...tapStock,
        capacity: tapStock.currentInventory
      }
    })
    const result = await handlePostRequest(processedTapStockTopup, `/taps/stocks/topup/`, true);
    return result;
  }),

  batchCreateTapStock: thunk(async (actions, payload) => {
    const { locationId, newStocks } = payload;
    const tapStocksToCreate = newStocks.map((tapStock) => {
      return {
        ...tapStock,
        locationId
      }
    })
    const result = await handlePostRequest(tapStocksToCreate, `/taps/stocks/batch/${locationId}`, true);
    return result;
  }),

  bulkRefillTapStock: thunk(async (actions, payload, { getState }) => {
    const { refillData } = payload;
    const topupData = refillData.map((stock) => {
      return {
        id: stock.id,
        currentInventory: stock.refillAmount,
      };
    });
    return await actions.refillTapStock(topupData);
  }),


  // Side effects
  onTapStockActions: thunkOn(
    (actions, storeActions) => [
      actions.deleteTapStock,
      actions.updateTapStock,
      actions.batchCreateTapStock,
      actions.bulkRefillTapStock,
    ],
    async (actions, payload, { getStoreActions }) => {
      const getTapStocks = actions.getTapStocksByLocationId(payload.payload.locationId);
      const getLocations = getStoreActions().locationsModel.getLocations({ flowplanProductTypeId: FlowplanProductTypes.Taps })
      const getTapStockCounterStatus = getStoreActions().tapStockCounterModel.getTapStockCountersByTaplocationId(payload.payload.locationId)
      await Promise.all([getTapStocks, getLocations, getTapStockCounterStatus])

    },
  ),
};

export default tapStockModel;
