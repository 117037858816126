import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { DataStatus } from '@flowplan/flowplan-shared/lib/data.suspicious/data.suspicious';
import { action, thunk, thunkOn } from 'easy-peasy';
import { handleDeleteRequest, handlePostRequest } from '../../server-requests/server-requests';
import { IGetSuspiciousDataParams, ISuspiciousDataModel } from '../types/data-suspicious-types';

const suspiciousDataModel: ISuspiciousDataModel = {
  loadingSuspiciousData: false,
  suspiciousDataList: [],
  suspiciousDataToApprove: [],

  handleDataForApproval: action((state, payload) => {
    state.suspiciousDataToApprove = payload;
  }),
  clearDataForApproval: action((state, payload) => {
    state.suspiciousDataToApprove = [];
  }),

  setLoadingSuspiciousData: action((state, payload) => {
    state.loadingSuspiciousData = payload;
  }),
  getSuspiciousData: thunk(async (actions, payload, { getStoreActions }) => {
    const params: IGetSuspiciousDataParams = {
      status: DataStatus.Suspicious,
    };

    actions.setLoadingSuspiciousData(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.suspiciousData, params });
    actions.setLoadingSuspiciousData(false);
    if (requestResponse.success) {
      actions.setSuspiciousDataList(requestResponse.data);
    }
  }),

  setSuspiciousDataList: action((state, payload) => {
    state.suspiciousDataList = payload;
  }),

  markSuspiciousDataDeleted: thunk(async (actions, payload) => {
    return await handleDeleteRequest({ id: payload }, Routes.suspiciousData);
  }),
  markSuspiciousDataNormal: thunk(async (actions, payload) => {
    return await handlePostRequest({ id: payload }, Routes.suspiciousData, true);
  }),

  onActionsThatUpdateSuspiciousData: thunkOn(
    (actions) => [
      actions.markSuspiciousDataDeleted,
      actions.markSuspiciousDataNormal,
    ],
    async (actions) => {
      actions.getSuspiciousData();
    },
  ),
};

export default suspiciousDataModel;
