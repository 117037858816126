import { action, thunk, thunkOn } from 'easy-peasy';
import { getUserSetting, LocalStore, storeUserSetting } from '../../../Utility/LocalStorageFunctions';
import { handleDeleteRequest, handlePostRequest, handlePutRequest } from '../../server-requests/server-requests';
import { EditOrderForm, IAddress, InvalidDeliveryType, IOrdersModel, LegacyAddress } from '../types/orders-type';

const newDraftForm: EditOrderForm = {
  name: '#xxxxxxx',
  comment: '',
  deliveryAddress: {
    companyName: '',
    country: '',
    street: '',
    zipCode: '',
    city: '',
    email: '',
  },
  deliveryType: InvalidDeliveryType.NoSelection,
  deliveryFees: 0,
  products: [],
};

const ordersModel: IOrdersModel = {
  loadingOrders: false,
  orders: [],

  setLoadingOrder: action((state, payload) => {
    state.loadingOrders = payload;
  }),
  setOrders: action((state, payload) => {
    state.orders = payload;
  }),

  getOrders: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingOrder(true);
    const route = '/orders/' + payload;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: route });
    if (requestResponse.success) {
      actions.setOrders(requestResponse.data);
    }
    actions.setLoadingOrder(false);
  }),

  createOrder: thunk(async (actions, payload, { getStoreState }) => {
    const isFlowplanAdmin = getStoreState().authModel.access.flowplanAdmin;
    const flowplanClientSelected = getStoreState().adminDataModel.flowplanClientSelected;
    const requestResponse = await handlePostRequest(
      payload,
      `/orders/?flowplanClientId=${isFlowplanAdmin ? flowplanClientSelected : -1}`,
      true,
    );
    return requestResponse;
  }),
  updateOrder: thunk(async (actions, payload, { getStoreState }) => {

    const orderInformation = { ...payload };

    const legacyAddressUpdated = orderInformation.deliveryAddress as LegacyAddress;
    if (legacyAddressUpdated.companyRegistrationNumber !== undefined) {
      delete legacyAddressUpdated.companyRegistrationNumber;
    }
    orderInformation.deliveryAddress = legacyAddressUpdated;

    const requestResponse = await handlePutRequest(orderInformation, `/orders/`, true);
    return requestResponse;
  }),
  deleteOrder: thunk(async (actions, payload) => {
    const requestResponse = await handleDeleteRequest({ id: payload }, '/orders/');
    return requestResponse;
  }),

  onActionsThatUpdateOrders: thunkOn(
    (actions) => [actions.createOrder, actions.updateOrder, actions.deleteOrder],
    async (actions, target, { getStoreState }) => {
      const flowplanClientId = getStoreState().adminDataModel.flowplanClientSelected;
      await actions.getOrders(flowplanClientId);
    },
  ),

  draftOrderBuffer: null,

  setEditBuffer: action((state, payload) => {
    if (typeof payload === 'object') {
      state.draftOrderBuffer = {
        draftOrder: state.draftOrderForm,
        draftOrderItems: state.draftOrderItems,
      };
      const {
        name,
        id,
        status,
        createdAt,
        updatedAt,
        products,
        comment,
        deliveryType,
        deliveryFees,
        deliveryAddress,
        flowplanClientId,
        flowplanClient,
      } = payload;

      const productsForOrderForm = products.map((product) => {
        return { id: product.id, quantity: product.quantity };
      });

      state.draftOrderForm = {
        name,
        id,
        status,
        createdAt,
        updatedAt,
        products: productsForOrderForm,
        comment,
        deliveryType,
        deliveryFees,
        deliveryAddress,
        flowplanClientId,
        flowplanClient,
      };
      state.draftOrderItems = products;
    } else if (payload === 'edit') {
      if (state.draftOrderBuffer) {
        state.draftOrderForm = state.draftOrderBuffer.draftOrder;
        state.draftOrderItems = state.draftOrderBuffer.draftOrderItems;
      } else {
        state.draftOrderForm = newDraftForm;
        state.draftOrderItems = [];
      }
      state.draftOrderBuffer = null;
    }
  }),

  draftOrderForm: newDraftForm,
  draftOrderItems: [],

  addDraftOrderItem: action((state, payload) => {
    state.draftOrderItems.push(payload);
    state.draftOrderForm.products.push({ id: payload.id, quantity: payload.quantity });
  }),
  updateDraftOrderItemQuantity: action((state, payload) => {
    const indexToUpdate = state.draftOrderItems.findIndex((product) => payload.id === product.id);

    if (payload.quantity > 0) {
      state.draftOrderItems = state.draftOrderItems.map((item) => {
        if (item.id === payload.id) {
          item.quantity = payload.quantity;
        }
        return item;
      });
      state.draftOrderForm.products = state.draftOrderForm.products.map((item) => {
        if (item.id === payload.id) {
          item.quantity = payload.quantity;
        }
        return item;
      });
    } else {
      state.draftOrderItems.splice(indexToUpdate, 1);
      state.draftOrderForm.products.splice(indexToUpdate, 1);
    }
  }),

  deleteDraftOrderItem: action((state, payload) => {
    state.draftOrderItems = state.draftOrderItems.filter((product) => product.id !== payload);
    state.draftOrderForm.products = state.draftOrderForm.products.filter((product) => product.id !== payload);
  }),

  updateDraftOrderDeliveryAddress: action((state, payload) => {
    state.draftOrderForm.deliveryAddress = { ...payload, email: state.draftOrderForm.deliveryAddress.email };

    const withoutEmail = { ...payload };
    withoutEmail.email = '';
    const storedAddresses = getUserSetting(LocalStore.OrderFormAddresses);

    let updatedStoredAddresses: IAddress[] = [];

    if (storedAddresses) {
      updatedStoredAddresses = JSON.parse(storedAddresses);
    }

    // check if address is already in list
    const alreadyInList = updatedStoredAddresses.find(
      (address) =>
        address.street === withoutEmail.street &&
        address.zipCode === withoutEmail.zipCode &&
        address.city === withoutEmail.city,
    );
    if (!alreadyInList) {
      updatedStoredAddresses.push(withoutEmail);
    }

    storeUserSetting({
      identifier: LocalStore.OrderFormAddresses,
      value: JSON.stringify(updatedStoredAddresses),
    });
  }),
  updateDraftOrderEmailForOrderUpdates: action((state, payload) => {
    state.draftOrderForm.deliveryAddress.email = payload;
  }),
  updateDraftOrderDeliveryType: action((state, payload) => {
    state.draftOrderForm.deliveryType = payload;
  }),
  updateDraftOrderDeliveryFees: action((state, payload) => {
    state.draftOrderForm.deliveryFees = payload;
  }),
  updateDraftOrderStatus: action((state, payload) => {
    state.draftOrderForm.status = payload;
  }),
  updateDraftOrderComment: action((state, payload) => {
    state.draftOrderForm.comment = payload;
  }),

  updateDraftOrder: action((state, payload) => {
    state.draftOrderForm = payload;
  }),
  resetDraftStates: action((state) => {
    state.draftOrderForm = newDraftForm;
    state.draftOrderItems = [];
    state.draftOrderBuffer = null;
  }),
};

export default ordersModel;
