import { EventTypeString } from '../../types/insights-consts';

export const getTypeOfInsightsEvent = (title: string): string => {
  let returnValue = ' events';

  if (title.includes('Coffee') || title.includes('Espresso')) {
    returnValue = ' cups';
  }

  return returnValue;
};

export const getTypeOfEvent = (title: string): EventTypeString => {
  let returnValue: EventTypeString = EventTypeString.blue;

  if (title.includes('Coffee')) {
    returnValue = EventTypeString.brown;
  }

  if (title.includes('Espresso')) {
    returnValue = EventTypeString.brown;
  }

  if (title.includes('Grouped Event')) {
    returnValue = EventTypeString.grey;
  }

  if (title.includes('Leak')) {
    returnValue = EventTypeString.red;
  }

  return returnValue;
};
