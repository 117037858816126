import { CompanyTag } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Fuse from 'fuse.js';
import { useSnackbar } from 'notistack';
import { memo, useEffect, useState } from 'react';
import { TagStatus } from '../../../../hooks/AvailableInstallationTags';
import { useStoreActions } from '../../../../Store';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpButtonIcon from '../../../common/components/FpButtonIcon';
import FpButtonRadio from '../../../common/components/FpButtonRadio/FpButtonRadio';
import FpContainer from '../../../common/components/FpContainer/FpContainer';
import FpTag from '../../../common/components/FpTag/FpTag';
import FpTextField from '../../../common/components/FpTextField/FpTextField';
import { IResponse } from '../../../server-requests/server-requests-model';
import { isUserGuest } from '../../../users/types/users-configs';
import { AddTagsFeedback } from '../../types/installations-model';
import CompanyTagOptions from './CompanyTagOptions';
import { AnchorOptions } from './InstallationTags';
import {
  canUserAddTagToInstallation,
  canUserModifyOrDeleteCompanyTag,
  ColorOptionsForButtons,
  DEFAULT_TAG_COLOR,
  getColorForButton,
  getColorOptionsForUser,
} from './tags-util';

export type TagOptions = {
  label: string;
  display: 'none' | 'inline-block';
  color?: string;
};

export type CompanyTagCreatedProps = {
  tagId: number;
  tagName: string;
};

type Props = {
  tagStatus: TagStatus;
  userRole: RoleType;
  companyTagSelection(companyTag: CompanyTag): void;
  companyTagCreated(tagInfo: CompanyTagCreatedProps): void;
};

const CreateCompanyTag = memo(({ companyTagCreated, companyTagSelection, tagStatus, userRole }: Props) => {
  const createTagForCompany = useStoreActions((actions) => actions.companyModel.createTagForCompany);

  const [tagColor, setTagColor] = useState<ColorOptionsForButtons>(DEFAULT_TAG_COLOR);
  const [tagName, setTagName] = useState('');
  const [tagsToShow, setTagsToShow] = useState<CompanyTag[]>([]);
  const [anchorEl, setAnchorEl] = useState<AnchorOptions>({
    anchorEl: null,
    label: '',
  });

  const { enqueueSnackbar } = useSnackbar();

  const guest = isUserGuest(userRole);
  const colorOptions = getColorOptionsForUser(userRole);

  useEffect(() => {
    if (tagName === '' || tagName.length < 2) {
      setTagsToShow(tagStatus.companyTags);
      return;
    }

    const fuse = new Fuse(tagStatus.companyTags, {
      keys: ['name'],
      threshold: 0.2,
    });

    const searchResults = fuse.search(tagName).map((item) => item.item);

    setTagsToShow(searchResults);
  }, [tagStatus, tagName]);

  const handleTagSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };

  const closeTagMenu = () => {
    setAnchorEl({
      anchorEl: null,
      label: '',
    });
  };

  if (guest) return null;

  return (
    <>
      <FpTextField
        handleInput={handleTagSearch}
        helperText={`${tagName.length}/${25}`}
        inputKey="Tag-search"
        inputText="Search for tag, you can add new tags if name doesn't exist"
        inputValue={tagName}
        maxLength={25}
        sx={{
          marginTop: '1rem',
          width: '100%',
        }}
        variant="outlined"
        disabled={guest} // Disable text field for guests
      />
      <FpContainer basic sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.1rem' }}>
        {tagsToShow.map((tag, index) => {
          const canModifyTag = canUserModifyOrDeleteCompanyTag(userRole, tag.flowplanClientId);
          const canAddTagToInstallation = canUserAddTagToInstallation(userRole);

          return (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FpTag
                selectionFunction={() => {
                  if (!canAddTagToInstallation) return; // Prevent unauthorized modifications

                  companyTagSelection(tag);
                }}
                sx={{ fontWeight: 'bold', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                color={tag.color}
                label={tag.name}
              >
                {canModifyTag && <CompanyTagOptions tag={tag} anchorOptions={anchorEl} closeMenu={closeTagMenu} />}
              </FpTag>
              <div
                style={{
                  background: tag.color,
                  borderTopRightRadius: '3px',
                  borderBottomRightRadius: '3px',
                }}
              >
                <FpButtonIcon
                  sx={{ borderRadius: '3px', py: 0, px: '0.25rem' }}
                  aria-label="options"
                  onClick={(event) => {
                    if (canModifyTag) {
                      event.stopPropagation();
                      setAnchorEl({
                        anchorEl: event.currentTarget,
                        label: tag.name,
                      });
                      return;
                    }
                    enqueueSnackbar('Only Flowplan can modify this tag', { variant: 'info' });
                  }}
                >
                  <MoreHorizIcon
                    sx={{
                      color: 'white !important',
                    }}
                  />
                </FpButtonIcon>
              </div>
            </div>
          );
        })}
      </FpContainer>
      {tagsToShow.length === 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
          <FpButtonRadio options={colorOptions} selectedValue={tagColor} setSelectedValue={setTagColor} />
          <FpButton
            color="secondary"
            size="large"
            disabled={guest}
            onClick={async () => {
              if (tagName.length < 3) {
                enqueueSnackbar('Tag name must be above 3 characters', { variant: 'error' });
                return;
              }
              const createTagResponse: IResponse = await createTagForCompany({
                color: getColorForButton(tagColor),
                tagName,
              });
              if (!createTagResponse.success) {
                enqueueSnackbar('Unable to add new company tag, check if it already exists.', { variant: 'error' });
                return;
              }
              const tagId = (createTagResponse.data as AddTagsFeedback[])[0].tagId;
              if (!tagId) {
                return;
              }

              companyTagCreated({
                tagId,
                tagName,
              });
              setTagName('');
            }}
          >
            Add new company tag
          </FpButton>
        </div>
      ) : null}
    </>
  );
});

export default CreateCompanyTag;
