import { IFilterChangeFixedTime } from '@flowplan/flowplan-shared';

interface IOptionFilterChangeBy {
  filterChangeByOptions: IFilterChangeFixedTime[];
}

const OptionFilterChangeBy = ({ filterChangeByOptions }: IOptionFilterChangeBy): JSX.Element => {
  return (
    <>
      {filterChangeByOptions.map((changeByOption) => {
        const flavorText = changeByOption.id !== -1 ? 'Months' : '';
        return (
          <option key={changeByOption.id} value={changeByOption.id}>
            {changeByOption.duration} {flavorText}
          </option>
        );
      })}
    </>
  );
};

export default OptionFilterChangeBy;
