import { Grid } from '@mui/material';
import FpText from '../../../../common/components/FpText/FpText';

type Props = {
  children: React.ReactNode;
  icon: React.ReactNode;
  title: string;
  translateTitle?: boolean
};

const LowUsageWizardContent = ({ children, icon, title, translateTitle = true }: Props): JSX.Element => {
  const leftContent = { xs: 4, sm: 4, md: 3, lg: 2 };
  const rightContent = { xs: 8, sm: 8, md: 9, lg: 10 };

  return (
    <Grid
      container
      spacing={{ sm: 1 }}
      rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }}
      columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }}
    >
      <Grid item {...leftContent}>
        {icon}
      </Grid>
      <Grid item {...rightContent}>
        <FpText variant="h6" className={!translateTitle ? 'do-not-translate' : ''}>{title}</FpText>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, paddingTop: 10 }}>{children}</div>
      </Grid>
    </Grid>
  );
};

export default LowUsageWizardContent;
