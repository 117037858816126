import { Close as CloseIcon } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { forwardRef } from 'react';
import FpButtonIcon from '../FpButtonIcon';

export interface DialogProps extends MuiDialogProps {
  onClose: () => void;
  showCloseButton?: boolean;
  disableBackdropClick?: boolean;
}

const Dialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
  const { onClose, children, showCloseButton = true, disableBackdropClick = false, ...rest } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const closeButton: JSX.Element | null = showCloseButton ? (
    <FpButtonIcon
      size="small"
      onClick={onClose}
      sx={() => ({
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
      })}
    >
      <CloseIcon />
    </FpButtonIcon>
  ) : null;

  const noBackdropClickOnCloseHandler: MuiDialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <MuiDialog
      onClose={disableBackdropClick ? noBackdropClickOnCloseHandler : onClose}
      {...rest}
      ref={ref}
      fullScreen={fullScreen}
      fullWidth={true}
    >
      {children}
      {closeButton}
    </MuiDialog>
  );
});

Dialog.displayName = 'Dialog';

export default Dialog;
