import { Grid, GridOwnProps } from '@mui/material';
import { forwardRef } from 'react';

const FpGridItem = forwardRef<HTMLDivElement, GridOwnProps>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <Grid item {...rest}>
      {children}
    </Grid>
  );
});

FpGridItem.displayName = 'GridItem';

export default FpGridItem;
