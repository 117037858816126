import { useEffect } from 'react';

type Props = {
  children: JSX.Element;
  title: string;
};

const Page = ({ children, title }: Props): JSX.Element => {
  useEffect(() => {
    document.title = 'Flowplan - Backoffice | ' + title;
  }, [title]);

  return children;
};

export default Page;
