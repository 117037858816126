import { ITaskInformation } from './tasks-utility';

export const taskInformationHardware = (): ITaskInformation => {
  const infoHeader = 'Hardware upgrade task';
  const infoTooltip = 'Please return this Beam to Flowplan.'
  const infoHighlight = 'We will replace this Beam with a newer hardware version';
  const infoList = 'Beam upgrade';

  return {
    infoHeader,
    infoHighlight,
    infoList,
    infoTooltip,
  };
};
