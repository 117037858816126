import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { UserTypeNames } from '@flowplan/flowplan-shared/lib/utils/texts';
import { IDropdownData } from '../../../common/interfacesFrontend';

export const userTypeList: IDropdownData[] = [
  { key: 1, value: RoleType.ADMIN, text: UserTypeNames.Administrator },
  { key: 2, value: RoleType.TECHNICIAN, text: UserTypeNames.Technician },
  { key: 3, value: RoleType.GUEST, text: UserTypeNames.Guest },
  // { key: 4, value: RoleType.EXTERNAL_TECHNICIAN, text: UserTypeNames.ExternalTechnician },
  { key: 4, value: RoleType.EXTERNAL_TECHNICIAN, text: 'System administrator' },
];

export const userTypeListAdminSection: IDropdownData[] = [
  ...userTypeList,
  { key: 4, value: RoleType.SUPER_ADMIN, text: UserTypeNames.FlowplanAdministrator },
];

export const isUserExternal = (userType: RoleType): boolean => {
  return userType === RoleType.GUEST || userType === RoleType.EXTERNAL_TECHNICIAN;
}

export const isUserGuest = (userType: RoleType): boolean => {
  return userType === RoleType.GUEST;
}

export const isUserAdmin = (userType: RoleType): boolean => {
  return userType === RoleType.ADMIN || userType === RoleType.SUPER_ADMIN;
}

export const isUserSuperAdmin = (userType: RoleType): boolean => {
  return userType === RoleType.SUPER_ADMIN;
}

export const isUserTechnician = (userType: RoleType): boolean => {
  return userType === RoleType.TECHNICIAN || userType === RoleType.EXTERNAL_TECHNICIAN;
}
