import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useState } from 'react';
import { useStoreState } from '../../../../Store';
import { defaultModalConfirm } from '../../../../common/defaults';
import LogDetailsList from '../../../../pages/AdminSection/Logs/LogDetailsList';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import FpText from '../../../common/components/FpText/FpText';

interface Props {
  alwaysLeftAlign?: boolean;
}

const InstallationLog = (props: Props): JSX.Element => {
  const { alwaysLeftAlign } = props;
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);

  const [showLogs, setShowLogs] = useState(false);

  const closeModal = () => {
    setShowLogs(false);
  };

  return (
    <>
      <FpButtonOutlinedAligned
        alwaysLeftAlign={alwaysLeftAlign}
        disabled={false}
        className={'greyButton'}
        icon={<TextSnippetIcon />}
        onClick={() => {
          setShowLogs(true);
        }}
        label="Show Beam logs"
      />
      <Dialog open={showLogs} onClose={closeModal} maxWidth={false} fullScreen>
        <DialogTitle>
          <FpText componentType='span' variant='h6'>Log details for: </FpText>
          <FpText componentType='span' variant='h6' className='do-not-translate'>{`${baseFilter.infoBasic.name} (${baseFilter.infoBasic.flowplanDeviceId})`}</FpText>
        </DialogTitle>

        <DialogContent>
          <LogDetailsList flowplanDeviceId={baseFilter.infoBasic.flowplanDeviceId} />
        </DialogContent>
        <DialogActions>
          <FpButton color="secondary" onClick={closeModal}>
            {defaultModalConfirm}
          </FpButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InstallationLog;
