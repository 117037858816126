import { formatLiters } from '@flowplan/flowplan-shared';
import _ from 'lodash';
import styles from './CustomTooltip.module.scss';

interface ICustomTooltip {
  alternateLabel?: string;
  allowZeroValues: boolean;
  dataUnit: string;
  dataUnitTwo: string;
  formatNumber: boolean;
  active?: boolean;
  payload?: IPayload[];
  label?: string;
}

interface IPayload {
  dataKey: string;
  name: string;
  value: string;
  payload: {
    date: string;
    name: string;
    nameTooltip: string;
    value: string;
    extraValue: string;
    estimatedValue: string;
    manualValue: string;
    stockValue: string;
    nameXAxis: string;
  };
}

const CustomTooltip = ({
  active,
  allowZeroValues,
  dataUnit,
  dataUnitTwo,
  alternateLabel,
  formatNumber,
  payload,
  label,
}: ICustomTooltip): JSX.Element | null => {
  if (!active || payload === null || payload === undefined) {
    return null;
  }

  if (payload[0] === undefined) {
    return null;
  }

  const tooltipInfo = payload[0].payload.nameTooltip;
  const labelToUse = !label ? alternateLabel : tooltipInfo ? tooltipInfo : label;

  let dataValue = 0;
  let estimatedValue = 0;
  let manualValue = 0;
  let stockValue = 0;
  let extraValue = 0;

  payload.forEach((item) => {
    if (item.dataKey === 'value') {
      dataValue = Number(item.value);
    }
    if (item.dataKey === 'estimatedValue') {
      estimatedValue = Number(item.value);
    }
    if (item.dataKey === 'manualValue') {
      manualValue = Number(item.value);
    }
    if (item.dataKey === 'stockValue') {
      stockValue = Number(item.value);
    }
    if (item.dataKey === 'extraValue') {
      extraValue = Number(item.value);
    }
  });

  const dataName = _.capitalize(payload[0].name);

  const valueData = formatNumber ? formatLiters(dataValue) : dataValue;
  const noData = dataValue === 0 && estimatedValue === 0 && manualValue === 0 && stockValue === 0 && !allowZeroValues;
  const showValue = dataValue !== 0 || allowZeroValues;
  return (
    <div className={styles.customTooltip}>
      <p className={styles.title}>{labelToUse}</p>
      {extraValue !== 0 ? <p className={styles.info}>{'Idle: ' + extraValue + dataUnit}</p> : null}
      {showValue ? <p className={styles.info}>{dataName + ': ' + valueData + dataUnit}</p> : null}
      {estimatedValue !== 0 ? <p className={styles.info}>{'Estimated: ' + estimatedValue + dataUnit}</p> : null}
      {manualValue !== 0 ? <p className={styles.info}>{'Added: ' + manualValue + dataUnit}</p> : null}
      {stockValue !== 0 ? <p className={styles.info}>{'Stock usage: ' + stockValue + dataUnitTwo}</p> : null}
      {noData ? <p className={styles.info}>No data available</p> : null}
    </div>
  );
};

export default CustomTooltip;
