import { Box } from '@mui/material';
import { getDateObject } from '../../../../Utility/time';
import FpText from '../../../common/components/FpText/FpText';

const Footer = (): JSX.Element => {
  return (
    <Box sx={{ padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <FpText variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '1rem' }}>
        Flowplan<sup>®</sup> Backoffice
      </FpText>
      <FpText variant="body1" sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
        © {getDateObject(null).year()} Flowplan<sup>®</sup>
      </FpText>
      <FpText variant="body1">Release: {import.meta.env.VITE_APP_VERSION}</FpText>
    </Box>
  );
};

export default Footer;
