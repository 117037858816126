import { formatError, IResponse } from '@hsjakobsen/utilities/lib/responses';
import { LatLngTuple } from 'leaflet';

export const getBaseRequestResponse = (): IResponse => formatError('Request failed');

export const daysNames: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const daysNamesLong: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const daysNumbers: number[] = [1, 2, 3, 4, 5, 6, 7];

export enum SampleTypes {
  Sample = 1,
  DuplicateSample = 2,
  Blank = 3,
  FieldBlank = 4,
}

// TODO: moved to shared package
export enum HardnessEnum {
  CARBONATE_HARDNESS = 'CARBONATE_HARDNESS',
  MICRO_SIEMENS_PER_CM = 'MICRO_SIEMENS_PER_CM',
  TOTAL_HARDNESS = 'TOTAL_HARDNESS',
}

export enum InputKeys {
  Name = 'name',
  Serial = 'deviceSerial',
  ShortDesc = 'shortDesc',
  LocationId = 'locationId',
  FlowmeterId = 'flowmeterId',
  ExternalSensorId = 'externalSensorId',
  FilterProducerId = 'filterProducerId',
  FilterId = 'filterId',
  Capacity = 'capacity',
  FilterChangeByLatestId = 'filterChangeByLatestId',
  ServiceChangeByLatestId = 'serviceChangeByLatestId',
  ServiceMaintenanceAtLiters = 'serviceMaintenanceAtLiters',
  ServiceCycleMaintenance = 'cycleMaintenance',
  ServiceCupsOfCoffee = 'serviceCupsOfCoffee',
  SteamOption = 'steamOption',
  BypassOverride = 'bypassOverride',
  FeatureService = 'serviceFeature',
  FeatureFilter = 'filterFeature',
}

export enum ItemsPerPage {
  Twenty = 20,
  Fifty = 50,
  Hundred = 100,
  OneThousand = 1000,
}

export enum SortingDirection {
  Ascending = 'ascending',
  Descending = 'descending',
}

export const filenameSeperator = ' ';

export const reDownloadCount = 5;

export enum SelectedOption {
  Lifetime = 1,
  LastMonth,
  SevenDays,
}

//Cell Width in data grid tables
export enum DataGridCellWidth {
  task = 30,
  tiny = 60,
  xSmall = 80,
  small = 100,
  medium = 150,
  large = 200,
  xLarge = 250,
  xxLarge = 300,
}

export const zoomWhenFindingLocation = 12;
export const maxZoomPossible = 18;
export const zoomLevelDefault = 7;
export const mapCoordsDefault: LatLngTuple = [55.936894, 11.09069824];

export const mobileWidth = 550;
export const dashboardMobileWidth = 770;

// browser support
// NOTE: When updating these versions, remember to change the browserslist setting in the package.json as well
export const minVersionRequiredFirefox = 90;
export const minVersionRequiredChrome = 80;
export const minVersionRequiredSafari = 14.1;
export const minVersionRequiredOpera = 70;
export const minVersionRequiredEdge = 80;
