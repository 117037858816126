import { getSerialNumberToShow } from '@flowplan/flowplan-shared';
import { HealthState } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';
import { useState } from 'react';
import { BeamHealthStatus, getBeamHealthStatus, NbConfig } from '../../../../hooks/BeamHealthStatus';
import { defaultHealthStatus } from '../../../../Models/graphSettings';
import { useStoreActions, useStoreState } from '../../../../Store';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import FpSelectSearch from '../../../common/components/FpSelectSearch/FpSelectSearch';
import FpText from '../../../common/components/FpText/FpText';
import HealthWarning, { HealthWarningInfo } from '../../../inventory/components/HealthWarning/HealthWarning';

interface Props {
  alwaysLeftAlign?: boolean;
}

const InstallationChangeSerial = (props: Props): JSX.Element => {
  const { alwaysLeftAlign } = props;

  const selectedInstallation = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const access = useStoreState((state) => state.authModel.access);
  const inventoryAvailable = useStoreState((state) => state.inventoryModel.inventoryAvailable);
  const inventoryToUse = useStoreState((state) => state.inventoryModel.inventoryToUse);
  const firmwareReleases = useStoreState((state) => state.firmwareReleasesModel.firmwareReleases);
  const changeSerial = useStoreActions((actions) => actions.deviceActionsModel.changeSerial);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();
  const [selectionValue, setSelectionValue] = useState<string>('');
  const [showChangeSerial, setShowChangeSerial] = useState(false);
  const [serialHealthState, setSerialHealthState] = useState<HealthState>(HealthState.Great);
  const [serialHealthStatus, setSerialHealthStatus] = useState<BeamHealthStatus>(defaultHealthStatus);
  const [showHealthWarning, setShowHealthWarning] = useState<HealthWarningInfo>({
    ignore: false,
    show: false,
  });

  const closeModal = () => {
    setShowChangeSerial(false);
  };

  const choiceConfirmed = async () => {
    const serial = String(selectionValue).substring(6);
    setSelectionValue('');
    const requestResponse = await changeSerial(serial);

    const feedback = requestResponse.success ? 'Installation serial changed' : 'Error: ' + requestResponse.data;
    const variant: VariantType = requestResponse.success ? 'success' : 'error';
    enqueueSnackbar(feedback, { variant });
    if (requestResponse.success) {
      closeModal();
    }
  };

  const isDisabled = selectionValue === '' || selectionValue === selectedInstallation.infoBasic.flowplanDeviceId;

  const gridLayout = { xs: 12, sm: 12, md: 12, lg: 12 };

  return (
    <>
      <FpButtonOutlinedAligned
        alwaysLeftAlign={alwaysLeftAlign}
        disabled={!access.installations.manage}
        className={'greyButton'}
        icon={<QrCodeIcon />}
        onClick={() => setShowChangeSerial(true)}
        label="Change serial"
      />
      <Dialog open={showChangeSerial} onClose={closeModal} fullWidth maxWidth="sm" fullScreen={fullScreen}>
        <DialogTitle>
          <FpText componentType='span' variant='h6'>Change serial for: </FpText>
          <FpText componentType='span' variant='h6' className='do-not-translate'>{selectedInstallation.infoBasic.name}</FpText>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={{ sm: 0.5 }} rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
            <Grid item {...gridLayout}>
              <FpText variant="h5">Select a new serial</FpText>
            </Grid>
            <Grid item {...gridLayout}>
              <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
                {'Current serial: ' + getSerialNumberToShow(selectedInstallation.infoBasic.flowplanDeviceId)}
              </FpText>
            </Grid>

            <Grid item {...gridLayout} sx={{ mt: 2 }}>
              <FpSelectSearch
                label="Available serials"
                options={inventoryAvailable}
                selectedValue={selectionValue}
                setSelectionValue={(value) => {
                  setSelectionValue(value as string);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FpButton color="error" onClick={closeModal}>
            Cancel
          </FpButton>
          <FpButton
            color="secondary"
            onClick={() => {
              const serialLookup = inventoryToUse.find((item) => item.flowplanDeviceId === selectionValue);
              if (!serialLookup) {
                enqueueSnackbar('Serial not found', { variant: 'error' });
                setSerialHealthState(HealthState.Great);
                return;
              }

              setSerialHealthState(serialLookup.healthState);
              const { dataConfiguration, batteryTypeId, firmware, hardware } = serialLookup;
              const nbConfigParsed = (dataConfiguration?.nbConfig as unknown as NbConfig) || null;
              const apn = nbConfigParsed?.gsmNbApn || 'None';
              const beamHealthStatus = getBeamHealthStatus({
                apn,
                batteryTypeId,
                firmware,
                firmwareReleases,
                hardware,
              });

              if (beamHealthStatus.shouldBeamBeReplaced) {
                setSerialHealthStatus(beamHealthStatus);
                setShowHealthWarning({
                  ignore: false,
                  show: true,
                });
                return;
              }

              setSerialHealthStatus(defaultHealthStatus);
              choiceConfirmed();
            }}
            disabled={isDisabled}
          >
            Confirm
          </FpButton>
        </DialogActions>
      </Dialog>
      <HealthWarning
        goodActionLabel="Select another Beam"
        onlyShowOneButton={false}
        choiceSelection={(info) => {
          setShowHealthWarning(info);
          if (info.ignore) {
            choiceConfirmed();
          }
        }}
        choiceSelectionInfo={showHealthWarning}
        healthStatus={serialHealthStatus}
        healthState={serialHealthState}
      />
    </>
  );
};

export default InstallationChangeSerial;
