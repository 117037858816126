import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { sortByKey } from '@hsjakobsen/utilities';
import { action, computed, thunk, thunkOn } from 'easy-peasy';
import { noSelectionMade } from '../../../Models/admin/admin.model';
import { IDropdownData } from '../../../common/interfacesFrontend';
import {
  handleDeleteRequest,
  handlePostRequest,
  handlePutRequest
} from '../../server-requests/server-requests';
import { IClientsModel } from '../types/clients-types';


export const defaultClientDropdownItem: IDropdownData = { key: -1, text: 'Select client', value: -1 };

const clientsModel: IClientsModel = {
  clientAdd: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await handlePostRequest(payload, Routes.clients, true);
    return requestResponse;
  }),

  clientUpdate: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await handlePutRequest(payload, Routes.clients, true)
    return requestResponse;
  }),

  clientRemove: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await handleDeleteRequest({ id: payload }, Routes.clients);
    return requestResponse;
  }),

  clientFoundById: undefined,
  clients: [],

  clientsDropdown: [],
  setClientDropdown: action((state, payload) => {
    const clientDropdown: IDropdownData[] = [];
    payload.forEach((client) => {
      const { name, id } = client;
      if (id) {
        clientDropdown.push({ key: id, text: name, value: id });
      }
    })

    sortByKey(clientDropdown, 'text', true)
    const itemToAdd: IDropdownData = defaultClientDropdownItem;
    clientDropdown.unshift(itemToAdd);
    state.clientsDropdown = clientDropdown;
  }),

  numClients: computed((state) => state.clients.length),

  getClientById: action((state, payload) => {
    state.clientFoundById = state.clients.find((x) => x.id === payload);
  }),

  loadingClientsData: false,


  getClients: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingClientsData(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.clients + payload });
    const { success, data } = requestResponse;
    if (success) {
      actions.setClients(data);
      actions.setClientDropdown(data);
    }
    actions.setLoadingClientsData(false);
  }),
  setLoadingClientsData: action((state, payload) => {
    state.loadingClientsData = payload;
  }),
  setClients: action((state, payload) => {
    state.clients = payload;
  }),

  resetClientsData: action((state) => {
    state.clients = [];
    state.clientsDropdown = [];
  }),

  onActionsThatUpdateClients: thunkOn(
    (actions, storeActions) => [
      actions.clientAdd,
      actions.clientUpdate,
      actions.clientRemove,
      storeActions.locationsModel.toggleNewLocationModal,
    ],
    async (actions) => {
      actions.getClients(noSelectionMade);
    },
  ),
};

export default clientsModel;
