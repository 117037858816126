import { BoxProps, Tooltip } from "@mui/material";
import FpText from "../../common/components/FpText/FpText";

interface Props extends BoxProps {
  showTooltip: boolean
}
const TapStockNoInventoryAvailableTooltip = ({ showTooltip, children }: Props) => {
  return <Tooltip
    title={showTooltip ?
      <FpText
        variant='body2'
        sx={{ fontSize: '1rem', padding: '0.5rem', borderRadius: 1, boxShadow: '1px 1px 15px rgba(0,0,0,0.2)' }}>
        You don't have available beams on your account. Please contact Flowplan to purchase more.
      </FpText>
      :
      ''
    }
    placement='left'
    sx={{ backgroundColor: 'transparent' }}>
    <span>
      {children}
    </span>
  </Tooltip>
}

export default TapStockNoInventoryAvailableTooltip
