export enum DeviceCategories {
  Coffee = 1,
  Washing = 2,
  Ice = 3,
  Oven = 4,
  Other = 5,
  NoSelection = 6,
}

export enum DeviceTypes {
  NoSelection = -1,
  Other = 1,
  SemiAutomatic = 2,
  FullyAutomatic = 3,
  FilterBrewer = 4,
  Tabletop = 5,
  Column = 6,
  Tunnel = 7,
  Regular = 8,
  Crushed = 9,
  SteamOven = 10,
  WaterDispenser = 11,
  FullyAutomaticFreshMilk = 12,
  CarWashManual = 13,
  CarWashAutomatic = 14,
  SoftIceMachine = 15,
  SlushIceMachine = 16,
}
