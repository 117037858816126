import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { ILocationHistory } from '@flowplan/flowplan-shared/lib/location.history/location.history';
import { sortByKey } from '@hsjakobsen/utilities';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { Action, action, Thunk, thunk } from 'easy-peasy';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { filenameSeperator } from '../../common/constsFrontend';
import { IStoreModel } from '../../Models';
import { getDateObject } from '../../Utility/time';
import { FpColumnHeader } from '../installations/components/Details/installation-details-export';
import { IFormattedLocationHistoryCSV } from './location-history-interfaces';
import { formatLocationHistoryData } from './location-history-utils';

interface ILocationHistoryParams {
  order?: string;
  page?: number;
  take?: number;
  userId?: number;
  locationId: number;
}

interface ILocationHistoryResponse {
  data: [ILocationHistory[], ILocationHistoryPaginationResponse];
}

interface ILocationHistoryPaginationResponse {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemCount: number;
  page: number;
  pageCount: number;
  take: number;
}

interface IExportLocationHistory {
  locationHistory: ILocationHistory[];
  documentTitle: string;
}

export interface ILocationHistoryModel {
  loadingLocationHistory: boolean;
  locationHistory: ILocationHistory[];

  pagination: ILocationHistoryPaginationResponse | null;

  getLocationHistory: Thunk<ILocationHistoryModel, ILocationHistoryParams, void, IStoreModel>;

  setLocationHistory: Action<ILocationHistoryModel, ILocationHistory[]>;
  setPagination: Action<ILocationHistoryModel, ILocationHistoryPaginationResponse>;
  setLoadingLocationHistory: Action<ILocationHistoryModel, boolean>;

  exportLocationHistoryToCSV: Thunk<ILocationHistoryModel, IExportLocationHistory, void, IStoreModel>;
}

const locationHistoryModel: ILocationHistoryModel = {
  loadingLocationHistory: false,
  locationHistory: [],

  pagination: null,

  getLocationHistory: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingLocationHistory(true);

    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.LocationHistory, params: payload });
    if (requestResponse.success) {
      const response: ILocationHistoryResponse = requestResponse as ILocationHistoryResponse;
      const locationHistory = response.data[0];
      actions.setLocationHistory(locationHistory);
      const pagination = response.data[1];
      actions.setPagination(pagination);
    }
    actions.setLoadingLocationHistory(false);
  }),

  setLoadingLocationHistory: action((state, payload) => {
    state.loadingLocationHistory = payload;
  }),

  setPagination: action((state, payload) => {
    state.pagination = payload;
  }),

  setLocationHistory: action((state, payload) => {
    sortByKey(payload, 'createdAt', false);
    state.locationHistory = payload;
  }),

  exportLocationHistoryToCSV: thunk(async (actions, payload, { getStoreState }) => {
    const users = getStoreState().usersModel.users;

    const documentTitle =
      'Location history' +
      filenameSeperator +
      getDateObject(null).format(dateFormatYMD) +
      filenameSeperator +
      payload.documentTitle;

    const headers: FpColumnHeader[] = [
      { displayLabel: 'Time of event', key: 'timeStamp' },
      { displayLabel: 'Event type', key: 'title' },
      { displayLabel: 'User', key: 'username' },
      { displayLabel: 'Info', key: 'info' }
    ];

    const formattedContentToExport: IFormattedLocationHistoryCSV[] = [];
    payload.locationHistory.forEach((dataSet) => {
      const processedDataSet = formatLocationHistoryData(dataSet, users, true);
      const dataSetToExport: IFormattedLocationHistoryCSV = {
        ...processedDataSet,
        info: processedDataSet.info?.join(' | ') || '',
      };
      formattedContentToExport.push(dataSetToExport);
    });


    if (formattedContentToExport.length === 0) {
      alert('No data to export');
      return;
    }

    const config = mkConfig({
      columnHeaders: headers,
      showColumnHeaders: true,
      useKeysAsHeaders: false,
      filename: documentTitle,
      title: documentTitle
    });

    const csv = generateCsv(config)(formattedContentToExport);
    download(config)(csv);
  }),
};

export default locationHistoryModel;
