import { Grid } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "../../../common/components";
import FpDivider from "../../../common/components/FpDivider/FpDivider";
import FpText from "../../../common/components/FpText/FpText";
import FpButton from "../../../common/components/FpButton/FpButton";
import { IFrontendBeamSerialNotInSystem } from "../../services/company-model";
import { formatNumberNoDecimals, getSerialNumberToShow, notAvailable } from "@flowplan/flowplan-shared";
import { getDateObject } from "../../../../Utility/time";
import { dateFormatYMD } from "@hsjakobsen/utilities/lib/date";
import { useStoreActions, useStoreState } from "../../../../Store";

const NotInSystemBeamInfo = ({ notInSystemSerial, toggleNotInSystemBeamInfoDialog }
  : {
    notInSystemSerial?: IFrontendBeamSerialNotInSystem;
    toggleNotInSystemBeamInfoDialog: () => void
  }) => {

  const flowplanClientSelected = useStoreState((state) => state.adminDataModel.flowplanClientSelected);
  const toggleSensorDetailsModal = useStoreActions((actions) => actions.newInstallationModel.toggleNewSensorModal);
  const parseModalNewSensorData = useStoreActions(
    (actions) => actions.newInstallationModel.parseModalNewInstallationData,
  );
  const pulsesCount = notInSystemSerial
    ? (notInSystemSerial.lastPayloadPulsesCount - notInSystemSerial.firstPayloadPulsesCount) / 1000
    : 0;
  const firstDate = getDateObject(notInSystemSerial?.firstPayloadReceivedAt || '');
  const lastDate = getDateObject(notInSystemSerial?.lastPayloadReceivedAt || '');
  const diffDays = lastDate.diff(firstDate, 'days');

  return (
    <Dialog open={!!notInSystemSerial} onClose={() => {
      toggleNotInSystemBeamInfoDialog()
    }} maxWidth="sm">
      <DialogTitle color="error">
        Not in system Beam: {getSerialNumberToShow(notInSystemSerial?.flowplanDeviceId || '')}
      </DialogTitle>
      <DialogContent>
        <FpText variant="h6">Transmission details:</FpText>
        <FpDivider height="0rem" marginY="0.25rem" hidden />
        <Grid container spacing={0} rowSpacing={1}>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            First data received on
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} textAlign="left">
            <strong>{firstDate.format(dateFormatYMD)}</strong>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            Last data received on
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} textAlign="left">
            <strong>{lastDate.format(dateFormatYMD)}</strong>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            Beam has been sending for
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} textAlign="left">
            <strong>{diffDays} days</strong>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            Data received
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} textAlign="left">
            <strong>{notInSystemSerial?.payloadReceivedCount}</strong>
          </Grid>
        </Grid>
        <FpDivider height="0rem" marginY="1rem" />
        <FpText variant="h6">Usage:</FpText>
        <FpDivider height="0rem" marginY="0.25rem" hidden />
        <Grid container spacing={0} rowSpacing={1}>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            3/8" flowmeter
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} textAlign="left">
            <strong>{`${formatNumberNoDecimals(Math.ceil(pulsesCount / 1.134))}L`}</strong>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            3/4" flowmeter
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} textAlign="left">
            <strong>{`${formatNumberNoDecimals(Math.ceil(pulsesCount / 0.283))}L`}</strong>
          </Grid>
        </Grid>
        <FpDivider height="0rem" marginY="1rem" />
        <FpText variant="h6">Beam status:</FpText>
        <FpDivider height="0rem" marginY="0.25rem" hidden />
        <Grid container spacing={0} rowSpacing={1}>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            Firmware
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} textAlign="left">
            <span>
              <strong>{notInSystemSerial?.firmware ? notInSystemSerial.firmware : notAvailable}</strong>
            </span>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FpButton color="error" onClick={() => {
          toggleNotInSystemBeamInfoDialog()
        }}>
          Cancel
        </FpButton>
        <FpButton
          color="secondary"
          onClick={() => {
            if (flowplanClientSelected !== 0) {
              toggleSensorDetailsModal({
                flowplanDeviceId: notInSystemSerial?.flowplanDeviceId,
                showModal: true,
                shouldRetrieveDetailsData: false,
              });
              parseModalNewSensorData({ installationData: undefined });
            } else {
              return;
            }
          }}
        >
          Add installation
        </FpButton>
      </DialogActions>
    </Dialog>)
}
export default NotInSystemBeamInfo
