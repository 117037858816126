import React, { ReactNode } from 'react';
import './ComponentRow.scss';

interface IComponentRow {
  children: ReactNode;
}

const ComponentRow = ({ children }: IComponentRow): JSX.Element => {
  return <div className="component-row-container">{children}</div>;
};

export default ComponentRow;
