import React from 'react';
import styles from './VisualElementContainer.module.scss';

interface IVisualElementContainer {
  children: React.ReactNode;
  enableFlex: boolean;
  extraClass: string;
}

const VisualElementContainer = ({ children, enableFlex, extraClass }: IVisualElementContainer): JSX.Element => {
  const styleToUse = styles.visualInfo + (enableFlex ? ' flex-1' : '') + ' ' + extraClass;
  return <div className={styleToUse}>{children}</div>;
};

export default VisualElementContainer;
