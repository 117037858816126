import React, { ChangeEvent } from 'react';
import { IBaseInputProps } from '../../common/interfacesFrontend';
import './DropdownList.scss';

interface IDropdownList extends IBaseInputProps {
  children: React.ReactNode;
  isRequired: boolean;
  inputKey: string;
  handleInput(e: ChangeEvent, inputKey: string): void;
}

const DropdownList = ({
  children,
  handleInput,
  inputKey,
  inputText,
  inputValue,
  isFirst,
  isFirstInRow,
  isRequired,
}: IDropdownList): JSX.Element => {
  let inputStyle = 'input-group input-dropdown' + (isFirst !== undefined && isFirst ? '-first' : '');
  inputStyle += isFirstInRow !== undefined ? ' input-dropdown-row' : '';
  return (
    <div className={inputStyle}>
      <label className="input-select-label">{inputText}</label>
      <select
        value={inputValue}
        className="input-dropdown-select"
        onChange={(e) => handleInput(e, inputKey)}
        required={isRequired}
      >
        {children}
      </select>
    </div>
  );
};

export default DropdownList;
