import { ChangeEvent, Fragment } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import Checkbox from '../../../../Components/Input/Checkbox';
import { useStoreActions, useStoreState } from '../../../../Store';
import { DeviceCategories, DeviceTypes } from '../../../../common/constsCategoryAndTypes';
import { InputKeys } from '../../../../common/constsFrontend';
import FlowplanMaintenanceChangeByLatestSelection from './components/Maintenance/ChangeByLatest';
import FlowplanMaintenanceLiters from './components/Maintenance/ConsumptionBased';
import MaintenanceCycles from './components/Maintenance/MaintenanceCycles';
import FlowplanMaintenanceStartDateSelection from './components/Maintenance/MaintenanceDate';

const FlowplanMaintenanceSection = (): JSX.Element => {
  const setFeatureService = useStoreActions((actions) => actions.newInstallationModel.setFeatureService);
  const featureService = useStoreState((actions) => actions.newInstallationModel.featureService);
  const isPremium = useStoreState((state) => state.companyModel.isPremium);
  const selectedCategory = useStoreState((state) => state.newInstallationModel.selectedCategory);
  const inputSelectedType = useStoreState((state) => state.newInstallationModel.inputSelectedType);
  return (
    <Segment>
      <Header size="medium">
        <Header.Content>
          Maintenance
          <Header.Subheader>
            <Checkbox
              inputValue={featureService}
              wideFormat={true}
              inputKey={InputKeys.FeatureService}
              inputText="Enable maintenance"
              handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
                const value = ev.target.checked;
                setFeatureService(value);
              }}
            />
          </Header.Subheader>
        </Header.Content>
      </Header>

      {featureService && (
        <Fragment>
          <FlowplanMaintenanceChangeByLatestSelection />
          {(!isPremium ||
            selectedCategory !== DeviceCategories.Coffee ||
            inputSelectedType === DeviceTypes.FilterBrewer) && <FlowplanMaintenanceLiters />}
          {isPremium &&
            selectedCategory === DeviceCategories.Coffee &&
            inputSelectedType !== DeviceTypes.FilterBrewer && <MaintenanceCycles />}
          <FlowplanMaintenanceStartDateSelection />
        </Fragment>
      )}
    </Segment>
  );
};

export default FlowplanMaintenanceSection;
