import { IStockSettingsView, IUsageForEvents } from '@flowplan/flowplan-shared/lib/stock.settings/stock.settings';
import { StockTypes } from '@flowplan/flowplan-shared/lib/stock/stock';
import { getStockMeasurementUnit } from '../../stock/stock-utils';
import { IGetInsightsUsage, IGetModifierValue, IInsightsUsageValue } from './stock-settings-interfaces';

export const getStockSettingMeasurementUnit = (stockType: StockTypes): string => {
  const measurementUnit = stockType === StockTypes.COFFEE ? 'gram' : 'milliliters';
  return measurementUnit;
};

interface IGetUsageForEventsData {
  isUpdate: boolean;
  stockType: StockTypes;
  stockSetting?: IStockSettingsView;
}

export const getUsageForEventsData = ({
  isUpdate,
  stockType,
  stockSetting,
}: IGetUsageForEventsData): IUsageForEvents => {
  if (stockType === StockTypes.COFFEE) {
    if (!isUpdate) {
      return {
        usagePerCoffee: 0,
        usagePerEspresso: 0,
      };
    }
    const usagePerCoffee = stockSetting?.usageForEvents?.usagePerCoffee ?? 0;
    const usagePerEspresso = stockSetting?.usageForEvents?.usagePerEspresso ?? 0;

    return {
      usagePerCoffee,
      usagePerEspresso,
    };
  }

  if (!isUpdate) {
    return {
      usagePerWash: 0,
    };
  }

  const usagePerWash = stockSetting?.usageForEvents?.usagePerWash ?? 0;

  return {
    usagePerWash,
  };
};

interface IValidateStockSettings {
  stockType: StockTypes;
  usageForEvents: IUsageForEvents;
}

export const validateStockSettings = ({ stockType, usageForEvents }: IValidateStockSettings): boolean => {
  if (stockType === StockTypes.COFFEE) {
    const usagePerCoffee = usageForEvents.usagePerCoffee;
    const usagePerEspresso = usageForEvents.usagePerEspresso;

    if (!usagePerCoffee || usagePerCoffee === 0 || !usagePerEspresso || usagePerEspresso === 0) {
      return false;
    }
  } else if (stockType === StockTypes.CLEANING_LIQUID) {
    const usagePerWash = usageForEvents.usagePerWash;

    if (!usagePerWash || usagePerWash === 0) {
      return false;
    }
  }

  return true;
};

interface IValidateStockSettingUpdate {
  stockType: StockTypes;
  updatedUsageForEvents: IUsageForEvents;
  existingUsageForEvents: IUsageForEvents;
}

export const validateStockSettingUpdate = ({
  existingUsageForEvents,
  stockType,
  updatedUsageForEvents,
}: IValidateStockSettingUpdate): boolean => {
  if (stockType === StockTypes.COFFEE) {
    const currentUsagePerCoffee = existingUsageForEvents.usagePerCoffee;
    const currentUsagePerEspresso = existingUsageForEvents.usagePerEspresso;
    const updatedUsagePerCoffee = updatedUsageForEvents.usagePerCoffee;
    const updatedUsagePerEspresso = updatedUsageForEvents.usagePerEspresso;

    if (
      (currentUsagePerCoffee === updatedUsagePerCoffee && currentUsagePerEspresso === updatedUsagePerEspresso) ||
      updatedUsagePerCoffee === 0 ||
      updatedUsagePerEspresso === 0
    ) {
      return false;
    }
  } else if (stockType === StockTypes.CLEANING_LIQUID) {
    const currentUsagePerWash = existingUsageForEvents.usagePerWash;
    const updatedUsagePerWash = updatedUsageForEvents.usagePerWash;

    if (currentUsagePerWash === updatedUsagePerWash || updatedUsagePerWash === 0) {
      return false;
    }
  }

  return true;
};

export const validateStockSettingDelete = ({
  existingUsageForEvents,
  stockType,
  updatedUsageForEvents,
}: IValidateStockSettingUpdate): boolean => {
  if (stockType === StockTypes.COFFEE) {
    const currentUsagePerCoffee = existingUsageForEvents.usagePerCoffee;
    const currentUsagePerEspresso = existingUsageForEvents.usagePerEspresso;
    const updatedUsagePerCoffee = updatedUsageForEvents.usagePerCoffee;
    const updatedUsagePerEspresso = updatedUsageForEvents.usagePerEspresso;

    if (
      currentUsagePerCoffee === updatedUsagePerCoffee ||
      currentUsagePerEspresso === updatedUsagePerEspresso ||
      updatedUsagePerCoffee !== 0 ||
      updatedUsagePerEspresso !== 0
    ) {
      return false;
    }
  } else if (stockType === StockTypes.CLEANING_LIQUID) {
    const currentUsagePerWash = existingUsageForEvents.usagePerWash;
    const updatedUsagePerWash = updatedUsageForEvents.usagePerWash;

    if (currentUsagePerWash === updatedUsagePerWash || updatedUsagePerWash !== 0) {
      return false;
    }
  }

  return true;
};

export const getModifierValue = ({ stockSettings, eventType, isEspresso }: IGetModifierValue): number => {
  const stockSettingToUse = stockSettings.find((stockSetting) => stockSetting.stock.stockType === eventType);

  if (!stockSettingToUse) {
    return 0;
  }

  const { usageForEvents } = stockSettingToUse;
  let modifierValue = usageForEvents.usagePerCoffee || 0;

  if (eventType === StockTypes.CLEANING_LIQUID) {
    modifierValue = usageForEvents.usagePerWash || 0;
  }

  if (isEspresso) {
    modifierValue = usageForEvents.usagePerEspresso || 0;
  }

  return modifierValue;
};

export const getInsightsUsage = ({ stockSettings, title }: IGetInsightsUsage): IInsightsUsageValue => {
  const isEspresso = title.includes('Espresso');
  const isCoffee = title.includes('Coffee');
  const eventType = isCoffee || isEspresso ? StockTypes.COFFEE : StockTypes.CLEANING_LIQUID;
  const modifierValue = getModifierValue({ eventType, isEspresso, stockSettings });

  return {
    modifier: modifierValue,
    unit: getStockMeasurementUnit(eventType),
    unitSmall: getStockSettingMeasurementUnit(eventType),
  };
};
