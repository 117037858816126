import { InstallationTag } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import LabelIcon from '@mui/icons-material/Label';
import { Icon } from 'semantic-ui-react';
import { IBaseInformation } from '../../types/details/installation-details-basic';
import DetailsItem from '../Common/DetailsItem';
import DetailsSection from '../Common/DetailsSection';
import ImageDeviceType from '../DeviceType/ImageDeviceType';
import InstallationTags from '../Tags/InstallationTags';

type Props = {
  baseInformation: IBaseInformation;
  tags: InstallationTag[];
};

const InstallationDetailsBasic = ({ baseInformation, tags }: Props): JSX.Element => {
  const {
    clientName,
    deviceType,
    deviceTypeText,
    installationDescription,
    installationId,
    installationName,
    locationAddress,
    locationPhoneNumber,
    locationTitle,
    microSiemensPerCmInfo,
    waterHardnessInfo,
  } = baseInformation;

  return (
    <DetailsSection sectionTitle="Overview">
      <DetailsItem title="Installation name" value={installationName} translateValue={false}>
        <Icon name="font" size="big" />
      </DetailsItem>
      <DetailsItem title="Description" value={installationDescription} translateValue={false}>
        <Icon name="tags" size="big" />
      </DetailsItem>
      <DetailsItem title="Machine type" value={deviceTypeText}>
        <ImageDeviceType typeId={deviceType} explanation={deviceTypeText} />
      </DetailsItem>
      <InstallationTags
        icon={<LabelIcon style={{ width: '35px', height: '35px', marginRight: '1rem' }} />}
        installationId={installationId}
        margins
        tags={tags}
      />
      <DetailsItem title="Client" value={clientName} translateValue={false}>
        <Icon name="address book" size="big" />
      </DetailsItem>
      <DetailsItem title="Location" value={locationTitle} secondValue={locationAddress} translateValue={false}>
        <Icon name="home" size="big" />
      </DetailsItem>
      <DetailsItem title="Phone number" value={locationPhoneNumber}>
        <Icon name="phone" size="big" />
      </DetailsItem>
      <DetailsItem title="Location water hardness" value={waterHardnessInfo}>
        <Icon name="tint" size="big" />
      </DetailsItem>
      {microSiemensPerCmInfo !== '' && (
        <DetailsItem title="Location conductivity" value={microSiemensPerCmInfo}>
          <Icon name="tint" size="big" />
        </DetailsItem>
      )}
    </DetailsSection>
  );
};

export default InstallationDetailsBasic;
