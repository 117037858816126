import { ISensorCategory } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { noSelectionMade } from '../../../../Models/admin/admin.model';
import { getDeviceTypeText } from '../../../../Utility/Texts';
import { DeviceCategories, DeviceTypes } from '../../../../common/constsCategoryAndTypes';
import { OptionProp } from '../../../common/components/FormikSelect/FormikSelect';

export const getCategoryFromType = (selectedType: number): DeviceCategories => {
  let categoryToReturn = DeviceCategories.Other;
  switch (selectedType) {
    case DeviceTypes.NoSelection:
    case DeviceTypes.SemiAutomatic:
    case DeviceTypes.FullyAutomatic:
    case DeviceTypes.FilterBrewer:
    case DeviceTypes.FullyAutomaticFreshMilk:
      categoryToReturn = DeviceCategories.Coffee;
      break;
    case DeviceTypes.Tabletop:
    case DeviceTypes.Column:
    case DeviceTypes.Tunnel:
      categoryToReturn = DeviceCategories.Washing;
      break;
    case DeviceTypes.Regular:
    case DeviceTypes.Crushed:
      categoryToReturn = DeviceCategories.Ice;
      break;
    case DeviceTypes.SteamOven:
      categoryToReturn = DeviceCategories.Oven;
      break;
    case DeviceTypes.WaterDispenser:
    case DeviceTypes.CarWashAutomatic:
    case DeviceTypes.CarWashManual:
    case DeviceTypes.SlushIceMachine:
    case DeviceTypes.SoftIceMachine:
      categoryToReturn = DeviceCategories.Other;
      break;
    default:
      categoryToReturn = DeviceCategories.Other;
      break;
  }

  return categoryToReturn;
};

interface NameSuggestion {
  inputLocationId: number;
  locations: OptionProp[];
  selectedType: number;
  sensorCategories: ISensorCategory[];
}

export const getNameSuggestionForMachine = ({
  inputLocationId,
  locations,
  selectedType,
  sensorCategories,
}: NameSuggestion): string => {
  let locationName = '';
  if (inputLocationId !== noSelectionMade) {
    const locationLookup = locations.find((x) => x.value === inputLocationId);
    locationName = locationLookup ? locationLookup.name : '';
  }

  const selectedCategory = getCategoryFromType(selectedType);
  let deviceCategoryName = '';
  if (selectedCategory === DeviceCategories.Other && selectedType === DeviceTypes.Other) {
    deviceCategoryName = '';
  } else {
    const deviceCategory = sensorCategories.find((x) => x.id === selectedCategory);
    deviceCategoryName = '';
    if (deviceCategory) {
      switch (selectedType) {
        case DeviceTypes.Crushed:
          deviceCategoryName = 'Crushed ' + deviceCategory.name;
          break;
        case DeviceTypes.SemiAutomatic:
        case DeviceTypes.FullyAutomatic:
        case DeviceTypes.FullyAutomaticFreshMilk:
          deviceCategoryName = ' Espresso machine';
          break;
        case DeviceTypes.FilterBrewer:
          deviceCategoryName = ' Coffee machine';
          break;
        case DeviceTypes.Regular:
        case DeviceTypes.Tabletop:
        case DeviceTypes.Column:
        case DeviceTypes.Tunnel:
          deviceCategoryName = deviceCategory.name + ' Machine';
          break;
        case DeviceTypes.SteamOven:
          deviceCategoryName = deviceCategory.name;
          break;
        case DeviceTypes.CarWashAutomatic:
        case DeviceTypes.CarWashManual:
        case DeviceTypes.SlushIceMachine:
        case DeviceTypes.SoftIceMachine:
        case DeviceTypes.WaterDispenser:
          deviceCategoryName = getDeviceTypeText(selectedType);
          break;
      }
    }
  }
  if (locationName === '' && deviceCategoryName === '') {
    return '';
  }

  return locationName + ' - ' + deviceCategoryName;
};

export const getFirstFromCategory = (selectedCategory: number): DeviceTypes => {
  let typeToReturn = DeviceTypes.NoSelection;
  switch (selectedCategory) {
    case DeviceCategories.Coffee:
      typeToReturn = DeviceTypes.SemiAutomatic;
      break;
    case DeviceCategories.Washing:
      typeToReturn = DeviceTypes.Tabletop;
      break;

    case DeviceCategories.Ice:
      typeToReturn = DeviceTypes.Regular;
      break;
    case DeviceCategories.Oven:
      typeToReturn = DeviceTypes.SteamOven;
      break;
    default:
      typeToReturn = DeviceTypes.Other;
      break;
  }

  return typeToReturn;
};

export const getCategoryColor = (selectedCategory: number): SemanticCOLORS => {
  let colorToShow: SemanticCOLORS = 'brown';
  switch (selectedCategory) {
    case DeviceCategories.Coffee:
      colorToShow = 'brown';
      break;
    case DeviceCategories.Washing:
      colorToShow = 'blue';
      break;
    case DeviceCategories.Ice:
      colorToShow = 'teal';
      break;
    case DeviceCategories.Oven:
      colorToShow = 'grey';
      break;
    case DeviceCategories.Other:
      colorToShow = 'black';
      break;
  }
  return colorToShow;
};
