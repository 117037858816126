import Chrome from '../../../../assetstore/icons/browser/Chrome.png';
import Edge from '../../../../assetstore/icons/browser/Edge.png';
import Firefox from '../../../../assetstore/icons/browser/Firefox.png';
import Opera from '../../../../assetstore/icons/browser/Opera.png';
import Safari from '../../../../assetstore/icons/browser/Safari.png';
import styles from './BrowserSupportItem.module.scss';

interface IBrowserSupport {
  browserName: string;
  extra?: string;
  version: number;
}

const BrowserSupportItem = ({ browserName, version, extra }: IBrowserSupport): JSX.Element => {
  const extraInfo = extra ? ' (' + extra + ')' : '';
  const browserVersionInfo = 'v.' + version + ' or newer';
  let url = '';
  let imageToUse;
  if (browserName === 'Chrome') {
    url = 'https://www.google.com/chrome/';
    imageToUse = Chrome;
  } else if (browserName === 'Edge') {
    url = 'https://www.microsoft.com/da-dk/edge?form=MA13FJ';
    imageToUse = Edge;
  } else if (browserName === 'Firefox') {
    url = 'https://www.mozilla.org/en-US/firefox/new/';
    imageToUse = Firefox;
  } else if (browserName === 'Opera') {
    url = 'https://www.opera.com/download';
    imageToUse = Opera;
  } else if (browserName === 'Safari') {
    url = 'https://www.apple.com/';
    imageToUse = Safari;
  }

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className={styles.supportedBrowserContainer}>
        <p className={styles.browserTitle}>{browserName}</p>
        <img className={styles.browserImage} alt={browserVersionInfo} title={browserVersionInfo} src={imageToUse} />
        <p className={styles.browserInfo}>{browserVersionInfo + extraInfo}</p>
      </div>
    </a>
  );
};

export default BrowserSupportItem;
