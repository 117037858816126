import { notAvailable } from '@flowplan/flowplan-shared';
import { FilterMachineTypes } from '@flowplan/flowplan-shared/lib/machine.types/machine.types';
import { IProcessedFilterInfo } from './filterdata.model';

const selectLocation = 'Select a location';

export const defaultFilterRelatedInformationResult: IProcessedFilterInfo = {
  capacity: 0,
  recommendedBypass: notAvailable,
  recommendedCapacity: 0,
  filterId: 0,
  hardness: notAvailable,
  disableCapacityInput: false,
  showCustomFilter: false,
  filterMachineTypeId: FilterMachineTypes.Normal,
};

export const locationNotFoundState: IProcessedFilterInfo = {
  capacity: selectLocation,
  disableCapacityInput: true,
  filterId: -1,
  hardness: '',
  recommendedBypass: selectLocation,
  recommendedCapacity: '',
  showCustomFilter: false,
  filterMachineTypeId: FilterMachineTypes.Normal,
};
