import SettingsIcon from '@mui/icons-material/Settings';
import { useStoreActions, useStoreState } from '../../../../Store';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';

interface Props {
  alwaysLeftAlign?: boolean;
}

const InstallationSettings = (props: Props): JSX.Element => {
  const { alwaysLeftAlign } = props;
  const openInstallationSettings = useStoreActions((actions) => actions.newInstallationModel.openInstallationSettings);
  const access = useStoreState((state) => state.authModel.access);

  const showSettings = () => {
    openInstallationSettings({
      shouldRetrieveDetailsData: true,
    });
  };

  return (
    <FpButtonOutlinedAligned
      alwaysLeftAlign={alwaysLeftAlign}
      disabled={!access.installations.manage}
      className={'greyButton'}
      icon={<SettingsIcon />}
      onClick={showSettings}
      label="Edit"
    />
  );
};

export default InstallationSettings;
