import { useMediaQuery, useTheme } from '@mui/material';
import { Fragment, memo } from 'react';
import { useStoreState } from '../../../../Store';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpModalConfirmSimple from '../../../common/components/FpModalConfirmSimple/FpModalConfirmSimple';
import FpText from '../../../common/components/FpText/FpText';
import { isUserGuest } from '../../../users/types/users-configs';
import { NEWS_IMAGE_BORDER } from '../../news-and-updates-consts';
import orderExample from './order-example.webp';
import orderView from './order-view.webp';
import productsOverview from './products-overview.webp';

type Props = {
  closeModal(closeNews?: boolean): void;
  showModal: boolean;
};

const ReleaseInfoOrders = memo(({ closeModal, showModal }: Props): JSX.Element => {
  const showMenu = useStoreState((state) => state.authModel.showMenu);
  const currentUser = useStoreState((state) => state.authModel.currentUser);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (!showMenu || isUserGuest(currentUser.role)) {
    return <Fragment />;
  }

  return (
    <FpModalConfirmSimple
      closeModal={closeModal}
      largerContentMargins={false}
      fullScreen={fullScreen}
      maxWidth="md"
      label="Close"
      modalTitle="2024 - 2nd November update"
      showModal={showModal}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center' }}>
        <FpText variant="body1" sx={{ textAlign: 'left' }}>
          We’re thrilled to introduce our new Flowplan Order System! As Flowplan continues to grow, we aim to provide
          you with a seamless and efficient way to browse our offerings and place orders for the items you need.
        </FpText>
        <FpDivider height="0.5rem" marginY="0.5rem" />
        <FpDivider hidden height="0.5rem" />

        <FpText variant="h5" sx={{ textAlign: 'left', mx: 2 }}>
          Easily explore Our Product Range
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          Get a quick overview of our offerings. Choose a product and add it to you order.
        </FpText>

        <FpDivider hidden height="0.5rem" marginY="0.25rem" />
        <img
          srcSet={productsOverview}
          src={productsOverview}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'The products available for you might differ from the image.'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          The products available for you might differ from the image.
        </FpText>

        <FpDivider height="0.5rem" marginY="1rem" />

        <FpText variant="h5" sx={{ textAlign: 'left', mx: 2 }}>
          Making Orders Made Simple
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          Adjust item quantities with ease and see your pricings before placing your order.
        </FpText>

        <FpDivider hidden height="0.5rem" marginY="0.25rem" />
        <img
          srcSet={orderExample}
          src={orderExample}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'An easy overview of what the order is, discounts and the total order value.'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          An easy overview of what the order is, discounts and the total order value.
        </FpText>

        <FpDivider height="0.5rem" marginY="1rem" />

        <FpText variant="h5" sx={{ textAlign: 'left', mx: 2 }}>
          View Past Orders
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          Need to revisit previous purchases? Our system provides a clear and concise overview of all your past orders.
        </FpText>

        <FpDivider hidden height="0.5rem" marginY="0.25rem" />
        <img
          srcSet={orderView}
          src={orderView}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Overview of past order.'}
          loading="lazy"
        />

        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          Overview of past order.
        </FpText>

        <FpDivider height="0.5rem" marginY="1rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We’d love to hear your thoughts on our new order system. Your feedback will help us make this platform even
          better!
        </FpText>

        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Important notes
        </FpText>

        <ul style={{ marginLeft: 2 }}>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              The order system is visible <strong>only for administrators.</strong>
            </FpText>
          </li>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              Prices are subject to errors. We reserve the right to cancel orders with incorrect pricing.
            </FpText>
          </li>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              Orders are <strong>not available for test customers</strong>. Contact us to set up an agreement
            </FpText>
          </li>
        </ul>
      </div>
    </FpModalConfirmSimple>
  );
});

export default ReleaseInfoOrders;
