import { useCallback, useEffect, useState } from 'react';
import { Divider } from 'semantic-ui-react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { SelectedOption } from '../../../../common/constsFrontend';
import LineChartWithControls from '../../../common/charts/Line/LineChartWithControls';
import styles from './DeviceConfiguration.module.scss';
import DeviceConfigurationComponent from './DeviceConfigurationComponent';

const DeviceConfiguration = (): JSX.Element => {
  const batteryData = useStoreState((state) => state.deviceConfigModel.batteryData);
  const uplinkCountData = useStoreState((state) => state.deviceConfigModel.uplinkCountData);
  const loadingBatteryData = useStoreState((state) => state.deviceConfigModel.loadingBatteryData);
  const loadingUplinkCountData = useStoreState((state) => state.deviceConfigModel.loadingUplinkCountData);

  const getBatteryData = useStoreActions((actions) => actions.deviceConfigModel.getBatteryData);
  const retrieveUplinkCountData = useStoreActions((actions) => actions.deviceConfigModel.retrieveUplinkCountData);

  const [selectedBatteryOption, setSelectedBatteryOption] = useState(SelectedOption.SevenDays);
  const [selectedUplinkCounterOption, setSelectedUplinkCounterOption] = useState(SelectedOption.SevenDays);

  const getbatteryData = useCallback(async () => {
    await getBatteryData(selectedBatteryOption);
  }, [selectedBatteryOption, getBatteryData]);

  const getUplinkCounterData = useCallback(async () => {
    await retrieveUplinkCountData(selectedUplinkCounterOption);
  }, [selectedUplinkCounterOption, retrieveUplinkCountData]);

  useEffect(() => {
    getUplinkCounterData();
  }, [getUplinkCounterData]);

  useEffect(() => {
    getbatteryData();
  }, [getbatteryData]);

  return (
    <div className={styles.configurationLayout}>
      <DeviceConfigurationComponent />
      <LineChartWithControls
        chartData={batteryData}
        dataUnit=" voltage"
        graphTitle="Battery transmission voltage history"
        interactionFunction={(optionSelected: number) => {
          setSelectedBatteryOption(optionSelected);
        }}
        isLoading={loadingBatteryData}
        placeholderText="No battery data found."
        selectedOption={selectedBatteryOption}
      />
      <Divider />
      <LineChartWithControls
        chartData={uplinkCountData}
        dataUnit=""
        graphTitle="Uplink counter history"
        interactionFunction={(optionSelected: number) => {
          setSelectedUplinkCounterOption(optionSelected);
        }}
        isLoading={loadingUplinkCountData}
        placeholderText="No uplink counter data found."
        selectedOption={selectedUplinkCounterOption}
      />
    </div>
  );
};

export default DeviceConfiguration;
