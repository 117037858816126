import { formatFlowRate, formatMilliLiters, formatSeconds, toLiters } from '@flowplan/flowplan-shared';
import { IDeviceDataStatus } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { timeFormatHMS } from '@hsjakobsen/utilities/lib/date';
import { getDateObject } from '../../../../../Utility/time';
import { IDeviceDataFormatted } from '../../../../../common/interfacesData';
import { getNotAvailableData } from '../../../../locations/locations-texts';

interface IFormatDeviceData {
  dataSet: IDeviceDataStatus;
  isExport: boolean;
  measurementUnit?: string;
}

export const formatDeviceData = ({ dataSet, isExport, measurementUnit }: IFormatDeviceData): IDeviceDataFormatted => {
  const { changeValue, date, flowTime, measurement, tickConstant, id, type, floweventtype } = dataSet;

  const timeStamp = getDateObject(date).format(timeFormatHMS);
  const milliLiters = changeValue / tickConstant;
  const flowTimeInSeconds = flowTime / 10;
  const flowRateNum = toLiters(milliLiters) / (flowTimeInSeconds / 60);

  const milliLitersReadable = isExport ? Number(milliLiters.toFixed(1)) : formatMilliLiters(milliLiters);
  let flowTimeToShow = isExport ? flowTimeInSeconds : formatSeconds(flowTimeInSeconds);
  let flowRate = isExport ? Number(flowRateNum.toFixed(1)) : formatFlowRate(flowRateNum);

  const notAvailableInfo = getNotAvailableData;

  if (flowTimeInSeconds < 1 && flowTimeInSeconds !== 0) {
    flowTimeToShow = isExport ? 0 : '<1';
    flowRate = isExport ? 0 : notAvailableInfo;
  } else if (flowTimeInSeconds === 0) {
    flowTimeToShow = isExport ? 0 : notAvailableInfo;
    flowRate = isExport ? 0 : notAvailableInfo;
  }

  const isMicroMovements: boolean = changeValue === 12;
  let externalReading = notAvailableInfo;
  if (measurement) {
    externalReading = formatExternalMeasurement(measurement);
  }

  const formattedDeviceData: IDeviceDataFormatted = {
    id,
    type,
    flowEventType: floweventtype,
    timeStamp,
    milliLiters: milliLitersReadable,
    flowTime: flowTimeToShow,
    flowRate,
    externalReading,
    isMicroMovements,
  };

  if (externalReading === notAvailableInfo) {
    delete formattedDeviceData.externalReading;
  }

  return formattedDeviceData;
};

function formatExternalMeasurement(measurement: number): string {
  if (measurement < 1) {
    return measurement.toFixed(2)
  }

  return measurement.toFixed(1)
}
