import { useMediaQuery, useTheme } from '@mui/material';
import { Fragment, memo } from 'react';
import { useStoreState } from '../../../../Store';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpModalConfirmSimple from '../../../common/components/FpModalConfirmSimple/FpModalConfirmSimple';
import FpText from '../../../common/components/FpText/FpText';
import { isUserGuest } from '../../../users/types/users-configs';
import { NEWS_IMAGE_BORDER } from '../../news-and-updates-consts';
import flowplanTapKegStatus from './flowplan-taps-keg-status.webp';
import flowplanTaps from './flowplan-taps.webp';
import navigationUpdate from './navigation-accountmenu.webp';
import notInSystemUpdate from './not-in-system-update.webp';

type Props = {
  closeModal(closeNews?: boolean): void;
  showModal: boolean;
};

const ReleaseInfoNotInSystemNavigationTaps = memo(({ closeModal, showModal }: Props): JSX.Element => {
  const showMenu = useStoreState((state) => state.authModel.showMenu);
  const currentUser = useStoreState((state) => state.authModel.currentUser);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (!showMenu || isUserGuest(currentUser.role)) {
    return <Fragment />;
  }

  return (
    <FpModalConfirmSimple
      closeModal={closeModal}
      largerContentMargins={false}
      fullScreen={fullScreen}
      maxWidth="md"
      label="Close"
      modalTitle="2025 - February update"
      showModal={showModal}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center' }}>
        <FpText variant="body1" sx={{ textAlign: 'left' }}>
          The whole team at Flowplan is very excited with our February 2025 release! We have updated our main
          navigation, updated our "Not in system" Beam location detection and we are introducing Flowplan Taps, a
          solution designed to manage beverages at bars, restaurants and hotels!
        </FpText>
        <FpDivider height="0.5rem" marginY="0.5rem" />
        <FpDivider hidden height="0.5rem" />

        <FpText variant="h5" sx={{ textAlign: 'left', mx: 2 }}>
          An updated main navigation and a new account menu
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We have refreshed the design of the main navigation, so you can collapse it to get more space for your data.
          We have also moved log out to be in the new account menu in the top right along with the Backoffice version
          number.
        </FpText>

        <FpDivider hidden height="0.5rem" marginY="0.25rem" />
        <img
          srcSet={navigationUpdate}
          src={navigationUpdate}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'We have updated our navigation with a new look and more functionality.'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          The navigation can be collapsed in the bottom left and log out is now in the top right.
        </FpText>

        <FpDivider height="0.5rem" marginY="1rem" />

        <FpText variant="h5" sx={{ textAlign: 'left', mx: 2 }}>
          Finding the Beams that are installed but not in Backoffice
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We have updated our logic for detecting Beams that are installed at the customer, but not yet created in
          Backoffice. Note that the coordinates is an estimate.
        </FpText>

        <FpDivider hidden height="0.5rem" marginY="0.25rem" />
        <img
          srcSet={notInSystemUpdate}
          src={notInSystemUpdate}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'The map is now more busy than ever.'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          The map now shows a lot more Beams with our new algorithm.
        </FpText>

        <FpDivider height="0.5rem" marginY="1rem" />

        <FpText variant="h5" sx={{ textAlign: 'left', mx: 2 }}>
          Introducing Flowplan Taps
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We have developed a complete solution for managing your beverage consumption in bars, hotels or restaurants.
        </FpText>

        <FpDivider hidden height="0.5rem" marginY="0.25rem" />
        <img
          srcSet={flowplanTaps}
          src={flowplanTaps}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'The Taps dashboard, which provides entry points to the new sections.'}
          loading="lazy"
        />

        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          The Taps dashboard, which provides entry points to the new sections.
        </FpText>

        <FpDivider hidden height="0.5rem" marginY="0.25rem" />
        <img
          srcSet={flowplanTapKegStatus}
          src={flowplanTapKegStatus}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'One of our key features is to be able to track each keg in a bar and how much is remaining-'}
          loading="lazy"
        />

        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          One of our key features is to be able to track each keg in a bar and how much is remaining
        </FpText>

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          If you have any potential customers or opportunities in relation to Taps and kegs, we look forward to talking
          with you about it.
        </FpText>
      </div>
    </FpModalConfirmSimple>
  );
});

export default ReleaseInfoNotInSystemNavigationTaps;
