import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Chip, SxProps, Theme } from '@mui/material';
import { AnchorOptions } from '../../../installations/components/Tags/InstallationTags';

type Props = {
  color: string;
  children?: React.ReactNode;
  label: string;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
  hideIcon?: boolean;
  selectionFunction?: () => void;
  optionsFunction?(value: AnchorOptions): void;
};

const FpTag = ({
  color,
  children,
  label,
  sx,
  optionsFunction,
  selectionFunction,
  size = 'small',
  hideIcon = false,
}: Props) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Chip
        label={label}
        icon={hideIcon ? undefined : <LocalOfferIcon sx={{ color: 'white !important', fontSize: '11px !important' }} />}
        size={size}
        onClick={
          selectionFunction
            ? (event) => {
                event.stopPropagation();
                selectionFunction();
              }
            : undefined
        }
        sx={{
          borderRadius: '4px',
          ...sx,
          backgroundColor: color,
          color: 'white',
        }}
        onDelete={
          optionsFunction
            ? (event) => {
                event.stopPropagation();
                optionsFunction({
                  anchorEl: event.currentTarget,
                  label,
                });
              }
            : undefined
        }
        deleteIcon={<MoreHorizIcon sx={{ color: 'white !important', fontSize: '12px !important' }} />}
      />
      {children}
    </div>
  );
};

FpTag.displayName = 'Tag';

export default FpTag;
