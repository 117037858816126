import { IClient, ILocation } from "@flowplan/flowplan-shared";
import { IStock } from "@flowplan/flowplan-shared/lib/stock/stock";
import { HardnessEnum } from "../../common/constsFrontend";
import { ExtendedLocationInfo } from "./locations-model";

export interface ILocationExtended extends ILocation {
    latitude: number;
    longitude: number;
    hardnessData: {
        hardnessTypeId: number;
        value: number;
        hardnessType: {
            name: HardnessEnum;
        };
    }[];
    sublocations: {
        createdAt: string;
        deletedAt: string;
        flowplanProductTypeId: number;
        id: number;
        locationId: number;
        name: string;
        updatedAt: string;
    }[];
    client: IClient;
    stock: IStock[];
    beamConnections: { beam: string; stock: string; sublocation: string }[];
    setupOption: string[]; // Add setupOption here
    dailyUsage: 0;
    waterFilter: 0;
    tapStockStatus: ITapStockStatus[];
}

export interface ITapStockStatus {
    locationId: number;
    tapStockId: number;
    tapStockName: string;
    kegSize: number;
    stockTypeDeletedAt: string | null;
    capacity: number;
    currentInventory: number;
    updatedAt: string;
    dailyConsumptionPerTapStock: number;
    daysLeft: number | null;
}

// Define `defaultLocationData` with all necessary properties
export const defaultLocationData: ILocationExtended = {
    id: 0,
    client: { id: 0, name: '', flowplanClientId: 0 },
    carbonateHardness: 0,
    hardnessData: [],
    name: '',
    city: '',
    clientId: 0,
    flowplanClientId: 0,
    latitude: 0,
    longitude: 0,
    stock: [],
    street: '',
    totalHardness: 0,
    microSiemensPerCm: 0,
    zipCode: '',
    phoneNumber: '',
    uuid: '',
    sublocations: [],
    beamConnections: [{ beam: '', stock: '', sublocation: '' }], // Now defaulted
    setupOption: [], // Now defaulted
    dailyUsage: 0,
    waterFilter: 0,
    tapStockStatus: [],
};

export const defaultLocationInfo: ExtendedLocationInfo = {
    address: '',
    contactDetail: '',
    installations: [],
    name: '',
    uuid: '',
    carbonateHardness: 0,
    totalHardness: 0,
    microSiemensPerCm: 0,
    id: '-1',
};


export interface IToggleModalLocationSettings {
    showModal: boolean;
    locationId: number | undefined;
}
