import FpListItem from '../../../modules/common/components/FpList/FpListItem';
import { getDateFormatted } from '../../../Utility/time';

interface ILogDetailsItem {
  data: string;
}

const LogDetailsItem = ({ data }: ILogDetailsItem): JSX.Element => {
  let logData;
  try {
    const LoRAItem = JSON.parse(data);
    let timeStamp = '';
    if (LoRAItem.metadata !== undefined) {
      timeStamp = getDateFormatted(LoRAItem.metadata.time);
      logData =
        timeStamp +
        ' - DeviceId: ' +
        LoRAItem.dev_id +
        '. Counter: ' +
        LoRAItem.counter +
        '. DR: ' +
        LoRAItem.metadata.data_rate +
        '. Payload: ' +
        JSON.stringify(LoRAItem.payload_raw);
    } else {
      if (LoRAItem.ts !== undefined) {
        timeStamp = getDateFormatted(LoRAItem.ts);
      }
      logData =
        timeStamp +
        ' - CMD: ' +
        LoRAItem.cmd +
        '. EUI: ' +
        LoRAItem.EUI +
        '. DR: ' +
        LoRAItem.dr +
        '. Data: ' +
        LoRAItem.data;
    }
  } catch (error) {
    logData = data;
  }

  return (
    <FpListItem
      primary=""
      secondary={logData}
      sx={{
        wordWrap: 'break-word',
        flex: 1,
        textAlign: 'left',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
      onClick={() => {
        'fplistitem clicked';
      }}
    />
  );
};

export default LogDetailsItem;
