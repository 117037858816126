import { Autocomplete, SxProps, TextField, Theme } from "@mui/material"
import FpContainer from "../common/components/FpContainer/FpContainer"
import FpText from "../common/components/FpText/FpText"
import { useMemo } from "react"
import { Languages } from "@flowplan/flowplan-shared/lib/users/users.database"
import { languageToWeglotValueMap } from "./languageOptions"

type LanguagePickerProps = {
  language: Languages
  handleChange: (value: Languages) => void;
  sx?: SxProps<Theme>;
}

const LanguagePicker = ({ language, handleChange, sx }: LanguagePickerProps) => {
  const languageOptions = useMemo(() => {
    const langKeyValPairs = Object.entries(Languages)
    return langKeyValPairs.map((keyValPair) => {
      return {
        id: keyValPair[0],
        label: keyValPair[1],
        value: languageToWeglotValueMap.get(keyValPair[1]) ?? 'en'
      }
    })
  }, [])

  return (
    <FpContainer basic sx={sx}>
      <FpText variant='body1' sx={{ marginRight: '1rem' }}>Language preference: </FpText>
      <Autocomplete
        disablePortal
        size='small'
        sx={{ width: '150px' }}
        options={languageOptions}
        renderOption={({ key, ...props }, option) => {
          return (
            <li key={key} {...props}>
              <FpText
                key={key}
                variant='body1'
                className='do-not-translate'
              >
                {option.label}
              </FpText>
            </li>
          )
        }}
        renderInput={(params) => <TextField {...params} label="" />}
        value={languageOptions.find((languageOption) => language === languageOption.label)}
        onChange={(_, newValue) => {
          if (newValue)
            handleChange(newValue.label)
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </FpContainer >

  )
}

export default LanguagePicker
