import { Breakpoint } from '@mui/material';
import { ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogSubtitle, DialogTitle } from '..';
import FpButton from '../FpButton/FpButton';

type ConfirmSimpleModalProps = {
  showModal: boolean;
  modalTitle: string;
  children?: ReactNode;
  label?: string;
  fullScreen: boolean | undefined;
  fullWidth?: boolean;
  largerContentMargins?: boolean;
  maxWidth: false | Breakpoint | undefined;
  closeModal: () => void;
};

const FpModalConfirmSimple = ({
  children,
  closeModal,
  fullScreen,
  fullWidth,
  label,
  largerContentMargins = true,
  maxWidth,
  modalTitle,
  showModal,
}: ConfirmSimpleModalProps): JSX.Element => {
  return (
    <Dialog open={showModal} onClose={closeModal} maxWidth={maxWidth} fullWidth={fullWidth} fullScreen={fullScreen}>
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent>{largerContentMargins ? <DialogSubtitle>{children}</DialogSubtitle> : children}</DialogContent>
      <DialogActions>
        <FpButton color="secondary" onClick={closeModal}>
          {label ? label : 'Confirm'}
        </FpButton>
      </DialogActions>
    </Dialog>
  );
};

export default FpModalConfirmSimple;
