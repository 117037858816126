import L, { MarkerCluster } from 'leaflet';

export const createClusterIconDataNotInSystem = (cluster: MarkerCluster): L.DivIcon => {
  const childCount = cluster.getChildCount();

  return new L.DivIcon({
    className: 'marker-cluster marker-cluster-general',
    html:
      `<div style="background: rgba(205, 0, 0, 0.6)"><span>` +
      childCount +
      '</span></div>',
    iconSize: new L.Point(40, 40),
  });
};
