import { Color } from '@flowplan/flowplan-shared';
import { IBarChartData } from '@flowplan/flowplan-shared/lib/interfaces/deviceDetails';
import _ from 'lodash';
import { memo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip as RechartsTooltip,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import CustomTooltip from '../Parts/CustomTooltip';
import BarChartStyles from './FlowplanBarChart.module.scss';
import watermarkStyles from './Watermark.module.scss';

type DomainType = string | number;

interface IFlowplanBarChart {
  yAxisDomain: [DomainType, DomainType];
  stockYAxisDomain: [DomainType, DomainType];
  maxBarSize: number;
  barSize: number;
  chartHeight: number;
  chartWidthPercentage: number;
  chartData: IBarChartData[];
  dataUnitTooltip: string;
  dataUnitXAxis: string;
  dataUnitYAxis: string;
  stockDataUnitYAxis: string;
  dataKey: string;
  yAxisTicks: number[];
  stockYAxisTicks: number[];
  yAxisTopPadding?: number;
  hasBackground: boolean;
  showGrid?: boolean;
}

const FlowplanBarChart = memo(
  ({
    barSize,
    yAxisDomain,
    stockYAxisDomain,
    stockYAxisTicks,
    maxBarSize,
    chartHeight,
    chartWidthPercentage,
    chartData,
    dataUnitTooltip,
    dataUnitXAxis,
    dataUnitYAxis,
    stockDataUnitYAxis,
    dataKey,
    yAxisTicks,
    yAxisTopPadding,
    hasBackground,
    showGrid,
  }: IFlowplanBarChart) => {
    const chartStyle = `${BarChartStyles.usageGraphContainer} ${watermarkStyles.fpWatermark} ${
      hasBackground ? ' ' + BarChartStyles.graphBackground : ''
    }`;
    const textColor = hasBackground ? 'rgba(255,255,255, 1)' : 'rgba(0,0,0, 0.9)';
    const transparentFill = hasBackground ? 'rgba(255,255,255, 0.4)' : 'rgba(0,0,0, 0.4)';
    const gridColor = 'rgba(56,167,223, 0.75)';
    const showGridEvaluation = showGrid === undefined || !showGrid ? false : true;
    const cartesianGrid = showGridEvaluation ? (
      <CartesianGrid strokeDasharray="3 3" stroke={gridColor} vertical={true} />
    ) : null;
    const yAxispaddingValue: number = yAxisTopPadding ? yAxisTopPadding : 0;
    const stockMaxValue = _.maxBy(chartData, 'stockValue') || { stockValue: 0 };
    const manualMaxValue = _.maxBy(chartData, 'manualValue') || { manualValue: 0 };
    const estimateMaxValue = _.maxBy(chartData, 'estimatedValue') || { estimatedValue: 0 };

    return (
      <ResponsiveContainer className={chartStyle} width={chartWidthPercentage + '%'} height={chartHeight}>
        <BarChart
          data={chartData}
          maxBarSize={maxBarSize}
          barSize={barSize}
          margin={{
            bottom: 10,
            left: 10,
            right: 10,
            top: 20,
          }}
        >
          {cartesianGrid}
          <XAxis
            dataKey="name"
            stroke={textColor}
            unit={dataUnitXAxis}
            dx={5}
            height={50}
            tickFormatter={(tick) => {
              const data = chartData.find((x) => x.name === tick);
              return data ? data.nameXAxis : tick;
            }}
          />
          <YAxis
            padding={{ top: yAxispaddingValue, bottom: 0 }}
            domain={yAxisDomain}
            unit={dataUnitYAxis}
            ticks={yAxisTicks}
            yAxisId="left"
            orientation="left"
            type="number"
            stroke={textColor}
          />
          {stockMaxValue.stockValue > 0 ? (
            <YAxis
              padding={{ top: yAxispaddingValue, bottom: 0 }}
              domain={stockYAxisDomain}
              unit={stockDataUnitYAxis}
              ticks={stockYAxisTicks}
              type="number"
              stroke={textColor}
              yAxisId="right"
              orientation="right"
            />
          ) : null}
          <RechartsTooltip
            content={
              <CustomTooltip
                dataUnit={dataUnitTooltip}
                dataUnitTwo={stockDataUnitYAxis}
                formatNumber={true}
                allowZeroValues={false}
              />
            }
            cursor={{
              fill: transparentFill,
              stroke: Color.FlowplanBlue,
              strokeWidth: 1,
            }}
          />
          <ReferenceLine yAxisId={'left'} y={0} stroke={textColor} />

          <Bar dataKey={dataKey} yAxisId="left">
            {chartData.map((entry, index) => {
              return <Cell key={entry.name} fill={Color.FlowplanBlue} />;
            })}
          </Bar>
          {estimateMaxValue.estimatedValue > 0 ? (
            <Bar dataKey={'estimatedValue'} yAxisId="left">
              {chartData.map((entry, index) => {
                return <Cell key={entry.name} fill="#afaeae" />;
              })}
            </Bar>
          ) : null}
          {manualMaxValue.manualValue > 0 ? (
            <Bar dataKey={'manualValue'} yAxisId="left">
              {chartData.map((entry, index) => {
                return <Cell key={entry.name} fill="#01b7ae" />;
              })}
            </Bar>
          ) : null}
          {stockMaxValue.stockValue > 0 ? (
            <Bar dataKey={'stockValue'} yAxisId="right">
              {chartData.map((entry, index) => {
                return <Cell key={entry.name} fill="#a5673f" />;
              })}
            </Bar>
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    );
  },
);

export default FlowplanBarChart;
