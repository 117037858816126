import { Breakpoint } from '@mui/material';
import React, { ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogSubtitle, DialogTitle } from '..';
import FpButton from '../FpButton/FpButton';

type Props = {
  open: boolean;
  title: ReactNode;
  subtitle?: string;
  maxWidth?: false | Breakpoint | undefined;
  children: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  isDisabled?: boolean;
  confirmText?: string;
  cancelText?: string;
};

const FpDialog = ({
  open,
  title,
  subtitle,
  maxWidth,
  children,
  onClose,
  onConfirm,
  isDisabled,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}: Props): JSX.Element => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth || 'sm'}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {subtitle && <DialogSubtitle>{subtitle}</DialogSubtitle>}
        {children}
      </DialogContent>

      <DialogActions>
        <FpButton color="error" onClick={onClose}>
          {cancelText}
        </FpButton>
        <FpButton disabled={isDisabled} color="secondary" onClick={onConfirm}>
          {confirmText}
        </FpButton>
      </DialogActions>
    </Dialog>
  );
};

export default FpDialog;
