import { Grid } from '@mui/material';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../Store';
import { ItemsPerPage } from '../../../common/constsFrontend';
import FpList from '../../../modules/common/components/FpList/FpList';
import FpLoader from '../../../modules/common/components/FpLoader/FpLoader';
import FpPagination from '../../../modules/common/components/FpPagination/FpPagination';
import FpTextField from '../../../modules/common/components/FpTextField/FpTextField';
import styles from '../../../modules/installations/components/Options/InstallationOptions.module.scss';
import LogDetailsItem from './LogDetailsItem';

interface ILogDetailsList {
  flowplanDeviceId: string;
}

const LogDetailsList = ({ flowplanDeviceId }: ILogDetailsList): JSX.Element => {
  const loadingLogDetails = useStoreState((state) => state.logsModel.loadingLogDetails);
  const logDetails = useStoreState((state) => state.logsModel.logDetails);
  const retrieveLogDetails = useStoreActions((actions) => actions.logsModel.retrieveLogDetails);

  useEffect(() => {
    if (!flowplanDeviceId) {
      return;
    }
    retrieveLogDetails(flowplanDeviceId);
  }, [flowplanDeviceId, retrieveLogDetails]);

  const [currentPage, setCurrentPage] = useState(1);
  const [inputValue, setInputValue] = useState(String(currentPage));

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    setInputValue(String(page));
  };

  const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    setInputValue(value);
    if (value === '') {
      return; // Do nothing if the input is empty
    }
    const valueNum = Number(value);
    if (!isNaN(valueNum) && valueNum >= 1 && valueNum <= totalpages) {
      setCurrentPage(valueNum);
    }
  };

  const totalpages = Math.ceil(logDetails.length / ItemsPerPage.Fifty);
  const indexMax = currentPage * ItemsPerPage.Fifty;
  const indexMin = indexMax - ItemsPerPage.Fifty;

  const actionsGridPropsPagination = { xs: 12, sm: 12, md: 8, lg: 8 };
  const actionsGridPropsSmall = { xs: 12, sm: 12, md: 4, lg: 4 };

  return (
    <Fragment>
      <Grid container spacing={{ sm: 1 }} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }} className={styles.muiGrid}>
        <Grid item {...actionsGridPropsPagination}>
          <FpPagination count={totalpages} page={currentPage} onChange={handlePageChange} color="primary" />
        </Grid>

        <Grid item {...actionsGridPropsSmall}>
          <FpTextField
            inputValue={inputValue}
            inputKey="currentPage"
            inputText="Set current page"
            onlyNumbers={true}
            handleInput={(ev: ChangeEvent<HTMLInputElement>) => handleInputChange(ev)}
            isRequired={true}
            variant="standard"
          />
        </Grid>
      </Grid>

      <FpList>
        {logDetails.map((logsItem, index) => {
          if (index >= indexMin && index <= indexMax) {
            return <LogDetailsItem key={index} data={logsItem} />;
          } else {
            return null;
          }
        })}
      </FpList>
      <FpLoader loading={loadingLogDetails} variant="h5" text="" />
    </Fragment>
  );
};

export default LogDetailsList;
