import { memo, useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { SelectedOption } from '../../../../common/constsFrontend';
import LineChartWithControls from '../../../common/charts/Line/LineChartWithControls';

const InternalTemperatureHistoryComponent = memo(() => {
  const internalTemperatureHistory = useStoreState((state) => state.deviceDataModel.internalTemperatureHistory);
  const internalTemperatureDomainAndTicks = useStoreState(
    (state) => state.deviceDataModel.internalTemperatureDomainAndTicks,
  );
  const loadingInternalTemperatureHistory = useStoreState(
    (state) => state.deviceDataModel.loadingInternalTemperatureHistory,
  );

  const getInternalTemperatureHistory = useStoreActions(
    (actions) => actions.deviceDataModel.getInternalTemperatureHistory,
  );

  const [selectedOption, setSelectionOption] = useState(SelectedOption.SevenDays);

  const retrieveInternalTemperatureHistoryFunction = useCallback(async () => {
    getInternalTemperatureHistory(selectedOption);
  }, [getInternalTemperatureHistory, selectedOption]);

  useEffect(() => {
    retrieveInternalTemperatureHistoryFunction();
  }, [retrieveInternalTemperatureHistoryFunction]);

  return (
    <LineChartWithControls
      graphTitle=""
      dataUnit="°C"
      domain={internalTemperatureDomainAndTicks.domain}
      yAxisTicks={internalTemperatureDomainAndTicks.yAxisTicks}
      chartData={internalTemperatureHistory}
      interactionFunction={(optionSelected: number) => {
        setSelectionOption(optionSelected);
      }}
      isLoading={loadingInternalTemperatureHistory}
      placeholderText="No internal temperature data found."
      selectedOption={selectedOption}
    />
  );
});

export default InternalTemperatureHistoryComponent;
