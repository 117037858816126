import { Fragment } from 'react';
import FlowplanDeviceCategoryAndTypeSelection from './components/DeviceCategoryAndType/DeviceCategoryAndType';
import FlowplanExternalSensorSelection from './components/ExternalSensor/ExternalSensor';
import FlowplanLocationSelection from './components/Location/Location';
import FlowplanDescriptionSelection from './Description';
import FlowplanFilterSection from './Filter';
import FlowplanFlowmeterSelection from './Flowmeter';
import FlowplanMaintenanceSection from './Maintenance';
import FlowplanNameSelection from './Name';
import FlowplanSerialSelection from './Serial';
import FlowplanStockSettingsSection, { StockSettingselectionProps } from './StockSettings';

const NewInstallationUpdate = (stockSettings: StockSettingselectionProps): JSX.Element => {
  return (
    <Fragment>
      <FlowplanDeviceCategoryAndTypeSelection />

      <FlowplanLocationSelection />

      <FlowplanNameSelection />

      <FlowplanSerialSelection />

      <FlowplanFlowmeterSelection />

      <FlowplanExternalSensorSelection />

      <FlowplanDescriptionSelection />

      <FlowplanFilterSection />

      <FlowplanMaintenanceSection />

      <FlowplanStockSettingsSection {...stockSettings} />
    </Fragment>
  );
};

export default NewInstallationUpdate;
