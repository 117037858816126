import { IClient } from '@flowplan/flowplan-shared';
import { DataSuspiciousView } from '@flowplan/flowplan-shared/lib/data.suspicious/data.suspicious';
import { IUpcomingLocations } from '@flowplan/flowplan-shared/lib/installations/dashboard';
import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { ILocationHistory } from '@flowplan/flowplan-shared/lib/location.history/location.history';
import { IUser } from '@flowplan/flowplan-shared/lib/users/users.database';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridCallbackDetails,
  GridColDef,
  GridDensity,
  GridEventListener,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridState,
} from '@mui/x-data-grid-premium';
import { ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IBlackListItemView } from '../../../../Models/blacklistData';
import { IDeviceDataFormatted } from '../../../../common/interfacesData';
import { IProductForPriorityReordering } from '../../../Products/containers/ProductsPriority/EditProductsPriority';
import { IProduct } from '../../../Products/types/products-types';
import { IFrontendBeamSerialNotInSystem } from '../../../company/services/company-model';
import { DashboardInstallationTableRow } from '../../../dashboard/dashboard-model';
import { IFaultyDeviceItem } from '../../../faulty-devices/services/faulty-devices-model';
import { IFlowplanClientGroup } from '../../../flowplan-client-groups/flowplan-client-groups-types';
import { FlowplanClient } from '../../../flowplan-clients/services/flowplan-clients-model';
import { TestInstallation } from '../../../flowplan-clients/types/flowplan-clients-types';
import { IFrontendInventory } from '../../../inventory/types/inventory-types';
import { ITapLocationHistory } from '../../../location-history/location.history.tap';
import { ILocationExtended } from '../../../locations/location.types';
import { LogsOverviewExtended } from '../../../logs/services/logs-model';
import { IProductWithPriceAndDiscount } from '../../../prodcut-discounts/types/product-discounts-types';
import { TapStockType } from '../../../taps/services/flowplanClient.stock.type.model';
import { WorkOrderItemParsed } from '../../../work-orders/types/work-order-item-types';
import { WorkOrderInstallation, WorkOrderParsed } from '../../../work-orders/types/work-order-types';
import DataGridCustomToolBar from './DataGridTableCustomToolbar';

type RowTypes =
  | WorkOrderParsed[]
  | WorkOrderItemParsed[]
  | WorkOrderInstallation[]
  | IProduct[]
  | IDeviceView[]
  | IFrontendBeamSerialNotInSystem[]
  | IFlowplanClientGroup[]
  | IFrontendInventory[]
  | IUser[]
  | DataSuspiciousView[]
  | ILocationExtended[]
  | FlowplanClient[]
  | TestInstallation[]
  | IClient[]
  | LogsOverviewExtended[]
  | IBlackListItemView[]
  | IFaultyDeviceItem[]
  | IUpcomingLocations[]
  | DashboardInstallationTableRow[]
  | IDeviceDataFormatted[]
  | IProductWithPriceAndDiscount[]
  | IProductForPriorityReordering[]
  | TapStockType[]
  | ILocationHistory[]
  | ITapLocationHistory[];

type Row = {
  id?: string | number;
  locationId?: string | number;
  infoBasic?: {
    id?: string | number;
  };
  flowplanDeviceId?: string | number;
};

interface DataGridTableProps extends DataGridPremiumProps {
  rows: RowTypes;
  columns: GridColDef[];
  showFilters?: boolean;
  fillScreenHeight?: boolean;
  viewOptions?: GridEventListener<'rowClick'>;
  showDensity?: boolean;
  showColumn?: boolean;
  showExportButton?: boolean;
  showQuickFilter?: boolean;
  loading: boolean;
  density?: GridDensity;
  addButtonFunction?: () => void;
  addButtonLabel?: string;
  addButtonLabelAlternate?: string;
  addButtonShow?: boolean;
  addButtonDisabled?: boolean;
  extraButtonFunction?: () => void;
  extraButtonLabel?: string;
  extraButtonLabelAlternate?: string;
  allowCheckboxSelection?: boolean;
  extraButtonShow?: boolean;
  extraButtonDisabled?: boolean;
  onSelectionModelChange?: (selectionModel: GridRowSelectionModel) => void;
  showRowSelectionCursor?: boolean;
  isRowSelectable?: (params: GridRowParams) => boolean;
  showToolbar?: boolean;
  showFooter?: boolean;
  showExtraContent?: boolean;
  extraContent?: ReactNode;
  saveTableStateHandler?: (stateToSave: GridState) => void;
  saveDensityChanges?: (density: GridDensity) => void;
  preloadUrlSearchParam?: boolean;
  pagination?: boolean;
}

// Enum for pagination options
enum PaginationOptions {
  Fifty = 50,
  SeventyFive = 100,
  OneHundred = 200,
}

const DataGridTable = (props: DataGridTableProps): JSX.Element => {
  const {
    columns,
    density,
    showFilters = false,
    rows,
    viewOptions,
    addButtonFunction,
    addButtonShow = true,
    addButtonLabel = '',
    addButtonDisabled = false,
    addButtonLabelAlternate = '',
    extraButtonFunction,
    extraButtonLabel = '',
    extraButtonLabelAlternate = '',
    extraButtonShow = false,
    extraButtonDisabled = false,
    fillScreenHeight = true,
    showColumn = false,
    showDensity = false,
    showQuickFilter = true,
    allowCheckboxSelection = false,
    onSelectionModelChange,
    isRowSelectable,
    loading,
    showToolbar = true,
    showRowSelectionCursor = true,
    saveTableStateHandler,
    saveDensityChanges,
    pagination = true,
    showExportButton = true,
    preloadUrlSearchParam = false,
    initialState,
    showExtraContent = false,
    extraContent = '',
    ...rest
  } = props;

  const [params] = useSearchParams();
  const filterState = params.get('search');

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const tableHeight = isMediumScreen ? window.innerHeight - 50 : fillScreenHeight ? 'calc(100vh - 85px)' : '100%';

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PaginationOptions.Fifty,
  });

  const paginationChange = (model: GridPaginationModel, details: GridCallbackDetails) => {
    setPaginationModel(model);
  };

  const getRowId = (row: Row): string | number => {
    if (row.id) return row.id;
    if (row.infoBasic && row.infoBasic.id) return row.infoBasic.id;
    if (row.flowplanDeviceId) return row.flowplanDeviceId;
    throw new Error('Row ID not found');
  };

  return (
    <Box
      sx={{
        height: tableHeight,
        width: '100%',
        backgroundColor: 'white',
        borderTopLeftRadius: theme.shape.borderRadius * 2.5,
        position: 'relative',
      }}
    >
      <DataGridPremium
        {...rest}
        onRowClick={viewOptions}
        sx={{
          '.MuiDataGrid-columnHeaderTitle': { fontWeight: 600 },
          '.MuiDataGrid-cell': { padding: 0 },
          '.not-promoted': {
            backgroundColor: 'gray',
            ':hover': {
              backgroundColor: 'gray',
            },
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            cursor: showRowSelectionCursor ? 'pointer' : 'default',
            paddingX: '0.75rem',
          },
          paddingTop: '0.5rem',
          borderTopLeftRadius: theme.shape.borderRadius * 2.5,
        }}
        rows={rows}
        columns={columns}
        checkboxSelection={allowCheckboxSelection}
        disableRowSelectionOnClick
        disableColumnReorder
        getRowId={getRowId}
        onRowSelectionModelChange={onSelectionModelChange}
        isRowSelectable={isRowSelectable}
        loading={loading}
        pagination={pagination}
        initialState={{
          filter: { filterModel: { items: [], quickFilterValues: preloadUrlSearchParam ? [filterState] : [] } },
          ...initialState,
        }}
        slots={{
          toolbar: showToolbar ? DataGridCustomToolBar : null,
        }}
        slotProps={{
          toolbar: {
            density: showDensity,
            column: showColumn,
            filters: showFilters,
            showQuickFilter,
            addButtonFunction,
            addButtonLabel,
            addButtonLabelAlternate,
            addButtonShow,
            addButtonDisabled,
            extraButtonFunction,
            extraButtonLabel,
            extraButtonLabelAlternate,
            extraButtonShow,
            extraButtonDisabled,
            syncState: saveTableStateHandler,
            storeDensitySetting: saveDensityChanges,
            showExportButton,
            showExtraContent,
            extraContent,
          },
        }}
        density={density ? density : 'standard'}
        paginationModel={paginationModel}
        onPaginationModelChange={paginationChange}
        pageSizeOptions={[PaginationOptions.Fifty, PaginationOptions.SeventyFive, PaginationOptions.OneHundred]}
        disableColumnMenu
        getRowHeight={() => 'auto'}
      />
    </Box>
  );
};

export default DataGridTable;
