import { LatLng, LatLngBounds, LatLngTuple, LeafletEvent, LeafletMouseEvent } from 'leaflet';
import { useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import { LocalStore, storeUserSetting } from '../../../../Utility/LocalStorageFunctions';
import './MapLeaflet.scss';
type IMapLeaflet = {
  areaSelection?(bounds: LatLngBounds): void;
  keyLat?: LocalStore;
  keyLng?: LocalStore;
  keyZoom?: LocalStore;
  overrideStartPosition?: LatLngTuple;
};

const MapLeafletEvents = ({ areaSelection, keyLat, keyLng, keyZoom, overrideStartPosition }: IMapLeaflet): null => {
  const [startPoint, setStartPoint] = useState<LatLng | null>(null);

  const map = useMapEvents({
    moveend(event: LeafletEvent) {
      const center = map.getCenter();
      const zoom = map.getZoom();

      if (keyZoom) {
        storeUserSetting({ identifier: keyZoom, value: zoom.toString() });
      }

      if (overrideStartPosition) {
        return;
      }

      if (keyLat) {
        storeUserSetting({ identifier: keyLat, value: center.lat.toString() });
      }

      if (keyLng) {
        storeUserSetting({ identifier: keyLng, value: center.lng.toString() });
      }
    },
    mousedown(event: LeafletMouseEvent) {
      if (event.originalEvent.shiftKey) {
        setStartPoint(event.latlng);
      }
    },
    mouseup(event: LeafletMouseEvent) {
      if (event.originalEvent.shiftKey && startPoint) {
        const bounds = new LatLngBounds(startPoint, event.latlng);
        if (areaSelection) {
          areaSelection(bounds);
        }
        setStartPoint(null);
      }
    },
  });

  return null;
};

export default MapLeafletEvents;
