import { Close as CloseIcon } from '@mui/icons-material';
import { SnackbarProvider as NotistackSnackbarProvider, SnackbarProviderProps } from 'notistack';
import { createRef } from 'react';
import FpButtonIcon from '../FpButtonIcon';

const SnackbarProvider = (props: SnackbarProviderProps): JSX.Element => {
  const { children, ...rest } = props;
  const ref = createRef<NotistackSnackbarProvider>();

  return (
    <NotistackSnackbarProvider
      action={(key) => (
        <FpButtonIcon onClick={() => ref.current?.closeSnackbar(key)}>
          <CloseIcon />
        </FpButtonIcon>
      )}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;
