import { getSerialNumberToShow } from '@flowplan/flowplan-shared';
import { ICalculatedCapacity } from '@flowplan/flowplan-shared/lib/filter/filter';
import { FilterMachineTypes } from '@flowplan/flowplan-shared/lib/machine.types/machine.types';
import { numberWithCommas } from '@hsjakobsen/utilities';
import { dateFormatDMY } from '@hsjakobsen/utilities/lib/date';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Box } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useWaterFilters from '../../../../hooks/FilterWaterFiltersByProducerId';
import { useStoreActions, useStoreState } from '../../../../Store';
import { formatDateYMD, getDateObject } from '../../../../Utility/time';
import FpAccordion from '../../../common/components/FpAccordion/FpAccordion';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import FpContainer from '../../../common/components/FpContainer/FpContainer';
import FpDatePicker from '../../../common/components/FpDatePicker';
import FpDialog from '../../../common/components/FpDialog/FpDialog';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpGrid from '../../../common/components/FpGrid/FpGrid';
import FpGridItem from '../../../common/components/FpGrid/FpGridItem';
import FpText from '../../../common/components/FpText/FpText';
import HealthWarning, { HealthWarningInfo } from '../../../inventory/components/HealthWarning/HealthWarning';
import { FilterSofteningType, getWaterHardnessInfo, getWaterHardnessType } from '../../../locations/locations-texts';
import { IResponse } from '../../../server-requests/server-requests-model';
import FlowplanCapacityAndSettingsSelection from '../NewInstallation/components/WaterFilter/FilterCapacity';
import FlowplanFilterChangeByLatestSelection from '../NewInstallation/components/WaterFilter/FilterChangeByLatest';
import FlowplanFilterSelection from '../NewInstallation/components/WaterFilter/FilterFilters';
import FlowplanMachineTypeSelection from '../NewInstallation/components/WaterFilter/FilterMachineTypes';
import FlowplanFilterProducerSelection from '../NewInstallation/components/WaterFilter/FilterProducers';
import { defaultWaterFilterSelection, SelectedWaterFilter } from '../../types/installations-action-model';

interface Props {
  featureEnabled: boolean;
  alwaysLeftAlign?: boolean;
}

const InstallationChangeWaterFilter = ({ alwaysLeftAlign, featureEnabled }: Props): JSX.Element => {
  const access = useStoreState((state) => state.authModel.access);
  const selectedInstallation = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const selectedInstallationHealthStatus = useStoreState(
    (state) => state.graphSettingsModel.selectedInstallationHealthStatus,
  );
  const companyHardnessUnit = useStoreState((state) => state.companyModel.companyHardnessUnit);
  const filterProducers = useStoreState((state) => state.staticDataModel.filterProducers);
  const filters = useStoreState((state) => state.staticDataModel.filters);

  const getMachineTypesForFilter = useStoreActions((action) => action.filterDataModel.getMachineTypesForFilter);
  const setLoadingAvailableFilterMachineType = useStoreActions((action) => action.filterDataModel.setLoadingAvailableFilterMachineType);
  const getCalculatedCapacity = useStoreActions((actions) => actions.filterDataModel.getCalculatedCapacity);
  const changeFilter = useStoreActions((actions) => actions.deviceActionsModel.changeWaterFilter);
  const changeToNewFilter = useStoreActions((actions) => actions.deviceActionsModel.changeToNewFilter);

  const [showNewFilterModal, setShowNewFilterModal] = useState(false);
  const [dateSelection, setDateSelection] = useState<Dayjs>(getDateObject(null));
  const [selectedWaterFilter, setSelectedWaterFilter] = useState<SelectedWaterFilter>(defaultWaterFilterSelection);
  const [showHealthWarning, setShowHealthWarning] = useState<HealthWarningInfo>({
    ignore: false,
    show: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setShowNewFilterModal(false);
    setSelectedWaterFilter(defaultWaterFilterSelection);
    setDateSelection(getDateObject(null));
  };

  const calculateFilterValues = async (updatedWaterFilter: SelectedWaterFilter) => {
    const { carbonateHardness, microSiemensPerCm, totalHardness } = selectedInstallation.infoLocationAndClient;
    const { filterId, filterMachineTypeId } = updatedWaterFilter;

    const calculatedCapacity: ICalculatedCapacity = await getCalculatedCapacity({
      filterId,
      filterMachineTypeId,
      carbonateHardness,
      totalHardness,
      microSiemensPerCm,
    });

    const { bypass, capacity, hardnessInfo } = calculatedCapacity;
    setSelectedWaterFilter({
      ...updatedWaterFilter,
      filterBypassInfo: bypass,
      filterCapacity: capacity,
      filterProperCapacity: capacity,
      hardness: hardnessInfo,
    });
  };

  const requestFilterChange = async () => {
    setShowNewFilterModal(true);
  };

  const handleConfirmChangeFilter = async () => {
    const changeFilterResponse = await changeFilter(dateSelection);

    if (!changeFilterResponse.success) {
      enqueueSnackbar('Water filter change error: ' + changeFilterResponse.data, { variant: 'error' });
      return;
    }

    enqueueSnackbar('Water filter changed', { variant: 'success' });

    const { filterCapacity, filterChangeOnTime, filterId, filterMachineTypeId, filterProducerId } = selectedWaterFilter;

    const { shouldBeamBeReplaced } = selectedInstallationHealthStatus;

    if (filterProducerId <= 0) {
      // No new filter type needs to be installed
      if (shouldBeamBeReplaced) {
        setShowHealthWarning({
          ignore: false,
          show: true,
        });
      }
      setShowNewFilterModal(false);
      return;
    }
    const changeNewFilterResponse: IResponse = await changeToNewFilter({
      installationId: selectedInstallation.infoBasic.id,
      waterFilterChangeDate: formatDateYMD(dateSelection),
      waterFilterProducerId: filterProducerId,
      waterFilterId: filterId,
      waterFilterChangeByLatestId: filterChangeOnTime,
      waterFilterMachineTypeId: filterMachineTypeId,
      waterFilterCapacity: +filterCapacity,
    });

    if (!changeNewFilterResponse.success) {
      enqueueSnackbar('Water filter type error: ' + changeNewFilterResponse.message, { variant: 'error' });
      return;
    }

    enqueueSnackbar(changeNewFilterResponse.message, { variant: 'success' });

    if (shouldBeamBeReplaced) {
      setShowHealthWarning({
        ignore: false,
        show: true,
      });
    }
    setShowNewFilterModal(false);
  };

  const waterFilters = useWaterFilters({
    filterProducerHasData: selectedWaterFilter.filterProducerHasData,
    filterProducerId: selectedWaterFilter.filterProducerId,
  });

  const infoGridPropsTitle = { xs: 6, sm: 4, md: 4, lg: 2 };
  const infoGridPropsValue = { xs: 6, sm: 8, md: 8, lg: 4 };

  const { name, flowplanDeviceId, initialInstallDate } = selectedInstallation.infoBasic;
  const { hasLowUsage, averageDailyConsumption } = selectedInstallation.infoUsage;
  const { capacityTotal, capacityUsed, filterName, filterProducerName, lastFilterInstallDate } =
    selectedInstallation.infoFilter;
  const { carbonateHardness, microSiemensPerCm, totalHardness } = selectedInstallation.infoLocationAndClient;

  const capacityRemaining = numberWithCommas((capacityTotal - capacityUsed).toFixed(0));

  const today = getDateObject(null);
  const minDate = getDateObject(lastFilterInstallDate ? lastFilterInstallDate : initialInstallDate);
  const minDateToUse = minDate.format(dateFormatDMY);
  const daysSinceChange = today.diff(minDate, 'days');
  return (
    <>
      <FpButtonOutlinedAligned
        alwaysLeftAlign={alwaysLeftAlign}
        disabled={!featureEnabled || !access.installations.manage}
        className="blueButton"
        icon={<SyncAltIcon />}
        onClick={() => requestFilterChange()}
        label="Change filter"
      />
      <FpDialog
        open={showNewFilterModal}
        title={
          <>
            <FpText componentType='span' variant='h6'>Change water filter: </FpText>
            <FpText componentType='span' variant='h6' className='do-not-translate'>{name}</FpText>
          </>
        }
        onClose={handleClose}
        onConfirm={handleConfirmChangeFilter}
      >
        <FpContainer backgroundColor="#f9fafb">
          <FpGrid spacing={{ sm: 1 }} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }}>
            <FpGridItem {...infoGridPropsTitle}>Serial:</FpGridItem>
            <FpGridItem {...infoGridPropsValue}>
              <strong>{getSerialNumberToShow(flowplanDeviceId)}</strong>
            </FpGridItem>
            <FpGridItem {...infoGridPropsTitle}>Daily usage:</FpGridItem>
            <FpGridItem
              {...infoGridPropsValue}
              sx={{
                color: hasLowUsage ? 'red' : undefined,
              }}
            >
              <strong>{numberWithCommas(Number(averageDailyConsumption).toFixed(0)) + ' Litres.'}</strong>
            </FpGridItem>
            <FpGridItem {...infoGridPropsTitle}>Filter brand:</FpGridItem>
            <FpGridItem {...infoGridPropsValue}>
              <strong className='do-not-translate'>{filterProducerName}</strong>
            </FpGridItem>
            <FpGridItem {...infoGridPropsTitle}>Water filter:</FpGridItem>
            <FpGridItem {...infoGridPropsValue}>
              <strong className='do-not-translate'>{filterName}</strong>
            </FpGridItem>
            <FpGridItem {...infoGridPropsTitle}>Capacity left:</FpGridItem>
            <FpGridItem {...infoGridPropsValue}>
              <strong>
                <span>{capacityRemaining}L</span>
              </strong>
            </FpGridItem>
            <FpGridItem {...infoGridPropsTitle}>Last change:</FpGridItem>
            <FpGridItem {...infoGridPropsValue}>
              <strong>
                <span>
                  {minDateToUse} ({daysSinceChange} days)
                </span>
              </strong>
            </FpGridItem>
            <FpGridItem {...infoGridPropsTitle}>Carbonate / Total</FpGridItem>
            <FpGridItem {...infoGridPropsValue}>
              <strong>
                {getWaterHardnessInfo({
                  carbonateHardness,
                  hardnessUnit: companyHardnessUnit,
                  totalHardness,
                })}
              </strong>
            </FpGridItem>
            <FpGridItem {...infoGridPropsTitle}>Conductivity:</FpGridItem>
            <FpGridItem {...infoGridPropsTitle}>
              <strong>
                {microSiemensPerCm +
                  getWaterHardnessType({
                    filterSofteningType: FilterSofteningType.MicroSiemens,
                    hardnessUnit: companyHardnessUnit,
                    includeLaymansTerm: false,
                    spaceInFront: true,
                  })}
              </strong>
            </FpGridItem>
          </FpGrid>
        </FpContainer>
        <FpDivider hidden height="0.5rem" />
        <Box>
          <FpText variant="body1">Please select a date when the water filter was changed.</FpText>
          <FpDivider hidden height="1rem" />
          <FpDatePicker
            disableFuture
            size="medium"
            fullWidth={true}
            minDate={getDateObject(minDate)}
            label="When was the filter changed?"
            onChange={(value) => {
              setDateSelection(value || getDateObject(null));
            }}
            value={dateSelection}
          />
        </Box>

        <FpDivider hidden height="1rem" />

        <div style={{ border: '1px solid #c4c4c4', borderRadius: '4px' }}>
          <FpAccordion
            title="Install a different water filter"
            elevation={0}
            onChange={(event, expanded) => {
              if (!expanded) {
                setSelectedWaterFilter(defaultWaterFilterSelection);
              }
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <FlowplanFilterChangeByLatestSelection
                onSelection={(value: number) => {
                  setSelectedWaterFilter({
                    ...selectedWaterFilter,
                    filterChangeOnTime: value,
                  });
                }}
                value={selectedWaterFilter.filterChangeOnTime}
              />

              <FlowplanFilterProducerSelection
                translateOptions={false}
                onSelection={(value: number) => {
                  const selectedValue = Number(value);
                  const filterProducer = filterProducers.find((item) => item.id === selectedValue);

                  const filterMachineTypeId = FilterMachineTypes.Normal;

                  setSelectedWaterFilter({
                    ...selectedWaterFilter,
                    filterId: -1,
                    filterProducerId: selectedValue,
                    filterProducerName: filterProducer?.name || '',
                    filterProducerHasData: filterProducer?.data === 1 || false,
                    filterMachineTypeId,
                    filterName: '',
                  });
                  setLoadingAvailableFilterMachineType()
                }}
                value={selectedWaterFilter.filterProducerId}
              />
              <FlowplanFilterSelection
                translateOptions={false}
                onSelection={async (value: number) => {
                  const selectedValue = value;

                  const filtersForProducer = filters[selectedWaterFilter.filterProducerId];
                  const waterFilter = filtersForProducer.find((item) => item.id === selectedValue);

                  const filterId = waterFilter?.id || -1;
                  const filterMachineTypeId = FilterMachineTypes.Normal;

                  const updatedWaterFilter = {
                    ...selectedWaterFilter,
                    filterId,
                    filterName: waterFilter?.name || '',
                    filterMachineTypeId,
                  };
                  setSelectedWaterFilter(updatedWaterFilter);

                  if (filterId !== -1) {
                    await getMachineTypesForFilter(filterId);

                    await calculateFilterValues(updatedWaterFilter);
                  }
                }}
                showCustomFilter={!selectedWaterFilter.filterProducerHasData}
                value={selectedWaterFilter.filterId}
                waterFilters={waterFilters}
              />
              {selectedWaterFilter.filterProducerId > 0 && (
                <FlowplanMachineTypeSelection
                  onSelection={async (value: number) => {
                    const selectedValue = value;
                    const updatedWaterFilter = {
                      ...selectedWaterFilter,
                      filterMachineTypeId: selectedValue,
                    };
                    setSelectedWaterFilter(updatedWaterFilter);

                    await calculateFilterValues(updatedWaterFilter);
                  }}
                  value={selectedWaterFilter.filterMachineTypeId}
                />
              )}
              <FlowplanCapacityAndSettingsSelection
                hardness={selectedWaterFilter.hardness}
                properCapacity={selectedWaterFilter.filterProperCapacity}
                bypassSetting={selectedWaterFilter.filterBypassInfo}
                disabled={selectedWaterFilter.filterProducerId < 0}
                onSelection={(value: number | string) => {
                  setSelectedWaterFilter({
                    ...selectedWaterFilter,
                    filterCapacity: value,
                  });
                }}
                value={selectedWaterFilter.filterCapacity}
              />
            </Box>
          </FpAccordion>
        </div>
      </FpDialog>
      <HealthWarning
        goodActionLabel="Do not change water filter"
        onlyShowOneButton={true}
        choiceSelection={(info) => {
          setShowHealthWarning(info);
        }}
        choiceSelectionInfo={showHealthWarning}
        healthStatus={selectedInstallationHealthStatus}
        healthState={selectedInstallation.infoHealth.healthState}
      />
    </>
  );
};

export default InstallationChangeWaterFilter;
