import { modalTitleAddEdit } from '@flowplan/flowplan-shared';
import AddItemModal from '../../../../Components/Modals/AddItemModal';
import { useStoreActions, useStoreState } from '../../../../Store';
import NewClientsGroupInput from '../NewClientsGroupInput/NewClientsGroupInput';

const NewClientsGroupModal = (): JSX.Element => {
  const toggleNewClientsGroupModal = useStoreActions(
    (actions) => actions.flowplanClientGroupsModel.toggleNewClientsGroupModal,
  );
  const handleClientsGroupData = useStoreActions((actions) => actions.flowplanClientGroupsModel.handleClientsGroupData);

  const newClientsGroupInteractionDisabled = useStoreState(
    (state) => state.flowplanClientGroupsModel.newClientsGroupInteractionDisabled,
  );
  const showNewClientsGroupModal = useStoreState((state) => state.flowplanClientGroupsModel.showNewClientsGroupModal);
  const isUpdateClientsGroup = useStoreState((state) => state.flowplanClientGroupsModel.isUpdateClientsGroup);

  const handleCloseModal = () => {
    toggleNewClientsGroupModal({ clientsGroupData: undefined, showModal: false });
  };

  const handleClicks = () => {
    handleClientsGroupData();
  };

  const modalTitle = modalTitleAddEdit(isUpdateClientsGroup, 'customer group');

  return (
    <AddItemModal
      isModalOpen={showNewClientsGroupModal}
      confirmInteractionDisabled={false}
      interactionDisabled={newClientsGroupInteractionDisabled}
      closeModal={handleCloseModal}
      confirmAction={handleClicks}
      modalCancelLabel="Cancel"
      modalConfirmLabel="Confirm"
      modalTitle={modalTitle}
    >
      <NewClientsGroupInput />
    </AddItemModal>
  );
};

export default NewClientsGroupModal;
