import { ChangeEvent } from 'react';
import DropdownList from '../../../../../../Components/Input/DropdownList';
import OptionFilterChangeBy from '../../../../../../Components/ListGenerators/OptionFilterChangeBy';
import { useStoreActions, useStoreState } from '../../../../../../Store';
import { InputKeys } from '../../../../../../common/constsFrontend';

const FlowplanMaintenanceChangeByLatestSelection = (): JSX.Element => {
  const inputServiceChangeByLatestId = useStoreState(
    (state) => state.newInstallationModel.inputServiceChangeByLatestId,
  );
  const setInputServiceChangeByLatestId = useStoreActions(
    (actions) => actions.newInstallationModel.setInputServiceChangeByLatestId,
  );
  const changeByOptions = useStoreState((state) => state.staticDataModel.changeByOptions);

  return (
    <DropdownList
      inputValue={inputServiceChangeByLatestId}
      inputKey={InputKeys.ServiceChangeByLatestId}
      inputText="Schedule maintenance based on time"
      handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
        const value = ev.target.value;
        setInputServiceChangeByLatestId(Number(value));
      }}
      isRequired={true}
    >
      <OptionFilterChangeBy filterChangeByOptions={changeByOptions} />
    </DropdownList>
  );
};

export default FlowplanMaintenanceChangeByLatestSelection;
