import { FirmwareRelease } from '@flowplan/flowplan-shared/lib/firmware.releases/firmware.releases';
import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { getBeamHealthStatus, HardwareGenerations, healthBeamHasIssue, healthIsOnlyBatteryChange, healthIsOnlyOtaUpdateMissing } from '../../../../hooks/BeamHealthStatus';
import { ITaskInformation } from './tasks-utility';

type TaskInformationHealth = {
  data: IDeviceView;
  firmwareReleases: FirmwareRelease[];
}

export enum BeamHealthTexts {
  IssueGenThree = 'Replace Beam with upgraded 3.0 Air or 4.0 Air to ensure optimal connectivity',
  IssueGenFour = 'Replace Beam with upgraded 4.0 Air to secure optimal connectivity',
  OptionalGenThree = '. Consider upgrading to Beam 3.0 Air or 4.0 Air',
  OptionalGenFour = '. Consider upgrading to Beam 4.0 Air',
  ChangeBattery = 'Please change battery',
  BeamHealthTitle = 'Beam configuration',
  BeamHealthOtaMissing = 'Good. Air optional',
  // BeamHealthIssueFound = 'Update required',
  BeamHealthNotSupported = 'Update recommended',
  BeamHealthGood = 'Good',
  BeamHealthGoodLong = 'Beam has optimal settings',
  BeamAir = 'Air',
}

export const taskInformationHealth = ({ data, firmwareReleases }: TaskInformationHealth): ITaskInformation => {

  const {
    apn,
    batteryTypeId,
    firmware,
    hardware,
  } = data.infoDeviceMetrics;

  const beamHealthStatus = getBeamHealthStatus({
    apn,
    batteryTypeId,
    firmware,
    firmwareReleases,
    hardware,
  });

  const {
    hardwareGeneration,
    shouldBeamBeReplaced
  } = beamHealthStatus;

  const infoHeader = BeamHealthTexts.BeamHealthTitle;

  const onlyOtaUpdates = healthIsOnlyOtaUpdateMissing(beamHealthStatus);
  if (onlyOtaUpdates) {

    let infoHighlight: string = BeamHealthTexts.BeamHealthGood;
    if (hardwareGeneration === HardwareGenerations.GenThree) {
      infoHighlight += BeamHealthTexts.OptionalGenThree;
    } else if (hardwareGeneration === HardwareGenerations.GenFour) {
      infoHighlight += BeamHealthTexts.OptionalGenFour;
    }

    return {
      infoHeader,
      infoHighlight,
      infoList: 'Beam swap',
      infoTooltip: '',
    };
  }

  if (!shouldBeamBeReplaced) {
    return {
      infoHeader,
      infoHighlight: '',
      infoList: '',
      infoTooltip: '',
    };
  }

  let issues = '';
  if (healthBeamHasIssue(beamHealthStatus)) {
    if (hardwareGeneration === HardwareGenerations.GenThree) {
      issues = BeamHealthTexts.IssueGenThree;
    } else if (hardwareGeneration === HardwareGenerations.GenFour) {
      issues = BeamHealthTexts.IssueGenFour;
    } else {
      issues = BeamHealthTexts.IssueGenThree;
    }
  }

  const onlyBatteryChange = healthIsOnlyBatteryChange(beamHealthStatus);

  const infoHighlight = (onlyBatteryChange ? BeamHealthTexts.ChangeBattery : issues);
  const infoList = 'Beam swap';

  return {
    infoHeader,
    infoHighlight,
    infoList,
    infoTooltip: '',
  };
};
