import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1800,
    },
  },
  typography: {
    h6: {
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
  },
  palette: {
    primary: {
      //same color for $color-flowplanblue: #38a7df
      main: '#38a7df',
      light: '#D5E7F7',

      //same color for $color-dark-blue: #1678c2
      dark: '#1678c2',
      contrastText: '#fff',
    },
    secondary: {
      //same color for $color-button-light-green: #2ecc71
      main: '#2ecc71',

      //same color for $color-button-light-green-hover: #27ae60
      dark: '#27ae60',

      // same color for $color-success: #5cb85c
      light: '#5cb85c',
      contrastText: '#fff',
    },
    error: {
      // same color for $color-flowplanred: #db2828
      main: '#db2828',

      // same color for $color-status-overdue: #cd0000
      dark: '#cd0000',
      contrastText: '#fff',
    },
    info: {
      main: '#dbae0e',
      contrastText: '#fff',
    },
    text: {
      primary: '#4b4b4b',
      secondary: '#4b4b4b',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#ecf0f5',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '1rem',
          marginBottom: '1rem',
          marginRight: '0.5rem',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          ':last-child': { paddingTop: '0.8rem' },
        },
        toolbar: {
          alignItems: 'baseline',
        },
        selectLabel: {
          fontSize: '1.1rem',
        },
        selectRoot: {
          fontSize: '1.1rem',
        },
        displayedRows: {
          fontSize: '1.1rem',
          fontWeight: 600,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: 'black',
          backgroundColor: '#fff',
        },
        arrow: {
          color: '#fff',
        },
      },
    },
  },
});

export default theme;
