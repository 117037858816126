import { Button, ButtonProps } from '@mui/material';
import { forwardRef } from 'react';

const FpButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <Button variant="contained" disableElevation {...rest} ref={ref}>
      {children}
    </Button>
  );
});

FpButton.displayName = 'Button';

export default FpButton;
