import { Action, action, Thunk, thunk } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { getQuarterPeriod } from './reports-utility';
import { ReportDataItem } from './reports.types';

const defaultReportDetails: ReportDetails = {
    name: '',
    reportsGraphData: [],
    reportsKpiData: [],
    reportType: {
        name: ''
    }
}

export enum ReportQuarters {
    Q1 = 'January-March',
    Q2 = 'April-June',
    Q3 = 'July-September',
    Q4 = 'October-December'
}

export type YearsAndQuartersItem = {
    reportYear: number;
    quarters: ReportQuarterInfo[];
};

type YearsAndQuarters = {
    [year: number]: YearsAndQuartersItem;
}

export type ReportQuarterInfo = {
    reportId: number;
    quarterNumber: number;
    quarterTitle: string;
    quarterPeriod: ReportQuarters;
};

type Report = {
    id: number;
    name: string;
    reportTypeId: number;
    reportType: {
        name: string;
    }
}

type ReportDetails = {
    name: string;
    reportType: {
        name: string;
    }
    reportsKpiData: {
        kpiId: number;
        value: string;
        reportKpiComparison?: {
            percentageChange: number;
        }
    }[];
    reportsGraphData: {
        graphId: number;
        data: ReportDataItem[];
    }[];
}

export interface ReportsModel {
    // Reports
    reports: Report[];
    reportYears: YearsAndQuartersItem[];
    loadingReports: boolean;
    setReports: Action<ReportsModel, Report[]>;
    setLoadingReports: Action<ReportsModel, boolean>;
    getReports: Thunk<ReportsModel, void, void, IStoreModel>;

    // Report details
    reportDetails: ReportDetails;
    loadingReportDetails: boolean;
    setReportDetails: Action<ReportsModel, ReportDetails>;
    setLoadingReportDetails: Action<ReportsModel, boolean>;
    getReportDetails: Thunk<ReportsModel, number, void, IStoreModel>; // ReportId
}

const reportsModel: ReportsModel = {
    reports: [],
    reportYears: [],
    loadingReports: false,
    setReports: action((state, payload) => {
        state.reports = payload;

        const reportYears: YearsAndQuarters = {};
        for (const report of payload) {
            const reportNameInfo = report.name.replace(/ /g, '').split('-');
            const reportYear = Number(reportNameInfo[0]);
            const quarterTitle = reportNameInfo[1];
            const quarterDetails = getQuarterPeriod(quarterTitle)
            const quarterInfo: ReportQuarterInfo = {
                reportId: report.id,
                quarterNumber: quarterDetails.quarterNumber,
                quarterPeriod: quarterDetails.quarterPeriod,
                quarterTitle,
            }

            let yearInfo = reportYears[reportYear];
            if (!yearInfo) {
                yearInfo = { quarters: [quarterInfo], reportYear };
                reportYears[reportYear] = yearInfo;
            } else {
                yearInfo.quarters.push(quarterInfo);
            }
        }

        const reportYearsAvailable = Object.values(reportYears);

        state.reportYears = reportYearsAvailable.reverse();
    }),
    setLoadingReports: action((state, payload) => {
        state.loadingReports = payload;
    }),
    getReports: thunk(async (actions, payload, { getStoreActions }) => {
        actions.setLoadingReports(true);
        const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/reports/' });
        actions.setLoadingReports(false);
        if (requestResponse.success) {
            actions.setReports(requestResponse.data);
        }
    }),

    reportDetails: defaultReportDetails,
    loadingReportDetails: false,
    setReportDetails: action((state, payload) => {
        state.reportDetails = payload;
    }),
    setLoadingReportDetails: action((state, payload) => {
        state.loadingReportDetails = payload;
    }),
    getReportDetails: thunk(async (actions, payload, { getStoreActions }) => {
        actions.setLoadingReportDetails(true);
        const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/reports/data/' + payload });
        actions.setLoadingReportDetails(false);
        if (requestResponse.success) {
            actions.setReportDetails(requestResponse.data as ReportDetails);
        }
    }),

};

export default reportsModel;
