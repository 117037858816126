import { CompanyTag } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';
import { memo } from 'react';
import { useAvailableInstallationTags } from '../../../../hooks/AvailableInstallationTags';
import { useStoreActions, useStoreState } from '../../../../Store';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import { isUserGuest } from '../../../users/types/users-configs';
import CreateCompanyTag, { CompanyTagCreatedProps } from './CreateCompanyTag';
import InstallationTags from './InstallationTags';

type Props = {
  installationId: number;
  userRole: RoleType;
  showTagModal: boolean;
  closeTagModal(): void;
};

const InstallationTagsOverview = memo(({ closeTagModal, installationId, showTagModal, userRole }: Props) => {
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);

  const createInstallationTag = useStoreActions((actions) => actions.installationsModel.createInstallationTag);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const tagStatus = useAvailableInstallationTags();

  const { enqueueSnackbar } = useSnackbar();

  const closeModal = () => {
    closeTagModal();
  };

  const addInstallationTag = async ({ tagId, tagName }: CompanyTagCreatedProps) => {
    const createInstallationTagResponse = await createInstallationTag({
      locked: userRole === RoleType.SUPER_ADMIN ? 1 : 0,
      sensorId: installationId,
      tagId,
    });

    const feedbackMessage = createInstallationTagResponse.success
      ? 'Tag added to installation successfully.'
      : 'Tag : ' + tagName + ' could not be added to installation';
    const variant: VariantType = createInstallationTagResponse.success ? 'success' : 'error';
    enqueueSnackbar(feedbackMessage, { variant });
  };

  if (isUserGuest(userRole)) return null;

  return (
    <Dialog open={showTagModal} onClose={closeModal} maxWidth="sm" fullScreen={fullScreen}>
      <DialogTitle>Tags for: {baseFilter.infoBasic.name}</DialogTitle>

      <DialogContent>
        <InstallationTags
          installationId={installationId}
          margins={false}
          showAddOption={false}
          tags={tagStatus.installationTags}
        />
        <FpDivider />
        <CreateCompanyTag
          companyTagCreated={({ tagId, tagName }: CompanyTagCreatedProps) => {
            addInstallationTag({ tagId, tagName });
          }}
          companyTagSelection={(tag: CompanyTag) => {
            addInstallationTag({ tagId: tag.id, tagName: tag.name });
          }}
          tagStatus={tagStatus}
          userRole={userRole}
        />
      </DialogContent>
      <DialogActions>
        <FpButton color="secondary" onClick={closeModal}>
          Close
        </FpButton>
      </DialogActions>
    </Dialog>
  );
});

export default InstallationTagsOverview;
