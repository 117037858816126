import Other from '../../../../assetstore/icons/appliances/01.png';
import CoffeeSemiAutomatic from '../../../../assetstore/icons/appliances/02.png';
import CoffeeFullyAutomatic from '../../../../assetstore/icons/appliances/03.png';
import CoffeeFilterBrew from '../../../../assetstore/icons/appliances/04.png';
import WashingTableTop from '../../../../assetstore/icons/appliances/05.png';
import WashingColumn from '../../../../assetstore/icons/appliances/06.png';
import WashingTunnel from '../../../../assetstore/icons/appliances/07.png';
import IceRegular from '../../../../assetstore/icons/appliances/08.png';
import IceCrushed from '../../../../assetstore/icons/appliances/09.png';
import OvenSteam from '../../../../assetstore/icons/appliances/10.png';
import WaterDispenser from '../../../../assetstore/icons/appliances/11.png';
import CarWashManual from '../../../../assetstore/icons/appliances/13.png';
import CarWashAutomatic from '../../../../assetstore/icons/appliances/14.png';
import SoftIceMachine from '../../../../assetstore/icons/appliances/15.png';
import SlushIceMachine from '../../../../assetstore/icons/appliances/16.png';
import { DeviceTypes } from '../../../../common/constsCategoryAndTypes';

export const getDeviceTypeImage = (typeId: number): string | undefined => {
  let typeImageToShow;
  switch (typeId) {
    case DeviceTypes.Other:
      typeImageToShow = Other;
      break;
    case DeviceTypes.SemiAutomatic:
      typeImageToShow = CoffeeSemiAutomatic;
      break;
    case DeviceTypes.FullyAutomatic:
      typeImageToShow = CoffeeFullyAutomatic;
      break;
    case DeviceTypes.FilterBrewer:
      typeImageToShow = CoffeeFilterBrew;
      break;
    case DeviceTypes.Tabletop:
      typeImageToShow = WashingTableTop;
      break;
    case DeviceTypes.Column:
      typeImageToShow = WashingColumn;
      break;
    case DeviceTypes.Tunnel:
      typeImageToShow = WashingTunnel;
      break;
    case DeviceTypes.Regular:
      typeImageToShow = IceRegular;
      break;
    case DeviceTypes.Crushed:
      typeImageToShow = IceCrushed;
      break;
    case DeviceTypes.SteamOven:
      typeImageToShow = OvenSteam;
      break;
    case DeviceTypes.WaterDispenser:
      typeImageToShow = WaterDispenser;
      break;
    case DeviceTypes.FullyAutomaticFreshMilk:
      typeImageToShow = CoffeeFullyAutomatic;
      break;
    case DeviceTypes.CarWashManual:
      typeImageToShow = CarWashManual;
      break;
    case DeviceTypes.CarWashAutomatic:
      typeImageToShow = CarWashAutomatic;
      break;
    case DeviceTypes.SoftIceMachine:
      typeImageToShow = SoftIceMachine;
      break;
    case DeviceTypes.SlushIceMachine:
      typeImageToShow = SlushIceMachine;
      break;
  }

  return typeImageToShow;
};
