import { DataParsingStatus } from '@flowplan/flowplan-shared/lib/interfaces/networkEUIToFlowplanSerialDb';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SyncIcon from '@mui/icons-material/Sync';
import { Divider } from '@mui/material';
import FpText from '../../../common/components/FpText/FpText';
import styles from './DataParsingStatus.module.scss';

type Props = {
  dataParsingStatus: DataParsingStatus;
  bottomMargin?: boolean;
};

const DataParsingStatusComponent = ({ bottomMargin, dataParsingStatus }: Props): JSX.Element => {
  return (
    <>
      {dataParsingStatus !== DataParsingStatus.DEFAULT ? (
        <div className={bottomMargin ? styles.dataParsingStatusBottomPadding : styles.dataParsingStatus}>
          <FpText variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center', my: 1 }}>
            Data processing status
          </FpText>
          <FpText variant="body1" sx={{ textAlign: 'center', display: 'flex' }}>
            {dataParsingStatus === DataParsingStatus.SCHEDULED && <AccessTimeIcon />}
            {dataParsingStatus === DataParsingStatus.PROCESSING && <SyncIcon />}

            {dataParsingStatus === DataParsingStatus.PROCESSING
              ? 'Historical data currently loading'
              : 'Historical data loading scheduled'}
          </FpText>
          <Divider />
        </div>
      ) : null}
    </>
  );
};

export default DataParsingStatusComponent;
