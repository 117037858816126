import { Action, action, Thunk, thunk, ThunkOn, thunkOn } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { handlePostRequest } from '../../server-requests/server-requests';

export enum NewsTypes {
    RELEASE = 'RELEASE',
    INCIDENT = 'INCIDENT',
    INFO = 'INFO'
}

export type NewsAndUpdates = {
    id: number;
    name: string;
    description: string;
    newsType: NewsTypes;
}

type NewsAndUpdateStatus = {
    newsId: number;
}

export interface NewsAndUpdatesModel {
    newsAndUpdates: NewsAndUpdates[];
    setNewsAndUpdates: Action<NewsAndUpdatesModel, NewsAndUpdates[]>;
    getNewsAndUpdates: Thunk<NewsAndUpdatesModel, void, void, IStoreModel>;

    newsAndUpdateStatus: NewsAndUpdateStatus[];
    setNewsAndUpdateStatus: Action<NewsAndUpdatesModel, NewsAndUpdateStatus[]>;
    getNewsAndUpdateStatus: Thunk<NewsAndUpdatesModel, void, void, IStoreModel>;
    markNewsAndUpdateAsRead: Thunk<NewsAndUpdatesModel, number, void, IStoreModel>;

    setShowNews: Action<NewsAndUpdatesModel, boolean>;
    showNews: boolean;

    onActionsThatUpdateNewAndUpdateStatus: ThunkOn<NewsAndUpdatesModel, void, IStoreModel>;
}

const newsAndUpdatesModel: NewsAndUpdatesModel = {
    newsAndUpdates: [],
    setNewsAndUpdates: action((state, payload) => {
        state.newsAndUpdates = payload;
    }),

    getNewsAndUpdates: thunk(async (actions, payload, { getStoreActions }) => {
        const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/news-and-updates/' });
        if (requestResponse.success) {
            actions.setNewsAndUpdates(requestResponse.data);
        }
    }),

    newsAndUpdateStatus: [],
    setNewsAndUpdateStatus: action((state, payload) => {
        state.newsAndUpdateStatus = payload;
    }),


    getNewsAndUpdateStatus: thunk(async (actions, payload, { getStoreActions }) => {
        const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/user-news-status/' });
        if (requestResponse.success) {
            actions.setNewsAndUpdateStatus(requestResponse.data);
        }
    }),
    markNewsAndUpdateAsRead: thunk(async (actions, payload, { getStoreActions }) => {
        const requestResponse = await handlePostRequest({ newsId: payload }, '/user-news-status/', true);
        return requestResponse;
    }),

    setShowNews: action((state, payload) => {
        state.showNews = payload;
    }),
    showNews: false,

    onActionsThatUpdateNewAndUpdateStatus: thunkOn(
        (actions, storeActions) => [
            actions.markNewsAndUpdateAsRead
        ],
        async (actions) => {
            actions.getNewsAndUpdateStatus();
        },
    ),

};

export default newsAndUpdatesModel;
