import FpDivider from '../../../../common/components/FpDivider/FpDivider';
import FpText from '../../../../common/components/FpText/FpText';

type Props = {
  numInstallations: number;
};

const LowUsageIntro = ({ numInstallations }: Props): JSX.Element => {
  return (
    <>
      <FpText variant="h5">We have detected {numInstallations} installations without any consumption.</FpText>
      <FpDivider marginY="1rem" height="0rem" />
      <FpText variant="body1" sx={{ marginBottom: 2 }}>
        This can be intended, e.g. because of temporary shutdown, holidays or similar.
      </FpText>

      <FpText variant="body1" sx={{ marginBottom: 4 }}>
        In the case where it’s not intended, you can activate estimated usage, and we will predict the consumption,
        based on the previous consumption levels.
      </FpText>
      <FpText variant="body1">
        Please spend a few minutes going through the installations and let us know if Backoffice should activate
        estimate usage for the following installations.
      </FpText>
    </>
  );
};

export default LowUsageIntro;
