import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

export function useScreenSize(breakPoint: Breakpoint) {
    const theme = useTheme();
    const isScreenSmallerThanSetting = useMediaQuery(theme.breakpoints.down(breakPoint));

    const [screenSizeState, setScreenSizeState] = useState(false);

    useEffect(() => {
        setScreenSizeState(isScreenSmallerThanSetting);
    }, [isScreenSmallerThanSetting]);
    return screenSizeState;
}