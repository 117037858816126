import { StockTypes } from '@flowplan/flowplan-shared/lib/stock/stock';
import { Fragment } from 'react';
import { Divider, Header, Segment } from 'semantic-ui-react';
import { useStoreState } from '../../../../Store';
import StockFeatureSelectionOption from './StockFeatureSelectionOption';

export interface IStockTypeSelection {
  id?: number;
  stockType: StockTypes;
  totalAmount: number;
}

interface StockFeatureSelectionProps {
  selectedStockTypes: IStockTypeSelection[];
  handleStockSelections: (stockItem: IStockTypeSelection, selected: boolean) => void;
}

const StockFeatureSelection = ({
  handleStockSelections,
  selectedStockTypes,
}: StockFeatureSelectionProps): JSX.Element | null => {
  const isPremium = useStoreState((state) => state.companyModel.isPremium);

  if (!isPremium) {
    return null;
  }

  return (
    <Fragment>
      <Divider />
      <Segment color="orange">
        <Header as="h3">Stock management</Header>
        <StockFeatureSelectionOption
          stockItem={selectedStockTypes.find((item) => item.stockType === StockTypes.COFFEE)}
          stockType={StockTypes.COFFEE}
          stockSelectionHandler={handleStockSelections}
        />
        {/* <StockFeatureSelectionOption
          stockItem={selectedStockTypes.find((item) => item.stockType === StockTypes.CLEANING_LIQUID)}
          stockType={StockTypes.CLEANING_LIQUID}
          stockSelectionHandler={handleStockSelections}
        /> */}
      </Segment>
    </Fragment>
  );
};

export default StockFeatureSelection;
