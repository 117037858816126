import { forwardRef } from 'react';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';

const Typography = forwardRef<HTMLDivElement, MuiTypographyProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <MuiTypography
      variant="subtitle2"
      color="textSecondary"
      sx={{
        fontSize: 13,
        p: 2,
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </MuiTypography>
  );
});

Typography.displayName = 'Typography';

export default Typography;
