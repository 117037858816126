import {
  formatLiters,
  formatNumberNoDecimals,
  IBarChartData,
  IGraphDataDetails,
  notAvailable,
  PeriodSetting,
} from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IInsightEventStat, IInsightsCycles, InsightsData } from '@flowplan/flowplan-shared/lib/insights/insights';
import { ISimpleLineData } from '@flowplan/flowplan-shared/lib/interfaces/installationView';
import { IResponse } from '@hsjakobsen/utilities';
import { dateFormatDMY, dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { Dayjs } from 'dayjs';
import { Action, action, TargetPayload, Thunk, thunk, thunkOn, ThunkOn } from 'easy-peasy';
import _ from 'lodash';
import { IStoreModel } from '.';
import { SelectedOption } from '../common/constsFrontend';
import { IDeviceDataFormatted } from '../common/interfacesData';
import { formatDeviceData } from '../modules/installations/components/Details/Graphs/datadetails-format';
import { getDateObject } from '../Utility/time';
import {
  getDataForDate,
  getDomainAndTick,
  getRouteForGraphData,
  parseDataForGraph,
  parseTypeToReadable,
} from './deviceData.utility';

const defaultDomainAndTicks: GraphDomainAndTick = {
  domain: [0, 1],
  yAxisTicks: [0, 1],
};

const defaultGraphDataDetails: IGraphDataDetails = {
  averageDaily: { events: 0, usage: 0 },
  timePeriodUsageAndEvents: { events: 0, usage: 0 },
  totalCountFilterChange: 0,
  totalCountMaintenance: 0,
  totalLifeTime: { events: 0, usage: 0 },
  totalEventsCoffee: 0,
  totalEventsEspresso: 0,
  totalEventsWater: 0,
};

const defaultGraphData: IBarChartData[] = [
  { name: '', nameTooltip: '', value: 0, estimatedValue: 0, manualValue: 0, stockValue: -1, nameXAxis: '' },
  { name: '', nameTooltip: '', value: 0, estimatedValue: 0, manualValue: 0, stockValue: -1, nameXAxis: '' },
  { name: '', nameTooltip: '', value: 0, estimatedValue: 0, manualValue: 0, stockValue: -1, nameXAxis: '' },
  { name: '', nameTooltip: '', value: 0, estimatedValue: 0, manualValue: 0, stockValue: -1, nameXAxis: '' },
  { name: '', nameTooltip: '', value: 0, estimatedValue: 0, manualValue: 0, stockValue: -1, nameXAxis: '' },
  { name: '', nameTooltip: '', value: 0, estimatedValue: 0, manualValue: 0, stockValue: -1, nameXAxis: '' },
  { name: '', nameTooltip: '', value: 0, estimatedValue: 0, manualValue: 0, stockValue: -1, nameXAxis: '' },
];

interface IEventStatsOverview {
  title: string;
  amount: number;
}

interface IDetailedDataListRequest {
  selectionDate: Dayjs;
  sensorId: number;
  intervalUpdateCount: number;
}

export interface IDataForPeriodParams {
  sensorId: number;
  startDate: string;
  period: PeriodSetting;
  utcOffsetMinutes: number;
}

export type GraphDomainAndTick = {
  domain: [number, number];
  yAxisTicks: number[];
};

export interface IDeviceDataModel {
  loadingData: boolean;
  setLoadingData: Action<IDeviceDataModel, boolean>;

  errorMessage: string;
  setErrorMessage: Action<IDeviceDataModel, string>;

  loadingDataList: boolean;
  setLoadingDataList: Action<IDeviceDataModel, boolean>;

  dataList: IDeviceDataFormatted[];
  setDataList: Action<IDeviceDataModel, IDeviceDataFormatted[]>;
  getDataList: Thunk<IDeviceDataModel, IDetailedDataListRequest, void, IStoreModel>;

  showDataList: boolean;
  setShowDataList: Action<IDeviceDataModel>;

  graphDomainAndTicks: GraphDomainAndTick;
  setGraphDomainAndTicks: Action<IDeviceDataModel, GraphDomainAndTick>;

  stockGraphDomainAndTicks: GraphDomainAndTick;
  setStockGraphDomainAndTicks: Action<IDeviceDataModel, GraphDomainAndTick>;

  retrievePeriodData: Thunk<IDeviceDataModel, void, void, IStoreModel>;

  graphDataDetails: IGraphDataDetails;
  setGraphDataDetails: Action<IDeviceDataModel, IGraphDataDetails>;
  graphData: IBarChartData[];
  setGraphData: Action<IDeviceDataModel, IBarChartData[]>;

  eventDataDetails: IInsightEventStat[];
  setEventDataDetails: Action<IDeviceDataModel, IInsightEventStat[]>;

  maintenanceCyclesDetails: IInsightsCycles;
  setMaintenanceCyclesDetails: Action<IDeviceDataModel, IInsightsCycles>;

  eventDataFromPeriod: IEventStatsOverview[];
  setEventDataFromPeriod: Action<IDeviceDataModel, IEventStatsOverview[]>;

  loadingSignalStrengthHistory: boolean;
  setLoadingSignalStrengthHistory: Action<IDeviceDataModel, boolean>;
  signalStrengthHistory: ISimpleLineData[];
  getSignalStrengthHistory: Thunk<IDeviceDataModel, SelectedOption, void, IStoreModel>;
  setSignalStrengthHistory: Action<IDeviceDataModel, ISimpleLineData[]>;

  loadingInternalTemperatureHistory: boolean;
  setLoadingInternalTemperatureHistory: Action<IDeviceDataModel, boolean>;
  internalTemperatureHistory: ISimpleLineData[];
  getInternalTemperatureHistory: Thunk<IDeviceDataModel, SelectedOption, void, IStoreModel>;
  setInternalTemperatureHistory: Action<IDeviceDataModel, ISimpleLineData[]>;
  internalTemperatureDomainAndTicks: GraphDomainAndTick;
  setInternalTemperatureDomainAndTicks: Action<IDeviceDataModel, GraphDomainAndTick>;

  loadingExternalSensorHistory: boolean;
  setLoadingExternalSensorHistory: Action<IDeviceDataModel, boolean>;
  externalSensorHistory: ISimpleLineData[];
  retrieveExternalSensorHistory: Thunk<IDeviceDataModel, SelectedOption, void, IStoreModel>;
  setExternalSensorHistory: Action<IDeviceDataModel, ISimpleLineData[]>;
  externalSensorDomainAndTicks: GraphDomainAndTick;
  setExternalSensorDomainAndTicks: Action<IDeviceDataModel, GraphDomainAndTick>;

  loadingPowerConsumptionHistory: boolean;
  setLoadingPowerConsumptionHistory: Action<IDeviceDataModel, boolean>;
  powerConsumptionHistory: ISimpleLineData[];
  retrievePowerConsumptionHistory: Thunk<IDeviceDataModel, SelectedOption, void, IStoreModel>;
  setPowerConsumptionHistory: Action<IDeviceDataModel, ISimpleLineData[]>;

  averageLifeTimeFilter: string;
  setAverageLifeTimeFilter: Action<IDeviceDataModel, string>;
  averageLifeTimeMaintenance: string;
  setAverageLifeTimeMaintenance: Action<IDeviceDataModel, string>;

  totalCountFilterChanges: number;
  totalCountMaintenance: number;
  periodUsage: string;
  periodEvents: string;
  lifetimeUsage: string;
  lifetimeEvents: string;

  onSetShowDataList: ThunkOn<IDeviceDataModel, void, IStoreModel>;
  onSetGraphSettingsTime: ThunkOn<IDeviceDataModel, void, IStoreModel>;

  onGraphDataDetailsChange: ThunkOn<IDeviceDataModel, void, IStoreModel>;
  onGraphDataChange: ThunkOn<IDeviceDataModel, void, IStoreModel>;
  onRetrievePeriodData: ThunkOn<IDeviceDataModel, void, IStoreModel>;
}

const deviceDataModel: IDeviceDataModel = {
  dataList: [],
  errorMessage: '',
  loadingData: false,
  loadingDataList: false,

  totalCountFilterChanges: 0,
  totalCountMaintenance: 0,
  lifetimeEvents: notAvailable,
  lifetimeUsage: notAvailable,
  periodEvents: notAvailable,
  periodUsage: notAvailable,

  graphData: defaultGraphData,
  setGraphData: action((state, payload) => {
    state.graphData = payload;
  }),

  graphDomainAndTicks: defaultDomainAndTicks,
  setGraphDomainAndTicks: action((state, payload) => {
    state.graphDomainAndTicks = payload;
  }),

  stockGraphDomainAndTicks: defaultDomainAndTicks,
  setStockGraphDomainAndTicks: action((state, payload) => {
    state.stockGraphDomainAndTicks = payload;
  }),

  graphDataDetails: defaultGraphDataDetails,
  setGraphDataDetails: action((state, payload) => {
    state.graphDataDetails = payload;
    const { totalCountFilterChange, totalCountMaintenance, timePeriodUsageAndEvents, totalLifeTime } = payload;

    state.totalCountFilterChanges = totalCountFilterChange;
    state.totalCountMaintenance = totalCountMaintenance;

    state.periodUsage = formatLiters(Number(timePeriodUsageAndEvents.usage));
    state.periodEvents = formatNumberNoDecimals(Number(timePeriodUsageAndEvents.events));

    state.lifetimeUsage = formatLiters(Number(totalLifeTime.usage));
    state.lifetimeEvents = formatNumberNoDecimals(Number(totalLifeTime.events));
  }),

  averageLifeTimeFilter: notAvailable,
  averageLifeTimeMaintenance: notAvailable,
  setAverageLifeTimeFilter: action((state, payload) => {
    state.averageLifeTimeFilter = payload;
  }),
  setAverageLifeTimeMaintenance: action((state, payload) => {
    state.averageLifeTimeMaintenance = payload;
  }),

  getDataList: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingDataList(true);

    if (payload.intervalUpdateCount === 0) {
      actions.setDataList([]);
    }

    const params = {
      utcOffsetMinutes: getDateObject(null).utcOffset(),
    };

    const dateToUse = payload.selectionDate.format(dateFormatYMD);
    const completeRoute = Routes.dataForPeriodLegacy + payload.sensorId + '/' + dateToUse + '/' + dateToUse;
    const requestResponse: IResponse = await getStoreActions().serverRequestsModel.get({
      route: completeRoute,
      params,
    });
    actions.setLoadingDataList(false);
    if (!requestResponse.success) {
      actions.setDataList([]);
      return;
    }

    const selectedInstallation = getStoreState().graphSettingsModel.selectedInstallation;

    const dataForSelectedDate = getDataForDate(dateToUse, requestResponse.data);
    const dataWithoutInvalidData = dataForSelectedDate.filter((item => item.changeValue !== 0));
    const formattedData: IDeviceDataFormatted[] = dataWithoutInvalidData.map((data) => {
      return formatDeviceData({
        dataSet: data,
        isExport: false,
        measurementUnit: selectedInstallation.infoExternalSensorData.measurementUnit,
      });
    });

    actions.setDataList(formattedData);
  }),
  setDataList: action((state, payload) => {
    payload.reverse();


    state.dataList = payload;
  }),
  setErrorMessage: action((state, payload) => {
    state.errorMessage = payload;
  }),
  setLoadingData: action((state, payload) => {
    state.loadingData = payload;
  }),
  setLoadingDataList: action((state, payload) => {
    state.loadingDataList = payload;
  }),
  showDataList: false,
  setShowDataList: action((state) => {
    const newValue = !state.showDataList;
    state.showDataList = newValue;
  }),

  loadingSignalStrengthHistory: false,
  setLoadingSignalStrengthHistory: action((state, payload) => {
    state.loadingSignalStrengthHistory = payload;
  }),
  signalStrengthHistory: [],
  getSignalStrengthHistory: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const selectedInstallation = getStoreState().graphSettingsModel.selectedInstallation;
    const requestRoute = getRouteForGraphData({
      installationId: selectedInstallation.infoBasic.id,
      route: Routes.signalStrengthHistory,
      selectedOption: payload
    })

    actions.setLoadingSignalStrengthHistory(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: requestRoute });
    actions.setLoadingSignalStrengthHistory(false);
    if (requestResponse.success) {
      actions.setSignalStrengthHistory(requestResponse.data);
    }
  }),
  setSignalStrengthHistory: action((state, payload) => {
    state.signalStrengthHistory = parseDataForGraph(payload);
  }),

  loadingInternalTemperatureHistory: false,
  setLoadingInternalTemperatureHistory: action((state, payload) => {
    state.loadingInternalTemperatureHistory = payload;
  }),

  internalTemperatureHistory: [],

  getInternalTemperatureHistory: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const selectedInstallation = getStoreState().graphSettingsModel.selectedInstallation;
    const requestRoute = getRouteForGraphData({
      installationId: selectedInstallation.infoBasic.id,
      route: Routes.internalTemperatureHistory,
      selectedOption: payload,
    });

    actions.setLoadingInternalTemperatureHistory(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: requestRoute });
    if (requestResponse.success) {
      actions.setInternalTemperatureHistory(requestResponse.data);
      const maxValueToUse = _.maxBy(requestResponse.data as ISimpleLineData[], 'value') || { value: -1 };
      const domainAndTick = getDomainAndTick(maxValueToUse.value);
      actions.setInternalTemperatureDomainAndTicks(domainAndTick);
    }
    actions.setLoadingInternalTemperatureHistory(false);
  }),
  setInternalTemperatureHistory: action((state, payload) => {
    state.internalTemperatureHistory = parseDataForGraph(payload);
  }),

  internalTemperatureDomainAndTicks: defaultDomainAndTicks,
  setInternalTemperatureDomainAndTicks: action((state, payload) => {
    state.internalTemperatureDomainAndTicks = payload;
  }),

  loadingExternalSensorHistory: false,
  setLoadingExternalSensorHistory: action((state, payload) => {
    state.loadingExternalSensorHistory = payload;
  }),

  externalSensorHistory: [],
  retrieveExternalSensorHistory: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const selectedInstallation = getStoreState().graphSettingsModel.selectedInstallation;
    const requestRoute = getRouteForGraphData({
      installationId: selectedInstallation.infoBasic.id,
      route: Routes.externalSensorData,
      selectedOption: payload
    });

    actions.setLoadingExternalSensorHistory(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: requestRoute });
    if (requestResponse.success) {
      const data = requestResponse.data as ISimpleLineData[];
      actions.setExternalSensorHistory(data);
      const maxValueToUse = _.maxBy(data, 'value') || { value: -1 };
      const domainAndTick = getDomainAndTick(maxValueToUse.value);
      actions.setExternalSensorDomainAndTicks(domainAndTick);
    }
    actions.setLoadingExternalSensorHistory(false);
  }),
  setExternalSensorHistory: action((state, payload) => {
    state.externalSensorHistory = parseDataForGraph(payload);
  }),

  externalSensorDomainAndTicks: defaultDomainAndTicks,
  setExternalSensorDomainAndTicks: action((state, payload) => {
    state.externalSensorDomainAndTicks = payload;
  }),

  loadingPowerConsumptionHistory: false,
  setLoadingPowerConsumptionHistory: action((state, payload) => {
    state.loadingPowerConsumptionHistory = payload;
  }),

  powerConsumptionHistory: [],

  retrievePowerConsumptionHistory: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const selectedInstallation = getStoreState().graphSettingsModel.selectedInstallation;
    const requestRoute = getRouteForGraphData({
      installationId: selectedInstallation.infoBasic.id,
      route: Routes.powerConsumptionHistory,
      selectedOption: payload
    });
    actions.setLoadingPowerConsumptionHistory(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: requestRoute });
    if (requestResponse.success) {
      actions.setPowerConsumptionHistory(requestResponse.data);
    }
    actions.setLoadingPowerConsumptionHistory(false);
  }),
  setPowerConsumptionHistory: action((state, payload) => {
    state.powerConsumptionHistory = parseDataForGraph(payload);
  }),

  retrievePeriodData: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    actions.setGraphDataDetails(defaultGraphDataDetails);
    actions.setGraphData(defaultGraphData);

    actions.setEventDataDetails([]);
    actions.setEventDataFromPeriod([]);

    const graphSettingsTime = getStoreState().graphSettingsModel.graphSettingsTime;
    const sensorId = getStoreState().graphSettingsModel.selectedInstallation.infoBasic.id;

    actions.setLoadingData(true);
    const params: IDataForPeriodParams = {
      sensorId,
      period: graphSettingsTime.timePeriodselection,
      startDate: graphSettingsTime.dateSelection.format(dateFormatDMY),
      utcOffsetMinutes: getDateObject(null).utcOffset(),
    };

    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.dataForPeriod, params });
    if (requestResponse.success) {
      actions.setGraphData(requestResponse.data);
    }

    const detailsRequestResponse = await getStoreActions().serverRequestsModel.get({
      route: '/data-details-for-period/',
      params,
    });
    if (detailsRequestResponse.success) {
      actions.setGraphDataDetails(detailsRequestResponse.data);
    }

    actions.setErrorMessage(requestResponse.message);
    actions.setLoadingData(false);
  }),

  onSetShowDataList: thunkOn(
    (actions) => actions.setShowDataList,
    async (actions, target, { getStoreState }) => {
      const showDataList = getStoreState().deviceDataModel.showDataList;
      if (!showDataList) {
        return;
      }
      const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
      const dateSelection = getStoreState().graphSettingsModel.graphSettingsTime.dateSelection;

      if (baseFilter.infoBasic.id === 0) {
        return;
      }

      const sensorDetailsIntervalUpdate = getStoreState().deviceActionsModel.sensorDetailsIntervalUpdate;

      actions.getDataList({
        intervalUpdateCount: sensorDetailsIntervalUpdate,
        selectionDate: dateSelection,
        sensorId: baseFilter.infoBasic.id,
      });
    },
  ),

  onSetGraphSettingsTime: thunkOn(
    (actions, storeActions) => storeActions.graphSettingsModel.setGraphSettingsTime,
    async (actions, target, { getStoreState }) => {
      const showDataList = getStoreState().deviceDataModel.showDataList;
      if (!showDataList) {
        return;
      }
      const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
      const dateSelection = getStoreState().graphSettingsModel.graphSettingsTime.dateSelection;

      if (baseFilter.infoBasic.id === 0) {
        return;
      }

      const sensorDetailsIntervalUpdate = getStoreState().deviceActionsModel.sensorDetailsIntervalUpdate;

      actions.getDataList({
        intervalUpdateCount: sensorDetailsIntervalUpdate,
        selectionDate: dateSelection,
        sensorId: baseFilter.infoBasic.id,
      });
    },
  ),

  onGraphDataDetailsChange: thunkOn(
    (actions, storeActions) => storeActions.deviceDataModel.setGraphDataDetails,
    async (actions, target, { getStoreState }) => {
      const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
      const daysSinceinstall = baseFilter.infoDeviceMetrics.daysSinceInitialInstall;

      let averageFilterLifeTimeToShow;
      if (target.payload.totalCountFilterChange === 0) {
        averageFilterLifeTimeToShow = notAvailable;
      } else {
        const averageFilterLifeTime = (daysSinceinstall / target.payload.totalCountFilterChange).toFixed(0);
        averageFilterLifeTimeToShow = averageFilterLifeTime + ' days';
      }
      actions.setAverageLifeTimeFilter(averageFilterLifeTimeToShow);

      let averageLifeTimeMaintenance;
      if (target.payload.totalCountMaintenance === 0) {
        averageLifeTimeMaintenance = notAvailable;
      } else {
        const averageFilterLifeTime = (daysSinceinstall / target.payload.totalCountMaintenance).toFixed(0);
        averageLifeTimeMaintenance = averageFilterLifeTime + ' days';
      }
      actions.setAverageLifeTimeMaintenance(averageLifeTimeMaintenance);
    },
  ),
  onGraphDataChange: thunkOn(
    (actions) => actions.setGraphData,
    async (actions, target: TargetPayload<IBarChartData[]>) => {
      const maxValue = _.maxBy(target.payload, 'value') || { value: 0 };
      const maxEstimatedValue = _.maxBy(target.payload, 'estimatedValue') || { estimatedValue: 0 };
      const maxManualValue = _.maxBy(target.payload, 'manualValue') || { manualValue: 0 };
      const maxValueToUse = Math.max(maxValue.value, maxEstimatedValue.estimatedValue, maxManualValue.manualValue);
      const graphDomainAndTicks = getDomainAndTick(maxValueToUse);
      actions.setGraphDomainAndTicks(graphDomainAndTicks);

      const stockMaxValue = _.maxBy(target.payload, 'stockValue') || { stockValue: -1 };
      const stockDomainAndTick = getDomainAndTick(stockMaxValue.stockValue);
      actions.setStockGraphDomainAndTicks(stockDomainAndTick);
    },
  ),

  eventDataDetails: [],
  setEventDataDetails: action((state, payload) => {
    state.eventDataDetails = payload;
  }),

  eventDataFromPeriod: [],
  setEventDataFromPeriod: action((state, payload) => {
    state.eventDataFromPeriod = payload;
  }),

  maintenanceCyclesDetails: { averageCycles: 0, currentCycles: 0, maintenanceCycles: 0, totalCycles: 0 },
  setMaintenanceCyclesDetails: action((state, payload) => {
    state.maintenanceCyclesDetails = payload;
  }),

  onRetrievePeriodData: thunkOn(
    (actions, storeActions) => actions.retrievePeriodData,
    async (actions, target, { getStoreState, getStoreActions }) => {
      const isPremium = getStoreState().companyModel.isPremium;
      if (!isPremium) {
        return;
      }

      const graphSettingsTime = getStoreState().graphSettingsModel.graphSettingsTime;
      const sensorId = getStoreState().graphSettingsModel.selectedInstallation.infoBasic.id;
      if (sensorId === undefined || sensorId === 0) {
        return;
      }

      const params: IDataForPeriodParams = {
        sensorId,
        period: graphSettingsTime.timePeriodselection,
        startDate: graphSettingsTime.dateSelection.format(dateFormatDMY),
        utcOffsetMinutes: getDateObject(null).utcOffset(),
      };
      const requestResponse = await getStoreActions().serverRequestsModel.get({
        route: Routes.dataForPeriodConsumption,
        params,
      });
      if (requestResponse.success) {
        const insightsData: InsightsData = requestResponse.data as InsightsData;

        const eventsOverview: IEventStatsOverview[] = [];
        Object.entries(insightsData.eventsFromPeriod).forEach(([key, value], index) => {
          if (Number(value) !== 0) {
            eventsOverview.push({ amount: value, title: parseTypeToReadable(key) });
          }
        });

        actions.setEventDataDetails(insightsData.eventsDetails);
        actions.setEventDataFromPeriod(_.sortBy(eventsOverview, ['title']));
        actions.setMaintenanceCyclesDetails(insightsData.cycleDetails);
      }
    },
  ),
};

export default deviceDataModel;
