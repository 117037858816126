import { InstallationTag } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Dayjs } from 'dayjs';
import { useSnackbar, VariantType } from 'notistack';
import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { getDateObject } from '../../../../Utility/time';
import { DialogActions } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import { canUserModifyOrDeleteInstallationTag } from '../../../installations/components/Tags/tags-util';
import { AnchorOptions } from './InstallationTags';
type Props = {
  tag: InstallationTag;
  children?: React.ReactNode;
  installationId: number;
  anchorOptions: AnchorOptions;
  closeMenu(): void;
};

const InstallationTagOptions = ({ anchorOptions, closeMenu, tag, installationId }: Props) => {
  const updateInstallationTag = useStoreActions((actions) => actions.installationsModel.updateInstallationTag);
  const removeInstallationTag = useStoreActions((actions) => actions.installationsModel.removeInstallationTag);
  const currentUser = useStoreState((state) => state.authModel.currentUser);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(getDateObject(null));

  const { enqueueSnackbar } = useSnackbar();

  const canModifyOrDelete = canUserModifyOrDeleteInstallationTag(currentUser.role, tag);

  // Menu should only open if the user has permission to modify or delete the tag
  const open = Boolean(anchorOptions.anchorEl) && anchorOptions.label === tag.tags.name;

  const handleSetExpirationDate = async (date: Dayjs | null) => {
    if (!canModifyOrDelete) {
      enqueueSnackbar('You do not have permission to set expiration dates.', { variant: 'error' });
      return;
    }

    const response = await updateInstallationTag({
      installationTagId: tag.id,
      sensorId: installationId,
      expirationDate: date ? date.format(dateFormatYMD) : null,
    });

    const feedback = response.success ? 'Expiration date set successfully.' : 'Failed to set expiration date.';
    const variant: VariantType = response.success ? 'success' : 'error';
    enqueueSnackbar(feedback, { variant });

    setShowDatePicker(false); // Close the date picker after setting the date
  };

  // Handle removing the expiration date
  const handleRemoveExpirationDate = async () => {
    if (!canModifyOrDelete) {
      enqueueSnackbar('You do not have permission to remove expiration dates.', { variant: 'error' });
      return;
    }

    const response = await updateInstallationTag({
      installationTagId: tag.id,
      sensorId: installationId,
      expirationDate: null,
    });

    const feedback = response.success ? 'Expiration date removed successfully.' : 'Failed to remove expiration date.';
    const variant: VariantType = response.success ? 'success' : 'error';
    enqueueSnackbar(feedback, { variant });

    closeMenu();
  };

  const handleTagDelete = async () => {
    if (!canModifyOrDelete) {
      enqueueSnackbar('You do not have permission to remove this tag.', { variant: 'error' });
      return;
    }

    if (!tag.id) {
      enqueueSnackbar('Tag not selected, please try again', { variant: 'error' });
      return;
    }

    const removeInstallationTagResponse = await removeInstallationTag({
      installationTagId: tag.id,
      sensorId: installationId,
    });

    const feedbackMessage = removeInstallationTagResponse.success
      ? 'Tag removed from installation successfully.'
      : 'Tag: ' + tag.tags.name + ' could not be removed from installation';
    const variant: VariantType = removeInstallationTagResponse.success ? 'success' : 'error';
    enqueueSnackbar(feedbackMessage, { variant });

    closeMenu();
  };

  const handleCancel = () => {
    setShowDatePicker(false); // Close the dialog when canceling
  };

  return (
    <>
      <Menu
        id="tag-information-menu"
        anchorEl={anchorOptions.anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{ cursor: 'default' }}>
          <ListItemIcon>
            <CalendarMonthIcon />
          </ListItemIcon>
          <ListItemText>{'Tag added: ' + getDateObject(tag.createdAt).format(dateFormatYMD)}</ListItemText>
        </MenuItem>

        <MenuItem sx={{ cursor: 'default' }}>
          <ListItemIcon>
            <AccessAlarmIcon />
          </ListItemIcon>
          <ListItemText>
            {tag.expirationDate
              ? 'Tag will expire: ' + getDateObject(tag.expirationDate).format(dateFormatYMD)
              : tag.tags.id === 49
              ? 'Tag will automatically be removed when a filter change is performed.'
              : tag.tags.id === 72
              ? 'Tag will automatically be removed when a serial change is performed.'
              : 'Tag will stay until removed'}
          </ListItemText>
        </MenuItem>

        {tag.expirationDate ? (
          <MenuItem onClick={handleRemoveExpirationDate}>
            <ListItemIcon>
              <RemoveCircleIcon />
            </ListItemIcon>
            <ListItemText>Remove expiration date</ListItemText>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              setShowDatePicker(true);
              closeMenu(); // Close the menu when opening the date picker
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Set expiration date</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={handleTagDelete} disabled={!canModifyOrDelete}>
          <ListItemIcon>
            <ClearIcon color="error" />
          </ListItemIcon>
          <ListItemText>{canModifyOrDelete ? 'Remove tag' : 'Only Flowplan can remove this tag'}</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog open={showDatePicker} onClose={handleCancel}>
        <DialogTitle>Select Expiration Date</DialogTitle>
        <DialogContent>
          <DateCalendar
            value={selectedDate}
            onChange={(newValue) => {
              if (newValue !== null) {
                setSelectedDate(newValue);
                handleSetExpirationDate(newValue); // Automatically set the selected date
                setShowDatePicker(false); // Close the dialog
              }
            }}
            disableFuture={false}
            minDate={getDateObject(null)}
          />
        </DialogContent>
        <DialogActions>
          <FpButton onClick={() => setShowDatePicker(false)} color="secondary">
            Cancel
          </FpButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InstallationTagOptions;
