import { IFilter, IFilterChangeFixedTime, IFilterProducer } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IFlowmeter } from '@flowplan/flowplan-shared/lib/flowmeters/flowmeters';
import { ISensorCategory, ISensorType } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { IExternalSensorCategory, IExternalSensors } from '@flowplan/flowplan-shared/lib/interfaces/externalsensor';
import { asyncForEach, sortByKey } from '@hsjakobsen/utilities';
import { Action, Thunk, action, thunk } from 'easy-peasy';
import { IStoreModel } from '.';
import { noSelectionMade } from './admin/admin.model';

interface IFilterResult {
  filters: IFilter[];
  filterProducerId: number;
}

export interface IFilterDictionary {
  [filterProducerId: number]: IFilter[];
}

export interface IStaticDataModel {
  // Sensor categories
  loadingSensorCategories: boolean;
  retrieveSensorCategories: Thunk<IStaticDataModel, void, void, IStoreModel>;
  setLoadingSensorCategories: Action<IStaticDataModel, boolean>;
  setSensorCategories: Action<IStaticDataModel, ISensorCategory[]>;
  sensorCategories: ISensorCategory[];

  // Sensor types
  loadingSensorTypes: boolean;
  retrieveSensorTypes: Thunk<IStaticDataModel, void, void, IStoreModel>;
  setLoadingSensorTypes: Action<IStaticDataModel, boolean>;
  setSensorTypes: Action<IStaticDataModel, ISensorType[]>;
  sensorTypes: ISensorType[];

  // External sensor categories
  loadingExternalSensorCategories: boolean;
  retrieveExternalSensorCategories: Thunk<IStaticDataModel, void, void, IStoreModel>;
  setLoadingExternalCategories: Action<IStaticDataModel, boolean>;
  setExternalSensorCategories: Action<IStaticDataModel, IExternalSensorCategory[]>;
  externalSensorCategories: IExternalSensorCategory[];

  // External sensors
  loadingExternalSensors: boolean;
  retrieveExternalSensors: Thunk<IStaticDataModel, void, void, IStoreModel>;
  setLoadingExternalSensors: Action<IStaticDataModel, boolean>;
  setExternalSensors: Action<IStaticDataModel, IExternalSensors[]>;
  externalSensors: IExternalSensors[];

  // Flowmeters
  loadingFlowmeters: boolean;
  retrieveFlowmeters: Thunk<IStaticDataModel, void, void, IStoreModel>;
  setLoadingFlowmeters: Action<IStaticDataModel, boolean>;
  setFlowmeters: Action<IStaticDataModel, IFlowmeter[]>;
  flowmeters: IFlowmeter[];

  // Filter producers
  loadingFilterProducers: boolean;
  retrieveFilterProducers: Thunk<IStaticDataModel, void, void, IStoreModel>;
  setLoadingFilterProducers: Action<IStaticDataModel, boolean>;
  setFilterProducers: Action<IStaticDataModel, IFilterProducer[]>;
  filterProducers: IFilterProducer[];

  // Filters
  loadingFilters: boolean;
  retrieveFilters: Thunk<IStaticDataModel, IFilterProducer[], void, IStoreModel>; // filterProducerId
  setLoadingFilters: Action<IStaticDataModel, boolean>;
  setFilters: Action<IStaticDataModel, IFilterResult>;
  filters: IFilterDictionary;

  // Change by options
  loadingChangeByOptions: boolean;
  retrieveChangeByOptions: Thunk<IStaticDataModel, void, void, IStoreModel>;
  setLoadingChangeByOptions: Action<IStaticDataModel, boolean>;
  setChangeByOptions: Action<IStaticDataModel, IFilterChangeFixedTime[]>;
  changeByOptions: IFilterChangeFixedTime[];
}

const staticDataModel: IStaticDataModel = {
  loadingFilterProducers: false,
  filterProducers: [],
  retrieveFilterProducers: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingFilterProducers(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.filterProducers });
    if (requestResponse.success) {
      actions.setFilterProducers(requestResponse.data);
      await actions.retrieveFilters(requestResponse.data);
    }
    actions.setLoadingFilterProducers(false);
  }),
  setLoadingFilterProducers: action((state, payload) => {
    state.loadingFilterProducers = payload;
  }),
  setFilterProducers: action((state, payload) => {
    payload.push({
      id: noSelectionMade,
      data: 0,
      monitor: 0,
      name: 'Select a filter producer',
      sortIndex: -1,
    })
    sortByKey(payload, 'sortIndex', true);
    state.filterProducers = payload;
  }),

  loadingFilters: false,
  filters: {},
  retrieveFilters: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingFilters(true);
    await asyncForEach(payload, async (filterProducer: IFilterProducer) => {
      if (filterProducer.data === 0) {
        return;
      }
      const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.filters + filterProducer.id });
      if (requestResponse.success) {
        actions.setFilters({ filterProducerId: filterProducer.id, filters: requestResponse.data });
      }
    });
    actions.setLoadingFilters(false);
  }),
  setLoadingFilters: action((state, payload) => {
    state.loadingFilters = payload;
  }),
  setFilters: action((state, payload) => {
    const sortedArray = sortByKey(payload.filters, 'sortIndex', true);
    state.filters[payload.filterProducerId] = sortedArray;
  }),

  loadingExternalSensorCategories: false,
  externalSensorCategories: [],
  retrieveExternalSensorCategories: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingExternalCategories(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.externalSensorCategories });
    if (requestResponse.success) {
      actions.setExternalSensorCategories(requestResponse.data);
    }
    actions.setLoadingExternalCategories(false);
  }),
  setLoadingExternalCategories: action((state, payload) => {
    state.loadingExternalSensorCategories = payload;
  }),
  setExternalSensorCategories: action((state, payload) => {
    state.externalSensorCategories = payload;
  }),

  loadingExternalSensors: false,
  externalSensors: [],
  retrieveExternalSensors: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingExternalSensors(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.externalSensors });
    if (requestResponse.success) {
      actions.setExternalSensors(requestResponse.data);
    }
    actions.setLoadingExternalSensors(false);
  }),
  setLoadingExternalSensors: action((state, payload) => {
    state.loadingExternalSensors = payload;
  }),
  setExternalSensors: action((state, payload) => {
    const itemToAdd: IExternalSensors = {
      id: -1,
      name: 'Select external sensor',
      externalSensorCategoryId: 0,
      sortIndex: 0,
    };
    payload.unshift(itemToAdd);
    state.externalSensors = payload;
  }),

  flowmeters: [],
  loadingFlowmeters: false,
  retrieveFlowmeters: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingFlowmeters(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.flowmeters });
    if (requestResponse.success) {
      actions.setFlowmeters(requestResponse.data);
    }
    actions.setLoadingFlowmeters(false);
  }),
  setFlowmeters: action((state, payload) => {
    state.flowmeters = payload;
  }),
  setLoadingFlowmeters: action((state, payload) => {
    state.loadingFlowmeters = payload;
  }),

  loadingChangeByOptions: false,
  changeByOptions: [],
  retrieveChangeByOptions: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingChangeByOptions(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.changeByOptions });
    if (requestResponse.success) {
      actions.setChangeByOptions(requestResponse.data);
    }
    actions.setLoadingChangeByOptions(false);
  }),
  setLoadingChangeByOptions: action((state, payload) => {
    state.loadingChangeByOptions = payload;
  }),
  setChangeByOptions: action((state, payload) => {
    const itemToAdd: IFilterChangeFixedTime = {
      id: -1,
      duration: 'No fixed change date',
    };
    payload.unshift(itemToAdd);
    state.changeByOptions = payload;
  }),

  loadingSensorCategories: false,
  retrieveSensorCategories: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingSensorCategories(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.sensorCategory });
    if (requestResponse.success) {
      actions.setSensorCategories(requestResponse.data);
    }
    actions.setLoadingSensorCategories(false);
  }),
  setLoadingSensorCategories: action((state, payload) => {
    state.loadingSensorCategories = payload;
  }),
  setSensorCategories: action((state, payload) => {
    state.sensorCategories = payload;
  }),
  sensorCategories: [],

  loadingSensorTypes: false,
  retrieveSensorTypes: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingSensorTypes(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.sensorType });
    if (requestResponse.success) {
      actions.setSensorTypes(requestResponse.data);
    }
    actions.setLoadingSensorTypes(false);
  }),
  setLoadingSensorTypes: action((state, payload) => {
    state.loadingSensorTypes = payload;
  }),
  setSensorTypes: action((state, payload) => {
    const sortedSensorTypes: ISensorType[] = sortByKey(payload, 'sortIndex', true) as ISensorType[];
    state.sensorTypes = sortedSensorTypes;
  }),
  sensorTypes: [],
};

export default staticDataModel;
