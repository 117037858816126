import { IFlowplanClientRequestPackage, minimumStringLength } from '@flowplan/flowplan-shared';
import { IUserRequestPackage } from '@flowplan/flowplan-shared/lib/users/users.requests';
import { IResponse, trimStringAndCheckLength, validateEmail } from '@hsjakobsen/utilities';
import { IUserInputAdmin } from '../../modules/users/types/users-types';
export const validateInputFlowplanClient = (state: IFlowplanClientRequestPackage): IFlowplanClientRequestPackage => {
  const flowplanClientData: IFlowplanClientRequestPackage = {
    ...state
  };

  const nameResponse = trimStringAndCheckLength(state.name || '', 'Company name', minimumStringLength, false);
  if (!nameResponse.success) {
    flowplanClientData.message = nameResponse.feedback;
    return flowplanClientData;
  }

  const adminPassword = state.adminPassword;
  if (adminPassword === undefined || adminPassword === '') {
    flowplanClientData.message = 'Admin password missing';
    return flowplanClientData;
  }

  flowplanClientData.success = true;
  flowplanClientData.name = nameResponse.result;
  flowplanClientData.adminPassword = adminPassword;

  return flowplanClientData;
};

export const validateInputAdminAddUser = (state: IUserInputAdmin): IResponse => {
  const validationResponse: IResponse = {
    success: false,
    message: '',
  };

  const nameResponse = trimStringAndCheckLength(state.newAccountUsername, 'Username', minimumStringLength, false);
  if (!nameResponse.success) {
    validationResponse.message = nameResponse.feedback;
    return validationResponse;
  }

  if (!validateEmail(state.newAccountEmail)) {
    validationResponse.message = 'Email not valid';
    return validationResponse;
  }

  if (state.newAccountName === '') {
    validationResponse.message = 'No name entered';
  }

  const flowplanClientId = state.newAccountCompanyId;
  if (flowplanClientId < 0) {
    validationResponse.message = 'Select a Flowplan client for the user';
    return validationResponse;
  }

  const adminPassword = state.adminVerificationPassword;
  if (adminPassword === undefined || adminPassword === '') {
    validationResponse.message = 'Admin password missing';
    return validationResponse;
  }

  const addUserRequest: IUserRequestPackage = {
    adminPassword,
    email: state.newAccountEmail,
    flowplanClientId: flowplanClientId,
    role: state.newAccountRole,
    name: state.newAccountName,
    username: nameResponse.result,
  };

  if (state.newAccountClientId) {
    addUserRequest.clientId = state.newAccountClientId;
  }

  validationResponse.success = true;
  validationResponse.message = '';
  validationResponse.data = addUserRequest;

  return validationResponse;
};
