// Navigation.tsx
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Drawer, Fab, IconButton, Toolbar, useTheme } from '@mui/material';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { loadMapApi } from '../../../../Components/Peripherals/GoogleAPI/GoogleAPI';
import { useStoreActions, useStoreState } from '../../../../Store';
import { getPageTitle } from '../../../../Utility/PageTitle';
import FpButtonIcon from '../../../common/components/FpButtonIcon';
import FpText from '../../../common/components/FpText/FpText';
import ScrollToTop from '../../../common/components/ScrollToTop/ScrollToTop';
import NewsAndNotifications from '../../../news-and-updates/NewsAndUpdates';
import AccountMenu from '../../../user-profile/components/AccountMenu';
import Footer from '../../components/Footer/Footer';
import { NavigationContent } from './NavigationContent';
import NavigationLogo from './NavigationLogo';

interface NavigationProps {
  children?: ReactNode;
}

const DRAWER_WIDTH = 260;
const COLLAPSED_WIDTH = 64;
const SIDEBAR_STORAGE_KEY = 'sidebarCollapsed';

const Navigation: FC<NavigationProps> = ({ children }) => {
  const theme = useTheme();
  const location = useLocation();
  const showMenu = useStoreState((state) => state.authModel.showMenu);
  const setGoogleAPILoaded = useStoreActions((actions) => actions.externalServices.setGoogleAPILoaded);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentPageName, setCurrentPageName] = useState(() => getPageTitle(location.pathname));
  const [isCollapsed, setIsCollapsed] = useState(() => {
    try {
      return localStorage.getItem(SIDEBAR_STORAGE_KEY) === 'true';
    } catch {
      return false;
    }
  });

  const currentDrawerWidth = useMemo(() => (isCollapsed ? COLLAPSED_WIDTH : DRAWER_WIDTH), [isCollapsed]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prev) => !prev);
  }, []);

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((prev) => {
      const newState = !prev;
      try {
        localStorage.setItem(SIDEBAR_STORAGE_KEY, String(newState));
      } catch (e) {
        console.error('Error storing sidebar state:', e);
      }
      return newState;
    });
  }, []);

  useEffect(() => {
    setCurrentPageName(getPageTitle(location.pathname));
  }, [location]);

  useEffect(() => {
    const googleMapScript = loadMapApi();

    if (typeof google === 'object' && typeof google.maps === 'object') {
      setGoogleAPILoaded(true);
      return;
    }

    googleMapScript.addEventListener('load', () => {
      setGoogleAPILoaded(true);
    });
  }, [setGoogleAPILoaded]);

  if (!showMenu) {
    return (
      <Box component="main" sx={{ height: '100vh', bgcolor: '#f7f7f7' }}>
        {children}
        <Footer />
        <ScrollToTop>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollToTop>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { xs: '100%', md: `calc(100% - ${currentDrawerWidth}px)` },
          ml: { xs: 0, md: `${currentDrawerWidth}px` },
          boxShadow: 'none',
          bgcolor: 'none',
          transition: theme.transitions.create(['width', 'margin']),
        }}
      >
        <Toolbar>
          <FpButtonIcon
            color="primary"
            aria-label="open navigation"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </FpButtonIcon>
          <FpText variant="h5" sx={{ color: '#4b4b4b', flexGrow: 1 }}>
            {currentPageName}
          </FpText>
          <NewsAndNotifications />
          <AccountMenu />
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: { xs: 0, md: currentDrawerWidth },
          flexShrink: 0,
          transition: theme.transitions.create('width'),
          display: { xs: 'none', md: 'block' },
        }}
        aria-label="Main navigation"
      >
        <Drawer
          variant="permanent"
          sx={{
            '& .MuiDrawer-paper': {
              width: currentDrawerWidth,
              transition: theme.transitions.create('width'),
              boxSizing: 'border-box',
              borderRight: 'none',
              height: '100vh',
              overflow: 'hidden',
              bgcolor: 'transparent',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                p: isCollapsed ? 1 : 2,
                borderColor: 'divider',
                textAlign: 'center',
                flexShrink: 0,
                minWidth: isCollapsed ? COLLAPSED_WIDTH : DRAWER_WIDTH,
                transition: theme.transitions.create(['min-width', 'padding']),
              }}
            >
              <NavigationLogo isCollapsed={isCollapsed} isSmallScreen={false} />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <NavigationContent collapsed={isCollapsed} isSmallScreen={false} />
            </Box>

            <IconButton
              onClick={toggleCollapse}
              size="small"
              aria-label={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
              sx={{
                position: 'absolute',
                right: -8,
                bottom: 25,
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: '5px',
                width: 32,
                height: 32,
                '&:hover': {
                  bgcolor: 'background.paper',
                },
                zIndex: 1200,
                boxShadow: 1,
              }}
            >
              <KeyboardArrowUpIcon
                sx={{
                  transform: isCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)',
                  transition: theme.transitions.create('transform'),
                }}
              />
            </IconButton>
          </Box>
        </Drawer>
      </Box>

      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
            borderRight: 'none',
            height: '100vh',
            bgcolor: '#f7f7f7',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box sx={{ p: 2, borderColor: 'divider', textAlign: 'center', flexShrink: 0 }}>
            <NavigationLogo isCollapsed={false} isSmallScreen={true} />
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
            <NavigationContent collapsed={false} isSmallScreen={true} />
          </Box>
        </Box>
      </Drawer>

      <Box
        component="main"
        id="main-content-container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          transition: theme.transitions.create(['width', 'margin']),
        }}
      >
        <Toolbar />
        <Box
          sx={{
            borderTopLeftRadius: theme.shape.borderRadius * 2.5,
            borderTopRightRadius: theme.shape.borderRadius * 1.5,
            flexGrow: 1,
            backgroundColor: 'white',
          }}
        >
          {children}
        </Box>
        <ScrollToTop>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollToTop>
      </Box>
    </Box>
  );
};

export default Navigation;
