import { GridColumnVisibilityModel, GridDensity, GridState } from '@mui/x-data-grid-premium';
import _ from 'lodash';
import { FilterSelection, defaultFilterSelections } from '../modules/dashboard/dashboard-marker-filter-model';
import { Paths } from '../modules/navigation/containers/Navigation/navigation-types';

export enum LocalStore {
  // Navigation & Routing
  LastVisited = 'lastvisited',
  LastKnownRoute = 'lastKnownRoute',

  // Map Related
  LastSelectedMapFilterUpdated = 'lastSelectedMapFilter2024',
  MapDashboardLat = 'lat',
  MapDashboardLng = 'lng',
  MapDashboardZoom = 'zoom',
  MapFindInstallationLat = 'workOrderFindInstallationsLat',
  MapFindInstallationLng = 'workOrderFindInstallationsLng',
  MapFindInstallationZoom = 'workOrderFindInstallationsZoom',
  MapFindTaskLat = 'workOrderFindTasksLat',
  MapFindTaskLng = 'workOrderFindTasksLng',
  MapFindTaskZoom = 'workOrderFindTasksZoom',
  MapWorkOrderContentLat = 'workOrderFindDetailsLat',
  MapWorkOrderContentLng = 'workOrderFindDetailsLng',
  MapWorkOrderContentZoom = 'workOrderFindDetailsZoom',
  NotInSystemLat = 'notInSystemLat',
  NotInSystemLng = 'notInSystemLng',
  NotInSystemZoom = 'notInSystemZoom',

  // Table States
  TableState = 'TableState',
  TableStateDensity = 'TableStateDensity',
  TableStateVisibility = 'TableStateVisibility',

  // Work Order Related
  WorkOrderDaysLeft = 'workOrderFindTasksDaysLeft',
  WorkOrderDistance = 'workOrderFindTasksDistance',
  WorkOrderSkipWelcome = 'workOrderSkipWelcome',
  OrderFormAddresses = 'orderFormAdress',

  // UI & Layout
  SidebarCollapsed = 'sidebarCollapsed',
  MobileMenuOpen = 'mobileMenuOpen',
  LastPageName = 'lastPageName',
  DrawerLayout = 'drawerLayout',
  SmallScreenPreference = 'smallScreenPreference',

  // Location Management
  TapsLastSelectedLocation = 'lastSelectedLocation',
  TapsLastTechnicianLocation = 'lastTechnicianLocation',
  TapsLastKegLocation = 'lastKegLocation',
  TapsLastStockLocation = 'lastStockLocation',
  TapsLastSelectedSublocation = 'lastSelectedSublocation',

  // View States & Preferences
  StockDashboardView = 'stockDashboardView',
  DefaultStockView = 'defaultStockView',
  TapKegViewPreferences = 'tapKegViewPreferences',
  TechnicianPortalTab = 'technicianPortalTab'
}


export enum LocalStoreBasic {
  AccessToken = 'accessToken',
  AccessTokenExpiring = 'accessTokenExpiring',
  FailedLogins = 'failedLogins',
  PageVisits = 'pageVisits',
  RefreshToken = 'refreshToken',
}

export enum TableStateName {
  Clients = 'Clients',
  Locations = 'Locations',
  Installations = 'Installations',
  FilteredList = 'FilteredList',
  WorkOrderTasks = 'WorkOrderTasks',
  WorkOrders = 'WorkOrders',
  WorkOrderContent = 'WorkOrderContent',
  Inventory = 'Inventory',
  TestCustomerOverView = 'TestCustomerOverview',
  DashboardInstallationsTable = 'DashboardInstallationsTable',
  TapLocation = 'TapLocation'
}

let localStorageKey = '';

export const setStorageKey = (key: string): void => {
  localStorageKey = key;
};

const getUserLocalStorageKey = (key: string) => {
  return localStorageKey + '_' + key;
};

/* 
  Local storage
*/

type StoreItemBasic = {
  identifier: LocalStoreBasic;
  value: string;
}

export const storeBasicSetting = ({ identifier, value }: StoreItemBasic): void => {
  localStorage.setItem(identifier, value);
}

export const getBasicSetting = (identifier: LocalStoreBasic): string | null => {
  return localStorage.getItem(identifier);
};

type GetItemNumberBasic = {
  identifier: LocalStoreBasic;
  fallback: number;
  returnFloat?: boolean;
}

export const getBasicSettingNumber = ({ fallback, identifier, returnFloat = false }: GetItemNumberBasic): number => {
  const numLookup = localStorage.getItem(identifier);
  const returnValue: number = numLookup ? (returnFloat ? parseFloat(numLookup) : parseInt(numLookup, 10)) : fallback;
  return returnValue;
}

type StoreItem = {
  identifier: LocalStore;
  value: string;
}

export const storeUserSetting = ({ identifier, value }: StoreItem): void => {
  localStorage.setItem(getUserLocalStorageKey(identifier), value);
}

export const getUserSetting = (identifier: LocalStore): string | null => {
  const returnValue = localStorage.getItem(getUserLocalStorageKey(identifier));
  return returnValue;
};

type GetItemNumber = {
  identifier: LocalStore;
  fallback: number;
  returnFloat?: boolean;
}

export const getUserSettingNumber = ({ fallback, identifier, returnFloat = false }: GetItemNumber): number => {
  const numLookup = localStorage.getItem(getUserLocalStorageKey(identifier));
  const returnValue: number = numLookup ? (returnFloat ? parseFloat(numLookup) : parseInt(numLookup, 10)) : fallback;
  return returnValue;
}

/* 
  Login
*/

export const deleteStoredTokens = (): void => {
  localStorage.removeItem(LocalStoreBasic.AccessToken);
  localStorage.removeItem(LocalStoreBasic.RefreshToken);
  localStorage.removeItem(LocalStoreBasic.AccessTokenExpiring);
};

/*
 Logout cleanup
*/

export const deleteStoredSettings = (): void => {
  _.forIn(LocalStore, (value, key) => {
    if (isNaN(Number(key))) {

      if (value === LocalStore.OrderFormAddresses) {
        return;
      }

      if (value === LocalStore.TableState) {
        _.forIn(TableStateName, (tableName, key) => {
          if (isNaN(Number(key))) {
            const tableToClear = getUserLocalStorageKey(`${tableName}_${value}`);
            localStorage.removeItem(tableToClear);
          }
        })
        return;
      }
      if (value === LocalStore.TableStateDensity) {
        _.forIn(TableStateName, (tableName, key) => {
          if (isNaN(Number(key))) {
            const tableDensityToClear = getUserLocalStorageKey(`${tableName}_${value}`);
            localStorage.removeItem(tableDensityToClear);
          }
        })
        return;
      }

      const keyToRemove = getUserLocalStorageKey(value);
      localStorage.removeItem(keyToRemove);
    }
  });
};

/*
  Navigation
*/

export const storeLastVisitedLocation = (location: string): void => {
  if (localStorageKey === '') {
    return;
  }

  localStorage.setItem(getUserLocalStorageKey(LocalStore.LastVisited), location);
};

export const getLastVisitedLocation = (): string => {
  const savedLocation = localStorage.getItem(getUserLocalStorageKey(LocalStore.LastVisited));

  const locationToUse = savedLocation === null ? Paths.dashboard : savedLocation;
  return locationToUse;
};

export const deleteLastVisitedLocation = (): void => {
  localStorage.removeItem(getUserLocalStorageKey(LocalStore.LastVisited));
};

/*
  Data-grid
*/

export const storeTableState = (state: GridState, tableName: TableStateName): void => {
  const stateToSave = _.cloneDeep(state) as Record<any, any>;
  stateToSave.filter.filterModel.quickFilterValues = [];
  stateToSave.rows = {};
  localStorage.setItem(getUserLocalStorageKey(`${tableName}_${LocalStore.TableState}`), JSON.stringify(stateToSave));
};

export const getTableState = (tableName: TableStateName): GridState => {
  const state = localStorage.getItem(getUserLocalStorageKey(`${tableName}_${LocalStore.TableState}`));
  return state ? JSON.parse(state) : null;
};

export const storeTableStateDensity = (density: GridDensity, tableName: TableStateName): void => {
  localStorage.setItem(getUserLocalStorageKey(`${tableName}_${LocalStore.TableStateDensity}`), density);
};

export const getTableStateDensity = (tableName: TableStateName): GridDensity => {
  const state = localStorage.getItem(getUserLocalStorageKey(`${tableName}_${LocalStore.TableStateDensity}`));
  return state ? (state.toLowerCase() as GridDensity) : 'compact';
};


export const getVisibilityState = (tableName: TableStateName): GridColumnVisibilityModel => {
  const state = localStorage.getItem(getUserLocalStorageKey(`${tableName}_${LocalStore.TableStateVisibility}`));
  return state ? JSON.parse(state) : {
    batteryTypeId: false,
    internalTemperature: false,
    externalTemperature: false,
  };
};

export const storeVisibilityState = (state: GridColumnVisibilityModel, tableName: TableStateName): void => {
  const stateToSave = _.cloneDeep(state) as Record<any, any>;
  localStorage.setItem(getUserLocalStorageKey(`${tableName}_${LocalStore.TableStateVisibility}`), JSON.stringify(stateToSave));
};

/* 
  Dashboard
*/

export const storeDashboardLastUsedMapFilter = (filterSelection: FilterSelection): void => {
  localStorage.setItem(getUserLocalStorageKey(LocalStore.LastSelectedMapFilterUpdated), JSON.stringify(filterSelection));
};

export const getDashboardLastUsedMapFilter = (): FilterSelection => {
  const mapFilterArr = localStorage.getItem(getUserLocalStorageKey(LocalStore.LastSelectedMapFilterUpdated));
  if (mapFilterArr && mapFilterArr.length) {
    const casted = JSON.parse(mapFilterArr) as FilterSelection;
    if (!casted.daysLeftSelection.length) {
      casted.daysLeftSelection = defaultFilterSelections.daysLeftSelection;
    }

    return casted;
  }
  return defaultFilterSelections;
};


export const storeLastLocation = (locationId: number | null, type: LocalStore): void => {
  if (locationId) {
    storeUserSetting({
      identifier: type,
      value: locationId.toString()
    });
  } else {
    localStorage.removeItem(getUserLocalStorageKey(type));
  }
};

export const getLastLocation = (type: LocalStore): number | null => {
  const locationId = getUserSetting(type);
  return locationId ? parseInt(locationId, 10) : null;
};

// Sublocation Management
export const storeLastSublocation = (sublocationId: number | null): void => {
  if (sublocationId) {
    storeUserSetting({
      identifier: LocalStore.TapsLastSelectedSublocation,
      value: sublocationId.toString()
    });
  } else {
    localStorage.removeItem(getUserLocalStorageKey(LocalStore.TapsLastSelectedSublocation));
  }
};

export const getLastSublocation = (): number | null => {
  const sublocationId = getUserSetting(LocalStore.TapsLastSelectedSublocation);
  return sublocationId ? parseInt(sublocationId, 10) : null;
};

// UI State Management
export const getSidebarCollapsed = (): boolean => {
  const stored = getUserSetting(LocalStore.SidebarCollapsed);
  return stored ? JSON.parse(stored) : false;
};

export const storeSidebarCollapsed = (isCollapsed: boolean): void => {
  storeUserSetting({
    identifier: LocalStore.SidebarCollapsed,
    value: JSON.stringify(isCollapsed)
  });
};

// View Mode Management
export type ViewMode = 'chart' | 'table';

export const storeViewMode = (mode: ViewMode, type: LocalStore): void => {
  // const identifier = type === 'stock' ? LocalStore.StockDashboardView : LocalStore.DefaultStockView;
  storeUserSetting({
    identifier: type,
    value: mode
  });
};

export const getViewMode = (type: LocalStore): ViewMode => {
  // const identifier = type === 'stock' ? LocalStore.StockDashboardView : LocalStore.DefaultStockView;
  const view = getUserSetting(type);
  return (view as ViewMode) || 'chart';
};

// Tab State Management
export const storeTechnicianTab = (tabIndex: number): void => {
  storeUserSetting({
    identifier: LocalStore.TechnicianPortalTab,
    value: tabIndex.toString()
  });
};

export const getTechnicianTab = (): number => {
  const tab = getUserSetting(LocalStore.TechnicianPortalTab);
  return tab ? parseInt(tab, 10) : 0;
};

/*
  Cleanup
*/

export enum LEGACY_LocalStoreValues {
  SelectedSortFilter = 'filterSort',
  SelectedSortLocation = 'locationSort',
  SelectedClientSort = 'filterByClient',
  LastSelectedMapFilter = 'lastSelectedMapFilter',

  SidebarCollapsed = 'sidebar_collapsed',
  MobileMenuOpen = 'mobileMenuOpen',
  LastPageName = 'lastPageName',
  DrawerLayout = 'drawerLayout',
  SmallScreenPreference = 'smallScreenPreference',
  LastSelectedLocation = 'lastSelectedLocation',
  LastTechnicianLocation = 'lastTechnicianLocation',
  LastKegLocation = 'lastKegLocation',
  LastStockLocation = 'lastStockLocation',
  LastSelectedSublocation = 'lastSelectedSublocation',
  LocationHistory = 'locationHistory',
  StockDashboardView = 'stockDashboardView',
  DefaultStockView = 'defaultStockView',
  TapKegViewPreferences = 'tapKegViewPreferences',
  TechnicianPortalTab = 'technicianPortalTab'
}

export const removeLegacyValues = (): void => {
  localStorage.removeItem(getUserLocalStorageKey(LEGACY_LocalStoreValues.SelectedClientSort));
  localStorage.removeItem(getUserLocalStorageKey(LEGACY_LocalStoreValues.SelectedSortFilter));
  localStorage.removeItem(getUserLocalStorageKey(LEGACY_LocalStoreValues.LastSelectedMapFilter));
  localStorage.removeItem((LEGACY_LocalStoreValues.SidebarCollapsed));
  localStorage.removeItem((LEGACY_LocalStoreValues.MobileMenuOpen));
  localStorage.removeItem((LEGACY_LocalStoreValues.LastPageName));
  localStorage.removeItem((LEGACY_LocalStoreValues.DrawerLayout));
  localStorage.removeItem((LEGACY_LocalStoreValues.SmallScreenPreference));
  localStorage.removeItem((LEGACY_LocalStoreValues.LastSelectedLocation));
  localStorage.removeItem((LEGACY_LocalStoreValues.LastTechnicianLocation));
  localStorage.removeItem((LEGACY_LocalStoreValues.LastKegLocation));
  localStorage.removeItem((LEGACY_LocalStoreValues.LastStockLocation));
  localStorage.removeItem((LEGACY_LocalStoreValues.LastSelectedSublocation));
  localStorage.removeItem((LEGACY_LocalStoreValues.StockDashboardView));
  localStorage.removeItem((LEGACY_LocalStoreValues.DefaultStockView));
  localStorage.removeItem((LEGACY_LocalStoreValues.TapKegViewPreferences));
  localStorage.removeItem((LEGACY_LocalStoreValues.TechnicianPortalTab));
};
