import { AccountCircle, EditTwoTone, Logout, SettingsTwoTone } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useCallback, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../Store';
import Footer from '../../navigation/components/Footer/Footer';
import EditUser from './EditUser';

export default function AccountMenu() {
  const userInformation = useStoreState((state) => state.usersModel.userInformation);

  const { confirmSimpleToggle, signout } = useStoreActions((actions) => ({
    confirmSimpleToggle: actions.modalModel.confirmSimpleToggle,
    signout: actions.authModel.signout,
  }));

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  // const [isPrefModalVisible, setIsPrefModalVisible] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  const handleEditModalOpen = () => {
    setSettingsAnchorEl(null);
    setIsEditModalVisible(true);
  };

  const handlePreferenceModal = () => {
    setSettingsAnchorEl(null);
    // setIsPrefModalVisible(true);
  };

  const handleLogout = useCallback(() => {
    confirmSimpleToggle({
      content: 'Are you sure you want to logout?',
      title: 'Confirm logout',
      showModal: true,
      size: 'tiny',
      confirmCallback: async () => {
        await signout();
      },
    });
  }, [confirmSimpleToggle, signout]);

  const open = Boolean(anchorEl);
  const settingsOpen = Boolean(settingsAnchorEl);

  return (
    <>
      <IconButton
        id="account-button"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <AccountCircle />
      </IconButton>

      {/* Primary Menu */}
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'account-button',
        }}
      >
        <MenuItem disabled sx={{ backgroundColor: 'background.paper', fontWeight: 'bold' }}>
          Account Settings
        </MenuItem>
        <MenuItem onClick={handleEditModalOpen}>
          <ListItemIcon>
            <AccountCircle fontSize="small" />
          </ListItemIcon>
          <ListItemText>Account</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
        <Divider sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} />
        <Footer />
      </Menu>

      {/* Account Settings Menu */}
      <Menu
        id="settings-menu"
        anchorEl={settingsAnchorEl}
        open={settingsOpen}
        onClose={handleSettingsClose}
        MenuListProps={{
          'aria-labelledby': 'settings-button',
        }}
      >
        <MenuItem disabled>{userInformation?.name}</MenuItem>
        <MenuItem onClick={handleEditModalOpen}>
          <ListItemIcon>
            <EditTwoTone fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit user settings</ListItemText>
        </MenuItem>
        <MenuItem onClick={handlePreferenceModal}>
          <ListItemIcon>
            <SettingsTwoTone fontSize="small" />
          </ListItemIcon>
          <ListItemText>Preferences</ListItemText>
        </MenuItem>
      </Menu>

      {/* Modals */}
      <EditUser
        isEditModalVisible={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
        modalUserData={userInformation}
      />
      {/* <PreferenceModal 
        isPrefModalVisible={isPrefModalVisible} 
        onClose={() => setIsPrefModalVisible(false)} 
      /> */}
    </>
  );
}
