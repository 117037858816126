import { IInfoFilter, TaskTypes } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { numberWithCommas } from '@hsjakobsen/utilities';
import { ITaskInformation } from './tasks-utility';

interface ITaskInformationWaterFilter {
  infoFilter: IInfoFilter;
  showEstimate: boolean;
}

export const taskInformationWaterFilter = ({
  infoFilter,
  showEstimate,
}: ITaskInformationWaterFilter): ITaskInformation => {
  const { daysLeft, capacityTotal, capacityUsed, estimatedUsedCapacity, percentageLeft, taskType } = infoFilter;
  const infoList = daysLeft + ' days';

  const infoHeader = 'Water filter task';

  const usage = capacityUsed + (showEstimate ? estimatedUsedCapacity : 0);

  const waterFilterLitersLeft = numberWithCommas((capacityTotal - usage).toFixed(0));
  const infoTooltip =
    taskType === TaskTypes.Time
      ? 'The water filter requires changing, as it has been set up to be changed periodically.'
      : 'The water filter needs to be changed soon, as it has:';
  const filterTaskHighlightUsage = daysLeft + ' days left / ' + waterFilterLitersLeft + ' litres left.';
  const filterTaskHighlightTime = daysLeft + ' days left';

  const infoHighlight = taskType === TaskTypes.Time ? filterTaskHighlightTime : filterTaskHighlightUsage;

  return {
    infoHeader,
    infoHighlight,
    infoList,
    infoTooltip,
    percentage: percentageLeft
  };
};
