import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React from 'react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { defaultModalConfirm } from '../../../../common/defaults';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import InstallationHistoryComponent from '../InstallationHistoryList/History.List';
import FpText from '../../../common/components/FpText/FpText';

interface Props {
  alwaysLeftAlign?: boolean;
}
const InstallationHistoryButton = (props: Props): JSX.Element => {
  const { alwaysLeftAlign } = props;
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const access = useStoreState((state) => state.authModel.access);
  const getInstallationHistory = useStoreActions((actions) => actions.installationDataModel.getInstallationHistory);
  const [showModal, setShowModal] = React.useState(false);
  const handleCloseClick = () => {
    setShowModal(false);
  };

  return (
    <>
      <FpButtonOutlinedAligned
        alwaysLeftAlign={alwaysLeftAlign}
        disabled={!access.installations.manage}
        className={'greyHistoryButton'}
        icon={<CalendarMonthIcon />}
        onClick={() => {
          setShowModal(true);
          getInstallationHistory();
        }}
        label="History"
      />
      <Dialog open={showModal} onClose={handleCloseClick} maxWidth={'lg'}>
        <DialogTitle>
          <FpText variant='h6' componentType='span'>Installation history:</FpText>
          <FpText variant='h6' componentType='span' className='do-not-translate' sx={{ marginLeft: '0.5rem' }}>{baseFilter.infoBasic.name}</FpText>
        </DialogTitle>

        <DialogContent>
          <InstallationHistoryComponent />
        </DialogContent>

        <DialogActions>
          <FpButton color="secondary" onClick={handleCloseClick}>
            {defaultModalConfirm}
          </FpButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default InstallationHistoryButton;
