import { action, thunk, thunkOn } from 'easy-peasy';
import { handleDeleteRequest, handlePostRequest } from '../../server-requests/server-requests';
import { IProductDiscountsModel } from '../types/product-discounts-types';

const productDiscountsModel: IProductDiscountsModel = {
  discountsByProduct: [],
  setDiscountsByProduct: action((state, payload) => {
    state.discountsByProduct = payload;
  }),
  getDiscountsByProduct: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingDiscountByProduct(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: `/product-discounts/by-product/${payload}`,
    });
    actions.setLoadingDiscountByProduct(false);
    if (requestResponse.success) {
      actions.setDiscountsByProduct(requestResponse.data);
    }
  }),

  productAndDiscountInfoForFlowplanCLientsGroup: [],
  setProductAndDiscountInfoForFlowplanClientsGroup: action((state, payload) => {
    state.productAndDiscountInfoForFlowplanCLientsGroup = payload;
  }),
  getProductAndDiscountInfoByFlowplanClientsGroup: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingProductAndDiscountInfoForFlowplanClientsGroup(true);
    const response = await getStoreActions().serverRequestsModel.get({
      route: `/product-discounts/by-group/${payload}`,
    });
    if (response.success) {
      actions.setProductAndDiscountInfoForFlowplanClientsGroup(response.data);
      actions.setLoadingProductAndDiscountInfoForFlowplanClientsGroup(false);
    }
  }),

  availableProductsForOrder: [],
  setAvailableProductsForOrder: action((state, payload) => {
    state.availableProductsForOrder = payload;
  }),
  getAvailableProductsForOrder: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingProductAndDiscountInfoForFlowplanClientsGroup(true);
    const response = await getStoreActions().serverRequestsModel.get({
      route: `/product-discounts/order-items/${payload.flowplanClientsGroupId}/${payload.flowplanClientId}`,
    });
    if (response.success) {
      actions.setAvailableProductsForOrder(response.data);
      actions.setLoadingProductAndDiscountInfoForFlowplanClientsGroup(false);
    }
  }),

  pricelistForProduct: [],
  setPricelistFroProduct: action((state, payload) => {
    state.pricelistForProduct = payload;
  }),
  editPricelistForProduct: action((state, payload) => {
    const priceIndex = state.pricelistForProduct.findIndex((item) => item.id === payload.id);
    if (priceIndex !== -1) {
      state.pricelistForProduct[priceIndex] = payload;
    }
  }),

  pricelistToCreateForProdcut: [],
  setPricelistToCreateForProduct: action((state, payload) => {
    state.pricelistToCreateForProdcut = payload;
  }),
  editPricelistToCreateForProduct: action((state, payload) => {
    const priceIndex = state.pricelistToCreateForProdcut.findIndex((item) => item.currency === payload.currency);
    if (priceIndex !== -1) {
      state.pricelistToCreateForProdcut[priceIndex] = payload;
    }
  }),

  createProductDiscount: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingProductDiscount(true);
    const response = await handlePostRequest(payload, `/product-discounts`, true);
    actions.setLoadingProductDiscount(false);
    return response;
  }),
  batchCreateProductDiscounts: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingProductDiscount(true);
    const response = await handlePostRequest(payload, `/product-discounts/batch`, true);
    actions.setLoadingProductDiscount(false);
    return response;
  }),
  deleteProductDiscount: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingProductDiscount(true);
    const response = await handleDeleteRequest({ id: payload }, `/product-discounts`);
    actions.setLoadingProductDiscount(false);
    return response;
  }),
  deleteProductDiscountsByProduct: thunk(async (actions, payload) => {
    actions.setLoadingProductDiscount(true);
    const response = await handleDeleteRequest(
      { flowplanClientsGroupId: Number(payload.flowplanClientsGroupId) },
      `/product-discounts/by-product/${payload.productId}`,
    );
    actions.setLoadingProductDiscount(false);
    return response;
  }),
  updateProductDiscount: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingProductDiscount(true);
    const requestResponse = await getStoreActions().serverRequestsModel.patch({ data: payload, route: `/product-discounts/` });
    // const response = await handlePatchRequest(payload, , true);
    actions.setLoadingProductDiscount(false);
    return requestResponse;
  }),
  batchUpdateProductDiscounts: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingProductDiscount(true);
    const requestResponse = await getStoreActions().serverRequestsModel.patch({ data: payload, route: `/product-discounts/batch` });
    actions.setLoadingProductDiscount(false);
    return requestResponse;
  }),
  onActionsThatUpdateProductDiscount: thunkOn(
    (actions) => [actions.createProductDiscount, actions.updateProductDiscount, actions.deleteProductDiscount],
    async (actions, target, { getStoreState }) => {
      const selectedClientId = getStoreState().adminDataModel.flowplanClientSelected;
      actions.setLoadingProductAndDiscountInfoForFlowplanClientsGroup(true);
      if (target.result?.success && !target.error) {
        actions.getProductAndDiscountInfoByFlowplanClientsGroup(selectedClientId);
      }
      actions.setLoadingProductAndDiscountInfoForFlowplanClientsGroup(false);
    },
  ),

  updatingProductDiscount: false,
  loadingDiscountsByProduct: false,
  loadingAvailableOrderItems: false,
  loadingProductAndDiscountInfoForFlowplanClientsGroup: false,
  setLoadingProductDiscount: action((state, payload) => {
    state.updatingProductDiscount = payload;
  }),
  setLoadingProductAndDiscountInfoForFlowplanClientsGroup: action((state, payload) => {
    state.loadingProductAndDiscountInfoForFlowplanClientsGroup = payload;
  }),
  setLoadingDiscountByProduct: action((state, payload) => {
    state.loadingDiscountsByProduct = payload;
  }),
  setLoadingAvailableOrderItems: action((state, payload) => {
    state.loadingAvailableOrderItems = payload;
  }),
};

export default productDiscountsModel;
