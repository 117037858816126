import { Color } from '@flowplan/flowplan-shared';
import { numberWithCommas } from '@hsjakobsen/utilities';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { UsageInformation } from '../../../services/company-model-types';
import LowUsageSpacer from '../Common/LowUsageSpacer';
import LowUsageWizardContent from '../Common/LowUsageWizardContent';

type Props = {
  daysSinceLastFlowEvent: number;
  noConsumptionState: boolean;
  usageInformation: UsageInformation;
};

const LowUsageConsumption = ({ daysSinceLastFlowEvent, noConsumptionState, usageInformation }: Props): JSX.Element => {
  const { averageDailyConsumptionHistorically, days, lastFlowEventDate } = usageInformation;

  const historicalAverage = numberWithCommas(averageDailyConsumptionHistorically.toFixed(1));
  const lastFlowEventInfo = noConsumptionState
    ? 'Not found'
    : lastFlowEventDate + ' (' + daysSinceLastFlowEvent + ' days ago)';
  const averageInfo = noConsumptionState ? 'No data found' : historicalAverage + 'L (' + days + ' days average)';
  const estimatedUsageSinceLastFlowEvent = noConsumptionState
    ? 'No consumption found'
    : Math.round(averageDailyConsumptionHistorically * daysSinceLastFlowEvent) + 'L';

  return (
    <LowUsageWizardContent
      title="Water consumption"
      icon={<WaterDropIcon sx={{ height: 60, width: 60, color: Color.FlowplanBlue }} />}
    >
      <span>
        Last flow event: <strong>{lastFlowEventInfo}</strong>
      </span>
      <span>
        Average daily usage: <strong>{averageInfo}</strong>
      </span>
      <LowUsageSpacer height={10} />
      <span>
        Estimated usage since last flow event: <strong>{estimatedUsageSinceLastFlowEvent}</strong>
      </span>
    </LowUsageWizardContent>
  );
};

export default LowUsageConsumption;
