import { notAvailable } from '@flowplan/flowplan-shared';
import QrCodeIcon from '@mui/icons-material/QrCode';
import VisualElementContainer from '../../../../../Components/Containers/VisualElementContainer';
import StatusConnection from '../../../../../Components/ListItems/Status/Status.Connection';
import InstallationsIcon from '../../../../../assetstore/icons/InstallationsIcon';
import FpText from '../../../../common/components/FpText/FpText';
import { BeamInformation } from '../../../services/company-model-types';
import LowUsageWizardContent from '../Common/LowUsageWizardContent';
import styles from './LowUsageBeamInformation.module.scss';

type Props = {
  beamInformation: BeamInformation;
};

const LowUsageBeamInformation = ({ beamInformation }: Props): JSX.Element => {
  const { averageSignalStrength, firmware, hardwareGeneration, hoursNotSeen, lastSeen, serial, status } =
    beamInformation;

  return (
    <LowUsageWizardContent
      title={'Beam ' + hardwareGeneration}
      icon={<InstallationsIcon height={70} width={70} strokeWidth={10} />}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        <QrCodeIcon width={24} height={24} />
        <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
          {serial}
        </FpText>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
          {firmware ? firmware : notAvailable}
        </FpText>
      </div>
      <VisualElementContainer enableFlex={false} extraClass={styles.connectionStatus}>
        <StatusConnection
          status={status}
          hoursNotSeen={hoursNotSeen}
          showIcon={true}
          signalStrength={averageSignalStrength}
        />
      </VisualElementContainer>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <FpText variant="body1">Last seen:</FpText>
        <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
          {lastSeen}
        </FpText>
      </div>
    </LowUsageWizardContent>
  );
};

export default LowUsageBeamInformation;
