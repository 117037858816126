import { Icon } from 'semantic-ui-react';
import FilterStatus from '../../../../Components/ImageComponents/FilterStatus';
import { IFilterInformation } from '../../types/details/installation-details-filter';
import { IMaintenanceInformation } from '../../types/details/installation-details-maintenance';
import DetailsItem from '../Common/DetailsItem';
import DetailsSection from '../Common/DetailsSection';
import InstallationDetailsFeaturesComponent from './InstallationDetailsFeaturesComponent';

interface IInstallationDetailsFeatures {
  filterInformation: IFilterInformation;
  serviceInformation: IMaintenanceInformation;
}

const InstallationDetailsFeatures = ({
  filterInformation,
  serviceInformation,
}: IInstallationDetailsFeatures): JSX.Element => {
  return (
    <DetailsSection sectionTitle="Features">
      <InstallationDetailsFeaturesComponent
        showEstimate={filterInformation.showEstimate}
        usage={filterInformation.usage}
        daysRemaining={filterInformation.daysRemaining}
        featureEnabled={filterInformation.featureEnabled}
        featureName="Water filter"
        lastDate={filterInformation.installDate}
        nextDate={filterInformation.changeDate}
        percentageLeft={filterInformation.percentageLeft}
        remaining={filterInformation.capacityRemaining}
        total={filterInformation.capacityTotal}
        estimatedUsage={filterInformation.usageEstimated}
        unit="L"
        unitHeadersOnly={false}
        taskIcon={
          <FilterStatus
            disableTooltip={false}
            showAsFilter={false}
            percentageStatus={100 - filterInformation.percentageLeft}
            centered={true}
          />
        }
      >
        <DetailsItem title="Filter type" value={filterInformation.filterInfo} customStyle="less-margin">
          <FilterStatus disableTooltip={true} showAsFilter={true} percentageStatus={1} centered={true} />
        </DetailsItem>
      </InstallationDetailsFeaturesComponent>
      <InstallationDetailsFeaturesComponent
        daysRemaining={serviceInformation.daysRemaining}
        usage={serviceInformation.usage}
        featureEnabled={serviceInformation.featureEnabled}
        featureName="Maintenance"
        lastDate={serviceInformation.lastChangeDate}
        nextDate={serviceInformation.changeDate}
        percentageLeft={serviceInformation.percentageLeft}
        remaining={serviceInformation.amountRemaining}
        estimatedUsage={serviceInformation.amountRemainingEstimate}
        showEstimate={serviceInformation.showEstimate}
        total={serviceInformation.amountTotal}
        unit={serviceInformation.cycleBasedMaintenance ? 'Cups' : 'L'}
        unitHeadersOnly={serviceInformation.cycleBasedMaintenance}
        taskIcon={<Icon name="wrench" size="big" color={serviceInformation.percentageLeft < 0 ? 'red' : 'black'} />}
      ></InstallationDetailsFeaturesComponent>
    </DetailsSection>
  );
};

export default InstallationDetailsFeatures;
