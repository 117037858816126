import { formatLiters, formatNumberNoDecimals } from '@flowplan/flowplan-shared';
import { IInfoFilter } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { getDateObject } from '../../../../Utility/time';

export interface IFilterInformation {
  capacityRemaining: string;
  capacityTotal: string;
  changeDate: string;
  daysRemaining: number;
  featureEnabled: boolean;
  filterInfo: string;
  installDate: string;
  percentageLeft: number;
  usage: number;
  usageEstimated: number;
  showEstimate: boolean;
}

type CollectFilterInfo = {
  infoFilter: IInfoFilter;
  showEstimate: boolean;
}

const collectFilterInformation = ({ infoFilter, showEstimate }: CollectFilterInfo): IFilterInformation => {
  const { capacityTotal, capacityUsed, dayOfLastChange, daysLeft, estimatedUsedCapacity, featureEnabled, filterName, filterProducerName, lastFilterInstallDate, percentageLeft, } = infoFilter;

  const capacityRemaining: number = capacityTotal - capacityUsed - (showEstimate ? estimatedUsedCapacity : 0);
  const capacityRemainingPretty = formatLiters(capacityRemaining);

  return {
    capacityRemaining: capacityRemainingPretty,
    capacityTotal: formatNumberNoDecimals(capacityTotal),
    daysRemaining: daysLeft,
    changeDate: getDateObject(dayOfLastChange).format(dateFormatYMD),
    featureEnabled,
    installDate: getDateObject(lastFilterInstallDate).format(dateFormatYMD),
    percentageLeft: 100 - percentageLeft,
    filterInfo: filterProducerName + ' ' + filterName,
    usage: capacityUsed,
    usageEstimated: estimatedUsedCapacity,
    showEstimate
  };
};

export { collectFilterInformation };
