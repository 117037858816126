import { IFilter } from '@flowplan/flowplan-shared';
import InputComponentToggle from '../../../../../../Components/Input/InputComponentToggle';
import { useStoreState } from '../../../../../../Store';
import FpSelectSearch from '../../../../../common/components/FpSelectSearch/FpSelectSearch';
import { getWaterHardnessType } from '../../../../../locations/locations-texts';

type Props = {
  onSelection(value: number): void;
  showCustomFilter: boolean;
  value: number;
  waterFilters: IFilter[];
  translateOptions?: boolean
};

const FlowplanFilterSelection = ({ onSelection, showCustomFilter, value, waterFilters, translateOptions = true }: Props): JSX.Element => {
  const companyHardnessUnit = useStoreState((state) => state.companyModel.companyHardnessUnit);

  return (
    <InputComponentToggle hideComponent={showCustomFilter}>
      <FpSelectSearch
        translateOptions
        isDisabled={showCustomFilter}
        label="Filter model*"
        options={waterFilters.map((filter) => {
          const hardnessToShow =
            filter.id > 0
              ? '(' +
              getWaterHardnessType({
                hardnessUnit: companyHardnessUnit,
                includeLaymansTerm: true,
                spaceInFront: false,
                filterSofteningType: filter.totalSoftening,
              }) +
              ')'
              : '';

          return {
            name: filter.name + ' ' + hardnessToShow,
            value: filter.id,
          };
        })}
        selectedValue={value}
        setSelectionValue={onSelection}
      />
      {/* <DropdownList
        inputValue={value}
        inputKey={InputKeys.FilterId}
        inputText="Filter model*"
        handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
          const value = ev.target.value;
          onSelection(Number(value));
        }}
        isRequired={true}
      >
        <OptionFilters filters={filtersList} hardnessUnit={companyHardnessUnit} />
      </DropdownList> */}
    </InputComponentToggle>
  );
};

export default FlowplanFilterSelection;
