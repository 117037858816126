import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

export type modalSize = 'small' | 'mini' | 'tiny' | 'large' | 'fullscreen' | undefined;
export type sizeChoices = 'small' | 'mini' | 'tiny' | 'large' | 'fullscreen';
export const defaultModalSize = 'large';
export const defaultModalCancel = 'Cancel';
export const defaultModalConfirm = 'Confirm';
export const defaultModalButtonColor: SemanticCOLORS = 'green';
export const defaultModalClose = 'Close';
export const defaultMaxLength = 10;
export const defaultCallback = (): void => {
  /* default */
};
