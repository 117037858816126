import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { getDateObject } from '../../../../Utility/time';
import { filenameSeperator } from '../../../../common/constsFrontend';
import { FpColumnHeader } from '../../../installations/components/Details/installation-details-export';
import { IFilterNotification, IFormattedInstallationHistory } from '../../installation-history-interfaces';
import { formatInstallationHistoryData } from './History.Item.Data';

interface IExportFilterHistory {
  baseFilter: IDeviceView;
  companyId: number;
  notificationHistory: IFilterNotification[];
}

export const exportFilterHistory = ({ baseFilter, companyId, notificationHistory }: IExportFilterHistory): void => {
  if (baseFilter.infoBasic.id === 0) {
    return;
  }
  const documentTitle =
    'Installation history' +
    filenameSeperator +
    getDateObject(null).format(dateFormatYMD) +
    filenameSeperator +
    baseFilter.infoBasic.name;

  const headers: FpColumnHeader[] = [
    { displayLabel: 'Time of event', key: 'timeStamp' },
    { displayLabel: 'Event type', key: 'notificationText' },
    { displayLabel: 'Additional info', key: 'extra' },
    { displayLabel: 'User', key: 'actor' },
    { displayLabel: 'Note', key: 'note' }
  ];

  const formattedContentToExport: IFormattedInstallationHistory[] = [];
  notificationHistory.forEach((dataSet) => {
    const processedDataSet = formatInstallationHistoryData(companyId, dataSet, baseFilter, true);
    formattedContentToExport.push(processedDataSet);
  });

  if (formattedContentToExport.length === 0) {
    alert('No data to export');
    return;
  }

  const config = mkConfig({
    columnHeaders: headers,
    showColumnHeaders: true,
    useKeysAsHeaders: false,
    filename: documentTitle,
    title: documentTitle
  });

  const csv = generateCsv(config)(formattedContentToExport);
  download(config)(csv);
};
