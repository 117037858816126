import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import styles from './ButtonDeviceTagging.module.scss';
import { getDeviceTypeImage } from './device-type-image';
import { getCategoryColor, getCategoryFromType } from './utility-category-and-type';

interface IButtonDeviceTagging {
  inputKey: string;
  inputValue: number;
  inputText: string;
  selectionValue: number;
  showImage: boolean;
  handleSelection(inputKey: string, inputValue: number): void;
}

const ButtonDeviceTagging = ({
  handleSelection,
  inputKey,
  inputText,
  inputValue,
  selectionValue,
  showImage,
}: IButtonDeviceTagging): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let imageComponent = <Fragment />;
  const isActive = inputValue === selectionValue;
  if (showImage) {
    const typeImageToShow = getDeviceTypeImage(inputValue);
    const imageClassName = styles.buttonImage + ' ' + (isActive ? styles.activeSelectionImage : '');
    imageComponent = (
      <Box
        component="img"
        className={imageClassName}
        sx={{
          height: '100%',
          width: '100%',
        }}
        alt=""
        src={typeImageToShow}
      />
    );
  }

  const spanClass = showImage ? styles.spanType : 'span-category';
  let buttonClass = showImage ? styles.buttonType : styles.buttonCategory;
  if (!showImage) {
    buttonClass += isActive ? '' : styles.fadedButtonGraphSection;
  }
  const categoryId = showImage ? getCategoryFromType(inputValue) : inputValue;
  const colorToShow = !showImage || isActive ? getCategoryColor(categoryId) : undefined;

  return (
    <Button
      color={colorToShow}
      className={buttonClass}
      fluid={isSmallScreen && !showImage}
      active={isActive}
      onClick={() => {
        handleSelection(inputKey, inputValue);
      }}
    >
      <div className={styles.labelInfoDiv}>
        {imageComponent}
        <span className={spanClass}>{inputText}</span>
      </div>
    </Button>
  );
};

export default ButtonDeviceTagging;
