import { Divider } from '@mui/material';
import { useStoreActions, useStoreState } from '../../../../../../Store';
import FpSelectSearch from '../../../../../common/components/FpSelectSearch/FpSelectSearch';

const FlowplanLocationSelection = (): JSX.Element => {
  const waterFilterLocationsDropdown = useStoreState((state) => state.locationsModel.waterFilterLocationsDropdown);
  const setInputLocationId = useStoreActions((actions) => actions.newInstallationModel.setInputLocationId);
  const inputLocationId = useStoreState((state) => state.newInstallationModel.inputLocationId);

  const evaluateName = useStoreActions((actions) => actions.newInstallationModel.evaluateName);
  const setManualChange = useStoreActions((actions) => actions.newInstallationModel.setManualChange);

  return (
    <>
      <Divider sx={{ height: 20 }} />
      <FpSelectSearch
        label="Select location"
        translateOptions={false}
        options={waterFilterLocationsDropdown}
        selectedValue={inputLocationId}
        setSelectionValue={(value) => {
          evaluateName();
          setManualChange(true);
          setInputLocationId(Number(value));
        }}
      />
    </>
  );
};

export default FlowplanLocationSelection;
