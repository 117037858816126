import { CompanyTag } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../Store';
import FpButtonRadio from '../../../common/components/FpButtonRadio/FpButtonRadio';
import {
  canUserModifyOrDeleteCompanyTag,
  ColorOptionsForButtons,
  ColorOptionsNames,
  getColorForButton,
  getColorOptionsForUser,
  parseStringAsColorOption,
  TagActionType,
} from '../../../installations/components/Tags/tags-util';
import { AnchorOptions } from './InstallationTags';
import TagActionModal from './TagActionModal';
import { MENU_ITEM_STYLES, NOTIFICATION_MESSAGES } from './tags-util';

interface CompanyTagOptionsProps {
  tag: CompanyTag;
  anchorOptions: AnchorOptions;
  closeMenu(): void;
}

const CompanyTagOptions = ({ anchorOptions, closeMenu, tag }: CompanyTagOptionsProps): JSX.Element => {
  const currentUser = useStoreState((state) => state.authModel.currentUser);

  const actions = useStoreActions((actions) => ({
    removeTag: actions.companyModel.removeTagForCompany,
    updateTag: actions.companyModel.updateTagForCompany,
    getInstallationCount: actions.companyModel.getInstallationCountByTag,
  }));

  const [initialColor, setInitialColor] = useState<ColorOptionsForButtons>(
    parseStringAsColorOption(tag.color) || ColorOptionsNames.Grey,
  );
  const [initialTagName, setInitialTagName] = useState<string>(tag.name);
  const [selectedColor, setSelectedColor] = useState<ColorOptionsForButtons>(initialColor);
  const [tagName, setTagName] = useState<string>(tag.name);
  const [installationCount, setInstallationCount] = useState<number | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [tagActionType, setTagActionType] = useState<TagActionType>(TagActionType.DELETE_REGULAR);

  const isGlobalTag = tag.flowplanClientId === null;
  const canModify = canUserModifyOrDeleteCompanyTag(currentUser.role, tag.flowplanClientId);
  const canDelete = !isGlobalTag && canModify;
  const colorOptions = getColorOptionsForUser(currentUser.role);
  const open = Boolean(anchorOptions.anchorEl) && anchorOptions.label === tag.name;
  const changesMade =
    anchorOptions.label === tag.name && (initialTagName !== tagName || initialColor !== selectedColor);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const currentColor = parseStringAsColorOption(tag.color) || ColorOptionsNames.Grey;
    setInitialTagName(tag.name);
    setTagName(tag.name);
    setInitialColor(currentColor);
    setSelectedColor(currentColor);
  }, [tag]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setTagName(event.target.value);

  const handleTagChange = async () => {
    if (!canModify || !changesMade) return;

    const colorCode = selectedColor !== initialColor ? getColorForButton(selectedColor) : tag.color;
    const response = await actions.updateTag({
      tagId: tag.id,
      color: colorCode,
      name: tagName,
    });

    const variant: VariantType = response.success ? 'success' : 'error';
    const message = response.success ? NOTIFICATION_MESSAGES.tagUpdated : NOTIFICATION_MESSAGES.tagUpdateFailed;
    enqueueSnackbar(message, { variant });

    if (response.success) closeMenu();
  };

  const fetchInstallationCount = async (tagId: number) => {
    setTagActionType(TagActionType.LOADING);

    try {
      const response = await actions.getInstallationCount(tagId);

      if (response.success) {
        setInstallationCount(response.data.count);

        if (isGlobalTag) {
          setTagActionType(TagActionType.DELETE_BLOCKED_GLOBAL);
        } else if (response.data.count > 1) {
          setTagActionType(TagActionType.DELETE_BLOCKED_MULTIPLE_USAGE);
        } else {
          setTagActionType(TagActionType.DELETE_REGULAR);
        }
      } else {
        setInstallationCount(0);
        setTagActionType(TagActionType.DELETE_REGULAR);
        enqueueSnackbar(NOTIFICATION_MESSAGES.countFetchFailed, { variant: 'error' });
      }
    } catch (error) {
      setInstallationCount(0);
      setTagActionType(TagActionType.DELETE_REGULAR);
      console.error('Error fetching installation count:', error);
    }
  };

  const handleShowDeleteModal = async () => {
    if (!canModify) return;

    setInstallationCount(null);
    setShowDeleteModal(true);
    await fetchInstallationCount(tag.id);
  };

  const handleTagDelete = async () => {
    if (isGlobalTag || !canDelete || !tag.id) {
      const message = isGlobalTag
        ? NOTIFICATION_MESSAGES.globalTagDeletion
        : !canDelete
        ? NOTIFICATION_MESSAGES.permissionError
        : NOTIFICATION_MESSAGES.tagNotSelected;

      enqueueSnackbar(message, { variant: 'error' });
      return;
    }

    if (installationCount && installationCount > 1) {
      enqueueSnackbar(NOTIFICATION_MESSAGES.multipleUsage(installationCount), {
        variant: 'warning',
      });
      return;
    }

    const response = await actions.removeTag(tag.id);
    const variant: VariantType = response.success ? 'success' : 'error';
    const message = response.success
      ? NOTIFICATION_MESSAGES.tagDeleted
      : NOTIFICATION_MESSAGES.tagDeleteFailed(tag.name, response.message);

    enqueueSnackbar(message, { variant });
    setShowDeleteModal(false);
    closeMenu();
  };

  const handleConfirmAction = () => {
    if (tagActionType === TagActionType.DELETE_REGULAR) {
      handleTagDelete();
    } else {
      setShowDeleteModal(false);
    }
  };

  if (!canModify) return <Fragment />;

  const globalTagNotice = isGlobalTag && (
    <MenuItem
      sx={{ cursor: 'default', padding: 0, ...MENU_ITEM_STYLES.noHover }}
      onClick={(e) => e.stopPropagation()}
      disableRipple
    >
      <Box
        sx={{
          backgroundColor: '#fff8e1',
          border: '1px solid #ffe082',
          padding: '12px 16px',
          borderRadius: '4px',
          marginBottom: '8px',
          display: 'flex',
          alignItems: 'flex-start',
          gap: '12px',
          width: '100%',
        }}
      >
        <WarningIcon color="warning" />
        <Typography variant="body2">Only FlowPlan admins can delete this tag.</Typography>
      </Box>
    </MenuItem>
  );

  return (
    <>
      <Menu
        id="tag-menu"
        anchorEl={anchorOptions.anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{ 'aria-labelledby': 'tag-menu-button' }}
      >
        {globalTagNotice}

        <MenuItem
          sx={{ cursor: 'default', ...MENU_ITEM_STYLES.noHover }}
          onClick={(e) => e.stopPropagation()}
          disableRipple
        >
          <TextField
            value={tagName}
            onChange={handleNameChange}
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Tag Name"
            onClick={(e) => e.stopPropagation()}
            disabled={!canModify}
          />
        </MenuItem>

        <MenuItem
          disabled={!canModify}
          sx={{ ...MENU_ITEM_STYLES.disabled, ...MENU_ITEM_STYLES.noHover }}
          disableRipple
        >
          <FpButtonRadio options={colorOptions} selectedValue={selectedColor} setSelectedValue={setSelectedColor} />
        </MenuItem>

        <MenuItem
          disabled={!canModify || !changesMade}
          onClick={handleTagChange}
          sx={MENU_ITEM_STYLES.conditionalHover(!canModify || !changesMade)}
        >
          <ListItemIcon>
            <CheckCircleOutlineIcon color="secondary" />
          </ListItemIcon>
          <ListItemText>Save changes</ListItemText>
        </MenuItem>

        <MenuItem
          disabled={!canDelete}
          onClick={handleShowDeleteModal}
          sx={MENU_ITEM_STYLES.conditionalHover(!canDelete)}
        >
          <ListItemIcon>
            <DeleteIcon color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <TagActionModal
        showModal={showDeleteModal}
        tagName={tag.name}
        tagId={tag.id}
        actionType={tagActionType}
        installationCount={installationCount || 0}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmAction}
      />
    </>
  );
};

export default CompanyTagOptions;
