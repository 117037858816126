import { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import { IInstallationInformation } from '../../types/details/installation-details-installation';
import DetailsItem from '../Common/DetailsItem';
import ExternalSensorHistoryComponentButton from '../ExternalSensor/ExternalSensorHistoryButton';

const InstallationDetailsExternalSensor = ({
  externalSensorData,
  externalMeasuringUnit,
  externalSensorId,
}: IInstallationInformation): JSX.Element => {
  return (
    <Fragment>
      {externalSensorData && (
        <DetailsItem
          title="Latest external sensor measurement"
          infoPopup={<ExternalSensorHistoryComponentButton />}
          value={externalSensorData + externalMeasuringUnit}
        >
          <Icon name="thermometer half" size="big" />
        </DetailsItem>
      )}
      {!externalSensorData && (
        <DetailsItem title="External sensor" value={externalSensorId > 0 ? 'N/A' : 'Not Attached'}>
          <Icon name="thermometer half" size="big" />
        </DetailsItem>
      )}
    </Fragment>
  );
};

export default InstallationDetailsExternalSensor;
