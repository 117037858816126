import { Dialog, DialogActions, DialogContent, DialogSubtitle, DialogTitle } from '../../modules/common/components';
import FpButton from '../../modules/common/components/FpButton/FpButton';
import { useStoreActions, useStoreState } from '../../Store';
import './ModalStyles.scss';

const ConfirmSimpleModal = (): JSX.Element => {
  const confirmSimpleCallback = useStoreState((state) => state.modalModel.confirmSimpleCallback);
  const confirmSimpleCancelLabel = useStoreState((state) => state.modalModel.confirmSimpleCancelLabel);
  const confirmSimpleConfirmLabel = useStoreState((state) => state.modalModel.confirmSimpleConfirmLabel);
  const confirmSimpleContent = useStoreState((state) => state.modalModel.confirmSimpleContent);
  const confirmSimpleShow = useStoreState((state) => state.modalModel.confirmSimpleShow);
  const confirmSimpleTitle = useStoreState((state) => state.modalModel.confirmSimpleTitle);
  const confirmSimpleToggle = useStoreActions((actions) => actions.modalModel.confirmSimpleToggle);

  const handleCancel = () => {
    confirmSimpleToggle({ content: undefined, showModal: false, title: '' });
  };

  const handleConfirm = () => {
    confirmSimpleCallback();
    handleCancel();
  };

  return (
    <Dialog open={confirmSimpleShow} onClose={handleCancel} maxWidth={'lg'}>
      <DialogTitle>{confirmSimpleTitle}</DialogTitle>

      <DialogContent>
        <DialogSubtitle>{confirmSimpleContent}</DialogSubtitle>
      </DialogContent>
      <DialogActions>
        <FpButton color="error" onClick={handleCancel}>
          {confirmSimpleCancelLabel}
        </FpButton>
        <FpButton color="secondary" onClick={handleConfirm}>
          {confirmSimpleConfirmLabel}
        </FpButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSimpleModal;
