import { StockTypes } from '@flowplan/flowplan-shared/lib/stock/stock';
import _ from 'lodash';
import React, { ChangeEvent, Fragment } from 'react';
import Checkbox from '../../../../Components/Input/Checkbox';
import TextInput from '../../../../Components/Input/TextInput';
import { getStockMeasurementUnit } from '../../stock-utils';
import { IStockTypeSelection } from './StockFeatureSelection';

interface StockFeatureSelectionProps {
  stockType: StockTypes;
  stockItem: IStockTypeSelection | undefined;
  stockSelectionHandler: (stockType: IStockTypeSelection, selected: boolean) => void;
}

const StockFeatureSelectionOption = ({
  stockItem,
  stockType,
  stockSelectionHandler,
}: StockFeatureSelectionProps): JSX.Element => {
  const selected = Boolean(stockItem);
  const measurementUnit = getStockMeasurementUnit(stockType);
  const inputText = 'Current stock (' + measurementUnit + ')';
  return (
    <Fragment>
      <Checkbox
        inputValue={selected}
        wideFormat={true}
        inputKey={'manageStockCoffee'}
        inputText={'Manage ' + _.capitalize(stockType)}
        handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
          const value = ev.target.checked;
          stockSelectionHandler({ stockType, totalAmount: 0 }, value);
        }}
      />

      <TextInput
        disabled={!selected}
        inputValue={stockItem?.totalAmount || ''}
        inputKey={stockType + 'stockAmount'}
        inputText={inputText}
        handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
          const amount = Number(ev.target.value);
          stockSelectionHandler({ stockType, totalAmount: amount }, true);
        }}
      />
    </Fragment>
  );
};

export default StockFeatureSelectionOption;
