import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { SyntheticEvent } from 'react';
import { Button } from 'semantic-ui-react';
import ComponentRow from '../../../../../../Components/Containers/ComponentRow';
import DisabledInput from '../../../../../../Components/Input/DisabledInput';
import { useStoreActions, useStoreState } from '../../../../../../Store';
import { getDateObject } from '../../../../../../Utility/time';
import FpDatePicker from '../../../../../common/components/FpDatePicker';
import FpDivider from '../../../../../common/components/FpDivider/FpDivider';
import styles from './MaintenanceDate.module.scss';

const FlowplanMaintenanceStartDateSelection = (): JSX.Element => {
  const inputServiceStartDate = useStoreState((state) => state.newInstallationModel.inputServiceStartDate);
  const serviceMinDate = useStoreState((state) => state.newInstallationModel.serviceMinDate);
  const isUpdate = useStoreState((state) => state.newInstallationModel.isUpdate);

  const setInputServiceStartDate = useStoreActions((actions) => actions.newInstallationModel.setInputServiceStartDate);

  const today = getDateObject(null);

  const setDateToToday = (event: SyntheticEvent) => {
    setInputServiceStartDate(today);
  };

  const setDateToInstallDate = (event: SyntheticEvent) => {
    if (serviceMinDate !== null) {
      setInputServiceStartDate(serviceMinDate);
    }
  };

  return (
    <div className={styles.inputDropdown}>
      <FpDivider hidden height="0.25rem" marginY="0.5rem" />
      <ComponentRow>
        <FpDatePicker
          disableFuture
          size="small"
          label="Date to start maintenance from"
          fullWidth={false}
          minDate={serviceMinDate || undefined}
          onChange={(value) => {
            setInputServiceStartDate(value || getDateObject(null));
          }}
          value={inputServiceStartDate}
        />

        <div className={styles.spacer} />
        <Button
          disabled={today.format(dateFormatYMD) === inputServiceStartDate.format(dateFormatYMD)}
          compact
          onClick={setDateToToday}
        >
          Today
        </Button>
        {isUpdate && serviceMinDate !== null && (
          <Button
            disabled={serviceMinDate.format(dateFormatYMD) === inputServiceStartDate.format(dateFormatYMD)}
            compact
            onClick={setDateToInstallDate}
          >
            Install date
          </Button>
        )}
      </ComponentRow>
      {isUpdate && serviceMinDate !== null && (
        <DisabledInput inputText="Created in Flowplan date" inputValue={serviceMinDate.format(dateFormatYMD)} />
      )}
    </div>
  );
};

export default FlowplanMaintenanceStartDateSelection;
