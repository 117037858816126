import { ButtonBase, styled } from '@mui/material';
import { ReactNode } from 'react';

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0,
  transition: theme.transitions.create('opacity'),
}));

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important',
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    '& .MuiImageBackdrop-root': {
      opacity: 0.4,
    },
  },
}));

interface ImageButtonProps {
  imageSrc: string;
  altText: string;
  onClick: () => void;
  isSelected: boolean;
  children?: ReactNode;
  buttonStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
}

const FpImageButton = ({
  imageSrc,
  altText,
  onClick,
  isSelected = false,
  children,
  buttonStyle,
  imageStyle,
}: ImageButtonProps): JSX.Element => {
  return (
    <StyledButton
      focusRipple
      onClick={onClick}
      style={{
        borderRadius: '5px',
        border: '2px solid #38A7DF',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: isSelected ? '#e0e0e0' : 'white',
        ...buttonStyle,
      }}
    >
      <img
        src={imageSrc}
        alt={altText}
        style={{
          width: '100%',
          height: 'inherit',
          objectFit: 'contain',
          scale: '0.85',
          ...imageStyle,
        }}
        loading="lazy"
      />
      {children}
      <ImageBackdrop className="MuiImageBackdrop-root" />
    </StyledButton>
  );
};

export default FpImageButton;
