import { Checkbox, FormControlLabel, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { Dialog, DialogActions, DialogContent, DialogSubtitle, DialogTitle } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import { ModalFlowStep } from '../../../company/services/company-model';

const file = './terms-and-conditions.pdf';

const TermsAndConditionsDialog: FunctionComponent = (): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const modalFlowStep = useStoreState((state) => state.companyModel.modalFlowStep);
  const acceptTermsAndConditions = useStoreActions((actions) => actions.companyModel.acceptTermsAndConditions);
  const [checked, setChecked] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleClick = async () => {
    const requestResponse = await acceptTermsAndConditions();
    if (requestResponse.success) {
      enqueueSnackbar('You have accepted Flowplan terms and conditions', { variant: 'success' });
    } else {
      enqueueSnackbar('An unexpected error has occurred.', { variant: 'error' });
    }
  };

  return (
    <Dialog
      open={modalFlowStep === ModalFlowStep.TermsAndConditions}
      maxWidth="xl"
      onClose={() => {
        return;
      }}
      showCloseButton={false}
    >
      <DialogTitle>Terms and Conditions</DialogTitle>
      <DialogSubtitle>
        In order to use Flowplan Backoffice platform, Read and accept the following terms and conditions
      </DialogSubtitle>
      <DialogContent>
        <iframe title="Contract" src={file} style={{ width: '100%', height: fullScreen ? '500px' : '1200px' }} />
        <FormControlLabel
          control={<Checkbox onChange={handleChange} checked={checked} />}
          label="Agree to Terms and Conditions"
        />
      </DialogContent>

      <DialogActions>
        <FpButton onClick={handleClick} disabled={!checked}>
          Accept
        </FpButton>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
