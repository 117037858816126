import { StockTypes } from '@flowplan/flowplan-shared/lib/stock/stock';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

export const getStockMeasurementUnit = (stockType: StockTypes): string => {
  const measurementUnit = stockType === StockTypes.COFFEE ? 'kg' : 'liters';
  return measurementUnit;
};

export const getStockColor = (stockType: StockTypes): SemanticCOLORS => {
  const color = stockType === StockTypes.COFFEE ? 'brown' : 'blue';
  return color;
};
