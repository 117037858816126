import React, { ReactNode } from 'react';
import { Grid } from 'semantic-ui-react';
import styles from './GraphSectionUsageElement.module.scss';

interface IUsageElement {
  boldTitle?: boolean;
  children?: ReactNode;
  title: string;
  tooltipInfo?: React.ReactNode;
  titleStyle?: string;
}

const GraphSectionUsageElement = ({
  boldTitle,
  children,
  title,
  titleStyle,
  tooltipInfo,
}: IUsageElement): JSX.Element => {
  const titleToShow = boldTitle ? <strong>{title}</strong> : title;

  return (
    <>
      <Grid.Column className={styles.usageElementColumn}>
        <div className={styles.usageElementTitle}>
          <p className={titleStyle}>
            {titleToShow}
            {tooltipInfo}
          </p>
        </div>
      </Grid.Column>
      <Grid.Column className={styles.usageElementColumn}>
        <div className={styles.usageElementData}>{children}</div>
      </Grid.Column>
    </>
  );
};

export default GraphSectionUsageElement;
