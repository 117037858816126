import { action, thunk, thunkOn } from 'easy-peasy';
import {
  handleDeleteRequest,
  handleDeleteRequestWithBody,
  handlePostRequest,
  handlePutRequest,
} from '../../server-requests/server-requests';
import { IProductsModel } from '../types/products-types';

const productsModel: IProductsModel = {
  loadingProducts: false,
  setLoadingProducts: action((state, payload) => {
    state.loadingProducts = payload;
  }),
  products: [],
  setProducts: action((state, payload) => {
    state.products = payload;
  }),

  getProducts: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingProducts(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: `/products/` });
    actions.setLoadingProducts(false);
    if (requestResponse.success) {
      actions.setProducts(requestResponse.data);
    }
  }),

  createProduct: thunk(async (actions, payload) => {
    const requestResponse = await handlePostRequest(payload, '/products/', true);
    return requestResponse;
  }),

  updateProduct: thunk(async (actions, payload) => {
    const requestResponse = await handlePutRequest(payload, '/products/', true);
    return requestResponse;
  }),
  updateProductPriority: thunk(async (actions, payload) => {
    const response = await handlePostRequest(payload, '/products/priority', true);
    return response;
  }),

  deleteProduct: thunk(async (actions, payload) => {
    const requestResponse = await handleDeleteRequest({ id: payload }, '/products/');
    return requestResponse;
  }),

  uploadProductImage: thunk(async (actions, payload) => {
    const requestResponse = await handlePostRequest(payload, '/s3/product-upload', true);
    return requestResponse;
  }),

  deleteProductImage: thunk(async (actions, payload, { getStoreActions }) => {
    const response = await handleDeleteRequestWithBody({ url: payload }, '/s3/');
    return response;
  }),

  onActionsThatUpdateProducts: thunkOn(
    (actions, storeActions) => [actions.createProduct, actions.updateProduct, actions.deleteProduct],
    async (actions, payload) => {
      actions.getProducts();
    },
  ),
};

export default productsModel;
