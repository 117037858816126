import { LocationSearchSettings } from '@flowplan/flowplan-shared/lib/interfaces/notificationDb';
import { Languages, RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { getUserType } from '@flowplan/flowplan-shared/lib/utils/texts';
import { FormLabel, Switch } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { IUserInput, IUserInputNotificationSettings } from '../../../common/interfacesInput';
import Feedback from '../../../Components/Feedback/Feedback';
import DisabledInput from '../../../Components/Input/DisabledInput';
import TextInput from '../../../Components/Input/TextInput';
import AddItemModal from '../../../Components/Modals/AddItemModal';
import { usePasswordValidation } from '../../../hooks/PasswordValidation';
import { useStoreActions, useStoreState } from '../../../Store';
import { getPasswordText } from '../../../Utility/Texts';
import PasswordChecks from '../../authentication/components/PasswordChecks/PasswordChecks';
import FpText from '../../common/components/FpText/FpText';
import FpTooltip from '../../common/components/FpTooltip/FpTooltip';
import NotificationSettings from '../../users/components/NotificationSettings/NotificationSettings';
import { isUserGuest } from '../../users/types/users-configs';
import { UserProfile } from '../types/user-profile-types';
import { useWeglot } from 'react-weglot';
import { weglotAPIKey } from '../../../config';
import { languageToWeglotValueMap } from '../../weglot/languageOptions';
import LanguagePicker from '../../weglot/LanguagePicker';
import { useNavigate } from 'react-router-dom';

type Props = {
  isEditModalVisible: boolean;
  onClose: () => void;
  modalUserData: UserProfile | undefined;
};

const EditUser = ({ isEditModalVisible, onClose, modalUserData }: Props): JSX.Element => {
  const newUserErrorMessage = useStoreState((state) => state.usersModel.newUserErrorMessage);

  const setNewUserErrorMessage = useStoreActions((actions) => actions.usersModel.setNewUserErrorMessage);
  const updateUser = useStoreActions((actions) => actions.usersModel.updateUser);

  const [userInfo, setUserInfo] = useState<IUserInput>({
    id: 0,
    inputClientId: null,
    inputCurrentPassword: '',
    inputEmail: '',
    inputName: '',
    inputNewPassword: '',
    inputNewPasswordRepeat: '',
    inputUsername: '',
    isUpdate: false,
    inputUserType: RoleType.ADMIN,
    inputUserLanguage: Languages.ENGLISH
  });
  const [userPreferences, setUserPreferences] = useState({
    inputAlertEmails: true,
    inputAlertLeaks: true,
    inputConfirmationEmails: true,
    locationSearch: LocationSearchSettings.ClientName,
  });

  const [showPasswordChecks, setShowPasswordChecks] = useState(false);

  const userLanguageSetting = languageToWeglotValueMap.get(modalUserData?.language ?? 'English')
  const [lang, setLang] = useWeglot(weglotAPIKey || '', userLanguageSetting);
  const navigate = useNavigate()

  useEffect(() => {
    setShowPasswordChecks(false);
    if (!modalUserData) {
      return;
    }
    setUserInfo({
      id: modalUserData.id,
      inputCurrentPassword: '',
      inputEmail: modalUserData.email,
      inputName: modalUserData.name,
      inputNewPassword: '',
      inputNewPasswordRepeat: '',
      inputUsername: modalUserData.username,
      inputUserType: modalUserData.role,
      inputUserLanguage: modalUserData.language,
      isUpdate: true,
    });
    setUserPreferences({
      inputAlertEmails: modalUserData.notificationSettings.alertEmails,
      inputAlertLeaks: modalUserData.notificationSettings.alertLeaks,
      inputConfirmationEmails: modalUserData.notificationSettings.confirmationEmails,
      locationSearch: modalUserData.notificationSettings.locationSearch,
    });
  }, [modalUserData, isEditModalVisible]);

  const handleTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'inputCurrentPassword') {
      setShowPasswordChecks(value.length > 0);
    }
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleNotificationChange = (event: ChangeEvent<HTMLInputElement>, inputKey: string) => {
    const value = event.target.checked;

    // Handle special case for locationSearch
    if (inputKey === 'locationSearch') {
      setUserPreferences({
        ...userPreferences,
        locationSearch: event.target.checked ? LocationSearchSettings.SearchResult : LocationSearchSettings.ClientName,
      });
    } else {
      setUserPreferences({
        ...userPreferences,
        [inputKey]: value,
      });
    }
  };

  const handleClicks = async () => {
    setNewUserErrorMessage('');
    const idToUse: number = userInfo.id ? userInfo.id : -1;

    const {
      inputCurrentPassword,
      inputEmail,
      inputName,
      inputNewPassword,
      inputNewPasswordRepeat,
      inputUserType,
      inputUsername,
      isUpdate,
      inputClientId,
      inputUserLanguage
    } = userInfo;

    const userInput: IUserInput = {
      id: idToUse,
      inputClientId,
      inputCurrentPassword,
      inputEmail,
      inputName,
      inputNewPassword,
      inputNewPasswordRepeat,
      inputUsername,
      isUpdate,
      inputUserType,
      inputUserLanguage
    };

    const { inputAlertEmails, inputAlertLeaks, inputConfirmationEmails, locationSearch } = userPreferences;
    const notificationInput: IUserInputNotificationSettings = {
      inputAlertEmails,
      inputConfirmationEmails,
      inputAlertLeaks,
      locationSearch,
    };

    // We do not update the clientId of a user here.
    if (userInfo.isUpdate) {
      delete userInput.inputClientId;
    }

    const result = await updateUser({ userInput, notificationInput });
    if (result) {
      handleCloseModal();
      const newLangSetting = languageToWeglotValueMap.get(userInput.inputUserLanguage) ?? 'en'
      if (lang !== newLangSetting) {
        setLang(newLangSetting)
        // This page refresh is necessary. 
        // Because after lang change, weglot will automatically replace text with previously generated translation.
        // This is problematic when the text has realtime data
        // The forced page refresh will force weglot to retranslate the page content as well.
        navigate(0)
      }
    }
  };

  const handleCloseModal = () => {
    clearState();
    onClose();
  };

  const clearState = () => {
    setUserInfo({
      id: 0,
      inputClientId: null,
      inputCurrentPassword: '',
      inputEmail: '',
      inputName: '',
      inputNewPassword: '',
      inputNewPasswordRepeat: '',
      inputUsername: '',
      isUpdate: false,
      inputUserType: RoleType.ADMIN,
      inputUserLanguage: Languages.ENGLISH
    });
    setUserPreferences({
      inputAlertEmails: true,
      inputAlertLeaks: true,
      inputConfirmationEmails: true,
      locationSearch: LocationSearchSettings.ClientName,
    });
  };

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar, different] = usePasswordValidation({
    currentPassword: userInfo.inputCurrentPassword,
    firstPassword: userInfo.inputNewPassword,
    secondPassword: userInfo.inputNewPasswordRepeat,
    role: userInfo.inputUserType,
    lengthValidation: true,
    lowercaseValidation: true,
    specialcharacterValidation: userInfo.inputUserType === RoleType.SUPER_ADMIN,
    uppercaseValidation: true,
    numberValidation: true,
  });

  const userTypeTextLookup =
    userInfo.inputUserType === RoleType.EXTERNAL_TECHNICIAN
      ? 'System administrator'
      : getUserType(userInfo.inputUserType);

  const requirementsPassed = userInfo.isUpdate
    ? !showPasswordChecks ||
    (validLength &&
      hasNumber &&
      upperCase &&
      lowerCase &&
      match &&
      specialChar &&
      (userInfo.isUpdate ? different : true))
    : true;

  return (
    <AddItemModal
      confirmInteractionDisabled={!requirementsPassed}
      isModalOpen={isEditModalVisible}
      interactionDisabled={false}
      closeModal={handleCloseModal}
      confirmAction={handleClicks}
      modalCancelLabel="Cancel"
      modalConfirmLabel="Confirm"
      modalTitle="Edit user settings"
    >
      <TextInput
        inputValue={userInfo.inputEmail}
        inputKey="inputEmail"
        isFirst={true}
        inputText="Email*"
        handleInput={handleTextInputChange}
        isRequired={true}
      />
      <TextInput
        inputValue={userInfo.inputUsername}
        inputKey="inputUsername"
        inputText={'Username* (' + userInfo.inputUsername.length + '/50 characters)'}
        maxLength={50}
        handleInput={handleTextInputChange}
        isRequired={true}
      />
      <TextInput
        inputValue={userInfo.inputName}
        inputKey="inputName"
        inputText="Name + surname*"
        handleInput={handleTextInputChange}
        isRequired={true}
      />
      <DisabledInput isFirstInRow={false} inputValue={userTypeTextLookup} isFirst={false} inputText="User type" />
      <TextInput
        inputValue={userInfo.inputCurrentPassword}
        inputKey="inputCurrentPassword"
        inputText="Current password*"
        type="password"
        handleInput={handleTextInputChange}
        isRequired={true}
      />
      <TextInput
        inputValue={userInfo.inputNewPassword}
        inputKey="inputNewPassword"
        inputText={getPasswordText(userInfo.isUpdate, false, true)}
        type="password"
        handleInput={handleTextInputChange}
        isRequired={true}
      />
      <TextInput
        inputValue={userInfo.inputNewPasswordRepeat}
        inputKey="inputNewPasswordRepeat"
        inputText={getPasswordText(userInfo.isUpdate, true, true)}
        type="password"
        handleInput={handleTextInputChange}
        isRequired={true}
      />
      {showPasswordChecks ? (
        <PasswordChecks
          currentPasswordCheck={userInfo.isUpdate}
          validLength={validLength}
          different={different}
          hasNumber={hasNumber}
          lowerCase={lowerCase}
          match={match}
          role={userInfo.inputUserType}
          specialChar={specialChar}
          upperCase={upperCase}
        />
      ) : null}
      <NotificationSettings
        inputAlertEmails={userPreferences.inputAlertEmails}
        inputAlertLeaks={userPreferences.inputAlertLeaks}
        inputConfirmationEmails={userPreferences.inputConfirmationEmails}
        handleInput={handleNotificationChange}
        inputKeyAccountAlertEmails="inputAlertEmails"
        inputKeyLeakAlerts="inputAlertLeaks"
        inputKeyAccountAlertNotifications="inputKeyAccountAlertNotifications"
        inputKeyAccountConfirmationEmails="inputConfirmationEmails"
        inputKeyAccountConfirmationNotifications="inputKeyAccountConfirmationNotifications"
        userTypeValue={userInfo.inputUserType}
      />
      <LanguagePicker sx={{ display: 'flex', alignItems: 'center', marginTop: '1.2rem', marginBottom: '1rem' }}
        language={userInfo.inputUserLanguage}
        handleChange={(newValue) => {
          setUserInfo({ ...userInfo, inputUserLanguage: newValue })
        }}
      />
      {!isUserGuest(userInfo.inputUserType) && (
        <>
          <FormLabel style={{ paddingTop: '1rem' }} component="legend">
            Use client name / google search name
          </FormLabel>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FpText variant="body1">Client</FpText>
            <Switch
              checked={userPreferences.locationSearch === LocationSearchSettings.SearchResult}
              onChange={(e) => handleNotificationChange(e, 'locationSearch')}
              color="primary"
            />

            {/* Right Label (Search Mode) */}
            <FpText variant="body1">Search</FpText>
            <FpTooltip title='When adding a new location, choose if the "Name" field is auto-filled based on the "Client" field or the search field' />
          </div>
        </>
      )}
      <Feedback feedbackMessage={newUserErrorMessage} />
    </AddItemModal>
  );
};

export default EditUser;
