import { Radio } from '@mui/material';
import React from 'react';
import {
  ColorOptionsForButtons,
  getColorForButton,
  parseStringAsColorOption,
  RadioButtonOptions,
} from '../../../installations/components/Tags/tags-util';
import FpText from '../FpText/FpText';

type Props = {
  options: RadioButtonOptions[];
  selectedValue: ColorOptionsForButtons;
  setSelectedValue(value: ColorOptionsForButtons): void;
};

const FpButtonRadio = ({ options, selectedValue, setSelectedValue }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const colorOption = parseStringAsColorOption(value);
    if (!colorOption) {
      return;
    }
    setSelectedValue(colorOption);
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'radio-button-' + item,
    inputProps: { 'aria-label': item },
  });

  return (
    <div>
      <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
        Tag color:
      </FpText>
      {options.map((option, index) => {
        const colorCode = getColorForButton(option.color);
        return (
          <Radio
            key={index}
            {...controlProps(option.color)}
            sx={{
              color: colorCode,
              '&.Mui-checked': {
                color: colorCode,
              },
            }}
          />
        );
      })}
    </div>
  );
};

export default FpButtonRadio;
