import { useStoreState } from '../../../../../../Store';
import FpSelectSearch from '../../../../../common/components/FpSelectSearch/FpSelectSearch';

type Props = {
  value: number;
  onSelection(value: number): void;
};

const FlowplanFilterChangeByLatestSelection = ({ onSelection, value }: Props): JSX.Element => {
  const changeByOptions = useStoreState((state) => state.staticDataModel.changeByOptions);

  return (
    <FpSelectSearch
      hideSearchBar
      label="Schedule filter replacement"
      options={changeByOptions.map((option) => ({
        name: `${option.duration} months`,
        value: option.id,
      }))}
      selectedValue={value}
      setSelectionValue={onSelection}
    />
  );
};

export default FlowplanFilterChangeByLatestSelection;
