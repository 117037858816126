import { IClient, IFlowplanClientRequestPackage } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IFlowplanClient } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { BillingStatus, BillingTypes } from '@flowplan/flowplan-shared/lib/interfaces/networkEUIToFlowplanSerialDb';
import { sortByKey, stringifyAndParse } from '@hsjakobsen/utilities';
import { Action, Thunk, action, thunk } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { noSelectionMade } from '../../../Models/admin/admin.model';
import { validateInputFlowplanClient } from '../../../Models/admin/admin.validation';
import { IDropdownData } from '../../../common/interfacesFrontend';
import {
  handleDeleteRequest,
  handleGetRequest,
  handlePostRequest,
  handlePutRequest,
} from '../../server-requests/server-requests';
import { InstallationClassification, TestInstallation } from '../types/flowplan-clients-types';

interface IFlowplanClientUpdateFields extends Omit<IFlowplanClientRequestPackage, 'success' | 'enableMatching'> {
  blockReason?: string;
  lastBillingDate?: string | null;
  enableMatching?: number | boolean;
  nextInvoicePeriodEnd?: string;
  success?: boolean;
}
interface IFlowplanClientUpdate {
  fields: IFlowplanClientUpdateFields;
  flowplanClientId: number;
}

export enum BillingIntervalOptions {
  One = 1,
  Three = 3,
  Six = 6,
  Nine = 9,
  Twelve = 12,
  TwentyFour = 24,
  ThirtySix = 36
}

type SetCompanyBillingInfo = {
  billingDateLast: string;
  billingDateNext: string;
  billingInterval: string;
};

export type AssignmentBillingInfo = {
  billingStatus: BillingStatus;
  billingType: BillingTypes;
};

export interface FlowplanClient extends IFlowplanClient {
  flowplanClientsGroup: {
    name: string;
  };
}

export interface IFlowplanClientsDropdownData extends IDropdownData {
  // Dropdown data go straight as native DOM element attributes, where camel case is not allowed.
  flowplanclientsgroupid?: number;
}

export interface FlowplanClientModel {
  flowplanCompanies: FlowplanClient[];
  flowplanCompaniesDropdown: IDropdownData[];
  flowplanCompaniesAdminInventoryDropdown: IDropdownData[];
  flowplanCompaniesAdminOrdersDropdown: IFlowplanClientsDropdownData[];
  loadingCompanies: boolean;
  setLoadingCompanies: Action<FlowplanClientModel, boolean>;
  getFlowplanCompanies: Thunk<FlowplanClientModel, void, void, IStoreModel>;
  setFlowplanCompanies: Action<FlowplanClientModel, IFlowplanClient[]>;

  flowplanCompanyClients: IDropdownData[];
  retrieveFlowplanCompanyClients: Thunk<FlowplanClientModel, number, void, IStoreModel>; // flowplan client id
  setFlowplanCompanyClients: Action<FlowplanClientModel, IClient[]>;
  addCompany: Thunk<FlowplanClientModel, IFlowplanClientRequestPackage, void, IStoreModel>;
  updateCompany: Thunk<FlowplanClientModel, IFlowplanClientUpdate, void, IStoreModel>;
  deleteCompany: Thunk<FlowplanClientModel, number, void, IStoreModel>;

  companyBillingDateLast: string;
  companyBillingDateNext: string;
  companyBillingInterval: string;

  companyBillingDateInfo: Action<FlowplanClientModel, SetCompanyBillingInfo>;

  billCustomers: Thunk<FlowplanClientModel, void, void, IStoreModel>;
  setCompanyBillingDates: Thunk<FlowplanClientModel, string>;
  generatePerformanceReports: Thunk<FlowplanClientModel, void, void, IStoreModel>;

  loadingTestCustomerOverview: boolean;
  setLoadingTestCustomerOverview: Action<FlowplanClientModel, boolean>;
  testCustomerOverview: TestInstallation[];
  setTestCustomerOverview: Action<FlowplanClientModel, TestInstallation[]>;
  getTestCustomerOverview: Thunk<FlowplanClientModel, void, void, IStoreModel>;
  updateTestInstallation: Thunk<FlowplanClientModel, Partial<InstallationClassification>>;
  getTestInstallationData: Thunk<FlowplanClientModel, number, void, IStoreModel>;
  loadingTestInstallationData: boolean;
  setLoadingTestInstallationData: Action<FlowplanClientModel, boolean>;

  logoUrl: string;
  setLogoUrl: Action<FlowplanClientModel, string>;
  uploadLogo: Thunk<FlowplanClientModel, FormData>;

  getFlowplanClient: Thunk<FlowplanClientModel, number, IFlowplanClient | null, IStoreModel>; // flowplan client id
}

const flowplanClientModel: FlowplanClientModel = {
  loadingCompanies: false,
  flowplanCompanies: [],
  flowplanCompaniesDropdown: [],
  flowplanCompaniesAdminInventoryDropdown: [],
  flowplanCompaniesAdminOrdersDropdown: [],
  getFlowplanCompanies: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingCompanies(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.flowplanClients });
    if (requestResponse.success) {
      actions.setFlowplanCompanies(requestResponse.data);
    }
    actions.setLoadingCompanies(false);
  }),
  setLoadingCompanies: action((state, payload) => {
    state.loadingCompanies = payload;
  }),
  setFlowplanCompanies: action((state, payload) => {
    sortByKey(payload, 'name', true);
    state.flowplanCompanies = payload as FlowplanClient[];
    const companyDropdown: IFlowplanClientsDropdownData[] = [];
    payload.forEach((company, index) => {
      const idToUse = company.id ? company.id : noSelectionMade;
      const companyItem: IFlowplanClientsDropdownData = {
        key: index,
        text: company.name || '',
        value: idToUse,
        flowplanclientsgroupid: company.flowplanClientsGroupId,
      };

      companyDropdown.push(companyItem);
    });
    companyDropdown.unshift({
      key: -1,
      text: 'Select Flowplan client',
      value: -1,
    });

    state.flowplanCompaniesDropdown = companyDropdown;

    const flowplanClientAdminInventory: IDropdownData[] = stringifyAndParse(companyDropdown);
    flowplanClientAdminInventory.unshift({ key: -3, text: ' Unassigned serials', value: -3 });
    flowplanClientAdminInventory.unshift({ key: -2, text: ' All assigned serials', value: -2 });
    state.flowplanCompaniesAdminInventoryDropdown = flowplanClientAdminInventory;

    const flowplanClientAdminOrders: IFlowplanClientsDropdownData[] = stringifyAndParse(companyDropdown);
    flowplanClientAdminOrders.unshift({ key: -2, text: ' All orders', value: -2 });

    state.flowplanCompaniesAdminOrdersDropdown = flowplanClientAdminOrders;
  }),

  addCompany: thunk(async (actions, payload, { getStoreActions }) => {
    const newCompanyData = validateInputFlowplanClient(payload);
    if (!newCompanyData.success) {
      return { success: false };
    }

    const requestResponse = await handlePostRequest(newCompanyData, Routes.flowplanClients, true);
    if (requestResponse.success) {
      actions.getFlowplanCompanies();
    }

    return requestResponse;
  }),
  updateCompany: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await handlePutRequest(
      { ...payload.fields },
      `${Routes.flowplanClients}${payload.flowplanClientId}`,
      true,
    );

    if (requestResponse.success) {
      actions.getFlowplanCompanies();
    }
    return requestResponse;
  }),
  deleteCompany: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await handleDeleteRequest({ id: payload }, `${Routes.flowplanClients}${payload}`);

    if (requestResponse.success) {
      actions.getFlowplanCompanies();
    }
    return requestResponse;
  }),

  billCustomers: thunk(async (actions, payload, { getStoreActions }) => {
    const route = '/bill-customers/';
    return await getStoreActions().serverRequestsModel.get({ route: route, params: { take: 10000 } });
  }),
  setCompanyBillingDates: thunk(async (actions, payload) => {
    const route = '/bill-customers/';
    return await handlePostRequest({ lastBillingDate: payload }, route, true);
  }),
  generatePerformanceReports: thunk(async (actions, payload, { getStoreActions }) => {
    const route = '/flowplanclientoverviews/';
    return await handleGetRequest(route, true);
  }),

  loadingTestCustomerOverview: false,
  testCustomerOverview: [],
  getTestCustomerOverview: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingTestCustomerOverview(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/test-customer-overview/' });
    if (requestResponse.success) {
      actions.setTestCustomerOverview(requestResponse.data as TestInstallation[]);
    }
    actions.setLoadingTestCustomerOverview(false);
  }),
  setTestCustomerOverview: action((state, payload) => {
    state.testCustomerOverview = payload;
  }),
  setLoadingTestCustomerOverview: action((state, payload) => {
    state.loadingTestCustomerOverview = payload;
  }),

  updateTestInstallation: thunk(async (actions, payload) => {
    const requestResponse = await handlePutRequest({ ...payload }, Routes.MLInstallationClassification, true);

    if (requestResponse.success) {
      actions.getTestCustomerOverview();
    }
    return requestResponse;
  }),
  getTestInstallationData: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingTestInstallationData(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: '/data-for-installation/' + payload,
    });
    actions.setLoadingTestInstallationData(false);
    if (!requestResponse.success) {
      return [];
    }
    return requestResponse;
  }),

  loadingTestInstallationData: false,
  setLoadingTestInstallationData: action((state, payload) => {
    state.loadingTestInstallationData = payload;
  }),

  uploadLogo: thunk(async (actions, payload) => {
    const requestResponse = await handlePostRequest(payload, Routes.S3uploadFile, true);
    if (requestResponse.success) {
      actions.setLogoUrl(requestResponse.data);
    }

    return requestResponse;
  }),

  logoUrl: '',
  setLogoUrl: action((state, payload) => {
    state.logoUrl = payload;
  }),

  flowplanCompanyClients: [],
  retrieveFlowplanCompanyClients: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.clients + payload });
    if (requestResponse.success) {
      actions.setFlowplanCompanyClients(requestResponse.data);
    }
  }),
  setFlowplanCompanyClients: action((state, payload) => {
    const clientDropdown: IDropdownData[] = [];
    payload.forEach((client) => {
      const { name, id } = client;
      if (id) {
        clientDropdown.push({ key: id, text: name, value: id });
      }
    });
    sortByKey(clientDropdown, 'text', true);
    state.flowplanCompanyClients = clientDropdown;
  }),

  companyBillingDateLast: '',
  companyBillingDateNext: '',
  companyBillingInterval: '',
  companyBillingDateInfo: action((state, payload) => {
    state.companyBillingDateLast = payload.billingDateLast;
    state.companyBillingDateNext = payload.billingDateNext;
    state.companyBillingInterval = payload.billingInterval;
  }),

  getFlowplanClient: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: `${Routes.flowplanClients}${payload}`,
    });
    return requestResponse.success ? requestResponse.data : null;
  }),
};

export default flowplanClientModel;
