import { FilterMachineTypes } from '@flowplan/flowplan-shared/lib/machine.types/machine.types';
import { Box } from '@mui/material';
import { ChangeEvent } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import Checkbox from '../../../../Components/Input/Checkbox';
import { useStoreActions, useStoreState } from '../../../../Store';
import { InputKeys } from '../../../../common/constsFrontend';
import FlowplanCapacityAndSettingsSelection from './components/WaterFilter/FilterCapacity';
import FlowplanFilterChangeByLatestSelection from './components/WaterFilter/FilterChangeByLatest';
import FlowplanFilterSelection from './components/WaterFilter/FilterFilters';
import FlowplanMachineTypeSelection from './components/WaterFilter/FilterMachineTypes';
import FlowplanFilterProducerSelection from './components/WaterFilter/FilterProducers';

const FlowplanFilterSection = (): JSX.Element => {
  const setInputFilterChangeByLatestId = useStoreActions(
    (actions) => actions.newInstallationModel.setInputFilterChangeByLatestId,
  );
  const setManualChange = useStoreActions((actions) => actions.newInstallationModel.setManualChange);
  const setInputFilterProducerId = useStoreActions((actions) => actions.newInstallationModel.setInputFilterProducerId);
  const setInputFilterMachineTypeId = useStoreActions(
    (actions) => actions.newInstallationModel.setInputFilterMachineTypeId,
  );
  const setInputFilterId = useStoreActions((actions) => actions.newInstallationModel.setInputFilterId);
  const setInputCapacity = useStoreActions((actions) => actions.newInstallationModel.setInputCapacity);
  const setFeatureFilter = useStoreActions((actions) => actions.newInstallationModel.setFeatureFilter);

  const featureFilter = useStoreState((actions) => actions.newInstallationModel.featureFilter);
  const inputFilterChangeByLatestId = useStoreState((state) => state.newInstallationModel.inputFilterChangeByLatestId);
  const inputFilterProducerId = useStoreState((state) => state.newInstallationModel.inputFilterProducerId);
  const inputFilterId = useStoreState((state) => state.newInstallationModel.inputFilterId);
  const inputFilterMachineTypeId = useStoreState((state) => state.newInstallationModel.inputFilterMachineTypeId);
  const inputCapacity = useStoreState((state) => state.newInstallationModel.inputCapacity);
  const showCustomFilter = useStoreState((state) => state.newInstallationModel.showCustomFilter);
  const filtersList = useStoreState((state) => state.newInstallationModel.filtersList);
  const bypassSetting = useStoreState((state) => state.newInstallationModel.bypassSetting);
  const flowplanCapacityRecommendationInfo = useStoreState(
    (state) => state.newInstallationModel.flowplanCapacityRecommendationInfo,
  );

  const { capacity, hardness } = flowplanCapacityRecommendationInfo;
  return (
    <Segment>
      <Header size="medium">
        <Header.Content>
          Water filter
          <Header.Subheader>
            <Checkbox
              inputValue={featureFilter}
              wideFormat={true}
              inputKey={InputKeys.FeatureFilter}
              inputText="Enable water filter"
              handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
                const value = ev.target.checked;
                setFeatureFilter(value);
              }}
            />
          </Header.Subheader>
        </Header.Content>
      </Header>

      {featureFilter && (
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <FlowplanFilterChangeByLatestSelection
            onSelection={(value: number) => {
              setInputFilterChangeByLatestId(value);
            }}
            value={inputFilterChangeByLatestId}
          />

          <FlowplanFilterProducerSelection
            translateOptions={false}
            onSelection={(value: number) => {
              setManualChange(true);
              setInputFilterProducerId(value);
              setInputFilterMachineTypeId(FilterMachineTypes.Normal);
            }}
            value={inputFilterProducerId}
          />
          <FlowplanFilterSelection
            translateOptions={false}
            onSelection={(value: number) => {
              setManualChange(true);
              setInputFilterId(value);
              setInputFilterMachineTypeId(FilterMachineTypes.Normal);
            }}
            showCustomFilter={showCustomFilter}
            value={inputFilterId}
            waterFilters={filtersList}
          />
          <FlowplanMachineTypeSelection
            onSelection={(value: number) => {
              setManualChange(true);
              setInputFilterMachineTypeId(value);
            }}
            value={inputFilterMachineTypeId}
          />
          <FlowplanCapacityAndSettingsSelection
            hardness={hardness}
            properCapacity={capacity}
            bypassSetting={bypassSetting}
            disabled={inputFilterProducerId < 0}
            onSelection={(value: number | string) => {
              setManualChange(true);
              setInputCapacity(value);
            }}
            value={inputCapacity}
          />
        </Box>
      )}
    </Segment>
  );
};

export default FlowplanFilterSection;
