import { googleAPIKey } from '../../../config';

type GoogleApiLoaded = {
  apiLoaded: () => void;
};

((window as unknown) as GoogleApiLoaded).apiLoaded = () => {
  // Google maps api loaded
}

export const loadMapApi = (): HTMLScriptElement => {
  const mapsURL = `https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&callback=apiLoaded&libraries=places`;

  const scripts = document.getElementsByTagName('script');
  // Go through existing script tags, and return google maps api tag when found.
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf(mapsURL) === 0) {
      return scripts[i];
    }
  }

  const googleMapScript = document.createElement('script');
  googleMapScript.src = mapsURL;
  googleMapScript.async = false;
  googleMapScript.defer = true;
  window.document.body.appendChild(googleMapScript);

  return googleMapScript;
};
