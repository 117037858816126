import { FlowplanProductTypes, IFlowplanClient } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { Action, action, Thunk, thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { IStoreModel } from '..';
import { AdminSectionArea } from '../../common/interfacesFrontend';
import { handlePutRequest } from '../../modules/server-requests/server-requests';

interface IMoveInstallation {
  installationId: number;
  newFlowplanClientId: number;
}

export enum adminSection {
  Devices = 0,
  Suspicious = 1,
  Logs = 2,
  Blacklist = 3,
  Serials = 4,
  Bottles = 5,
  TestCustomerOverview = 6,
  FaultyDeviceOverview = 7,
  MoveInstallations = 8,
  Companies = 9,
  Status = 10,
  ClientsGroup = 11,
  Products = 12,
  orders = 13,
  Billing = 14,
}

export const adminDailySupport: AdminSectionArea[] = [
  { color: 'blueButton', text: 'Installations', value: adminSection.Devices },
  { color: 'blueButton', text: 'Inventory', value: adminSection.Serials },
  {
    color: 'blueButton',
    text: 'Faulty device overview',
    value: adminSection.FaultyDeviceOverview,
  },
];

export const adminEssentialsTwo: AdminSectionArea[] = [
  { color: 'orangeButton', text: 'Suspicious data', value: adminSection.Suspicious },
  { color: 'greyButton', text: 'Logs', value: adminSection.Logs },
  { color: 'greenButton', text: 'Flowplan stats', value: adminSection.Status },
];

export const adminCompanyManagement: AdminSectionArea[] = [
  {
    color: 'blueButton',
    text: 'Customer management',
    value: adminSection.Companies,
  },
  {
    color: 'orangeButton',
    text: 'Billing and various actions',
    value: adminSection.Billing,
  },
  {
    color: 'blueButton',
    text: 'Test customer overview',
    value: adminSection.TestCustomerOverview,
  },
];

export const adminOrderManagement: AdminSectionArea[] = [
  { color: 'blueButton', text: 'Customer groups', value: adminSection.ClientsGroup },
  { color: 'tealButton', text: 'Flowplan products', value: adminSection.Products },
  { color: 'greenButton', text: 'Order overview', value: adminSection.orders },
];
export const adminOtherTools: AdminSectionArea[] = [
  {
    color: 'redButton',
    text: 'Move installations',
    value: adminSection.MoveInstallations,
  },
  { color: 'purpleButton', text: 'Blacklist', value: adminSection.Blacklist },
];
export const adminCurrentlyNotUsed: AdminSectionArea[] = [
  { color: 'greyButton', text: 'Bottles', value: adminSection.Bottles },
];

export const noSelectionMade = -1;
export const fetchForAll = -2;

export interface IAdminDataModel {
  flowplanClientSelected: number;
  setFlowplanClientSelection: Action<IAdminDataModel, number>;

  selectedFlowplanClientInfo: IFlowplanClient | undefined;
  setSelectedFlowplanClientInfo: Action<IAdminDataModel, IFlowplanClient>;

  flowplanClientGroupId: number;
  setFlowplanClientGroupId: Action<IAdminDataModel, number>;

  flowplanProductTypeId: FlowplanProductTypes;
  setFlowplanProductTypeId: Action<IAdminDataModel, FlowplanProductTypes>;

  onFlowplanClientSelected: ThunkOn<IAdminDataModel, void, IStoreModel>;

  changeInstallationCompany: Thunk<IAdminDataModel, IMoveInstallation>;
}

const adminDataModel: IAdminDataModel = {
  changeInstallationCompany: thunk(async (actions, payload) => {
    const data = {
      id: payload.installationId,
      flowplanClientId: payload.newFlowplanClientId,
    };
    const requestResponse = await handlePutRequest(data, '/sensors/change-company/', true);
    return requestResponse;
  }),

  flowplanClientSelected: noSelectionMade,
  setFlowplanClientSelection: action((state, payload) => {
    state.flowplanClientSelected = payload;
  }),

  selectedFlowplanClientInfo: undefined,
  setSelectedFlowplanClientInfo: action((state, payload) => {
    state.selectedFlowplanClientInfo = payload;
  }),

  flowplanClientGroupId: noSelectionMade,
  setFlowplanClientGroupId: action((state, payload) => {
    state.flowplanClientGroupId = payload;
  }),

  flowplanProductTypeId: FlowplanProductTypes.WaterFilter,
  setFlowplanProductTypeId: action((state, payload) => {
    state.flowplanProductTypeId = payload;
  }),

  onFlowplanClientSelected: thunkOn(
    (actions, storeActions) => storeActions.adminDataModel.setFlowplanClientSelection,
    async (actions, target, { getStoreActions, getStoreState }) => {
      const storeActions = getStoreActions();
      const selectedFlowplanClientId = target.payload;
      if (selectedFlowplanClientId === noSelectionMade) {
        storeActions.companyModel.setCompanyInstallations([]);
        storeActions.adminDataModel.setFlowplanClientGroupId(noSelectionMade);
      }

      const isGuest = getStoreState().authModel.currentUser.role === RoleType.GUEST;
      if (isGuest) {
        return;
      }

      await storeActions.inventoryModel.getBillingInformation(selectedFlowplanClientId);

      const flowplanClientInfo: IFlowplanClient | null = await storeActions.flowplanClientModel.getFlowplanClient(
        selectedFlowplanClientId,
      );
      if (!flowplanClientInfo) return;
      storeActions.adminDataModel.setSelectedFlowplanClientInfo(flowplanClientInfo);
      storeActions.adminDataModel.setFlowplanClientGroupId(
        flowplanClientInfo.flowplanClientsGroupId ?? noSelectionMade,
      );
      await storeActions.clientsModel.getClients(selectedFlowplanClientId);
      await storeActions.locationsModel.getLocations({
        flowplanClientId: selectedFlowplanClientId,
        flowplanProductTypeId: FlowplanProductTypes.WaterFilter
      });
    },
  ),
};

export default adminDataModel;
