import { formatLiters, formatNumberNoDecimals } from '@flowplan/flowplan-shared';
import { IInfoService } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { getDateObject } from '../../../../Utility/time';

export interface IMaintenanceInformation {
  featureEnabled: boolean;
  changeDate: string;
  daysRemaining: number;
  lastChangeDate: string;
  amountRemaining: string;
  amountRemainingEstimate: number;
  usage: number;
  percentageLeft: number;
  amountTotal: string;
  cycleBasedMaintenance: boolean;
  showEstimate: boolean;
}

type CollectMaintenanceInfo = {
  infoService: IInfoService;
  showEstimate: boolean;
}

const collectMaintenanceInformation = ({ infoService, showEstimate }: CollectMaintenanceInfo): IMaintenanceInformation => {
  const { cycleBasedMaintenance, dayOfLastChange, daysLeft, estimatedMaintenanceUsage, featureEnabled, lastMaintenanceDate, percentageLeft, serviceAtLiters, serviceUsage } = infoService;

  const amountRemainingNumber = serviceAtLiters - serviceUsage - (showEstimate ? estimatedMaintenanceUsage : 0);
  const amountRemaining = (cycleBasedMaintenance ? amountRemainingNumber.toFixed(0) : formatLiters(amountRemainingNumber));

  return {
    changeDate: getDateObject(dayOfLastChange).format(dateFormatYMD),
    daysRemaining: daysLeft,
    featureEnabled: featureEnabled,
    lastChangeDate: getDateObject(lastMaintenanceDate).format(dateFormatYMD),
    amountRemaining,
    amountRemainingEstimate: estimatedMaintenanceUsage,
    percentageLeft: 100 - percentageLeft,
    amountTotal: formatNumberNoDecimals(serviceAtLiters),
    cycleBasedMaintenance: cycleBasedMaintenance,
    showEstimate,
    usage: serviceUsage,
  };
};

export { collectMaintenanceInformation };
