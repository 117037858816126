import { createStore, createTypedHooks } from 'easy-peasy';
import storeModel, { IStoreModel } from '../Models';

const store = createStore(storeModel);

const typedHooks = createTypedHooks<IStoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreState = typedHooks.useStoreState;
export const useStoreDispatch = typedHooks.useStoreDispatch;

export default store;
