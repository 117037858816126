import { useMediaQuery, useTheme } from '@mui/material';
import { Fragment, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from '../../../../Store';
import { storeLastVisitedLocation } from '../../../../Utility/LocalStorageFunctions';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpModalConfirmSimple from '../../../common/components/FpModalConfirmSimple/FpModalConfirmSimple';
import FpText from '../../../common/components/FpText/FpText';
import { isUserAdmin, isUserGuest } from '../../../users/types/users-configs';
import { NEWS_IMAGE_BORDER } from '../../news-and-updates-consts';
import nextPeriod from './next-period.webp';
import overviewSection from './overview-section.webp';
import thisQuarter from './this-quarter.webp';
import { Paths } from '../../../navigation/containers/Navigation/navigation-types';

type Props = {
  closeModal(closeNews?: boolean): void;
  showModal: boolean;
};

const ReleaseInfoPerformanceReports = memo(({ closeModal, showModal }: Props): JSX.Element => {
  const showMenu = useStoreState((state) => state.authModel.showMenu);
  const currentUser = useStoreState((state) => state.authModel.currentUser);

  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (!showMenu || isUserGuest(currentUser.role)) {
    return <Fragment />;
  }

  return (
    <FpModalConfirmSimple
      closeModal={closeModal}
      largerContentMargins={false}
      fullScreen={fullScreen}
      maxWidth="md"
      label="Close"
      modalTitle="2024 - November. Introducing Performance Reports! 🚀"
      showModal={showModal}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center' }}>
        <FpText variant="body1" sx={{ textAlign: 'left' }}>
          We’re excited to launch Performance Reports, a brand-new feature designed to give you valuable insights into
          your business performance!
        </FpText>
        <FpDivider height="0.5rem" marginY="0.5rem" />
        <FpDivider hidden height="0.5rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          Performance reports give you insights to how your business is doing, how much work you have done and how much
          we predict you will be doing in the future!
        </FpText>
        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          Performance reports are already available for all previous quarters of 2024 as well as Q4 of 2023. They can be
          found in the left side of Backoffice under the Management section.
        </FpText>
        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          The performance reports currently consist of 3 tabs - 2 more will be added soon!
        </FpText>
        <FpDivider hidden height="1rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          1. Overview:
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          A quick overview of your machines in Flowplan.
        </FpText>

        <FpDivider hidden height="1rem" />
        <img
          srcSet={overviewSection}
          src={overviewSection}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Get an easy overview of your installations and their types'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          A quick overview of your machines in Flowplan at the end of a quarter.
        </FpText>

        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          2. This quarter:
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          See your performance for this quarter as well as benchmarks to your previous performance. You also get
          insights into how much your customer's machines are consuming.
        </FpText>

        <FpDivider hidden height="1rem" />
        <img
          srcSet={thisQuarter}
          src={thisQuarter}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Performance for the quarter'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          A quick overview of how each quarter has developed for your company,
        </FpText>

        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          3. Next 12 months:
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          View your predicted tasks for the next 12 months as well as the most important locations, filter types etc.
        </FpText>

        <FpDivider hidden height="1rem" />
        <img
          srcSet={nextPeriod}
          src={nextPeriod}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Get our best estimates for how your next year is going to look'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          Being able to predict your future workload is essential for planning ahead.
        </FpText>
        <FpDivider hidden height="1rem" />

        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          4. Last 12 months (coming soon):
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          Here you will be able to see your performance for the previous 12 months as well as the most important
          locations, filter types etc.
        </FpText>

        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          5. Lifetime (coming soon):
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          Here you will be able to see everything that has ever been done in Flowplan
        </FpText>

        <FpDivider hidden height="2rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          The future of performance reports
        </FpText>

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          While there are a lot of things we want to include, we have prioritised to give you performance reports
          earlier, since we believe they already provide so much value.
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We will keep adding data to the reports - and do you have ideas for new features? We’d love to hear them! Send
          us your requests and help us make Performance Reports even better.
        </FpText>

        <FpDivider hidden height="1rem" />

        <FpButton
          color="success"
          disabled={!isUserAdmin(currentUser.role)}
          onClick={() => {
            closeModal(true);
            storeLastVisitedLocation(Paths.reports);
            navigate(Paths.reports);
          }}
        >
          Check Out Your Performance Reports Now
        </FpButton>

        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Note: Performance reports are only visible for administrators.
        </FpText>
      </div>
    </FpModalConfirmSimple>
  );
});

export default ReleaseInfoPerformanceReports;
