import React, { useState } from 'react';
import { Icon, Popup, SemanticCOLORS } from 'semantic-ui-react';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import styles from './IconPlacement.module.scss';

interface IPopupWithIcon {
  children: React.ReactNode;
  extraStyle?: string;
  iconColor?: SemanticCOLORS;
  iconSize?: IconSizeProp;
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center';
}

const PopupWithIcon = ({ children, extraStyle, iconColor, iconSize, position }: IPopupWithIcon): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const iconColorToSet: SemanticCOLORS = iconColor ? iconColor : 'blue';
  const iconSizeToSet: IconSizeProp = iconSize ? iconSize : 'small';
  const iconStyle = styles.infoIconPlacement + ' ' + (extraStyle === undefined ? '' : extraStyle);
  return (
    <Popup
      on="click"
      position={position}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      trigger={<Icon size={iconSizeToSet} className={iconStyle} name="info circle" color={iconColorToSet} />}
    >
      <Popup.Content>{children}</Popup.Content>
    </Popup>
  );
};

export default PopupWithIcon;
