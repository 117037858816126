import { useStoreState } from '../../../../../../Store';
import FpSelectSearch from '../../../../../common/components/FpSelectSearch/FpSelectSearch';

type Props = {
  value: number;
  onSelection(value: number): void;
};

const FlowplanMachineTypeSelection = ({ onSelection, value }: Props): JSX.Element => {
  const availableFilterMachineTypes = useStoreState((state) => state.filterDataModel.availableFilterMachineTypes);

  const standardSelectionType = [{ name: 'Standard machine selection', id: 1 }];

  const options = availableFilterMachineTypes.length === 1 ? standardSelectionType : availableFilterMachineTypes;

  return (
    <FpSelectSearch
      isDisabled={availableFilterMachineTypes.length === 1}
      hideSearchBar
      label="Machine types*"
      options={options.map((type) => ({
        name: type.name,
        value: type.id,
      }))}
      selectedValue={value}
      setSelectionValue={onSelection}
    />
  );
};

export default FlowplanMachineTypeSelection;
