import { memo, useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { SelectedOption } from '../../../../common/constsFrontend';
import LineChartWithControls from '../../../common/charts/Line/LineChartWithControls';

const SignalStrengthHistoryComponent = memo(() => {
  const getSignalStrengthHistory = useStoreActions((actions) => actions.deviceDataModel.getSignalStrengthHistory);
  const loadingSignalStrengthHistory = useStoreState((state) => state.deviceDataModel.loadingSignalStrengthHistory);
  const signalStrengthHistory = useStoreState((state) => state.deviceDataModel.signalStrengthHistory);

  const [selectedOption, setSelectionOption] = useState(SelectedOption.SevenDays);

  const retrieveSignalHistory = useCallback(async () => {
    getSignalStrengthHistory(selectedOption);
  }, [getSignalStrengthHistory, selectedOption]);

  useEffect(() => {
    retrieveSignalHistory();
  }, [retrieveSignalHistory]);

  return (
    <LineChartWithControls
      graphTitle=""
      dataUnit=" RSSI"
      chartData={signalStrengthHistory}
      interactionFunction={(optionSelected: number) => {
        setSelectionOption(optionSelected);
      }}
      isLoading={loadingSignalStrengthHistory}
      placeholderText="No signal strength data found."
      selectedOption={selectedOption}
    />
  );
});

export default SignalStrengthHistoryComponent;
