import React, { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { IDropdownData } from '../../common/interfacesFrontend';

interface IDropdownListSearchable {
  items: IDropdownData[];
  placeholder: string;
  selectedValue: number[] | number | string;
  onChangeFunction(e: SyntheticEvent, data: DropdownProps): void;
  className?: string
}

const DropdownListSearchable = ({
  selectedValue,
  placeholder,
  items,
  onChangeFunction,
  className
}: IDropdownListSearchable): JSX.Element => {
  return (
    <Dropdown
      placeholder={placeholder}
      value={selectedValue}
      onChange={onChangeFunction}
      fluid
      search
      selection
      options={items}
      className={className}
    />
  );
};

export default DropdownListSearchable;
