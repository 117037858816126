import { Color } from '@flowplan/flowplan-shared';
import filterImage from '../../../../../assetstore/icons/filter/filter.png';
import styles from './LowUsageFilterStatus.module.scss';

type Props = {
  percentageStatus: number;
};

const LowUsageFilterStatus = ({ percentageStatus }: Props): JSX.Element => {
  let percentageCalculation = percentageStatus < 0 || percentageStatus > 100 ? 100 : percentageStatus;
  if (percentageStatus < 10 && percentageStatus > 0) {
    percentageCalculation = 10;
  }

  const percentageStyle = 81 * (percentageCalculation / 100);
  let colorToUse = Color.FlowplanBlue;
  if (percentageStatus >= 20) {
    colorToUse = Color.FlowplanBlue;
  } else if (percentageStatus >= 10) {
    colorToUse = Color.Week;
  } else if (percentageStatus < 0) {
    colorToUse = Color.Overdue;
  }
  return (
    <div className={styles.filterStatusIndicator}>
      <div className={styles.filterStatusProgressBarAlternate}>
        <div className={styles.filterStatusFiller} style={{ height: percentageStyle + '%', background: colorToUse }} />
        <img className={styles.waterFilter} src={filterImage} alt="Waterfilter" />
      </div>
    </div>
  );
};

export default LowUsageFilterStatus;
