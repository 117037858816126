import L from 'leaflet';
import flowplan from './marker-icon-flowplan.png';
import good from './marker-icon-good.png';
import none from './marker-icon-none.png';
import oneMonth from './marker-icon-one-month.png';
import oneWeek from './marker-icon-one-week.png';
import overdue from './marker-icon-overdue.png';
import shadow from './marker-shadow.png';

export const iconGood = new L.Icon({
  iconUrl: good,
  iconRetinaUrl: good,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const iconNone = new L.Icon({
  iconUrl: none,
  iconRetinaUrl: none,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const iconFlowplan = new L.Icon({
  iconUrl: flowplan,
  iconRetinaUrl: flowplan,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const iconOneMonth = new L.Icon({
  iconUrl: oneMonth,
  iconRetinaUrl: oneMonth,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const iconOneWeek = new L.Icon({
  iconUrl: oneWeek,
  iconRetinaUrl: oneWeek,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const iconOverdue = new L.Icon({
  iconUrl: overdue,
  iconRetinaUrl: overdue,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
