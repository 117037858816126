import { useMediaQuery, useTheme } from '@mui/material';
import { Fragment, memo } from 'react';
import { useStoreState } from '../../../../Store';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpModalConfirmSimple from '../../../common/components/FpModalConfirmSimple/FpModalConfirmSimple';
import FpText from '../../../common/components/FpText/FpText';
import { isUserGuest } from '../../../users/types/users-configs';
import { NEWS_IMAGE_BORDER } from '../../news-and-updates-consts';
import fieldOffice from './field-office-tags.webp';
import tagsDashboard from './tags-dashboard.webp';
import tagsList from './tags-installation-list.webp';

type Props = {
  closeModal(): void;
  showModal: boolean;
};

const ReleaseInformationTags = memo(({ closeModal, showModal }: Props): JSX.Element => {
  const showMenu = useStoreState((state) => state.authModel.showMenu);
  const currentUser = useStoreState((state) => state.authModel.currentUser);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (!showMenu || isUserGuest(currentUser.role)) {
    return <Fragment />;
  }

  return (
    <FpModalConfirmSimple
      closeModal={closeModal}
      largerContentMargins={false}
      fullScreen={fullScreen}
      maxWidth="md"
      label="Close"
      modalTitle="2024 - August. Tags!"
      showModal={showModal}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center' }}>
        <FpText variant="body1" sx={{ textAlign: 'left' }}>
          Tags is our newest feature to make planning and organizing even simpler. Tags allow you to group and filter
          installations across all clients and locations.
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left' }}>
          We have also added a new section, where we can show you news and highlights from Flowplan. It is not visible
          to guest users.
        </FpText>

        <FpDivider height="0.5rem" marginY="0.5rem" />
        <FpDivider hidden height="1rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Introducing tags
        </FpText>

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          With tags you can easily categorize and manage your installations with custom labels like “Ignore - bad
          payer”, "Holiday closed," or "Very important."
        </FpText>

        <FpDivider hidden height="1rem" />
        <img
          srcSet={tagsList}
          src={tagsList}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Installations list with tags'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ fontStyle: 'italic', fontSize: '12px' }}>
          Tags are now visible in the installation list.
        </FpText>

        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          After adding tags, you can choose to either exclude or include installations with your tags on the dashboard
          and installation list, allowing you to ignore, prioritize and inform all the people in your team.
        </FpText>
        <FpDivider hidden height="0.25rem" />
        <img
          srcSet={tagsDashboard}
          src={tagsDashboard}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: '2px solid #22242642' }}
          alt={'Dashboard map now has a new section to filter using tags'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ fontStyle: 'italic', fontSize: '12px' }}>
          It is now possible to exclude installations with specific tags on them.
        </FpText>
        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          Tags are available in both Backoffice and Fieldoffice ensuring everyone is always kept up to date!
        </FpText>
        <FpDivider hidden height="0.25rem" />
        <img
          srcSet={fieldOffice}
          src={fieldOffice}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Field Office tags'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ fontStyle: 'italic', fontSize: '12px' }}>
          You can also view and manage tags via our mobile app.
        </FpText>

        <FpDivider hidden height="0.5rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2, fontWeight: 'bold' }}>
          Note: Tags are visible to all users except guests.
        </FpText>
      </div>
    </FpModalConfirmSimple>
  );
});

export default ReleaseInformationTags;
