import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { blue, orange } from '@mui/material/colors';
import { action, thunk, thunkOn } from 'easy-peasy';
import { handleDeleteRequest, handlePostRequest, handlePutRequest } from '../../server-requests/server-requests';
import { IResponse } from '../../server-requests/server-requests-model';
import IInstallationsModel, {
  CompanyDetailsModel,
  GraphDataModel,
  IInstallationStatusList,
  IStatusAllCompanies,
  IStatusSingleCompany
} from '../types/installations-model';
import { graphColors } from './installations-util';
import { FlowplanProductTypes } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';

const defaultDataAllCompanies: IStatusAllCompanies = {
  allFlowplanClients: [],
  allOfflineCount: [],
  allOfflinePercent: [],
  allInventoryDevelopment: [],
};
const defaultDataSingleCompany: IStatusSingleCompany = {
  singleFlowplanClients: [],
  singleInventoryDevelopment: [],
  singleOfflineCount: [],
  singleOfflinePercent: [],
};

const installationsModel: IInstallationsModel = {
  loadingSensors: false,

  sensors: [],

  getInstallations: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingSensors(true);
    const route = payload ? `${Routes.sensors}${payload.flowplanProductType}` : Routes.sensors

    const requestResponse = await getStoreActions().serverRequestsModel.get({ route, params: { take: 20000 } });
    if (requestResponse.success) {
      actions.setSensors(requestResponse.data);
      actions.setInstallationsList(requestResponse.data);
    }
    actions.setLoadingSensors(false);
  }),

  setLoadingSensors: action((state, payload) => {
    state.loadingSensors = payload;
  }),
  setSensors: action((state, payload) => {
    state.sensors = payload ? payload : [];
  }),

  installationsList: [],
  setInstallationsList: action((state, payload) => {
    state.installationsList = payload;
  }),

  loadingInstallationStatusList: false,
  setLoadingInstallationStatusList: action((state, payload) => {
    state.loadingInstallationStatusList = payload;
  }),

  statusAllCompanies: defaultDataAllCompanies,
  setStatusAllCompanies: action((state, payload) => {
    state.statusAllCompanies = payload;
  }),

  getStatusAllCompanies: thunk(async (actions, payload, { getState, getStoreActions }) => {
    actions.setLoadingInstallationStatusList(true);

    const route = '/customerSensorLogs';
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: route, params: payload });
    actions.setLoadingInstallationStatusList(false);
    if (!requestResponse.success) {
      actions.setStatusAllCompanies(defaultDataAllCompanies);
      return;
    }
    const installationStatus: IInstallationStatusList[] = requestResponse.data as IInstallationStatusList[];

    const allFlowplanClients: CompanyDetailsModel[] = [];
    const allOfflineCount: GraphDataModel[] = [];
    const allOfflinePercent: GraphDataModel[] = [];
    const allInventoryDevelopment: GraphDataModel[] = [];

    const installationsSummary = getState().installationStatusSummary;

    installationStatus.forEach((item) => {
      const { assigned, date, name, onlineCount, totalCount } = item;
      const totalTyped = Number(totalCount);
      const flowplanClient: CompanyDetailsModel | undefined = allFlowplanClients.find(
        (company) => company.name === name,
      );
      if (!flowplanClient) {
        allFlowplanClients.push({ name, color: graphColors[allFlowplanClients.length] });
      }

      const offlineNumber = totalTyped - onlineCount;
      const offlineNumberFixed = offlineNumber.toFixed(0);

      let totalOfflineInstallations = offlineNumberFixed;
      if (installationsSummary) {
        totalOfflineInstallations = ((offlineNumber / installationsSummary.result.totalCount) * 100).toFixed(2);
      }
      const offlinePercentCheck: GraphDataModel | undefined = allOfflinePercent.find(
        (graphItem) => graphItem.date === date,
      );
      if (offlinePercentCheck) {
        offlinePercentCheck[name] = totalOfflineInstallations;
      } else {
        const obj: GraphDataModel = {
          date,
          [name]: totalOfflineInstallations,
        };
        allOfflinePercent.push(obj);
      }

      const offlineCountCheck: GraphDataModel | undefined = allOfflineCount.find(
        (graphItem) => graphItem.date === date,
      );
      if (offlineCountCheck) {
        offlineCountCheck[name] = offlineNumberFixed;
      } else {
        const obj: GraphDataModel = {
          date,
          [name]: offlineNumberFixed,
        };
        allOfflineCount.push(obj);
      }

      const inventoryStatus = (assigned - totalTyped).toFixed(0);
      const allInventoryDevelopmentCheck: GraphDataModel | undefined = allInventoryDevelopment.find(
        (graphItem) => graphItem.date === date,
      );
      if (allInventoryDevelopmentCheck) {
        allInventoryDevelopmentCheck[name] = inventoryStatus;
      } else {
        const obj: GraphDataModel = {
          date,
          [name]: inventoryStatus,
        };
        allInventoryDevelopment.push(obj);
      }
    });

    actions.setStatusAllCompanies({
      allFlowplanClients,
      allOfflineCount,
      allInventoryDevelopment,
      allOfflinePercent,
    });
  }),

  statusSingleCompany: defaultDataSingleCompany,
  setStatusSingleCompany: action((state, payload) => {
    state.statusSingleCompany = payload;
  }),

  getStatusSingleCompany: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingInstallationStatusList(true);

    const route = '/customerSensorLogs';
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: route, params: payload });
    actions.setLoadingInstallationStatusList(false);
    if (!requestResponse.success) {
      actions.setStatusSingleCompany(defaultDataSingleCompany);
      return;
    }
    const installationStatus: IInstallationStatusList[] = requestResponse.data as IInstallationStatusList[];

    const flowplanClientNegative: CompanyDetailsModel = { name: installationStatus[0].name, color: orange[600] };
    const flowplanClientPositive: CompanyDetailsModel = { name: installationStatus[0].name + '-pos', color: blue[400] };

    const singleFlowplanClients: CompanyDetailsModel[] = [flowplanClientNegative, flowplanClientPositive];

    const singleInventoryDevelopment: GraphDataModel[] = [];
    const singleOfflineCount: GraphDataModel[] = [];
    const singleOfflinePercent: GraphDataModel[] = [];

    installationStatus.forEach((item) => {
      const { assigned, date, name, onlineCount, totalCount } = item;
      const totalTyped = Number(totalCount);

      const offlinePercent = (((totalTyped - onlineCount) / totalTyped) * 100).toFixed(1);
      const onlinePercent = ((onlineCount / totalTyped) * 100).toFixed(1);
      singleOfflinePercent.push({
        date,
        [name]: offlinePercent,
        [name + '-pos']: onlinePercent,
      });

      const offlineNumber = (totalTyped - onlineCount).toFixed(0);
      singleOfflineCount.push({
        date,
        [name]: offlineNumber,
        [name + '-pos']: onlineCount.toFixed(0),
      });

      const inventoryStatus = (assigned - totalTyped).toFixed(0);
      singleInventoryDevelopment.push({
        date,
        [name]: inventoryStatus,
        [name + '-pos']: totalTyped.toFixed(1),
      });
    });

    actions.setStatusSingleCompany({
      singleFlowplanClients,
      singleInventoryDevelopment,
      singleOfflineCount,
      singleOfflinePercent,
    });
  }),
  retrieveInstallationStatusSummary: thunk(async (actions, payload, { getStoreActions }) => {
    const route = '/customerSensorLogsSummary';
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: route });
    if (requestResponse.success) {
      actions.setInstallationStatusSummary(requestResponse.data);
    }
  }),
  installationStatusSummary: null,
  setInstallationStatusSummary: action((state, payload) => {
    state.installationStatusSummary = payload;
  }),

  getInstallationTags: thunk(async (actions, payload, { getStoreActions }) => {
    const route = Routes.installationTags + payload;
    actions.setLoadingInstallationTags(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: route });
    actions.setLoadingInstallationTags(false);
    if (!requestResponse.success) {
      actions.setInstallationTags([]);
      return;
    }

    actions.setInstallationTags(requestResponse.data);
  }),
  installationTags: [],
  setInstallationTags: action((state, payload) => {
    state.installationTags = payload;
  }),
  loadingInstallationTags: false,
  setLoadingInstallationTags: action((state, payload) => {
    state.loadingInstallationTags = payload;
  }),
  createInstallationTag: thunk(async (actions, payload) => {
    const requestResponse = await handlePostRequest({ tags: [payload] }, Routes.installationTags, true);
    return requestResponse;
  }),
  removeInstallationTag: thunk(async (actions, payload) => {
    return await handleDeleteRequest({ id: payload.installationTagId }, Routes.installationTags);
  }),
  updateInstallationTag: thunk(async (actions, payload) => {
    return await handlePutRequest(payload, Routes.installationTags, true);
  }),


  onActionsThatUpdateLocations: thunkOn(
    (actions, storeActions) => [
      storeActions.deviceActionsModel.addSensor,
      storeActions.deviceActionsModel.updateSensor,
      storeActions.deviceActionsModel.removeSensor,
      storeActions.deviceActionsModel.changeSerial,
      storeActions.deviceActionsModel.changeWaterFilter,
      storeActions.deviceActionsModel.performMaintenance,
      storeActions.deviceActionsModel.addUsage,
      storeActions.companyModel.removeTagForCompany
    ],
    async (actions) => {
      actions.getInstallations({ flowplanProductType: FlowplanProductTypes.WaterFilter });
    },
  ),
  onActionsThatUpdateInstallationTags: thunkOn(
    (actions, storeActions) => [
      actions.createInstallationTag,
      actions.removeInstallationTag,
      actions.updateInstallationTag,
    ],
    async (actions, { payload }, { getState, getStoreActions }) => {
      actions.getInstallationTags(payload.sensorId);
      const installationResponse: IResponse = await getStoreActions().deviceActionsModel.getInstallationById(payload.sensorId);
      if (!installationResponse.success) {
        return;
      }
      const installation = installationResponse.data as IDeviceView;
      getStoreActions().graphSettingsModel.setSelectedInstallation(installation);
      const installations = getState().installationsList;
      const itemLookup = installations.find((item) => item.infoBasic.id === payload.sensorId);
      if (itemLookup) {
        const index = installations.indexOf(itemLookup);
        if (index !== -1) {
          const updatedInstallations = installations.map(a => { return { ...a } })
          updatedInstallations[index] = installation;
          actions.setInstallationsList(updatedInstallations);
        }
      }
    },
  ),
};

export default installationsModel;
