import { Box, Fade, useScrollTrigger } from '@mui/material';

interface Props {
  children: React.ReactElement;
}

const ScrollToTop = (props: Props): JSX.Element => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    window.scroll({
      behavior: 'auto',
      top: 0,
    });
  };
  return (
    <Fade in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        {children}
      </Box>
    </Fade>
  );
};

export default ScrollToTop;
