import { IFilter } from '@flowplan/flowplan-shared';
import { useEffect, useState } from 'react';
import { useStoreState } from '../Store';

const defaultWaterfilter: IFilter = {
  id: -1,
  filterProducerId: -1,
  fixedCapacity: 0,
  hasBypassOption: 0,
  hasOvenVariant: 0,
  hasSteamVariant: 0,
  sortIndex: 0,
  totalSoftening: 0,
  name: 'Please select item'
};

type Props = {
  filterProducerId: number;
  filterProducerHasData: boolean;
}

function useWaterFilters({ filterProducerHasData, filterProducerId }: Props) {
  const filters = useStoreState((state) => state.staticDataModel.filters);

  const [data, setData] = useState<IFilter[]>([]);

  useEffect(() => {
    if (filterProducerId === -1 || !filterProducerHasData) {
      setData([defaultWaterfilter]);
      return;
    }

    const newFiltersList: IFilter[] = filters[filterProducerId] as IFilter[];
    const newFilterListClone = [defaultWaterfilter, ...newFiltersList];
    setData(newFilterListClone);
  }, [filters, filterProducerId, filterProducerHasData]);

  return data;
}

export default useWaterFilters;
