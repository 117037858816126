import { SxProps, Theme, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ReactNode } from 'react';

type props = {
  children: ReactNode;
  variant: Variant;
  sx?: SxProps<Theme>;
  componentType?: 'div' | 'span' | 'h1' | 'h2';
  noWrap?: boolean;
  className?: string;
  onClick?: () => void;
};

const FpText = ({ children, componentType, sx, variant, className, onClick, noWrap = false }: props): JSX.Element => {
  return (
    <Typography
      sx={sx}
      variant={variant}
      component={componentType ? componentType : 'div'}
      noWrap={noWrap}
      className={className}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </Typography>
  );
};

FpText.displayName = 'FPTitle';

export default FpText;
