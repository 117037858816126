import { useMediaQuery, useTheme } from '@mui/material';
import { Fragment, memo } from 'react';
import { useStoreState } from '../../../../Store';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpModalConfirmSimple from '../../../common/components/FpModalConfirmSimple/FpModalConfirmSimple';
import FpText from '../../../common/components/FpText/FpText';
import { isUserGuest } from '../../../users/types/users-configs';
import { NEWS_IMAGE_BORDER } from '../../news-and-updates-consts';
import cleaningCalendar from './cleaning-calendar.webp';
import featuredProducts from './featured-products.webp';
import flowplanTapUpdates from './flowplan-tap-updates.webp';

type Props = {
  closeModal(closeNews?: boolean): void;
  showModal: boolean;
};

const ReleaseInformationCleaningAndImprovements = memo(({ closeModal, showModal }: Props): JSX.Element => {
  const showMenu = useStoreState((state) => state.authModel.showMenu);
  const currentUser = useStoreState((state) => state.authModel.currentUser);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (!showMenu || isUserGuest(currentUser.role)) {
    return <Fragment />;
  }

  return (
    <FpModalConfirmSimple
      closeModal={closeModal}
      largerContentMargins={false}
      fullScreen={fullScreen}
      maxWidth="md"
      label="Close"
      modalTitle="2025 - March update"
      showModal={showModal}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center' }}>
        <FpText variant="body1" sx={{ textAlign: 'left' }}>
          We are very thrilled at announcing our our newest feature: <strong>Cleaning detection!</strong> Furthermore we
          have added improvements to our Orders section, made improvements to our mobile app and made adjustments to our
          new Taps feature.
        </FpText>
        <FpDivider height="0.5rem" marginY="0.5rem" />
        <FpDivider hidden height="0.5rem" />

        <FpText variant="h5" sx={{ textAlign: 'left', mx: 2 }}>
          Cleaning detection
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We have heard from many customers that being able to track when customers have performed cleaning on their
          machines is essential. Therefore we are happy to introduce our latest feature, which is the ability for us to
          track and show when a machine has been cleaned. We have added 2 new locations where you can track cleaning. A
          calendar for overview and a new column in the Installations list, where you can see date of the last cleaning
          we have detected.
        </FpText>

        <FpDivider hidden height="0.5rem" marginY="0.25rem" />
        <img
          srcSet={cleaningCalendar}
          src={cleaningCalendar}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'In the cleaning calendar you are able to see when and how frequently cleaning is done on a machine.'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          In the cleaning calendar you are able to see when and how frequently cleaning is done on a machine.
        </FpText>

        <FpDivider height="0.5rem" marginY="1rem" />

        <FpText variant="h5" sx={{ textAlign: 'left', mx: 2 }}>
          Order flow improvements
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We have added a new featured products section, to make it easier to start a new order. You are also able to
          see the price of the product when you hover over any of the products.
        </FpText>

        <FpDivider hidden height="0.5rem" marginY="0.25rem" />
        <img
          srcSet={featuredProducts}
          src={featuredProducts}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'The new featured products section on the order page'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          The new featured products section on the order page.
        </FpText>

        <FpDivider height="0.5rem" marginY="1rem" />

        <FpText variant="h5" sx={{ textAlign: 'left', mx: 2 }}>
          Flowplan Taps updates
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          After the release of Flowplan Taps last month, we have been showcasing it and in this release, we are updating
          sections that received great feedback.
        </FpText>

        <FpDivider hidden height="0.5rem" marginY="0.25rem" />
        <img
          srcSet={flowplanTapUpdates}
          src={flowplanTapUpdates}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'The taps dashboard has been updated with a new section to manage stock types.'}
          loading="lazy"
        />

        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          The taps dashboard has been updated with a new section to manage stock types.
        </FpText>

        <FpDivider height="0.5rem" marginY="1rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Other updates
        </FpText>

        <ul style={{ marginLeft: 2 }}>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              Field Office will now automatically let you know when a new app version has been released.
            </FpText>
          </li>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              Field Office has been updated with a new layout for installation details.
            </FpText>
          </li>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              Backoffice and Field Office now block you from deleting tags that are in use on many installations and
              explain why some tags cannot be deleted.
            </FpText>
          </li>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              Changing the water filter type has been updated to have more information and a new layout.
            </FpText>
          </li>
        </ul>
      </div>
    </FpModalConfirmSimple>
  );
});

export default ReleaseInformationCleaningAndImprovements;
