import { Color, formatNumberNoDecimals, getSerialNumberToShow, notAvailable } from '@flowplan/flowplan-shared';
import { DataParsingStatus } from '@flowplan/flowplan-shared/lib/interfaces/networkEUIToFlowplanSerialDb';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { Marker } from 'react-leaflet';
import { useStoreActions, useStoreState } from '../../../../Store';
import { LocalStore } from '../../../../Utility/LocalStorageFunctions';
import { getDateObject } from '../../../../Utility/time';
import { DataGridCellWidth } from '../../../../common/constsFrontend';
import {
  CustomTooltip,
  DataGridTable,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpContainer from '../../../common/components/FpContainer/FpContainer';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import MapLeaflet from '../../../common/components/FpMap/MapLeaflet';
import MapMarkerCluster from '../../../common/components/FpMap/MapMarkerCluster';
import FpText from '../../../common/components/FpText/FpText';
import { iconOverdue } from '../../../dashboard/components/Map.Markers/MarkerIcons';
import { IFrontendBeamSerialNotInSystem } from '../../services/company-model';
import { createClusterIconDataNotInSystem } from './DataNotInSystemDialog.Cluster.Utilities';
import NotInSystemBeamInfo from './NotInSystemBeamInfo';

type Props = {
  showNotInSystemDialog: boolean;
  handleClose(): void;
};

const DataNotInSystemDialog = ({ handleClose, showNotInSystemDialog }: Props): JSX.Element => {
  const loadingBeamsSerialNotInSystem = useStoreState((state) => state.companyModel.loadingBeamsSerialNotInSystem);
  const beamSerialsNotInSystem = useStoreState((state) => state.companyModel.beamSerialNotInSystem);
  const numNotInSystemWithPosition = useStoreState((state) => state.companyModel.numNotInSystemWithPosition);
  const flowplanClientSelected = useStoreState((state) => state.adminDataModel.flowplanClientSelected);

  const toggleSensorDetailsModal = useStoreActions((actions) => actions.newInstallationModel.toggleNewSensorModal);
  const parseModalNewSensorData = useStoreActions(
    (actions) => actions.newInstallationModel.parseModalNewInstallationData,
  );

  const [notInSystemSerial, setNotInSystemSerial] = useState<IFrontendBeamSerialNotInSystem>();

  const addNewInstallation = (flowplanDeviceId: string | undefined) => {
    //assigned serials and unassigned serials
    if (flowplanClientSelected !== 0) {
      toggleSensorDetailsModal({
        flowplanDeviceId,
        showModal: true,
        shouldRetrieveDetailsData: false,
      });
      parseModalNewSensorData({ installationData: undefined });
    } else {
      return;
    }
  };

  const onClose = () => {
    handleClose();
  };

  const columnConfig: GridColDef[] = [
    {
      field: 'action',
      headerName: 'Action',
      type: 'actions',
      flex: 1,
      minWidth: DataGridCellWidth.xSmall,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          color="secondary"
          label="New Installation"
          disabled={params.row.dataParsingStatus !== DataParsingStatus.DEFAULT}
          icon={
            <CustomTooltip title="New Installation">
              <AddCircleIcon fontSize="medium" />
            </CustomTooltip>
          }
          onClick={() => {
            addNewInstallation(params.row.flowplanDeviceId);
          }}
        />,
      ],
    },
    {
      field: 'flowplanDeviceId',
      headerName: 'Serial',
      flex: 1,
      minWidth: DataGridCellWidth.small,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value == null) {
          return notAvailable;
        }
        const valueFormatted = getSerialNumberToShow(params.value);
        return `${valueFormatted}`;
      },
    },
    {
      field: 'usage',
      headerName: 'Usage',
      flex: 1,
      minWidth: DataGridCellWidth.small,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        let largeFlowmeterUsage = Math.ceil(params.value / 0.283);
        if (largeFlowmeterUsage < 0) {
          largeFlowmeterUsage = 0;
        }
        let smallFlowmeterUsage = Math.ceil(params.value / 1.134);
        if (smallFlowmeterUsage < 0) {
          smallFlowmeterUsage = 0;
        }

        return `${formatNumberNoDecimals(largeFlowmeterUsage)}L (3/4") ${formatNumberNoDecimals(
          smallFlowmeterUsage,
        )}L (3/8")`;
      },
      renderCell: (params: GridRenderCellParams) => {
        let largeFlowmeterUsage = Math.ceil(params.value / 0.283);
        if (largeFlowmeterUsage < 0) {
          largeFlowmeterUsage = 0;
        }
        let smallFlowmeterUsage = Math.ceil(params.value / 1.134);
        if (smallFlowmeterUsage < 0) {
          smallFlowmeterUsage = 0;
        }
        return (
          <div>
            <div>
              <span>3/4" =</span>
              <Box component={'span'} sx={{ fontWeight: 600, pl: 0.5 }}>
                {formatNumberNoDecimals(largeFlowmeterUsage)}L
              </Box>
            </div>
            <div>
              <span>3/8" =</span>
              <Box component={'span'} sx={{ fontWeight: 600, pl: 0.5 }}>
                {formatNumberNoDecimals(smallFlowmeterUsage)}L
              </Box>
            </div>
          </div>
        );
      },
    },
    {
      field: 'payloadReceivedCount',
      headerName: 'Data',
      flex: 1,
      minWidth: DataGridCellWidth.xSmall,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
    },
    {
      field: 'firstPayloadReceivedAt',
      headerName: 'First Date',
      type: 'date',
      flex: 1,
      minWidth: DataGridCellWidth.small,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params: GridValueFormatterParams<string>): string => {
        if (params.value) {
          return getDateObject(params.value).format(dateFormatYMD);
        } else {
          return notAvailable;
        }
      },
    },
    {
      field: 'lastPayloadReceivedAt',
      headerName: 'Last Date',
      type: 'date',
      flex: 1,
      minWidth: DataGridCellWidth.small,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params: GridValueFormatterParams<string>): string => {
        if (params.value) {
          return getDateObject(params.value).format(dateFormatYMD);
        } else {
          return notAvailable;
        }
      },
    },
    {
      field: 'firmware',
      headerName: 'Firmware',
      flex: 1,
      minWidth: DataGridCellWidth.xSmall,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        if (params.value !== notAvailable) {
          return (
            <>
              <span>{params.value ? params.value : notAvailable}</span>
            </>
          );
        } else {
          return params.value;
        }
      },
    },
  ];

  return (
    <>
      <Dialog open={showNotInSystemDialog} onClose={onClose} maxWidth="lg">
        <DialogTitle color="error">Beams installed at customer, but not created in Backoffice</DialogTitle>

        <DialogContent>
          <FpText variant="h6">
            Number of Beams that have sent data to our system in the last 7 days, but are not registered in Backoffice:{' '}
            <strong>{beamSerialsNotInSystem.length}</strong>.
          </FpText>
          <FpDivider height="1rem" hidden />
          <FpText variant="body1">Map of estimated locations:</FpText>
          {numNotInSystemWithPosition > 0 ? (
            <>
              <FpText variant="body1">
                We have been able to roughly estimate the location of <strong>{numNotInSystemWithPosition}</strong> of the
                beams. They are visible on the map below:
              </FpText>
              <MapLeaflet
                styling=""
                keyLat={LocalStore.NotInSystemLat}
                keyLng={LocalStore.NotInSystemLng}
                keyZoom={LocalStore.NotInSystemZoom}
                search={null}
              >
                <MapMarkerCluster iconCreateFunction={createClusterIconDataNotInSystem}>
                  {beamSerialsNotInSystem.map((marker, index) => {
                    if (!marker.notInSystemLocationEstimate) {
                      return null;
                    }
                    const { latitude, longitude } = marker.notInSystemLocationEstimate;

                    return (
                      <Marker
                        eventHandlers={{
                          click: () => {
                            setNotInSystemSerial(marker);
                          },
                        }}
                        key={index}
                        icon={iconOverdue}
                        position={[latitude, longitude]}
                        {...marker}
                      />
                    );
                  })}
                </MapMarkerCluster>
              </MapLeaflet>
            </>
          ) : (
            <FpContainer backgroundColor="#f9fafb">
              <FpDivider height="1rem" hidden marginY="1rem" />
              <FpText variant="h6" sx={{ textAlign: 'center', color: Color.Overdue }}>
                No estimates available at the moment, for your "not in system" Beams.
              </FpText>
              <FpDivider height="1rem" marginY="1rem" hidden />
            </FpContainer>
          )}
          <FpDivider height="0.5rem" hidden />
          <FpText variant="body1">
            Beams we have detected: <strong>{beamSerialsNotInSystem.length}</strong>
          </FpText>
          <DataGridTable
            rows={beamSerialsNotInSystem}
            columns={columnConfig}
            loading={loadingBeamsSerialNotInSystem}
            showRowSelectionCursor={false}
            showToolbar={false}
          />
        </DialogContent>
        <DialogActions>
          <FpButton color="error" onClick={onClose}>
            cancel
          </FpButton>
        </DialogActions>
      </Dialog>
      {
        notInSystemSerial ?
          <NotInSystemBeamInfo notInSystemSerial={notInSystemSerial} toggleNotInSystemBeamInfoDialog={() => {
            setNotInSystemSerial(undefined)
          }} />
          : <></>
      }
    </>
  );
};

export default DataNotInSystemDialog;
