import { InstallationTag } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';

export enum TagActionType {
    DELETE_REGULAR,
    DELETE_BLOCKED_GLOBAL,
    DELETE_BLOCKED_MULTIPLE_USAGE,
    LOADING,
}

/**
 * Color codes for tags (RGBA values)
 */
export enum ColorCodesForTags {
    Blue = 'rgba(56, 167, 223, 1)',
    LightGreen = 'rgba(63, 176, 69, 1)',
    Red = 'rgba(219, 40, 40, 1)',
    Yellow = 'rgba(219, 174, 14, 1)',
    DarkGreen = 'rgba(56, 118, 29, 1)',
    Orange = 'rgba(237, 124, 49, 1)',
    Grey = 'rgba(150, 150, 150, 1)',
    Black = 'rgba(0, 0, 0, 1)',
}

/**
 * Semantic color names for buttons
 */
export enum ColorOptionsNames {
    Blue = 'primary',
    LightGreen = 'secondary',
    Red = 'error',
    Yellow = 'info',
    DarkGreen = 'success',
    Orange = 'warning',
    Grey = 'default',
    Black = 'global',
}

// All available color options for buttons
export const COLOR_OPTIONS_BUTTONS = [
    ColorOptionsNames.Blue,
    ColorOptionsNames.LightGreen,
    ColorOptionsNames.Red,
    ColorOptionsNames.Yellow,
    ColorOptionsNames.DarkGreen,
    ColorOptionsNames.Orange,
    ColorOptionsNames.Grey,
    ColorOptionsNames.Black
] as const;

export type ColorOptionsForButtons = typeof COLOR_OPTIONS_BUTTONS[number];

// Default tag color
export const DEFAULT_TAG_COLOR: ColorOptionsForButtons = ColorOptionsNames.Grey;

// Type definition for radio button options
export type RadioButtonOptions = {
    color: ColorOptionsForButtons;
};

// Standard color options available to all users
export const COLOR_OPTIONS: RadioButtonOptions[] = [
    { color: ColorOptionsNames.Grey },
    { color: ColorOptionsNames.Blue },
    { color: ColorOptionsNames.LightGreen },
    { color: ColorOptionsNames.DarkGreen },
    { color: ColorOptionsNames.Red },
    { color: ColorOptionsNames.Yellow },
    { color: ColorOptionsNames.Orange },
];

// Alert styles for tag-related modals and notifications
export const ALERT_STYLES = {
    base: {
        padding: '12px 16px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '12px',
        marginBottom: '16px',
    },
    warning: {
        backgroundColor: '#fff8e1',
        border: '1px solid #ffe082',
    },
    error: {
        backgroundColor: '#ffebee',
        border: '1px solid #ffcdd2',
    },
    info: {
        backgroundColor: '#e3f2fd',
        border: '1px solid #bbdefb',
    },
};

// Menu item style constants for tag option menus
export const MENU_ITEM_STYLES = {
    noHover: {
        '&:hover': { background: 'transparent' },
        '&.Mui-focused': { background: 'transparent' },
    },
    disabled: {
        '&.Mui-disabled': { opacity: 0.7 },
    },
    conditionalHover: (isDisabled: boolean) => ({
        '&.Mui-disabled': { opacity: 0.7 },
        '&:hover': { background: isDisabled ? 'transparent' : undefined },
    }),
};

// Standard messages for tag-related notifications
export const NOTIFICATION_MESSAGES = {
    tagUpdated: 'Tag updated successfully.',
    tagUpdateFailed: 'Failed to update tag.',
    tagDeleted: 'Tag deleted successfully.',
    tagDeleteFailed: (tagName: string, message: string) => `Tag: ${tagName} could not be deleted. ${message}`,
    globalTagDeletion: 'This is a global tag and cannot be deleted.',
    permissionError: 'Only SUPER ADMINS are allowed to delete tags.',
    tagNotSelected: 'Tag not selected, please try again',
    multipleUsage: (count: number) => `Cannot delete tag: It is currently used by ${count} installations.`,
    countFetchFailed: 'Failed to fetch installation count.',
};

/**
 * Returns color options available based on user's role
 * @param role The user's role
 * @returns Array of color options available to the user
 */
export const getColorOptionsForUser = (role: RoleType): RadioButtonOptions[] => {
    if (role !== RoleType.SUPER_ADMIN) {
        return COLOR_OPTIONS;
    }

    const adminColor: RadioButtonOptions = {
        color: ColorOptionsNames.Black
    };

    const adminColorOptions = [...COLOR_OPTIONS, adminColor];
    return adminColorOptions;
};

/**
 * Parses a color string value to its corresponding ColorOptionsForButtons value
 * @param value The color value to parse
 * @returns The matching ColorOptionsForButtons value or null if no match
 */
export const parseStringAsColorOption = (value: string): ColorOptionsForButtons | null => {
    // First check if the value is already a valid ColorOptionsForButtons
    const colorOption = COLOR_OPTIONS_BUTTONS.find((validName) => validName === value);
    if (colorOption) {
        return colorOption;
    }

    // Otherwise, map from color code to option name
    switch (value) {
        case ColorCodesForTags.Blue:
            return ColorOptionsNames.Blue;
        case ColorCodesForTags.LightGreen:
            return ColorOptionsNames.LightGreen;
        case ColorCodesForTags.Red:
            return ColorOptionsNames.Red;
        case ColorCodesForTags.Yellow:
            return ColorOptionsNames.Yellow;
        case ColorCodesForTags.DarkGreen:
            return ColorOptionsNames.DarkGreen;
        case ColorCodesForTags.Orange:
            return ColorOptionsNames.Orange;
        case ColorCodesForTags.Grey:
            return ColorOptionsNames.Grey;
        case ColorCodesForTags.Black:
            return ColorOptionsNames.Black;
        default:
            return null;
    }
};

/**
 * Gets the RGBA color code for a given color option
 * @param color The color option to convert
 * @returns The corresponding RGBA color code
 */
export const getColorForButton = (color: ColorOptionsForButtons): string => {
    switch (color) {
        case ColorOptionsNames.Grey:
            return ColorCodesForTags.Grey;
        case ColorOptionsNames.Black:
            return ColorCodesForTags.Black;
        case ColorOptionsNames.Blue:
            return ColorCodesForTags.Blue;
        case ColorOptionsNames.LightGreen:
            return ColorCodesForTags.LightGreen;
        case ColorOptionsNames.Red:
            return ColorCodesForTags.Red;
        case ColorOptionsNames.Yellow:
            return ColorCodesForTags.Yellow;
        case ColorOptionsNames.DarkGreen:
            return ColorCodesForTags.DarkGreen;
        case ColorOptionsNames.Orange:
            return ColorCodesForTags.Orange;
        default:
            return ColorCodesForTags.Grey;
    }
};

/**
 * Determines if a user can modify or delete a company tag
 * @param role The user's role
 * @param flowplanClientId The client ID of the tag
 * @returns Boolean indicating whether user can modify/delete the tag
 */
export const canUserModifyOrDeleteCompanyTag = (role: RoleType, flowplanClientId: number | null): boolean => {
    if (role === RoleType.GUEST) {
        return false;
    }
    if (role === RoleType.SUPER_ADMIN) {
        return true;
    }
    return flowplanClientId !== null;
};

/**
 * Determines if a user can add a tag to an installation
 * @param role The user's role
 * @returns Boolean indicating whether user can add tags
 */
export const canUserAddTagToInstallation = (role: RoleType): boolean => {
    return role !== RoleType.GUEST;
};

/**
 * Determines if a user can modify or delete an installation tag
 * @param role The user's role
 * @param tag The installation tag
 * @returns Boolean indicating whether user can modify/delete the tag
 */
export const canUserModifyOrDeleteInstallationTag = (role: RoleType, tag: InstallationTag): boolean => {
    const isSuperAdmin = role === RoleType.SUPER_ADMIN;
    const isGuest = role === RoleType.GUEST;
    return isSuperAdmin || (!tag.locked && !isGuest);
};
