import { MarkerCluster } from 'leaflet';
import { ReactNode } from 'react';
import MarkerClusterGroup from 'react-leaflet-cluster';

type MapMarkerClusterProps = {
  children: ReactNode;
  iconCreateFunction(cluster: MarkerCluster): L.DivIcon;
};

const MapMarkerCluster = ({ children, iconCreateFunction }: MapMarkerClusterProps): JSX.Element => {
  return (
    <MarkerClusterGroup iconCreateFunction={iconCreateFunction} maxClusterRadius={40}>
      {children}
    </MarkerClusterGroup>
  );
};

export default MapMarkerCluster;
