import { Alert, Stack } from '@mui/material';
import styles from './PasswordGenerated.module.scss';

const PasswordGenerated = (): JSX.Element => {
  return (
    <Stack sx={{ width: '100%' }} spacing={0.25}>
      <Alert className={styles.alertStyle} severity="info">
        Password will be automatically generated and sent by email
      </Alert>
    </Stack>
  );
};

export default PasswordGenerated;
