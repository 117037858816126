import { daysRemainingStandardValue, DeviceStatus } from '@flowplan/flowplan-shared';
import { ITaskInformation } from './tasks-utility';

interface ICheckIfBatteryTaskShouldBeShown {
  daysBetweenTasks: number;
  daysLeftTask: number;
  batteryRemainingDays: number;
  batteryShowInformation: boolean;
}

export const checkIfBatteryTaskShouldBeShown = ({
  daysBetweenTasks,
  daysLeftTask,
  batteryRemainingDays,
  batteryShowInformation,
}: ICheckIfBatteryTaskShouldBeShown): boolean => {
  const daysLeftBattery = batteryShowInformation ? batteryRemainingDays : daysRemainingStandardValue;

  if (daysLeftBattery === null) {
    // no data received yet
    return false;
  }

  if (!batteryShowInformation) {
    return true;
  }

  if (isBatteryAlmostUsed(daysLeftBattery)) {
    return true;
  }

  if (daysBetweenTasks < daysRemainingStandardValue) {
    return isBatteryChangeComingBeforeSoonerThanNextTask({ daysLeftBattery, daysBetweenTasks, daysLeftTask });
  }

  return false;
};

interface ITaskInformationBattery {
  deviceStatus: DeviceStatus;
  firmware: string;
  batteryRemainingDays: number;
  batteryShowInformation: boolean;
}

export const taskInformationBattery = ({
  deviceStatus,
  firmware,
  batteryRemainingDays,
  batteryShowInformation
}: ITaskInformationBattery): ITaskInformation => {
  if (deviceStatus === DeviceStatus.Pending) {
    return {
      infoHeader: 'Beam pending',
      infoHighlight: '',
      infoTooltip: 'Beam has not sent data yet. Ensure Beam has beeped 4 times after connecting to the flowmeter.',
      infoList: 'Pending',
    }
  }

  if (firmware === undefined) {
    return {
      infoHeader: 'Firmware version not known',
      infoHighlight: '',
      infoTooltip: 'Beam has not sent firmware version yet. This will be sent automatically within the next few days.',
      infoList: 'Data needed',
    }
  }

  let infoHeader = '';
  let infoHighlight = '';
  let infoList = '';
  let infoTooltip = '';

  if (!batteryShowInformation) {
    return {
      infoHeader,
      infoHighlight,
      infoList,
      infoTooltip,
    }
  }

  const batteryDaysLeft = Math.floor(batteryRemainingDays);
  if (batteryDaysLeft > 0) {
    infoTooltip =
      'The battery is running out of power, please change it during the next ' + batteryDaysLeft + ' days.';
  } else {
    infoTooltip =
      'Battery is very low, it will run out of power very soon!';
  }

  infoHeader = 'Battery change';
  infoHighlight = batteryDaysLeft + ' days left';
  infoList = batteryDaysLeft + ' days';

  return {
    infoHeader,
    infoHighlight,
    infoList,
    infoTooltip,
  };
};

const isBatteryAlmostUsed = (daysLeftBattery: number) => {
  return daysLeftBattery < 30;
};

interface IIsBatteryChangeComingBeforeSoonerThanNextTask {
  daysLeftTask: number;
  daysLeftBattery: number;
  daysBetweenTasks: number;
}

const isBatteryChangeComingBeforeSoonerThanNextTask = ({
  daysBetweenTasks,
  daysLeftBattery,
  daysLeftTask,
}: IIsBatteryChangeComingBeforeSoonerThanNextTask) => {
  if (daysBetweenTasks > 365) {
    return false;
  }
  const batterySwapWithinNextTwoTasks = daysLeftBattery / (daysBetweenTasks) < 2;
  if (batterySwapWithinNextTwoTasks) {
    if (daysLeftTask > 30) {
      return false;
    }
  }
  return batterySwapWithinNextTwoTasks;
};
