import { List, SxProps, Theme } from '@mui/material';

type Props = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const FpList = ({ children, sx }: Props) => {
  return <List sx={sx}>{children}</List>;
};

FpList.displayName = 'List';

export default FpList;
