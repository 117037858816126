import { Button, Popup } from 'semantic-ui-react';
import { useStoreActions, useStoreState } from '../../../../Store';
import PowerConsumptionHistoryPopup from './PowerConsumptionHistory.Popup';

const PowerConsumptionHistoryButton = (): JSX.Element => {
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const confirmationToggle = useStoreActions((actions) => actions.modalModel.confirmationToggle);

  const showBatteryConsumptionHistory = () => {
    const content = <PowerConsumptionHistoryPopup />;
    const title = 'Power consumption history for: ' + baseFilter.infoBasic.name;
    confirmationToggle({
      content,
      showModal: true,
      title,
      confirmationSize: 'xl',
    });
  };

  return (
    <Popup
      content="View Power consumption history"
      trigger={<Button icon="line graph" basic onClick={() => showBatteryConsumptionHistory()} />}
    />
  );
};

export default PowerConsumptionHistoryButton;
