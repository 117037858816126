import { passwordChangeRequired } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { RouteDirectories } from '@flowplan/flowplan-shared/lib/api/routes.Directories';
import { IMobileSigninResponse } from '@flowplan/flowplan-shared/lib/auth/auth.mobile.requests';
import { IAuthRequestRefeshToken, IAuthRequestSignin } from '@flowplan/flowplan-shared/lib/auth/auth.requests';
import { FlowplanProductTypes, SubscriptionOptions } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { IVerifiedLoginCredentials, formatError, verifyLoginCredentials } from '@hsjakobsen/utilities';
import dayjs from 'dayjs';
import { action, thunk } from 'easy-peasy';
import { noSelectionMade } from '../../../Models/admin/admin.model';
import { defaultBaseFilter } from '../../../Models/graphSettings';
import {
  LocalStoreBasic,
  deleteLastVisitedLocation,
  deleteStoredSettings,
  deleteStoredTokens,
  getBasicSetting,
  getBasicSettingNumber,
  getLastVisitedLocation,
  removeLegacyValues,
  setStorageKey,
  storeBasicSetting,
} from '../../../Utility/LocalStorageFunctions';
import { defaultInventoryStatus } from '../../inventory/services/inventory-model';
import { handlePostRequest } from '../../server-requests/server-requests';
import { isUserAdmin } from '../../users/types/users-configs';
import { IAuthModel, defaultAccess, defaultUserValues } from '../types/auth-types';

const authModel: IAuthModel = {
  loginChecks: thunk((actions) => {
    const failedLogins = getBasicSettingNumber({ identifier: LocalStoreBasic.FailedLogins, fallback: 0 });
    actions.setFailedLogins(failedLogins);

    const pageVisits = getBasicSettingNumber({ identifier: LocalStoreBasic.PageVisits, fallback: 0 });
    if (pageVisits === 0) {
      return;
    }
    actions.handleVerifySession();
  }),
  showMenu: false,
  setShowMenu: action((state, payload) => {
    state.showMenu = payload;
  }),

  redirectToSavedLocation: false,
  setRedirectToSavedLocation: action((state, payload) => {
    state.redirectToSavedLocation = payload;
  }),
  savedLocation: '',
  setSavedLocation: action((state, payload) => {
    state.savedLocation = payload;
  }),
  redirectToReferrer: false,
  setRedirectToReferrer: action((state, payload) => {
    state.redirectToReferrer = payload;
  }),
  failedLogins: 0,
  setFailedLogins: action((state, payload) => {
    state.failedLogins = payload;
  }),
  retrieveData: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    let pageVisits = getBasicSettingNumber({ identifier: LocalStoreBasic.PageVisits, fallback: 0 });
    pageVisits++;
    storeBasicSetting({ identifier: LocalStoreBasic.PageVisits, value: pageVisits.toString() });

    const storeActions = getStoreActions();

    await storeActions.companyModel.getCompanyAccessStatus();
    const blockReason = getStoreState().companyModel.companyAccessStatus;
    if (blockReason) {
      actions.setLoading(false);
      actions.setLoadingText('');
      actions.setFormDisabled(true);
      return;
    }

    actions.setLoadingText('Retrieving common data');
    removeLegacyValues();
    // Basic data
    await Promise.all([
      storeActions.companyModel.getCompanyInfo(),
      storeActions.usersModel.getUserInformation(),
      // getCompanyInfo triggers modal flow due to side-effect in company-model.
    ]);

    const storeState = getStoreState();
    const role = storeState.authModel.currentUser.role;
    const flowplanProductTypeId = storeState.adminDataModel.flowplanProductTypeId;

    const promises = [];

    if (role !== RoleType.GUEST) {
      // Guests don't need the following data
      if (flowplanProductTypeId === FlowplanProductTypes.WaterFilter || flowplanProductTypeId === FlowplanProductTypes.Everything) {
        promises.push(storeActions.staticDataModel.retrieveSensorCategories());
        promises.push(storeActions.staticDataModel.retrieveSensorTypes());
        promises.push(storeActions.staticDataModel.retrieveChangeByOptions());
        promises.push(storeActions.staticDataModel.retrieveFilterProducers());
        promises.push(storeActions.locationsModel.getLocations({
          flowplanClientId: noSelectionMade,
          flowplanProductTypeId: FlowplanProductTypes.WaterFilter
        }));

        const isAdministrator = isUserAdmin(role);
        if (isAdministrator) {
          promises.push(storeActions.companyModel.getLowUsageInstallations());
          promises.push(storeActions.companyModel.getBeamSerialNotInSystem());
        }
      } else if (flowplanProductTypeId === FlowplanProductTypes.Taps) {
        promises.push(storeActions.locationsModel.getLocations({
          flowplanClientId: noSelectionMade,
          flowplanProductTypeId: FlowplanProductTypes.Taps
        }));
      }

      // Common functionality for both Taps and Waterfilter
      promises.push(storeActions.staticDataModel.retrieveFlowmeters());
      promises.push(storeActions.staticDataModel.retrieveExternalSensorCategories());
      promises.push(storeActions.staticDataModel.retrieveExternalSensors());
      promises.push(storeActions.firmwareReleasesModel.retrieveFirmwareReleases());
      promises.push(storeActions.inventoryModel.getBillingInformation(noSelectionMade));
      promises.push(storeActions.companyModel.getTagsForCompany());
      promises.push(storeActions.newsAndUpdatesModel.getNewsAndUpdates());
      promises.push(storeActions.newsAndUpdatesModel.getNewsAndUpdateStatus());
    }

    await Promise.all(promises);

    const aMonthAgo = dayjs().subtract(1, 'month')
    const createdAt = dayjs(storeState.authModel.currentUser.createdAt);
    const userCreatedMoreThanOneMonthAgo = createdAt < aMonthAgo
    // do not bombard new users with all the new letters 
    if (role !== RoleType.GUEST && userCreatedMoreThanOneMonthAgo) {
      const newsItems = getStoreState().newsAndUpdatesModel.newsAndUpdates;
      const newsStatus = getStoreState().newsAndUpdatesModel.newsAndUpdateStatus;

      let showNews = false;
      for (const news of newsItems) {
        const isRead = newsStatus.find((item) => item.newsId === news.id);
        if (!isRead) {
          showNews = true;
        }
      }
      storeActions.newsAndUpdatesModel.setShowNews(showNews);
    }

    actions.setShowMenu(true);
    if (payload) {
      // Normal login, go to dashboard and reset failed logins
      actions.setFailedLogins(0);
      actions.setRedirectToReferrer(true);
      return;
    }
    // Session re-used, go to last visited page
    const savedLocation = getLastVisitedLocation();
    actions.setSavedLocation(savedLocation);
    actions.setRedirectToSavedLocation(true);
  }),

  //   changePasswordHandling
  formDisabled: false,
  setFormDisabled: action((state, payload) => {
    state.formDisabled = payload;
  }),

  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  loadingText: '',
  setLoadingText: action((state, payload) => {
    state.loadingText = payload;
  }),

  passwordChangeInfo: { role: RoleType.GUEST, username: '' },
  setPasswordChangeInfo: action((state, payload) => {
    state.passwordChangeInfo = payload;
  }),

  showChangePasswordModal: false,
  setShowChangePasswordModal: action((state, payload) => {
    state.showChangePasswordModal = payload;
  }),

  signInError: '',
  setSignInError: action((state, payload) => {
    state.signInError = payload;
  }),

  isAuthenticated: false,
  setIsAuthenticated: action((state, payload) => {
    state.isAuthenticated = payload;
  }),

  currentUser: defaultUserValues,

  access: defaultAccess,

  handleSuccess: action((state, payload) => {
    state.currentUser = payload;
    const { role, storageKey } = payload;
    setStorageKey(storageKey);

    const everyone =
      role === RoleType.ADMIN ||
      role === RoleType.SUPER_ADMIN ||
      role === RoleType.TECHNICIAN ||
      role === RoleType.EXTERNAL_TECHNICIAN ||
      role === RoleType.GUEST;

    const everyoneButGuest = role !== RoleType.GUEST;
    const everyoneButExternal = role !== RoleType.GUEST && role !== RoleType.EXTERNAL_TECHNICIAN;

    const onlyAdmins = role === RoleType.ADMIN || role === RoleType.SUPER_ADMIN;
    const onlyFlowplanAdmin = role === RoleType.SUPER_ADMIN;

    state.access = {
      clients: {
        manage: everyoneButExternal,
        view: everyoneButGuest,
      },
      flowplanAdmin: onlyFlowplanAdmin,
      installations: {
        manage: everyoneButGuest,
        manageExternalTasks: onlyAdmins,
        view: everyone,
      },
      inventory: {
        dataNotInSystem: onlyAdmins,
        lowUsageInstallations: onlyAdmins,
        manage: onlyAdmins,
        view: everyoneButGuest,
      },
      locations: {
        manage: everyoneButGuest,
        view: everyone,
      },
      orders: {
        manage: onlyAdmins,
        view: onlyAdmins,
      },
      support: {
        manage: onlyAdmins,
        view: everyoneButGuest,
      },
      users: {
        manage: onlyAdmins,
        view: onlyAdmins,
      },
      reports: {
        manage: onlyAdmins,
        view: onlyAdmins,
      },
      workOrders: {
        manage: onlyAdmins,
        view: everyoneButExternal,
      },
      companySettings: {
        manage: onlyAdmins,
        view: onlyAdmins
      },
      taps: {
        view: everyone,
        manage: everyoneButExternal,
        edit: everyoneButGuest
      },
      tapSettings: {
        manage: onlyAdmins,
        edit: onlyAdmins,
        view: everyoneButGuest
      }
    };
  }),

  isPasswordChangeRequired: false,
  setIsPasswordChangeRequired: action((state, payload) => {
    state.isPasswordChangeRequired = payload.message === passwordChangeRequired && !payload.success;
  }),

  signout: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const route = RouteDirectories.Auth + Routes.logout;
    const storeActions = getStoreActions();

    const requestResponse = await storeActions.serverRequestsModel.get({ route, noTokenCheck: true });
    deleteStoredTokens();
    storeBasicSetting({ identifier: LocalStoreBasic.PageVisits, value: '0' });
    deleteStoredSettings();
    if (requestResponse.success) {
      actions.setRedirectToReferrer(false);
      actions.setRedirectToSavedLocation(false);
      actions.setShowMenu(false);
      actions.setLoading(false);
      actions.setIsAuthenticated(!requestResponse.success);
      if (!getStoreState().authModel.isAuthenticated) {
        storeActions.clientsModel.resetClientsData();
        storeActions.dashboardModel.resetModelData();
        storeActions.locationsModel.resetModelData();
        storeActions.usersModel.resetUserData();
        storeActions.flowplanClientModel.setFlowplanCompanies([]);
        storeActions.blackListDataModel.setBlacklist([]);
        storeActions.companyModel.setCompanyInfo({
          creationDate: '',
          name: '',
          nameLowerCase: '',
          enableMatching: 0,
          lastBillingDate: '',
          subscription: SubscriptionOptions.ESSENTIALS,
        });
        storeActions.installationsModel.setSensors([]);
        storeActions.installationsModel.setInstallationsList([]);
        storeActions.graphSettingsModel.setSelectedInstallation(defaultBaseFilter);
        storeActions.inventoryModel.setBillingInformation({
          inventory: [],
          inventoryStatus: defaultInventoryStatus,
          monthData: [],
        });

        storeActions.logsModel.setLogOverview([]);
        storeActions.logsModel.setLogDetails([]);
        storeActions.staticDataModel.setFilterProducers([]);
        storeActions.staticDataModel.setExternalSensorCategories([]);
        storeActions.staticDataModel.setExternalSensors([]);
        storeActions.staticDataModel.setFlowmeters([]);
        storeActions.staticDataModel.setChangeByOptions([]);
        storeActions.staticDataModel.setSensorCategories([]);
        storeActions.staticDataModel.setSensorTypes([]);
        storeActions.suspiciousDataModel.setSuspiciousDataList([]);
        storeActions.usersModel.setUsers([]);
        storeActions.markerFilterModel.resetData();
        storeActions.ordersModel.setOrders([]);
        storeActions.reportsModel.setReports([]);

        actions.resetAuthData();
      }
    }
  }),

  resetAuthData: action((state) => {
    deleteLastVisitedLocation();
    deleteStoredTokens();
    state.currentUser = defaultUserValues;
    state.access = defaultAccess;
    setStorageKey('');
  }),

  useRefreshToken: thunk(async (actions, payload, { getStoreState }) => {
    const storedRefreshToken = getBasicSetting(LocalStoreBasic.RefreshToken);
    deleteStoredTokens();
    if (!storedRefreshToken) {
      return formatError('Your session has expired');
    }
    const refreshTokenPackage: IAuthRequestRefeshToken = {
      refreshToken: storedRefreshToken,
    };
    const route = RouteDirectories.Auth + Routes.refreshToken;
    const requestResponse = await handlePostRequest(refreshTokenPackage, route, true);
    if (requestResponse.success) {
      actions.setIsAuthenticated(requestResponse.success);
      if (getStoreState().authModel.isAuthenticated) {
        const authData = requestResponse.data as IMobileSigninResponse;
        actions.handleSuccess(authData.userInformation);
        storeBasicSetting({ identifier: LocalStoreBasic.AccessToken, value: authData.token });
        storeBasicSetting({ identifier: LocalStoreBasic.RefreshToken, value: authData.refreshToken });
        storeBasicSetting({
          identifier: LocalStoreBasic.AccessTokenExpiring,
          value: requestResponse.extraData.expiration,
        });
      }
    }

    return requestResponse;
  }),

  verifySession: thunk(async (actions, payload, { getStoreState }) => {
    actions.setLoading(true);
    actions.setLoadingText('Authenticating user');
    const route = RouteDirectories.Auth + Routes.verify;
    let requestResponse = await handlePostRequest({}, route, true);
    if (requestResponse.success) {
      actions.setIsPasswordChangeRequired(requestResponse);
      actions.setIsAuthenticated(requestResponse.success);
      storeBasicSetting({
        identifier: LocalStoreBasic.AccessTokenExpiring,
        value: requestResponse.extraData.expiration,
      });
      if (getStoreState().authModel.isAuthenticated) {
        actions.handleSuccess(requestResponse.data);
      }
    } else {
      requestResponse = await actions.useRefreshToken();
    }
    return requestResponse;
  }),
  handleVerifySession: thunk(async (actions, payload, { getStoreState }) => {
    const verifyResponse = await actions.verifySession();
    actions.setIsPasswordChangeRequired(verifyResponse);
    if (getStoreState().authModel.isPasswordChangeRequired) {
      actions.setFormDisabled(true);
      actions.setLoading(false);
      actions.setPasswordChangeInfo(verifyResponse.data);
      actions.setShowChangePasswordModal(true);
      actions.setSignInError(verifyResponse.message);
      return;
    }
    if (!verifyResponse.success) {
      actions.setLoading(false);
      actions.setSignInError(verifyResponse.message.toString());
      return;
    } else {
      await actions.retrieveData(false);
    }
  }),

  authenticate: thunk(async (actions, payload, { getStoreState }) => {
    const { username, password } = payload;
    const loginInfo: IVerifiedLoginCredentials = verifyLoginCredentials(username, password);
    if (loginInfo.success !== undefined && !loginInfo.success) {
      return {
        message: 'Credentials are not correct',
        success: false,
      };
    }
    const authRequest: IAuthRequestSignin = {
      password: loginInfo.password,
      username: loginInfo.username,
    };

    const route = RouteDirectories.Auth + Routes.signin;
    const requestResponse = await handlePostRequest(authRequest, route, true);
    if (requestResponse.success) {
      actions.setIsPasswordChangeRequired(requestResponse);
      actions.setIsAuthenticated(requestResponse.success);
      if (getStoreState().authModel.isAuthenticated) {
        const authData = requestResponse.data as IMobileSigninResponse;
        actions.handleSuccess(authData.userInformation);
        storeBasicSetting({ identifier: LocalStoreBasic.AccessToken, value: authData.token });
        storeBasicSetting({ identifier: LocalStoreBasic.RefreshToken, value: authData.refreshToken });
        storeBasicSetting({
          identifier: LocalStoreBasic.AccessTokenExpiring,
          value: requestResponse.extraData.expiration,
        });
      }
    }
    return requestResponse;
  }),

  handleLoginResponse: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    if (!payload.success) {
      let failedLoginsUpdate = getStoreState().authModel.failedLogins;
      failedLoginsUpdate++;
      storeBasicSetting({ identifier: LocalStoreBasic.FailedLogins, value: failedLoginsUpdate.toString() });
      actions.setFailedLogins(failedLoginsUpdate);
      actions.setLoading(false);
      actions.setSignInError(payload.message);
      return;
    }

    storeBasicSetting({ identifier: LocalStoreBasic.FailedLogins, value: '0' });
    await actions.retrieveData(true);
  }),

  doLogin: thunk(async (actions, payload, { getStoreState }) => {
    actions.setLoading(true);
    actions.setLoadingText('Logging in');
    const loginResponse = await actions.authenticate(payload);
    actions.setIsPasswordChangeRequired(loginResponse);
    if (getStoreState().authModel.isPasswordChangeRequired) {
      actions.setFormDisabled(true);
      actions.setLoading(false);
      actions.setPasswordChangeInfo(loginResponse.data);
      actions.setShowChangePasswordModal(true);
      actions.setSignInError(loginResponse.message);
      return;
    }
    actions.handleLoginResponse(loginResponse);
  }),

  //Forgot Password
  startForgotPassword: thunk(async (actions, payload) => {
    const route = RouteDirectories.Users + Routes.passwordForgotten;
    return await handlePostRequest(payload, route, false);
  }),
  completeForgotPassword: thunk(async (actions, payload) => {
    const route = RouteDirectories.Users + Routes.passwordReset;
    return await handlePostRequest(payload, route, false);
  }),
  requiredPasswordChange: thunk(async (actions, payload) => {
    const route = RouteDirectories.Users + Routes.passwordChange;
    return await handlePostRequest(payload, route, false);
  }),
};

export default authModel;
