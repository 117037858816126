import { dateFormatDMY, dateFormatYMD, timeFormatHM, timeFormatHMS } from '@hsjakobsen/utilities/lib/date';
import dayjs, { Dayjs, UnitType } from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { round } from 'lodash';

dayjs.extend(quarterOfYear);

export const delayAction = (milliSeconds: number) => new Promise((res) => setTimeout(res, milliSeconds));

export const getDateObject = (input: string | Date | number | Dayjs | null): Dayjs => {
  return input === null ? dayjs() : dayjs(input);
};

/* DATE FORMATTING */
export const formatDateBase = (date: Dayjs, format: string): string => {
  return date.format(format);
};

export const formatDateYMDHM = (date: Dayjs): string => {
  return formatDateBase(date, dateFormatYMD + ' - ' + timeFormatHM);
};

export const formatDateDMY = (date: Dayjs): string => {
  return formatDateBase(date, dateFormatDMY);
};

export const formatDateYMD = (date: Dayjs): string => {
  return formatDateBase(date, 'YYYY-MM-DD');
};

export const getDateFormatted = (time: string): string => {
  const dateToReturn = getDateObject(time);
  return formatDateYMDHM(dateToReturn);
};

export const formatDateYMDFull = (date: Dayjs): string => {
  return formatDateBase(date, dateFormatYMD + ' ' + timeFormatHMS);
};

export const dateDMYToYMD = (dateSelection: string): string => {
  if (dateSelection === undefined) {
    return '';
  }
  const dateParsed: string[] = splitDate(dateSelection);
  const dateReconstruction = dateParsed[2] + '-' + dateParsed[1] + '-' + dateParsed[0];
  return dateReconstruction;
};

export const stringToDateObject = (dateStr: string): Date => {
  return dayjs(dateStr).toDate();
};

export const splitDate = (dateStr: string): string[] => {
  return dateStr.split('-');
};

export const diff = (date: Dayjs, diffDate: Dayjs, timeUnitToReturn: UnitType): number => {
  return date.diff(diffDate, timeUnitToReturn);
};

// This function splits a date formatted like this: DD-MM-YYYY and returns YYYY-MM-DD
export const reverseDate = (dateInput: string): string => {
  const datePieces = dateInput.split('-');
  return datePieces[2] + '-' + datePieces[1] + '-' + datePieces[0];
};

export const calculateTimeDuration = (milliSeconds: number): string => {
  let duration = milliSeconds / 1000
  if (isNaN(duration) || duration < 0) {
    return 'N/A'
  }

  duration = duration / 60
  if (duration < 0) {
    return 'Less than a minute'
  }
  if (duration < 60) {
    const roundupMins = round(duration)
    return `${roundupMins} minute${roundupMins > 1 ? 's' : ''}`
  }
  duration = duration / 60
  if (duration < 60) {
    const roundupHours = round(duration)
    return `${round(duration)} hour${roundupHours > 1 ? 's' : ''}`
  }

  duration = duration / 24
  const roundupDays = round(duration)
  return `${round(duration)} day${roundupDays > 1 ? 's' : ''}`
}

export function mapKeyToWeekday({ key, abbr }: { key: string, abbr?: boolean }) {
  let day = abbr ? 'Sun' : 'Sunday'
  switch (key) {
    case '1':
      day = abbr ? 'Mon' : 'Monday';
      break;
    case '2':
      day = abbr ? 'Tue' : 'Tuesday';
      break;
    case '3':
      day = abbr ? 'Wed' : 'Wednesday';
      break;
    case '4':
      day = abbr ? 'Thur' : 'Thursday';
      break;
    case '5':
      day = abbr ? 'Fri' : 'Friday';
      break;
    case '6':
      day = abbr ? 'Sat' : 'Saturday';
  }
  return day
}

