import { IStockSettingsView } from '@flowplan/flowplan-shared/lib/stock.settings/stock.settings';
import { IStock } from '@flowplan/flowplan-shared/lib/stock/stock';
import { Action, action, Thunk, thunk } from 'easy-peasy';
import { IStoreModel } from '../../Models';

interface IStockAndSettings {
  stock: IStock[];
  stockSettings: IStockSettingsView[];
}
interface IGetStockSettings {
  locationId?: number;
  sensorId?: number;
}

export interface IStockDailyDataModel {
  stock: IStock[];
  stockSettings: IStockSettingsView[];
  getStockAndSettings: Thunk<IStockDailyDataModel, IGetStockSettings, void, IStoreModel>;
  setStockAndSettings: Action<IStockDailyDataModel, IStockAndSettings>;
}

const stockDailyDataModel: IStockDailyDataModel = {
  getStockAndSettings: thunk(async (actions, payload, { getStoreActions }) => {
    if (payload === undefined || payload.locationId === -1) {
      return;
    }

    const stock: IStock[] = await getStoreActions().stockModel.getStock(payload.locationId);
    if (stock.length === 0) {
      actions.setStockAndSettings({ stock, stockSettings: [] });
      return;
    }

    const stockSettings = await getStoreActions().stockSettingsModel.getStockSettings(payload);

    actions.setStockAndSettings({ stock, stockSettings });
  }),

  setStockAndSettings: action((state, payload) => {
    state.stock = payload.stock;
    state.stockSettings = payload.stockSettings;
  }),

  stock: [],
  stockSettings: [],
};

export default stockDailyDataModel;
