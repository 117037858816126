import { Grid, GridOwnProps } from '@mui/material';
import { forwardRef } from 'react';

const FpGrid = forwardRef<HTMLDivElement, GridOwnProps>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <Grid container {...rest}>
      {children}
    </Grid>
  );
});

FpGrid.displayName = 'Grid';

export default FpGrid;
