import React from 'react';
import './EmptyListItem.scss';

const EmptyListItem = (): JSX.Element => {
  return (
    <div className="empty-list center-align">
      <span className="empty-list-label">No items found</span>
    </div>
  );
};

export default EmptyListItem;
