import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { checkBrowserCommand } from '../../../../Controllers/CheckBrowserCommand';
import logo from '../../../../assetstore/logo/flowplan-large.png';
import {
  minVersionRequiredChrome,
  minVersionRequiredEdge,
  minVersionRequiredFirefox,
  minVersionRequiredOpera,
  minVersionRequiredSafari,
} from '../../../../common/constsFrontend';
import Footer from '../../../navigation/components/Footer/Footer';
import FpDivider from '../FpDivider/FpDivider';
import styles from './BrowserSupport.module.scss';
import BrowserSupportItem from './BrowserSupportItem';

interface IProps {
  children: JSX.Element;
}

const BrowserSupport = ({ children }: IProps): JSX.Element => {
  const [browserSupported, setBrowserSupported] = useState(false);

  useEffect(() => {
    const checkBrowserResult = checkBrowserCommand();
    setBrowserSupported(checkBrowserResult);
  }, []);

  const actionsGridPropsTopRow = { xs: 12, sm: 6, md: 6, lg: 4 };
  const actionsGridPropsBottomRow = { xs: 12, sm: 6, md: 6, lg: 6 };

  if (!browserSupported) {
    return (
      <>
        <Segment className={styles.browserSupport}>
          <img src={logo} className="App-logo" alt="logo" />
          <Header as="h2">Browser not supported</Header>
          <FpDivider hidden />

          <Header textAlign="center">We recommend one of the following browsers:</Header>
          <Grid
            container
            spacing={{ sm: 0.5 }}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
            className={styles.quickControlsGrid}
          >
            <Grid item {...actionsGridPropsTopRow}>
              <BrowserSupportItem browserName="Firefox" version={minVersionRequiredFirefox} />
            </Grid>
            <Grid item {...actionsGridPropsTopRow}>
              <BrowserSupportItem browserName="Chrome" version={minVersionRequiredChrome} />
            </Grid>
            <Grid item {...actionsGridPropsTopRow}>
              <BrowserSupportItem browserName="Edge" version={minVersionRequiredEdge} />
            </Grid>
            <Grid item {...actionsGridPropsBottomRow}>
              <BrowserSupportItem browserName="Opera" version={minVersionRequiredOpera} />
            </Grid>
            <Grid item {...actionsGridPropsBottomRow}>
              <BrowserSupportItem browserName="Safari" extra="macOS 10.12" version={minVersionRequiredSafari} />
            </Grid>
          </Grid>
        </Segment>
        <Footer />
      </>
    );
  }

  return children;
};

export default BrowserSupport;
