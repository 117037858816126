import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IStock, StockTypes } from '@flowplan/flowplan-shared/lib/stock/stock';
import { Action, Thunk, action, thunk } from 'easy-peasy';
import { IStoreModel } from '../../Models';
import {
  handleDeleteRequest,
  handlePostRequest,
  handlePutRequest
} from '../server-requests/server-requests';
import { IStockTypeSelection } from './components/StockFeatureSelection/StockFeatureSelection';

interface IManageStockUpdate {
  selectedStockTypes: IStockTypeSelection[];
  stockData: IStock[];
  locationId: number | null;
}

interface IManageStockCreation {
  selectedStockTypes: IStockTypeSelection[];
  locationId: number;
}

interface ICreateStock {
  locationId: number;
  flowplanClientId: number;
  totalAmount: number;
  stockType: StockTypes;
}

interface IUpdateStock {
  totalAmount: number;
  stockId: number;
}

interface IGetStockParams {
  locationId: number | undefined;
  flowplanClientId?: number;
}

export interface IStockModel {
  loadingStock: boolean;
  setLoadingStock: Action<IStockModel, boolean>;
  manageStockUpdates: Thunk<IStockModel, IManageStockUpdate, void, IStoreModel>;
  manageStockCreation: Thunk<IStockModel, IManageStockCreation, void, IStoreModel>;

  getStock: Thunk<IStockModel, number | undefined, void, IStoreModel>; // locationId
  createStock: Thunk<IStockModel, ICreateStock>;
  updateStock: Thunk<IStockModel, IUpdateStock>;
  removeStock: Thunk<IStockModel, number>; // stockId
  //   restore: Thunk<IStockManagementModel, number, void, IStoreModel>;
}

const stockModel: IStockModel = {
  loadingStock: false,
  setLoadingStock: action((state, payload) => {
    state.loadingStock = payload;
  }),

  getStock: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingStock(true);

    const flowplanClientSelected = getStoreState().adminDataModel.flowplanClientSelected;

    const params: IGetStockParams = {
      locationId: payload,
      flowplanClientId: flowplanClientSelected,
    };

    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.Stock, params });
    let stockReceived: IStock[] = [];
    if (requestResponse.success) {
      stockReceived = requestResponse.data as IStock[];
    }
    actions.setLoadingStock(false);
    return stockReceived;
  }),

  manageStockCreation: thunk(async (actions, payload, { getStoreState }) => {
    const { selectedStockTypes, locationId } = payload;
    let operationSuccess = false;
    let operationFeedback = '';
    const companyId = getStoreState().companyModel.companyId;
    for (const stockTypeItem of selectedStockTypes) {
      const { stockType, totalAmount } = stockTypeItem;
      const stockResult = await actions.createStock({
        flowplanClientId: companyId,
        locationId,
        stockType,
        totalAmount,
      });
      operationSuccess = stockResult.success;
      operationFeedback = stockResult.message;
      if (!operationSuccess) {
        break;
      }
    }
    return {
      success: operationSuccess,
      message: operationFeedback,
    };
  }),
  manageStockUpdates: thunk(async (actions, payload, { getStoreState }) => {
    const { locationId, selectedStockTypes, stockData } = payload;
    if (!locationId) {
      return {
        success: false,
        message: 'Location not selected',
      };
    }
    const companyId = getStoreState().companyModel.companyId;
    let operationSuccess = true;
    let operationFeedback = '';
    const newStockOnLocation: IStockTypeSelection[] = [];

    selectedStockTypes.forEach((stockItem) => {
      const stockCheck = stockData.find((x) => x.stockType === stockItem.stockType);
      if (!stockCheck) {
        newStockOnLocation.push(stockItem);
      }
    });

    for (const newStock of newStockOnLocation) {
      if (!newStock) {
        continue;
      }

      const { stockType, totalAmount } = newStock;
      const stockResult = await actions.createStock({
        flowplanClientId: companyId,
        locationId,
        stockType,
        totalAmount,
      });
      operationSuccess = stockResult.success;
      operationFeedback = stockResult.message;
      if (!operationSuccess) {
        break;
      }
    }

    if (!operationSuccess) {
      return {
        success: false,
        message: operationFeedback,
      };
    }

    const updatedStockAlreadyOnLocation = selectedStockTypes.map((stockItem) => {
      const updatedStockAlreadyOnLocation = stockData.find(
        (x) => x.stockType === stockItem.stockType && x.totalAmount !== stockItem.totalAmount,
      );
      return updatedStockAlreadyOnLocation ? { ...stockItem, id: updatedStockAlreadyOnLocation.id } : null;
    });

    for (const updatedStock of updatedStockAlreadyOnLocation) {
      if (!updatedStock) {
        continue;
      }

      const { totalAmount } = updatedStock;
      const stockResult = await actions.updateStock({
        stockId: updatedStock.id,
        totalAmount,
      });
      operationSuccess = stockResult.success;
      operationFeedback = stockResult.message;
      if (!operationSuccess) {
        break;
      }
    }

    if (!operationSuccess) {
      return {
        success: false,
        message: operationFeedback,
      };
    }

    const stockToDelete = stockData.map((stockDataItem) => {
      const stockToDelete = selectedStockTypes.find((x) => x.stockType === stockDataItem.stockType);
      return !stockToDelete ? stockDataItem : null;
    });

    for (const removedStock of stockToDelete) {
      if (!removedStock) {
        continue;
      }
      const { id } = removedStock;
      const stockResult = await actions.removeStock(id);
      operationSuccess = stockResult.success;
      operationFeedback = stockResult.message;
      if (!operationSuccess) {
        break;
      }
    }

    return {
      success: operationSuccess,
      message: operationFeedback,
    };
  }),

  createStock: thunk(async (actions, payload) => {
    const requestResponse = await handlePostRequest(payload, Routes.Stock, true);
    return requestResponse;
  }),

  updateStock: thunk(async (actions, payload) => {
    const route = Routes.Stock + payload.stockId;
    const requestResponse = await handlePutRequest({ totalAmount: payload.totalAmount }, route, true);
    return requestResponse;
  }),

  removeStock: thunk(async (actions, payload) => {
    const route = Routes.Stock + payload;
    const requestResponse = await handleDeleteRequest({ id: payload }, route);
    return requestResponse;
  }),
};

export default stockModel;
