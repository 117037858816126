import { FlowplanProductTypes } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { IDeviceDataOverview } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { noSelectionMade } from '../../../Models/admin/admin.model';
import { handlePostRequest } from '../../server-requests/server-requests';
import { ITapStockBatchCreate, ITapStockCreateWithoutLocationId } from './tap.stock.model';

export interface ITapLocationBase {
  name: string;
  city: string;
  street: string;
  zipCode: string;
  longitude: number;
  latitude: number;
}

export interface ITapLocationCreate extends ITapLocationBase {
  clientId: number;
  phoneNumber?: string;
}

export interface ITapLocationCreateInStepperForm extends Omit<ITapLocationCreate, 'longitude' | 'latitude'> {
  longitude?: number;
  latitude?: number;
}

export type ITapLocationEdit = Partial<ITapLocationCreate> & { id: number };

export interface ITapSublocationBase {
  flowplanProductTypeId: number;
  name: string;
  locationId: number;
}

export type ITapSublocationCreate = Omit<ITapSublocationBase, 'flowplanProductTypeId'>;
export type ITapSublocationUpdate = Omit<ITapSublocationBase, 'flowplanProductTypeId'> & { id: number };
export interface ITapSublocationCreateWithSensors extends Omit<ITapSublocationCreate, 'locationId'> {
  sensors: ITapSensorCreate[];
}

export interface ITapSensorCreate {
  name: string;
  flowplanSerial: string;
  deviceType: number;
  shortDesc?: string;
  flowmeterId: number;
  externalSensorId?: number;
  locationId: number | null;
  sublocationIndex: number;
  tapStockIndex: number;
  matchingEnabled?: number;
  maintenanceFeatureEnabled: number;
  maintenanceAtLiters: number; // ???
  maintenanceStartDate?: string; // depends on id maintance is enabled
  waterFilterFeatureEnabled: number;
}

// Define the StockCounter type
interface ITapStockCounter {
  id: number;
  stockId: number;
  sensorId: number;
  count: number;
  createdAt: string;
  updatedAt: string;
}

export interface ITapSensor {
  id: number;
  name: string;
  status: number;
  hoursNotSeen: number;
  hasLowUsage: number;
  lowUsageDays: number;
  shortDesc: string;
  flowplanDeviceId: string;
  externalSensorId: number;
  flowmeterId: number;
  installmentDate: string;
  firstDataTransfer: string;
  consumptionRatio: string;
  consumptionState: string;
  tapStockCounters?: ITapStockCounter;
  dataOverView: IDeviceDataOverview;
}

export interface ILocation extends ITapLocationBase {
  carbonateHardness?: number; // Make optional to allow undefined values
  totalHardness?: number;
  microSiemensPerCm?: number;
  ppm?: number;
}

export interface ITapStepperFormData {
  location: ITapLocationCreateInStepperForm;
  tapStocks: ITapStockBatchCreate;
  sublocations: ITapSublocationCreateWithSensors[];
}

export const emptyLocation = {
  name: '',
  street: '',
  city: '',
  zipCode: '',
  phoneNumber: '',
  clientId: 0,
};

// Andreas types

export interface ITapsModel {
  tapStepperFormData: ITapStepperFormData;
  tapLocationToEdit: ITapLocationEdit | NonNullable<unknown>;
  serialsAdded: string[];

  resetFormData: Action<ITapsModel>;
  resetSublocationsInFormData: Action<ITapsModel>;

  setFormLoacation: Action<ITapsModel, ITapLocationCreate>;
  setFormSubloacation: Action<ITapsModel, ITapSublocationCreateWithSensors[]>;
  submitTapStepperForm: Thunk<ITapsModel, ITapStepperFormData, void, IStoreModel>;
  setFormAddress: Action<ITapsModel, ITapLocationBase>;
  setFormTapStocks: Action<ITapsModel, ITapStockBatchCreate>;
  setFormClientId: Action<ITapsModel, number>;

  addSublocationToForm: Action<ITapsModel, ITapSublocationCreateWithSensors>;
  changeSublocationName: Action<ITapsModel, { index: number; name: string }>;
  removeSublocationFromForm: Action<ITapsModel, number>;

  addSensorToSublocation: Action<ITapsModel, { sublocationIndex: number; sensor: ITapSensorCreate }>;
  removeSensorFromSublocation: Action<ITapsModel, { sublocationIndex: number; sensorIndex: number }>;
  addSerialUsed: Action<ITapsModel, string>;
  removeSerialUsed: Action<ITapsModel, string>;
  resetSerialUsed: Action<ITapsModel>;

  setTapStocksInForm: Action<ITapsModel, ITapStockBatchCreate>;
  updateTapStockInForm: Action<ITapsModel, ITapStockCreateWithoutLocationId>;
  updateTapStockCurrentInventoryInForm: Action<ITapsModel, Omit<ITapStockCreateWithoutLocationId, 'capacity'>>;
  updateTapStockCapacityInForm: Action<ITapsModel, Omit<ITapStockCreateWithoutLocationId, 'currentInventory'>>;
  addTapStockToForm: Action<ITapsModel, ITapStockBatchCreate>;
  deleteTapStocksInForm: Action<ITapsModel, number[]>;

  setEditTapLocation: Action<ITapsModel, ITapLocationEdit>;
  resetEditTapLocation: Action<ITapsModel>;
  editTapLocation: Thunk<ITapsModel, ITapLocationEdit, void, IStoreModel>;

  onActionsThatUpdateTapLocations: ThunkOn<ITapsModel, void, IStoreModel>;
  onActionsThatUpdateTapLocation: ThunkOn<ITapsModel, void, IStoreModel>;
}

const tapsModel: ITapsModel = {
  tapStepperFormData: {
    location: emptyLocation,
    tapStocks: [],
    sublocations: [{ name: '', sensors: [] }],
  },
  tapLocationToEdit: {},
  serialsAdded: [],

  resetFormData: action((state) => {
    state.tapStepperFormData = {
      location: emptyLocation,
      tapStocks: [],
      sublocations: [{ name: '', sensors: [] }],
    };
  }),
  resetSublocationsInFormData: action((state) => {
    state.tapStepperFormData.sublocations = [{ name: '', sensors: [] }];
  }),

  setFormLoacation: action((state, payload) => {
    const { name, city, street, zipCode, longitude, latitude, clientId, phoneNumber } = payload;
    state.tapStepperFormData.location = {
      name,
      city,
      street,
      zipCode,
      longitude,
      latitude,
      clientId,
      phoneNumber,
    };
  }),
  setFormSubloacation: action((state, payload) => {
    state.tapStepperFormData.sublocations = payload
  }),
  submitTapStepperForm: thunk(async (actions, formData, { getStoreActions }) => {
    const response = await handlePostRequest(formData, `/taplocation/`, true);
    return response;
  }),

  setFormTapStocks: action((state, payload) => {
    state.tapStepperFormData.tapStocks = payload;
  }),
  setFormAddress: action((state, payload) => {
    state.tapStepperFormData.location = {
      ...state.tapStepperFormData.location,
      ...payload,
    };
  }),
  setFormClientId: action((state, payload) => {
    state.tapStepperFormData.location.clientId = payload;
  }),

  addSublocationToForm: action((state, payload) => {
    state.tapStepperFormData.sublocations.push(payload);
  }),
  changeSublocationName: action((state, payload) => {
    if (state.tapStepperFormData.sublocations.length > payload.index) {
      state.tapStepperFormData.sublocations[payload.index].name = payload.name;
    }
  }),
  removeSublocationFromForm: action((state, payload) => {
    state.tapStepperFormData.sublocations.splice(payload, 1);
  }),

  addSensorToSublocation: action((state, payload) => {
    const { sublocationIndex: index, sensor } = payload;
    state.tapStepperFormData.sublocations[index].sensors.push(sensor);
  }),
  removeSensorFromSublocation: action((state, payload) => {
    const { sublocationIndex, sensorIndex } = payload;
    state.tapStepperFormData.sublocations[sublocationIndex].sensors.splice(sensorIndex, 1);
  }),
  addSerialUsed: action((state, payload) => {
    state.serialsAdded.push(payload);
  }),
  removeSerialUsed: action((state, payload) => {
    const targetIndex = state.serialsAdded.findIndex((serial) => serial === payload);
    if (targetIndex >= 0) {
      state.serialsAdded.splice(targetIndex, 1);
    }
  }),
  resetSerialUsed: action((state) => {
    state.serialsAdded = [];
  }),

  setTapStocksInForm: action((state, payload) => {
    state.tapStepperFormData.tapStocks = payload;
  }),
  addTapStockToForm: action((state, payload) => {
    state.tapStepperFormData.tapStocks.push(...payload);
  }),
  deleteTapStocksInForm: action((state, stockTypeIds) => {
    stockTypeIds.forEach((stockTypeId) => {
      const indexOfTapStockToDelete = state.tapStepperFormData.tapStocks.findIndex(
        (stockType) => stockType.stockTypeId === stockTypeId,
      );
      if (indexOfTapStockToDelete !== -1) {
        state.tapStepperFormData.tapStocks.splice(indexOfTapStockToDelete, 1);
        state.tapStepperFormData.sublocations.forEach((sublocation, index) => {
          const indexOfSensorTrackingDeletedStock = sublocation.sensors.findIndex((sensor) => sensor.tapStockIndex === indexOfTapStockToDelete)
          if (indexOfSensorTrackingDeletedStock !== -1) {
            state.tapStepperFormData.sublocations[index].sensors = [];
          }
        })
      }
    });
  }),
  updateTapStockInForm: action((state, payload) => {
    const indexToUpdate = state.tapStepperFormData.tapStocks.findIndex(
      (stockType) => stockType.stockTypeId === payload.stockTypeId,
    );
    if (indexToUpdate !== -1) {
      state.tapStepperFormData.tapStocks[indexToUpdate] = payload;
    } else {
      state.tapStepperFormData.tapStocks.push(payload);
    }
  }),

  updateTapStockCapacityInForm: action((state, payload) => {
    const indexToUpdate = state.tapStepperFormData.tapStocks.findIndex(
      (stockType) => stockType.stockTypeId === payload.stockTypeId,
    );
    if (indexToUpdate !== -1) {
      state.tapStepperFormData.tapStocks[indexToUpdate].capacity = payload.capacity;
    }
  }),

  updateTapStockCurrentInventoryInForm: action((state, payload) => {
    const indexToUpdate = state.tapStepperFormData.tapStocks.findIndex(
      (stockType) => stockType.stockTypeId === payload.stockTypeId,
    );
    if (indexToUpdate !== -1) {
      state.tapStepperFormData.tapStocks[indexToUpdate].currentInventory = payload.currentInventory;
    }
  }),

  setEditTapLocation: action((state, payload) => {
    const { id, name, city, street, zipCode, longitude, latitude, phoneNumber } = payload;
    state.tapLocationToEdit = {
      id,
      name,
      city,
      street,
      zipCode,
      longitude,
      latitude,
      phoneNumber,
    };
  }),
  resetEditTapLocation: action((state,) => {
    state.tapLocationToEdit = {};
  }),
  editTapLocation: thunk(async (actions, tapLocationToEdit) => {
    const response = await handlePostRequest(tapLocationToEdit, `/taplocation/edit`, true);
    return response;
  }),

  onActionsThatUpdateTapLocations: thunkOn(
    (actions, storeActions) => [actions.submitTapStepperForm, storeActions.locationsModel.RemoveTapLocation],
    async (actions, target, { getStoreState, getStoreActions }) => {
      getStoreActions().locationsModel.getLocations({
        flowplanClientId: noSelectionMade,
        flowplanProductTypeId: FlowplanProductTypes.Taps,
      });
    },
  ),

  onActionsThatUpdateTapLocation: thunkOn(
    (actions) => [actions.editTapLocation],
    async (_actions, target, { getStoreActions }) => {
      const locationId = target.payload.id
      getStoreActions().locationsModel.getLocationData(locationId);
    },
  ),
};

export default tapsModel;
