import { notAvailable } from '@flowplan/flowplan-shared';
import { HealthState } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import BatteryStatus from '../../../../Components/ImageComponents/BatteryStatus';
import { getHealthStateColor, getHealthStateText, HardwareGenerations } from '../../../../hooks/BeamHealthStatus';
import { IBeamInformation } from '../../types/details/installation-details-beam';
import PowerConsumptionHistoryButton from '../Battery/PowerConsumptionHistory.Button';
import DetailsItem from '../Common/DetailsItem';
import DetailsItemDataSets from '../Common/DetailsItemAligned';
import DetailsSection from '../Common/DetailsSection';
import HealthStateSimple from '../HealthStatus/HealthStateSimple';
import SensorInternalTemperatureHistoryButton from '../InternalTemperature/InternalTemperatureHistoryButton';
import SignalStrengthComponent from '../SignalStrength/SignalStrengthComponent';
import SignalStrengthHistoryToggle from '../SignalStrength/SignalStrengthHistoryToggle';
import { BeamHealthTexts } from '../Tasks/tasks-utility-health';

type Props = {
  beamInfo: IBeamInformation;
};

const InstallationDetailsBeam = ({ beamInfo }: Props): JSX.Element => {
  const {
    batteryCharge,
    batteryDaysRemaining,
    batteryInstallationDate,
    deviceStatus,
    showBatteryInformation,
    deviceTemperature,
    firmware,
    flowplanDeviceId,
    hardware,
    healthState,
    network,
    powerStatus,
    signalInfo,
    signalStrength,
  } = beamInfo;

  return (
    <DetailsSection sectionTitle="Beam info">
      <DetailsItem title="Serial no." value={flowplanDeviceId}>
        <Icon name="qrcode" size="big" />
      </DetailsItem>
      <DetailsItem
        title={BeamHealthTexts.BeamHealthTitle}
        value={getHealthStateText({
          hardwareGeneration: hardware ? hardware : HardwareGenerations.GenThree,
          healthState,
          short: false,
        })}
      >
        <HealthStateSimple color={getHealthStateColor(healthState)} text="" large />
      </DetailsItem>
      <DetailsItemDataSets icon={<Icon name="file code" size="big" />} customStyle="">
        <div className="dataset two">
          <p className="header">Beam</p>
          <p className="value">{hardware + (healthState === HealthState.Great ? ' Air' : '')}</p>
        </div>
        <div className="dataset two">
          <p className="header">Firmware</p>
          <p className="value">{firmware}</p>
        </div>
      </DetailsItemDataSets>
      <DetailsItem title="Signal Strength" infoPopup={<SignalStrengthHistoryToggle />} value={signalInfo.signalText}>
        <SignalStrengthComponent signalStrength={signalStrength} network={network} />
      </DetailsItem>
      {!showBatteryInformation && (
        <DetailsItem title="Battery status" value={notAvailable}>
          <BatteryStatus
            deviceStatus={deviceStatus}
            firmware={firmware}
            percentageStatus={batteryCharge}
            showBatteryInformation={showBatteryInformation}
            network={network}
          />
        </DetailsItem>
      )}
      {showBatteryInformation && (
        <Fragment>
          <DetailsItem
            title="Battery installation date."
            value={batteryInstallationDate}
            infoPopup={<PowerConsumptionHistoryButton />}
          >
            <Icon name="calendar alternate outline" size="big" />
          </DetailsItem>
          <DetailsItemDataSets
            icon={
              <BatteryStatus
                deviceStatus={deviceStatus}
                firmware={firmware}
                percentageStatus={batteryCharge}
                showBatteryInformation={showBatteryInformation}
                network={network}
              />
            }
            customStyle=""
          >
            <div className="dataset two">
              <p className="header">Power status</p>
              <p className="value">{powerStatus}</p>
            </div>
            <div className="dataset two">
              <p className="header">Days left battery</p>
              <p className="value">{batteryDaysRemaining}</p>
            </div>
          </DetailsItemDataSets>
        </Fragment>
      )}
      {!deviceTemperature && (
        <DetailsItem title="Internal device temperature" value={notAvailable}>
          <Icon name="thermometer half" size="big" />
        </DetailsItem>
      )}
      {deviceTemperature ? (
        <DetailsItem
          title="Latest Beam temperature"
          infoPopup={<SensorInternalTemperatureHistoryButton />}
          value={deviceTemperature + ' °C (Estimate)'}
        >
          <Icon name="thermometer half" size="big" />
        </DetailsItem>
      ) : null}
    </DetailsSection>
  );
};

export default InstallationDetailsBeam;
