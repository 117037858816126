import { IUsageForEvents } from '@flowplan/flowplan-shared/lib/stock.settings/stock.settings';
import { StockTypes } from '@flowplan/flowplan-shared/lib/stock/stock';
import { Fragment } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { noSelectionMade } from '../../../../Models/admin/admin.model';
import { useStoreState } from '../../../../Store';
import StockSettingsItem from '../../../stock-settings/components/StockSettings/StockSettings';

export interface IStockSettingSelection {
  id?: number;
  stockId: number;
  stockType: StockTypes;
  usageForEvents: IUsageForEvents;
}

export interface StockSettingselectionProps {
  selectedStockSettings: IStockSettingSelection[];
  handleStockSettingSelections: (stockSettingItem: IStockSettingSelection) => void;
}

const FlowplanStockSettingsSection = ({
  handleStockSettingSelections,
  selectedStockSettings,
}: StockSettingselectionProps): JSX.Element => {
  const isPremium = useStoreState((state) => state.companyModel.isPremium);
  const inputLocationId = useStoreState((state) => state.newInstallationModel.inputLocationId);
  const stock = useStoreState((state) => state.stockDailyDataModel.stock);

  if (!isPremium || inputLocationId === noSelectionMade || stock.length === 0) {
    return <Fragment />;
  }

  return (
    <Segment>
      <Header size="medium">
        <Header.Content>
          Stock Consumption settings
          <Header.Subheader>Changing all values to 0 for a stock type, will delete the stock setting.</Header.Subheader>
        </Header.Content>
      </Header>
      {selectedStockSettings.map((stockSettingItem, index) => {
        return (
          <StockSettingsItem
            stockSetting={stockSettingItem}
            stockSettingSelectionHandler={handleStockSettingSelections}
            stockType={stockSettingItem.stockType}
            key={index}
          />
        );
      })}
    </Segment>
  );
};

export default FlowplanStockSettingsSection;
