import { DeviceStatus, notAvailable, PeriodSetting, periodSettingOptions, periodSettingsHours } from '@flowplan/flowplan-shared';
import { ClassificationStatus } from '@flowplan/flowplan-shared/lib/installation.classification/installation.classification.status';
import { ConsumptionState } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { HealthState, IDeviceView, TaskTypes } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { BatteryType, DataParsingStatus } from '@flowplan/flowplan-shared/lib/interfaces/networkEUIToFlowplanSerialDb';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { Dayjs } from 'dayjs';
import { Action, action, Thunk, thunk, ThunkOn, thunkOn } from 'easy-peasy';
import { IStoreModel } from '.';
import { getDateObject } from '../Utility/time';
import { BeamHealthStatus, getBeamHealthStatus, HardwareGenerations } from '../hooks/BeamHealthStatus';

export const defaultGraphSettings: IGraphSettingsTime = {
  dateSelection: getDateObject(null),
  timePeriodselection: PeriodSetting.Three,
};

export const defaultBaseFilter: IDeviceView = {
  infoBasic: {
    description: '',
    flowplanDeviceId: '',
    id: 0,
    name: '',
    initialInstallDate: '',
    sensorTypeId: 1,
    uuid: '',
  },
  infoExternalSensorData: {
    externalSensorData: null,
    externalSensorId: 0,
    matchingEnabled: false,
    measurementUnit: '',
  },
  infoDeviceMetrics: {
    apn: 'None',
    batteryCharge: 0,
    batteryInstallationDate: '',
    batteryRemainingDays: 0,
    batteryShowInformation: false,
    batteryTypeId: BatteryType.SAFT,
    dataParsingStatus: DataParsingStatus.DEFAULT,
    daysSinceFirstDataTransfer: 0,
    daysSinceInitialInstall: 0,
    deviceStatus: DeviceStatus.Pending,
    firmware: '',
    firstDataTransfer: '',
    hardware: '',
    hoursNotSeen: 0,
    lastSeen: '',
    network: 5,
    signalStrengthAverage: 0,
    signalStrengthLatest: 0,
    temperatureInternalAverage: 0,
    temperatureInternalLatest: 0,
  },
  infoFilter: {
    capacityTotal: 0,
    capacityUsed: 0,
    dayOfLastChange: '',
    daysLeft: 0,
    daysSinceLastFilterChange: 0,
    filterId: 0,
    filterName: '',
    estimatedUsedCapacity: 0,
    featureEnabled: false,
    filterProducerName: '',
    lastFilterInstallDate: '',
    percentageLeft: 0,
    taskType: TaskTypes.Time,
    totalSoftening: 0,
    usingSteamVariant: 0,
  },
  infoFlowmeter: {
    flowmeterName: '',
    flowmeterTickConstant: 0,
  },
  infoHealth: {
    healthState: HealthState.Great
  },

  infoLocationAndClient: {
    clientId: 0,
    clientName: '',
    carbonateHardness: 0,
    microSiemensPerCm: 0,
    totalHardness: 0,
    latitude: 0,
    locationAddress: '',
    locationId: 0,
    longitude: 0,
    locationName: '',
    locationPhoneNumber: '',
    sublocationId: 0,
  },
  infoMeta: {
    daysBetweenTask: 0,
    daysLeft: 0,
    lastDayBeforeOverdue: '',
    showInCountDownList: false,
    workOrderItemsExists: false
  },
  infoService: {
    cycleBasedMaintenance: false,
    dayOfLastChange: '',
    daysLeft: 0,
    daysSinceLastService: 0,
    estimatedMaintenanceUsage: 0,
    featureEnabled: false,
    lastMaintenanceDate: '',
    percentageLeft: 0,
    serviceAtLiters: 0,
    serviceUsage: 0,
    taskType: TaskTypes.Time,
  },
  infoUsage: {
    averageDailyConsumption: 0,
    averageDailyConsumptionDays: 0,
    consumptionState: ConsumptionState.NORMAL,
    hasLowUsage: false,
    lowUsageDays: 0,
    totalUsage: 0,
  },
  infoInsights: {
    insightsAvailable: false,
    lastCleaning: notAvailable,
    status: ClassificationStatus.COMPLETED
  },
  infoTags: []
};

export const defaultHealthStatus: BeamHealthStatus = {
  batteryHealthy: true,
  connectivityHealthy: true,
  firmwareHealthy: true,
  hardwareHealthy: true,
  otaHealthy: true,
  hardwareGeneration: HardwareGenerations.GenThree,
  shouldBeamBeReplaced: false
};

type SetSelectedInstallationData = {
  installation: IDeviceView;
  installationHealthStatus: BeamHealthStatus;
}

export interface IGraphSettingsTime {
  dateSelection: Dayjs;
  timePeriodselection: PeriodSetting;
}

export interface IGraphSettingsAndDataModel {
  selectedInstallation: IDeviceView;
  selectedInstallationHealthStatus: BeamHealthStatus;

  setSelectedInstallationData: Action<IGraphSettingsAndDataModel, SetSelectedInstallationData>;
  setSelectedInstallation: Thunk<IGraphSettingsAndDataModel, IDeviceView, void, IStoreModel>;

  timePeriodOptions: { [value: number]: string };

  comparisonFilters: IDeviceView[];
  setComparisonFilters: Action<IGraphSettingsAndDataModel, IDeviceView[]>;

  graphTitle: string;
  setGraphTitle: Action<IGraphSettingsAndDataModel, string>;

  graphSettingsTime: IGraphSettingsTime;
  setGraphSettingsTime: Action<IGraphSettingsAndDataModel, IGraphSettingsTime>;

  barSize: number;
  setBarSize: Action<IGraphSettingsAndDataModel, number>;

  onGraphTimeSettingsChange: ThunkOn<IGraphSettingsAndDataModel, void, IStoreModel>;
}

const graphSettingsModel: IGraphSettingsAndDataModel = {
  selectedInstallation: defaultBaseFilter,
  selectedInstallationHealthStatus: defaultHealthStatus,

  timePeriodOptions: periodSettingOptions,

  setSelectedInstallationData: action((state, payload) => {
    state.selectedInstallation = payload.installation;
    state.selectedInstallationHealthStatus = payload.installationHealthStatus;
  }),

  setSelectedInstallation: thunk((actions, payload, { getStoreState }) => {
    const firmwareReleases = getStoreState().firmwareReleasesModel.firmwareReleases;

    const installationData: SetSelectedInstallationData = {
      installation: payload,
      installationHealthStatus: defaultHealthStatus,
    }

    if (payload.infoBasic.id !== 0) {
      installationData.installationHealthStatus = getBeamHealthStatus({
        apn: payload.infoDeviceMetrics.apn,
        batteryTypeId: payload.infoDeviceMetrics.batteryTypeId,
        firmware: payload.infoDeviceMetrics.firmware,
        firmwareReleases,
        hardware: payload.infoDeviceMetrics.hardware
      })
    }

    actions.setSelectedInstallationData(installationData);
  }),

  comparisonFilters: [],
  setComparisonFilters: action((state, payload) => {
    state.comparisonFilters = payload;
  }),

  graphSettingsTime: defaultGraphSettings,
  setGraphSettingsTime: action((state, payload) => {
    state.graphSettingsTime = payload;
  }),

  barSize: 13,
  setBarSize: action((state, payload) => {
    state.barSize = payload;
  }),

  graphTitle: '',
  setGraphTitle: action((state, payload) => {
    state.graphTitle = payload;
  }),

  onGraphTimeSettingsChange: thunkOn(
    (actions, storeActions) => storeActions.graphSettingsModel.setGraphSettingsTime,
    async (actions, target, { getStoreState, getStoreActions }) => {
      const selectedInstallation = getStoreState().graphSettingsModel.selectedInstallation;
      if (selectedInstallation.infoBasic.id === 0) {
        return;
      }

      const { dateSelection, timePeriodselection } = target.payload;

      let barSize = 13;
      switch (timePeriodselection) {
        case PeriodSetting.Two:
          barSize = 7;
          break;
        case PeriodSetting.Three:
          barSize = 13;
          break;
        case PeriodSetting.Four:
          barSize = 5;
          break;
        case PeriodSetting.Five:
          barSize = 10;
          break;
        default:
          barSize = 13;
          break;
      }

      actions.setBarSize(barSize);

      const hoursToSubtract = periodSettingsHours[timePeriodselection];
      const formatToUse = timePeriodselection === PeriodSetting.Five ? 'MMM YYYY' : dateFormatYMD;
      const timeToAdd = timePeriodselection < PeriodSetting.Five ? 1 : 7;

      const dateFirst = getDateObject(dateSelection)
        .subtract(hoursToSubtract, 'hours')
        .add(timeToAdd, 'day')
        .format(formatToUse);
      const dateLast = getDateObject(dateSelection).format(formatToUse);
      const newGraphTitle = timePeriodselection === PeriodSetting.Two ? dateLast : dateFirst + ' to ' + dateLast;
      actions.setGraphTitle(newGraphTitle);

      getStoreActions().deviceDataModel.retrievePeriodData();
    },
  ),
};

export default graphSettingsModel;
