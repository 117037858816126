import { DeviceStatus, getDays } from '@flowplan/flowplan-shared';
import { ConsumptionState } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';

export interface IStatusInformation {
  averageDailyConsumption: number;
  averageDailyConsumptionDays: string;
  deviceStatus: DeviceStatus;
  hoursNotSeen: number;
  consumptionState: ConsumptionState;
  lastSeen: string;
  totalUsage: number;
}

const collectStatusInformation = (sensor: IDeviceView): IStatusInformation => {
  const { deviceStatus, hoursNotSeen, lastSeen } = sensor.infoDeviceMetrics;
  const { totalUsage, averageDailyConsumption, averageDailyConsumptionDays, consumptionState } = sensor.infoUsage;

  const averageDailyConsumptionDaysInfo = getDays(averageDailyConsumptionDays ? averageDailyConsumptionDays : 0);

  return {
    averageDailyConsumption,
    averageDailyConsumptionDays: averageDailyConsumptionDaysInfo,
    consumptionState,
    deviceStatus,
    hoursNotSeen,
    lastSeen,
    totalUsage,
  };
};

export { collectStatusInformation };
