import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { useEffect, useState } from 'react';

export const getPasswordLength = (role: RoleType | undefined): number => {
  switch (role) {
    case RoleType.SUPER_ADMIN:
      return 40;
    case RoleType.ADMIN:
      return 10;
    case RoleType.TECHNICIAN:
      return 10;
    case RoleType.EXTERNAL_TECHNICIAN:
      return 10;
    case RoleType.GUEST:
      return 10;
    default:
      return 10;
  }
};

interface IUsePasswordValidation {
  currentPassword: string;
  firstPassword: string;
  secondPassword: string;
  role: RoleType | undefined;
  numberValidation: boolean;
  lowercaseValidation: boolean;
  uppercaseValidation: boolean;
  specialcharacterValidation: boolean;
  lengthValidation: boolean;
}

export const usePasswordValidation = ({
  currentPassword,
  firstPassword,
  secondPassword,
  role,
  numberValidation,
  lowercaseValidation,
  uppercaseValidation,
  specialcharacterValidation,
  lengthValidation,
}: IUsePasswordValidation): readonly [boolean, boolean, boolean, boolean, boolean, boolean, boolean] => {
  const [validLength, setValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [match, setMatch] = useState(false);
  const [different, setDifferent] = useState(false);

  const requiredLength = getPasswordLength(role);

  useEffect(() => {
    setDifferent(currentPassword !== firstPassword && !firstPassword.includes(currentPassword));
    if (lengthValidation)
      setValidLength(firstPassword.length >= requiredLength);

    if (uppercaseValidation)
      setUpperCase(firstPassword.toLowerCase() !== firstPassword);

    if (lowercaseValidation)
      setLowerCase(firstPassword.toUpperCase() !== firstPassword);

    if (numberValidation)
      setHasNumber(/\d/.test(firstPassword));

    if (specialcharacterValidation)
      setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};:"\\|,.<>?~]/.test(firstPassword));

    if (!specialcharacterValidation)
      setSpecialChar(true);

    setMatch(firstPassword.length !== 0 && firstPassword === secondPassword);
  }, [
    currentPassword,
    firstPassword,
    secondPassword,
    requiredLength,
    lengthValidation,
    uppercaseValidation,
    lowercaseValidation,
    numberValidation,
    specialcharacterValidation,
  ]);

  return [validLength, hasNumber, upperCase, lowerCase, match, specialChar, different] as const;
};
