import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

type Props = {
  value: Dayjs | null;
  label: string;
  size: 'small' | 'medium';
  disableFuture: boolean;
  fullWidth?: boolean;
  onChange: (value: Dayjs | null) => void;
  minDate?: Dayjs;
  error?: boolean;
  helperText?: string | false | undefined;
};

const FpDatePicker = ({
  disableFuture,
  label,
  onChange,
  value,
  minDate,
  error,
  helperText,
  size,
  fullWidth = true,
}: Props) => {
  return (
    <DatePicker
      disableFuture={disableFuture}
      showDaysOutsideCurrentMonth
      label={label}
      minDate={minDate}
      format={dateFormatYMD}
      value={value}
      onChange={onChange}
      slotProps={{
        textField: {
          size,
          fullWidth,
          error,
          helperText,
        },
      }}
    />
  );
};

FpDatePicker.displayName = 'DatePicker';

export default FpDatePicker;
