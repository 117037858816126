import React from 'react';
import ComponentRow from '../../../../../../Components/Containers/ComponentRow';
import TextInput from '../../../../../../Components/Input/TextInput';
import { useStoreActions, useStoreState } from '../../../../../../Store';
import { checkNumbersOnlyRestriction } from '../../../../../../Utility/Texts';
import { DeviceCategories } from '../../../../../../common/constsCategoryAndTypes';
import { InputKeys } from '../../../../../../common/constsFrontend';
const MaintenanceCycles = (): JSX.Element => {
  const selectedCategory = useStoreState((state) => state.newInstallationModel.selectedCategory);
  const maintenanceCycles = useStoreState((state) => state.newInstallationModel.inputMaintenanceAtCycles);
  const setMaintenanceCycles = useStoreActions((actions) => actions.newInstallationModel.setInputMaintenanceAtCycles);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newCyclesOfCoffee = checkNumbersOnlyRestriction(event) ? value : Number(maintenanceCycles);
    if (selectedCategory === DeviceCategories.Coffee) {
      setMaintenanceCycles(newCyclesOfCoffee);
    }
  };
  return (
    <ComponentRow>
      <TextInput
        inputValue={maintenanceCycles}
        inputKey={InputKeys.ServiceCycleMaintenance}
        inputText="Number of cycles between maintenance"
        handleInput={handleChange}
        onlyNumbers={true}
        isRequired={true}
      />
    </ComponentRow>
  );
};
export default MaintenanceCycles;
