import { blue, deepPurple, green, orange, red, teal } from '@mui/material/colors';
import _ from 'lodash';

export const graphColors: string[] = _.concat(
  _.dropRight(_.drop(_.values(blue), 4), 4),
  _.dropRight(_.drop(_.values(orange), 4), 4),
  _.dropRight(_.drop(_.values(green), 4), 4),
  _.dropRight(_.drop(_.values(red), 4), 4),
  _.dropRight(_.drop(_.values(teal), 4), 4),
  _.dropRight(_.drop(_.values(deepPurple), 4), 4),
);
