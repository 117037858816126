import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { FirmwareRelease, FirmwareStatus } from '@flowplan/flowplan-shared/lib/firmware.releases/firmware.releases';
import { Action, Thunk, action, thunk } from 'easy-peasy';
import { IStoreModel } from '../../Models';
import { IDropdownData } from '../../common/interfacesFrontend';

export const descDevelopment =
  'This is a development version of the firmware, intended for limited release or testing.';
export const descNA = 'We have not received firmware information from the Beam.';

export interface FirmwareReleasesModel {
  supportedFirmwares: string[];
  firmwareReleases: FirmwareRelease[];
  firmwareReleasesDropdown: IDropdownData[];
  setFirmwareReleases: Action<FirmwareReleasesModel, FirmwareRelease[]>;
  retrieveFirmwareReleases: Thunk<FirmwareReleasesModel, void, void, IStoreModel>;
}

const firmwareReleasesModel: FirmwareReleasesModel = {
  supportedFirmwares: [],
  firmwareReleases: [],
  firmwareReleasesDropdown: [],

  retrieveFirmwareReleases: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.versionHistoryFirmware });
    if (requestResponse.success) {
      actions.setFirmwareReleases(requestResponse.data);
    }

    return requestResponse;
  }),

  setFirmwareReleases: action((state, payload) => {
    state.firmwareReleases = payload;
    const firmwareReleasesDropdown: IDropdownData[] = payload.map((item, index) => {
      return {
        key: index,
        text: item.firmware,
        value: item.firmware,
      };
    });
    state.firmwareReleasesDropdown = firmwareReleasesDropdown;
    state.supportedFirmwares = payload.filter((item) => item.statusGenThree === FirmwareStatus.Supported || item.statusGenFour === FirmwareStatus.Supported).map((item) => item.firmware);
  }),
};

export default firmwareReleasesModel;
