import { capacityText } from '@flowplan/flowplan-shared';
import { Alert, Box } from '@mui/material';
import { ChangeEvent } from 'react';
import { InputKeys } from '../../../../../../common/constsFrontend';
import FpText from '../../../../../common/components/FpText/FpText';
import FpTextField from '../../../../../common/components/FpTextField/FpTextField';

type Props = {
  bypassSetting: string | number;
  properCapacity: number | string;
  disabled: boolean;
  hardness: number | string;
  onSelection(value: number | string): void;
  value: number | string;
};

const FlowplanCapacityAndSettingsSelection = ({
  bypassSetting,
  disabled,
  hardness,
  onSelection,
  properCapacity,
  value,
}: Props): JSX.Element => {
  const showRecommendedCapacity = !(
    properCapacity === '' ||
    properCapacity === 0 ||
    properCapacity === '' ||
    Number(value) === Number(properCapacity)
  );
  return (
    <>
      <Box style={{ display: 'flex', flexDirection: 'row', gap: '1rem', flexWrap: 'wrap' }}>
        <FpTextField
          disabled={true}
          sx={{
            flex: '2',
          }}
          inputValue={bypassSetting.toString()}
          inputKey={InputKeys.Capacity}
          label="Bypass recommendation:"
          handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
            // nothing here
          }}
          isRequired={false}
          variant={'outlined'}
        />
        <FpTextField
          disabled={disabled}
          sx={{
            flex: '3',
          }}
          inputValue={value}
          inputKey={InputKeys.Capacity}
          inputText={capacityText}
          onlyNumbers={false}
          handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
            const value = ev.target.value;
            onSelection(value);
          }}
          isRequired={true}
          variant={'outlined'}
        />
      </Box>
      {showRecommendedCapacity && (
        <Alert severity="warning">
          <FpText variant="body1">
            Recommended capacity (@ {hardness} ):
            <span style={{ fontWeight: '600' }}> {properCapacity}L</span>
          </FpText>
        </Alert>
      )}
    </>
  );
};

export default FlowplanCapacityAndSettingsSelection;
