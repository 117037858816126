import TextAreaInput from '../../../../Components/Input/TextAreaInput';
import { useStoreActions, useStoreState } from '../../../../Store';
import { InputKeys } from '../../../../common/constsFrontend';

const FlowplanDescriptionSelection = (): JSX.Element => {
  const setInputShortDesc = useStoreActions((actions) => actions.newInstallationModel.setInputShortDesc);
  const inputShortDesc = useStoreState((state) => state.newInstallationModel.inputShortDesc);

  return (
    <TextAreaInput
      value={inputShortDesc}
      inputKey={InputKeys.ShortDesc}
      maxLength={200}
      height="6rem"
      inputText="Short description"
      onlyNumbers={false}
      isRequired={false}
      onChange={(e) => {
        const value = e.currentTarget.value;
        setInputShortDesc(value);
      }}
    />
  );
};

export default FlowplanDescriptionSelection;
