import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { Fragment } from 'react';
import { DataGridCellWidth } from '../../../../../common/constsFrontend';
import { IDeviceDataFormatted } from '../../../../../common/interfacesData';
import PopupWithIcon from '../../../../../Components/Popup/PopupWithIcon';
import { useStoreState } from '../../../../../Store';
import { DataGridTable } from '../../../../common/components';
import { flowEventTypeInformation, getReadingTypeFromMeasurementUnit } from './dataoverview-item-eventype-util';
import Styles from './DataOverviewItem.module.scss';
import DataDetailsItemEventType from './DataOverviewItemEventType';

const DataOverview = (): JSX.Element => {
  const selectedInstallation = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const showDataList = useStoreState((state) => state.deviceDataModel.showDataList);
  const dataList = useStoreState((state) => state.deviceDataModel.dataList);
  const loadingDataList = useStoreState((state) => state.deviceDataModel.loadingDataList);

  if (!showDataList) {
    return <Fragment />;
  }

  const columnsConfig: GridColDef[] = [
    {
      field: 'timestamp',
      renderHeader: () => {
        return (
          <p className={Styles.dataDetailsName}>
            <b>Timestamp</b>
          </p>
        );
      },
      flex: 3,
      minWidth: DataGridCellWidth.medium,
      headerAlign: 'left',
      align: 'left',
      cellClassName: Styles.dataDetailsValue,
      valueGetter: ({ row }: GridValueGetterParams<IDeviceDataFormatted>) => {
        return row.timeStamp;
      },
    },
    {
      field: 'eventType',
      renderHeader: () => {
        return (
          <p className={Styles.dataDetailsName}>
            <b>Event type</b>
          </p>
        );
      },
      headerName: 'Flow Event Type',
      flex: 2,
      minWidth: DataGridCellWidth.medium,
      headerAlign: 'left',
      align: 'left',
      cellClassName: Styles.dataDetailsValue,
      valueGetter: ({ row }: GridValueGetterParams<IDeviceDataFormatted>) => {
        return flowEventTypeInformation(row.flowEventType, row.type).flowEventName;
      },
      renderCell: ({ row }: GridRenderCellParams<IDeviceDataFormatted>) => {
        return <DataDetailsItemEventType flowEventType={row.flowEventType} type={row.type} />;
      },
    },
    {
      field: 'volume',
      renderHeader: () => {
        return (
          <p className={Styles.dataDetailsName}>
            <b>Volume </b>
            <span>[ml]</span>
          </p>
        );
      },
      headerName: 'Volume [ml]',
      flex: 2,
      minWidth: DataGridCellWidth.medium,
      headerAlign: 'left',
      align: 'left',
      cellClassName: Styles.dataDetailsValue,
      valueGetter: ({ row }: GridValueGetterParams<IDeviceDataFormatted>) => {
        return row.milliLiters;
      },
      renderCell: ({ row }: GridRenderCellParams<IDeviceDataFormatted>) => {
        return (
          <>
            <div>{row.milliLiters}</div>
            {row.isMicroMovements && (
              <div className={Styles.popupInfoContainer}>
                <PopupWithIcon position="right center">
                  <p>
                    This payload is an accumulation of several smaller payloads. This is completely normal and is
                    usually due to very small amounts of water drippings or vibrations in the water supply.
                  </p>
                </PopupWithIcon>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: 'flowtime',
      renderHeader: () => {
        return (
          <p className={Styles.dataDetailsName}>
            <b>Flowtime </b>
            <span>[s]</span>
          </p>
        );
      },
      headerName: 'Flowtime (seconds)',
      flex: 2,
      minWidth: DataGridCellWidth.medium,
      headerAlign: 'left',
      align: 'left',
      cellClassName: Styles.dataDetailsValue,
      valueGetter: (params: GridValueGetterParams<IDeviceDataFormatted>) => {
        return params.row.flowTime;
      },
    },
    {
      field: 'flowrate',
      renderHeader: () => {
        return (
          <p className={Styles.dataDetailsName}>
            <b>Flowrate </b>
            <span>[L/min]</span>
          </p>
        );
      },
      headerName: 'Flowrate (L/min)',
      flex: 2,
      minWidth: DataGridCellWidth.medium,
      headerAlign: 'left',
      align: 'left',
      cellClassName: Styles.dataDetailsValue,
      valueGetter: (params: GridValueGetterParams<IDeviceDataFormatted>) => {
        return params.row.flowRate;
      },
    },
    {
      field: 'extraReading',
      headerName: getReadingTypeFromMeasurementUnit(selectedInstallation.infoExternalSensorData.measurementUnit),
      renderHeader: () => {
        const { measurementUnit } = selectedInstallation.infoExternalSensorData;
        return (
          <p className={Styles.dataDetailsName}>
            <b>{getReadingTypeFromMeasurementUnit(measurementUnit)} </b>
            <span>[{measurementUnit}]</span>
          </p>
        );
      },
      flex: 3,
      minWidth: 115,
      headerAlign: 'left',
      align: 'left',
      cellClassName: Styles.dataDetailsValue,
      valueGetter: ({ row }: GridValueGetterParams<IDeviceDataFormatted>) => {
        return row.externalReading;
      },
    },
  ];

  return (
    <Fragment>
      <DataGridTable
        loading={loadingDataList}
        rows={dataList}
        columns={columnsConfig}
        columnVisibilityModel={{
          extraReading: selectedInstallation.infoExternalSensorData.matchingEnabled,
        }}
        showColumn={false}
        showQuickFilter={false}
        addButtonShow={false}
        fillScreenHeight={false}
      />
    </Fragment>
  );
};

export default DataOverview;
